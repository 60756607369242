.search-suggestions {
    background: $white;
    @include position(absolute, 0 0 null 0);

    &-product-grid {
        padding-left: 0;
        padding-right: 0;

        &-row {
            margin-left: -4px;
            margin-right: -4px;

            &:not(.slick-initialized) {
                .search-suggestions-product-tile {
                    @include breakpoint(medium) {
                        max-width: 33.3%;
                    }
                }
            }
        }

        .slick-list {
            // Create offset to show edge of next
            padding-right: $megamenu-padding-x !important; // Important to override auto generated inline style
            margin-right: -$megamenu-padding-x;
            width: calc(100% + #{$megamenu-padding-x});
        }
    }

    &-product-tile {
        @include breakpoint(medium) {
            flex-grow: 1;
        }
    }
}

.search-suggestion-wrapper {
    @extend %clearfix;
    width: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    @include breakpoint(large) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .search-suggestions-product-grid {
        max-width: 100%;
    }

    .hitgroup {
        flex-shrink: 0;
        width: 260px;

        .header {
            margin: 0 0 8px 0;
            height: auto;
            @include font-source($f-body, $f-size, null, 24px, $f-weight-bold);
            text-transform: none;
            color: $c-gray;
            position: relative;
        }

        .hit {
            display: block;
            font-weight: $f-weight-medium;
            line-height: 1.43;
            margin-bottom: 5px;

            &:hover {
                text-decoration: underline;
            }
        }

        .parent-category {
            position: relative;
            padding-right: 16px;
            margin-right: 5px;

            &:after {
                @include position(absolute, 50% 0 null null);
                transform: translateY(-50%) rotate(180deg) scale(0.6);
                @include svg-view-nav-left;
            }
        }
    }

    a {
        color: $black;
    }
}

.phrase-suggestions {
    display: flex;
        flex-wrap: wrap;
        gap: 25px;
    margin-bottom: 9px;
    width: 100%;

    @include breakpoint(large) {
        gap: 34px;
        margin-bottom: 0;
    }
}

.suggestions-recommended-products {
    width: 100%;
}

.search-phrase {

    .completed {
        .original {
            text-decoration: underline;
            font-weight: $f-weight-bold;
        }
    }

    .corrected {
        font-weight: $f-weight-bold;
    }
}
