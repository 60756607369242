#main .dropdown {
    position: relative;

    &.is-open {
        .dropdown-toggle svg {
            transform: rotate(90deg);
        }
    }

    .dropdown-menu {
        color: $white;
        padding-top: 12px;
        position: absolute;
            top: 100%;
            z-index: 1;
        width: max-content;

        @at-root :where(#{&}) { // Use a weaker selector for base styles so they can more easily be customized, since this is otherwise a highly specific selector
            left: -6px;
        }

        &:not(.is-open) {
            display: none;
        }

        &-inner {
            background-color: $c-black;

            @at-root :where(#{&}) {
                &::before { // Use a weaker selector for base styles so they can more easily be customized, since this is otherwise a highly specific selector
                    left: 10px;
                }
            }

            &::before {
                border-bottom: 8px solid $c-black;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                content: '';
                position: absolute;
                    top: 4px;
            }
        }

        ul,
        ol {
            display: flex;
                flex-direction: column;
            list-style: none;
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 0;
        }

        li {
            font-family: $f-body;
            font-size: 10px;
            letter-spacing: 1px;
            line-height: 1.4;
            padding: 13px 38px 12px;
            text-align: center;
            text-transform: uppercase;

            &:not(:last-child) {
                border-bottom: 1px solid $white;
            }
        }

        a {
            color: inherit;
        }
    }

    &--right {
        .dropdown-menu {
            left: initial;
            right: -6px;

            &-inner {
                background-color: $c-black;
    
                &::before {
                    position: absolute;
                        left: initial;
                        right: 10px;
                }
            }
        }
    }
}