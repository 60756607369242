.s-preorder-dialog {
    margin: 27px;
    max-width: 580px;
    padding: 60px 20px 38px;
    text-align: center;

    * {
        box-sizing: border-box;
    }

    @include breakpoint(small) {
        padding: 60px;
    }

    .ui-widget-header {
        position: absolute;
            top: 0;
            right: 0;
            left: 0;
    }

    .ui-dialog-titlebar-close {
        right: 8px;
        top: 8px;

        &,
        &:hover:not(.disabled):not(:disabled) {
            background-color: transparent;
        }
    }
}

.c-preorder {
    display: none;

    &__modal {

    }

    &__content {
        color: $c-charcoal;
        font-size: $f-size-xs;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;

        h2, h3 {
            font-family: $f-header;
            font-size: $f-header-h2-size;
            font-weight: bold;
            line-height: 1.13;
            letter-spacing: 0.5px;
            color: $black;
        }
    }

    &__checkbox {
        display: inline-flex;
            align-items: flex-start;
        margin-bottom: 30px;

        &__input {
            margin-top: 10px;
            margin-left: 12px;
        }

        &__label {
            color: $c-charcoal;
            font-size: $f-size-xs;
            font-weight: normal;
            letter-spacing: normal;
            margin-left: 20px;
            text-transform: none;
        }
    }

    &__submit {
        color: $white;
        background-color: $black;
        max-width: 384px;
        width: 100%;

        &:hover:not(.disabled):not(:disabled) {
            color: $black;
            background: $white;
        }

        &[disabled] {
            &,
            &:hover {
                background-color: rgba($black, 0.35);
                border-color: rgba($black, 0.35);
                color: $white;
            }
        }
    }
}
