.pt_error,
.pt_product-search-noresult {

    &#wrapper {
        background-color: $c-mostly-white;
    }

    .breadcrumb {
        margin-top: 61px;
        margin-bottom: 10px;

        .breadcrumb-element:nth-child(n+2) {
            color: $c-gray;
            font-weight: $f-weight-bold;
        }

        @include breakpoint(medium) {
            margin-bottom: 0;
            margin-top: 75px;
        }
    }

    .error-page-search {
        .form-row {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        .field-wrapper {
            margin-bottom: 0;
            margin-right: 8px;
            flex-basis: 100%;

            @include breakpoint(large) {
                margin-right: 16px;
            }
        }

        button {
            height: 34px;
        }
    }
}
