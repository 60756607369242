.setlist-wrapper {

    ul {
        list-style: none;
        padding: 0;
        margin-top: 8px;

        &:first-child {
            margin-top: 3px;
        }

        li {
            border-bottom: 1px solid $c-light-gray;
            padding: 8px 17px;
            position: relative;
        }

        .setNum {
            color: $c-gray;
            font-weight: $f-weight-bold;
            @include position(absolute, null null auto 16px);
            font-size: $f-size-xs;

            @include breakpoint(large) {
                font-size: $f-size;
            }
        }

        .songName {
            color: $black;
            font-weight: $f-weight-bold;
            margin-left: 29px;
            font-size: $f-size-xs;

            @include breakpoint(large) {
                font-size: $f-size;
            }
        }
        
        .songDescription{
            font-size: $f-size-xxs;
		    font-weight: $f-weight-light;
		    margin: 0 0 0 29px;
        	color: $black;

            @include breakpoint(large) {
                font-size: $f-size-xs;
            }
        }
    }

    .play-audio-clip {
        margin-left: 27px;
        margin-bottom: 2px;
    }
}
