.pt_storefront, .pt_content_empty {
    overflow: hidden;

    .s-banner-content {
        h2 {
            @include breakpoint(medium) {
                font-size: 48px;
            }
        }
    }
    /////////////////////
    ///HOME HERO BLOCK///
    ////////////////////

    .c-amp-banner-carousel {
        .slick-arrow {
            background: none;

            &.slick-disabled {
                opacity: 0.5;
            }

            &:hover {
                background: none;
            }

            &.slick-next {
                right: calc(((100vw - 100%) / -2) + 100px);
            }

            &.slick-prev {
                left: calc(((100vw - 100%) / -2) + 100px);
            }
        }

        .slick-prev:before,
        .slick-next:before {
            background: none;
            content: '';
            height: 14px;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
            width: 14px;
        }

        .slick-next:before {
            border-right: 3px solid $white;
            border-top: 3px solid $white;
            right: 0;
        }

        .slick-prev:before {
            border-bottom: 3px solid $white;
            border-left: 3px solid $white;
            left: 0;
        }

        &.is-dark {
            .slick-next::before {
                border-right-color: $c-black;
                border-top-color: $c-black;
            }

            .slick-prev::before {
                border-bottom-color: $c-black;
                border-left-color: $c-black;
            }
        }
    }

    .hero-grid {
        margin-bottom: 0;
    }

    .hero-grid > .row {
        margin-bottom: 0;
    }

    .hero-col {
        position: relative;

        .homepage-hero {

            img {
                height: 100vh;
                object-fit: cover;
                max-height: 420px;

                @include breakpoint(medium) {
                    max-height: 394px;
                }

                @include breakpoint(large) {
                    max-height: none;
                }
            }

            .hero-show-info {
                @include position(absolute, null null 2px 24px);
                color: $white;
                display: flex;
                flex-direction: column;
                line-height: 20px;
                z-index: 1;
                height: 72px;
                width: 109px;

                @include breakpoint(medium) {
                    @include position(absolute, null null 2px 38px);
                }

                @include breakpoint(large) {
                    @include position(absolute, 50% null null 52px);
                    padding-top: 10px;
                }

                @include breakpoint(xlarge) {
                    @include position(absolute, 50% null null 62px);
                }

                a {
                    color: $white;
                    background: transparent;
                }

                &:hover {
                    @include breakpoint(medium) {
                        @include position(absolute, null null 2px 0);
                        background: rgba(0, 0, 0, 0.25);
                        padding: 10px 10px 0 38px;
                        height: 72px;
                        width: 109px;
                    }

                    @include breakpoint(large) {
                        padding: 10px 10px 0 52px;
                    }

                    @include breakpoint(xlarge) {
                        padding: 10px 10px 0 62px;
                    }
                }
            }

            .hero-text {
                @include position(absolute, 50% 0 null null);
                background: rgba(0, 0, 0, 0.25);
                padding: 8px 18px 8px 23px;

                @include breakpoint(medium) {
                    padding: 9px 45px 9px 24px;
                }

                @include breakpoint(large) {
                    padding: 19px 60px 0 32px;
                    @include position(absolute, 40% 0 null null);
                }

                @include breakpoint(xlarge) {
                    padding: 19px 65px 0 32px;
                    @include position(absolute, 45% 0 null null);
                }

                h4 {
                    color: $white;

                    @include breakpoint(medium) {
                        font-size: 40px;
                        line-height: 40px;
                        max-width: 240px;
                    }

                    @include breakpoint(xlarge) {
                        max-width: none;
                    }
                }

                p {
                    display: none;

                    @include breakpoint(large) {
                        display: block;
                        color: $white;
                        max-width: 338px;
                        margin-top: 0;
                    }

                    @include breakpoint(xlarge) {
                        max-width: 440px;
                    }
                }
            }

            .hero-upcoming {
                display: none;

                @include breakpoint(large) {
                    @include position(absolute, null null -8px 52px);
                    color: $white;
                    display: flex;
                    z-index: 1;

                    p {
                        margin-right: 17px;
                        line-height: 27px;
                    }

                    ul {
                        display: flex;
                        align-items: center;

                        li {
                            padding: 0 16px;
                            position: relative;
                            list-style: none;

                            &:first-child {
                                padding-left: 0;

                                &:hover {
                                    .event-tooltip {
                                        @include position(absolute, null null 20px -9px);
                                    }
                                }
                            }

                            a {
                                color: $white;
                            }

                            &:hover {
                                .event-tooltip {
                                    display: block;
                                    @include position(absolute, null null 20px 7px);
                                    background: rgba(0, 0, 0, 0.25);
                                    padding: 10px 10px 0 10px;
                                    min-width: 136px;

                                    a {
                                        color: $white;
                                        background: transparent;

                                        span {
                                            padding: 0 0 0 15px;
                                        }
                                    }

                                    small {
                                        line-height: 20px;
                                        display: block;
                                    }

                                    .source {
                                        display: block;
                                    }
                                }
                            }

                            .event-tooltip {
                                display: none;
                            }
                        }
                    }
                }

                @include breakpoint(xlarge) {
                    @include position(absolute, null null -5px 62px);
                }
            }
        }

        .social-links {
            display: none;

            @include breakpoint(medium) {
                display: block;
                @include position(absolute, null 31px -9px null);
                z-index: 1;
            }

            @include breakpoint(xlarge) {
                @include position(absolute, null 57px -7px null);
            }

            span {
                display: none;
            }

            ul {

                li {
                    display: inline-block;
                    list-style: none;
                    padding: 0 10px;

                    a {
                        display: block;
                    }

                    .facebook-white {
                        &:before {
                            @include svg-view-facebook-follow-white;
                        }
                    }

                    .twitter-white {
                        &:before {
                            @include svg-view-twitter-follow-white;
                        }
                    }

                    .instagram-white {
                        &:before {
                            @include svg-view-instagram-follow-white;
                        }
                    }

                    .snapchat-white {
                        &:before {
                            @include svg-view-snapchat-follow-white;
                        }
                    }

                    .youtube-white {
                        &:before {
                            @include svg-view-youtube-follow-white;
                        }
                    }

                    .apple-white {
                        &:before {
                            @include svg-view-apple-follow-white;
                        }
                    }

                    .spotify-white {
                        &:before {
                            @include svg-view-spotify-follow-white;
                        }
                    }

                    .soundcloud-white {
                        &:before {
                            @include svg-view-soundcloud-follow-white;
                        }
                    }
                }
            }
        }
    }


    ///////////////////////////////
    ///HOME UPCOMING TOURS BLOCK///
    ///////////////////////////////

    .upcoming-grid {
        background-size: cover;

        @include breakpoint(large) {
            background-position: right;
        }

        @include breakpoint(xlarge) {
            background-position: right 20px;
        }

        @media (min-width: 1300px) {
            background-position: right 20px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .upcoming-col {
        position: relative;

        .header {
            @include position(absolute, 44px null null 7px);

            @include breakpoint(medium) {
                @include position(absolute, 56px null null 13px);
            }

            @include breakpoint(large) {
                @include position(absolute, 64px null null 16px);
            }

            @include breakpoint(xlarge) {
                @include position(absolute, 72px null null 21px);
            }

            .name {
                font-size: 32px;
                line-height: 40px;
                text-transform: uppercase;
                font-weight: $f-weight-bold;
                display: block;

                @include breakpoint(medium) {
                    display: inline-block;
                    margin-right: 3px;
                }
            }

            .year {
                font-size: 24px;
                line-height: 25px;
                text-transform: uppercase;
                font-weight: $f-weight-bold;
                display: block;

                @include breakpoint(medium) {
                    display: inline-block;
                }
            }
        }

        .ghost-year {
            @include position(absolute, 114px null null -10px);
            font-family: $f-header;
            color: $white;
            opacity: 0.35;
            font-size: 72px;
            line-height: 80px;

            @include breakpoint(medium) {
                @include position(absolute, 112px null null -10px);
            }

            @include breakpoint(large) {
                @include position(absolute, 119px null null -18px);
            }

            @include breakpoint(xlarge) {
                @include position(absolute, 127px null null -20px);
            }
        }

        .view-all {
            @include position(absolute, null null 51px 50%);
            transform: translate(-50%, 0);
            width: 148px;
            padding: 6px 30px;

            @include breakpoint(small) {
                width: auto;
            }

            @include breakpoint(large) {
                @include position(absolute, null null 43px 50%);
            }
        }

        .shows-list {
            margin-top: 109px;
            margin-bottom: 116px;
            width: 100%;
            max-width: 708px;
            z-index: 1;
            position: relative;

            .show .show-date-venue .venue .venue-city p {
                white-space: nowrap;
            }

            @include breakpoint(medium) {
                margin-top: 94px;
                margin-bottom: 109px;
            }

            @include breakpoint(large) {
                margin-top: 95px;
                margin-bottom: 101px;
            }

            @include breakpoint(xlarge) {
                max-width: 784px;
            }


        }
    }


    /////////////////////////////
    ///HOME TOUR CALLOUT BLOCK///
    /////////////////////////////

    .callout-col {
        position: relative;
        margin-bottom: 11px;

        @include breakpoint(medium) {
            margin-bottom: 8px;
        }

        @include breakpoint(large) {
            margin-bottom: 4px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 0;
        }

        .home-tour-callout-image {

            img {
                height: 180px;
                object-fit: cover;

                @include breakpoint(medium) {
                    height: 296px;
                }

                @include breakpoint(large) {
                    height: 394px;
                }

                @include breakpoint(xlarge) {
                    height: 492px;
                }
            }
        }

        .home-tour-callout {
            width: 100%;
            text-align: center;
            margin-top: 20px;

            @include breakpoint(medium) {
                margin-top: 0;
                @include position(absolute, null null 40px 50%);
                transform: translate(-50%, 0);
            }

            @include breakpoint(large) {
                @include position(absolute, null null 43px 50%);
            }

            h4 {
                text-align: center;
                margin-bottom: 10px;

                @include breakpoint(medium) {
                    color: $white;
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 5px;
                }

                @include breakpoint(large) {
                    font-size: 40px;
                    line-height: 56px;
                    margin-bottom: 0;
                }
            }

            p {
                text-align: center;
                font-size: 14px;
                line-height: 24px;
                max-width: 310px;
                margin: 0 auto 16px auto;

                @include breakpoint(medium) {
                    color: $white;
                    font-size: 16px;
                    max-width: 480px;
                    margin: 0 auto 20px auto;
                }

                @include breakpoint(large) {
                    max-width: 430px;
                }

                @include breakpoint(xlarge) {
                    max-width: 590px;
                }
            }

            a {
                // border: $button-border;
                // color: $c-primary-button-f-color;
                @include primary-button;

                @include breakpoint(medium) {
                    border-color: rgba(255, 255, 255, 0.75);
                    background: rgba(0, 0, 0, 0.25);
                    color: #fff;
                }
            }
        }
    }


    ///////////////////////////////////////
    ///HOME CONTENT GRID BLOCK///
    ///////////////////////////////////////

    .content-grid {
        margin-bottom: 16px;

        @include breakpoint(medium) {
            margin-bottom: 19px;
        }

        @include breakpoint(large) {
            margin-bottom: 26px;
            margin-top: 35px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 18px;
        }

        .news-button {
            margin: 11px auto 0 auto;

            @include breakpoint(medium) {
                margin: 2px auto 0 auto;
            }

            @include breakpoint(large) {
                margin: 10px auto 0 auto;
            }

            @include breakpoint(xlarge) {
                margin: 3px auto 0 auto;
            }
        }
    }


    ///////////////////////////////////////
    ///HOME FIFTH MEMBER PROD GRID BLOCK///
    ///////////////////////////////////////

    .fifth-member-merch {
        background-color: $black;
        margin-top: 34px;

        .callout-col {
            * {
                color: $white;
            }

            a.button {
                @include hollow-button;
            }
        }

        .juicer-feed-wrapper {
            margin-bottom: 12px;

            @include breakpoint(medium) {
                margin-bottom: 22px;
            }

            @include breakpoint(large) {
                margin-bottom: 28px;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 36px;
            }

            h2 {
                color: $white;
                padding: 0 20px;
                margin: 23px 0 15px 0;

                @include breakpoint(large) {
                    margin-top: 29px;
                    margin-bottom: 28px;
                }
            }
        }

        .grid--container {
            margin-bottom: 0;
        }

        .fifth-member-content {
            text-align: center;
            padding-top: 3px;
            margin-bottom: 22px;

            @include breakpoint(medium) {
                padding-top: 8px;
            }

            @include breakpoint(large) {
                padding-top: 0;
                margin-bottom: 35px;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 28px;
            }

            img {
                width: 95px;

                @include breakpoint(large) {
                    width: 121px;
                    height: 144px;
                }

                @include breakpoint(xlarge) {
                    width: 208px;
                    height: 244px;
                }
            }

            h4 {
                color: $white;
                margin-top: 13px;

                @include breakpoint(medium) {
                    font-size: 32px;
                    line-height: 40px;
                    margin-top: 18px;
                }

                @include breakpoint(large) {
                    font-size: 40px;
                    line-height: 56px;
                    margin-top: 20px;
                }

                @include breakpoint(xlarge) {
                    margin-top: 10px;
                }

                span {
                    font-weight: $f-weight-bold;
                }
            }

            p {
                color: $white;
                font-size: 14px;
                line-height: 24px;
                max-width: 460px;
                margin: 8px auto 16px auto;

                @include breakpoint(medium) {
                    margin: 5px auto 20px auto;
                }

                @include breakpoint(large) {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0 auto 20px auto;
                    max-width: 520px;
                }
            }

        }

        .homepage-prod-grid-slider-count {
            text-align: center;
            position: relative;

            @include breakpoint(small) {
                margin-bottom: 14px;
            }

            @include breakpoint(medium) {
                margin-top: -8px;
                margin-bottom: 26px;
            }

            @include breakpoint(large) {
                display: none;
            }

            p {
                color: $white;
                text-transform: uppercase;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 2px;

                @include breakpoint(medium) {
                    margin-top: 0;
                }

                span {
                    font-weight: $f-weight-bold;
                    color: $white;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            button {
                background: transparent;

                &.slick-prev {
                    left: 14%;

                    @include breakpoint(small) {
                        left: 33%;
                    }

                    &:before {
                        @include svg-view-navigate-left;
                    }
                }

                &.slick-next {
                    right: 14%;

                    @include breakpoint(small) {
                        right: 33%;
                    }

                    &:before {
                        @include svg-view-navigate-right;
                    }
                }
            }
        }

        .product-slot {
            margin: 0 -18px 0 -18px;
            padding-top: 10px;

            @include breakpoint(medium) {
                padding-top: 34px;
            }

            @include breakpoint(large) {
                margin: initial;
            }
        }

        .grid.grid--no-gutters + .merch-slot .product-slot {
            padding-top: 0;
        }

        .search-result-items {
            padding: 0;
            margin: 16px 0;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }

            @include breakpoint(large) {
                @include grid-row;
                margin: -12px -6px 10px -6px !important;
            }

            .grid-tile {
                width: auto;
                margin: 0 3px 0 3px;

                @include breakpoint(medium) {
                    margin: 0 8px 0 8px;
                }

                @include breakpoint(large) {
                    width: 25%;
                    @include grid-col;
                    flex: none;
                    margin: 0;
                    padding: 11px 16px 0 16px !important;
                }

                .product-tile {

                    .gated-flag {
                        font-size: 12px;
                        margin-top: 15px;
                        margin-bottom: 4px;
                    }

                    .gated-flag + .product-name {

                        @include breakpoint(medium) {
                            margin-top: 0;
                        }

                        @include breakpoint(large) {
                            margin-top: -1px;
                        }
                    }
                }

                .product-name {
                    text-align: left;
                    margin-bottom: 0;

                    a {
                        font-family: $f-header;
                        color: $white;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                    }
                }

                .product-pricing {
                    text-align: left;

                    span {
                        color: $white;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: $f-weight-bold;
                    }

                    .product-standard-price {
                        color: $c-gray;
                    }

                    .product-standard-price + .product-sales-price {
                        color: $c-red;
                    }
                }
            }
        }

        .homepage-prod-grid-cta {
            text-align: center;
            padding-bottom: 46px;
            margin-top: 18px;

            @include breakpoint(medium) {
                padding-bottom: 41px;
            }

            @include breakpoint(large) {
                padding-bottom: 55px;
                margin-top: 10px;
            }
        }
    }

    ///////////////////////////
    ///HOME VIDEO GRID BLOCK///
    ///////////////////////////

    .video-block {
        margin-bottom: 47px;
        margin-top: 40px;

        .product-listing__header {
            h4 {
                a {
                    color: $c-gray;
                }
            }
        }

        @include breakpoint(large) {
            margin-bottom: 66px;
            margin-top: 50px;
        }

        .secondary-video-wrapper {
            margin-top: 0;
        }

        .home-video {
            position: relative;
            cursor: pointer;

            .show-name {
                @include position(absolute, null null -6px 50%);
                transform: translate(-50%, 0);
                color: $white;
                font-size: 14px;
                line-height: 24px;
                font-weight: $f-weight-bold;
                width: 100%;
                text-align: center;

                p {
                    padding: 0 20px;

                    @include breakpoint(large) {
                        text-align: center;
                        padding-left: 52px;
                        padding-bottom: 26px;
                    }
                }
            }

            &.large {

                &:before {

                    @include breakpoint(medium) {
                        @include svg-view-btn_youtube_mobile;
                        @include position(absolute, 50% null null 50%);
                        transform: translate(-50%, -50%);
                        cursor: pointer;

                        @include breakpoint(large) {
                            @include svg-view-btn_youtube;
                        }
                    }
                }

                & > .video-image {
                    display: none;

                    @include breakpoint(medium) {
                        display: block;
                    }
                }

                .video-container {

                    @include breakpoint(medium) {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        overflow: hidden;
                    }

                    .video-image:before {
                        @include svg-view-btn_youtube_mobile;
                        @include position(absolute, 50% null null 50%);
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                    }

                    iframe {
                        display: none;

                        @include breakpoint(medium) {
                            display: block;
                        }
                    }

                    & > a {

                        display: block;

                        @include breakpoint(medium) {
                            display: none;
                        }
                    }
                }
            }

            &.small {

                .video-image {

                    &:before {
                        @include svg-view-btn_youtube_mobile;
                        @include position(absolute, 50% null null 50%);
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                    }
                }

                &:last-child {
                    @include breakpoint(medium) {
                        display: none;
                    }

                    @include breakpoint(large) {
                        display: block;
                    }
                }

                .show-name p {

                    @include breakpoint(large) {
                        padding: 0 20px;
                        text-align: center;
                        max-width: none;
                    }
                }
            }
        }

        .video-container {
            position: relative;

            .badge-wrapper {
                position: absolute;
                top: 15px;
                left: 18px;
            }

            .exclusive-badge {
                font-family: $sans-serif;
                font-weight: $f-weight-bold;
                font-size: 10px;
                color: $c-red;
                letter-spacing: 0.83px;
                line-height: 16px;
                text-transform: uppercase;
                z-index: 2;
                @include breakpoint(large) {
                    color: $white;
                    text-align: left;
                    font-size: 12px;
                    letter-spacing: 1px;
                    line-height: 16px;
                    padding: 0;
                }
            }
        }

        .videos-button {
            margin: 31px auto 3px auto;

            @include breakpoint(large) {
                margin: 39px auto 0 auto;
            }
        }
    }


    ///////////////////////////
    ///HOME PHOTO GRID BLOCK///
    ///////////////////////////

    .photos-block {
        margin-bottom: 47px;

        @include breakpoint(medium) {
            padding-bottom: 51px;
            margin-bottom: 22px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 54px;
        }

        a {
            display: block;
        }

        .col {
            z-index: 1;
        }

        .homepage-photo-large, .homepage-photo-small {
            position: relative;
            margin-bottom: 4px;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }

            .show-date-venue {
                @include position(absolute, null null -6px 50%);
                transform: translate(-50%, 0);
                color: $white;
                font-size: 14px;
                line-height: 24px;
                font-weight: $f-weight-bold;
                width: 100%;
                text-align: center;

                p {
                    padding: 0 20px;
                }
            }
        }

        .photos-button {
            margin: 27px auto 0 auto;

            @include breakpoint(large) {
                margin: 35px auto 0 auto;
            }
        }
    }

    //////////////////////////
    ///HOME INSTAFEED BLOCK///
    //////////////////////////

    .instafeed {

        &>div.grid--container {
            @include breakpoint(xlarge) {
                margin-bottom: 26px;
            }
        }

        .product-listing__header {
            margin-top: 13px;
            margin-bottom: 14px;

            @include breakpoint(medium) {
                margin-bottom: 15px;
                margin-top: 0;
            }

            @include breakpoint(large) {
                margin-bottom: 29px;
            }

        }

        .instafeed-heading {

            @include breakpoint(medium) {
                padding-top: 22px;
            }

            @include breakpoint(large) {
                padding-top: 30px;
            }

            @include breakpoint(xlarge) {
                padding-top: 38px;
            }

            h2 {

                @include breakpoint(medium) {
                    font-size: 32px;
                    line-height: 40px;
                }

                @include breakpoint(large) {
                    font-size: 40px;
                    line-height: 48px;
                }

                span {
                    font-weight: $f-weight-bold;
                }
            }
        }

        .col.col--xs-12.text--xs-center {

            @include breakpoint(xlarge) {
                padding-top: 25px;
            }
        }
    }
}

/////////////////////////////////////
///HOME FIFTH MEMBER SO WHAT BLOCK///
/////////////////////////////////////

.so-what-block {
    background-color: $c-mostly-white;
}

.fifth-member-comm-block {
    position: relative;
    margin-bottom: 0;
    z-index: 0;
    padding-bottom: 45px;

    @include breakpoint(large) {
        padding-bottom: 68px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 31px;

        @include breakpoint(small) {
            flex-direction: row;
            margin-bottom: 15px;
        }

        @include breakpoint(large) {
            margin-top: 7px;
            margin-bottom: 9px;
        }

        @include breakpoint(xlarge) {
            margin-top: 0;
            margin-bottom: 0;
        }

        .logo-header {
            display: flex;
            padding-top: 20px;
            margin-bottom: 5px;

            @include breakpoint(medium) {
                padding-top: 2px;
            }

            img {
                max-width: 101px;
                align-self: baseline;
                margin-right: 23px;

                @include breakpoint(small) {
                    max-width: 204px;
                    margin-right: 30px;
                }

                @include breakpoint(large) {
                    max-width: 269px;
                }
            }

            h3.header-mobile {
                display: flex;
                align-self: center;
                max-width: 200px;

                @include breakpoint(small) {
                    display: none;
                }
            }
        }

        .info-mobile {
            display: flex;
            flex-direction: column;

            @include breakpoint(small) {
                display: none;
            }

            .description {
                margin-bottom: 16px;
                max-width: 324px;

                @include breakpoint(small) {
                    margin-top: -3px;
                    margin-bottom: 14px;
                }

                span {
                    position: static;
                    color: $black;
                    font-family: $sans-serif;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .ctas {
                a:first-child {
                    margin-right: 31px;
                }
            }
        }

        .info {
            display: none;

            @include breakpoint(small) {
                display: flex;
                flex-direction: column;
            }

            @include breakpoint(large) {
                padding-top: 100px;
                margin-left: 32px;
            }

            @include breakpoint(xlarge) {
                margin-left: 42px;
            }

            h3 {
                display: none;

                @include breakpoint(small) {
                    display: flex;
                }

                @include breakpoint(large) {
                    font-size: 40px;
                    line-height: 48px;
                }
            }

            .description {
                margin-bottom: 16px;
                max-width: 324px;

                @include breakpoint(large) {
                    max-width: 399px;
                    margin-top: 11px;
                    margin-bottom: 25px;
                }

                span {
                    position: static;
                    color: $black;
                    font-family: $sans-serif;
                    font-size: 14px;
                    line-height: 24px;

                    @include breakpoint(large) {
                        font-size: 16px;
                        line-height: 24px;
                        margin-top: 4px;
                        margin-bottom: 25px;
                    }
                }
            }

            .ctas {
                a:first-child {
                    margin-right: 31px;

                    @include breakpoint(large) {
                        margin-right: 39px;
                    }
                }
            }
        }
    }

    .counter {
        @include position(absolute, 42px -7px null null);
        font-size: 90px;
        color: $white;
        font-family: $f-header;
        z-index: -1;

        @include breakpoint(medium) {
            @include position(absolute, 80px -7px null null);
            font-size: 180px;
        }

        @include breakpoint(large) {
            @include position(absolute, -10px -27px null null);
            font-size: 300px;
            line-height: 300px;
        }
    }

    .content-tile {
        margin-bottom: 20px;

        &:nth-of-type(4) {
            display: none;

            @include breakpoint(large) {
                display: flex;
            }
        }

        .content-image {
            background: $white;
        }

        .content-info {
            background: $white;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            @include breakpoint(medium) {
                min-height: 167px;
            }

            @include breakpoint(large) {
                min-height: 215px;
            }

            @include breakpoint(xlarge) {
                min-height: 183px;
            }

            .content-title {
                margin: 20px 16px -2px 16px;

                @include breakpoint(large) {
                    margin: 20px 16px 4px 16px;
                }

                .content-title__date {
                    margin-bottom: 8px;

                    @include breakpoint(large) {
                        margin-bottom: 3px;
                    }
                }

                .content-title__name {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: $f-weight-medium;

                    @include breakpoint(large) {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }
            }
        }

        .content-body {
            margin: 0 16px 13px 16px;

            p {
                @include breakpoint(large) {
                    display: none;
                }
            }

            .button.button--see-more {
                display: block;
            }
        }
    }

    .so-what-text {
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: $c-gray;
    }

    .so-what-cta {
        text-align: center;
        padding-top: 12px;

        @include breakpoint(large) {
            padding-top: 20px;
        }
    }
}
