.ui-widget-overlay {
    background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $night-rider;
    opacity: 0.9;
}

.ui-dialog {
    background-color: $white;
    padding: 0;
    overflow: visible;
    max-width: 90%;

    @include breakpoint(small) {
        max-width: auto;
    }

    .ui-widget-header {
        padding: 0;
    }

    .ui-dialog-content {
        padding: $l-small-padding;

        @include breakpoint(medium) {
            padding: $l-medium-padding;
        }

        @include breakpoint(large) {
            padding: $l-large-padding;
        }

        @include breakpoint(xlarge) {
            padding: $l-xlarge-padding;
        }
    }

    .ui-dialog-title {
        color: $night-rider;
        font-weight: bold;
        display: none;
    }

    .ui-icon-closethick {
        @include svg-view-dialog-close;
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
    }

    .ui-dialog-titlebar-close {
        background-color: $dialog-close-button-bg-color;
        border: 0 none;
        border-radius: 0 0 0 0;
        height: $dialog-close-button-size;
        margin: 0;
        padding: 0;
        @include position(absolute, $dialog-close-button-position);
        width: $dialog-close-button-size;
        z-index: 9999;

        &:hover {
            cursor: pointer;
        }

        span {
            margin: 0;
            padding: 0;
        }
    }

    &.shipping-method-list-err {
        padding: 20px 10px;
        position: fixed;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%);

        .ui-dialog-titlebar-close {
            display: none;
        }

        .ui-dialog-buttonpane {
            margin: 20px 0 0 !important;
            padding: 0;
        }
    }
}

.no-close .ui-dialog-titlebar-close {
    display: none;
}

.ui-accordion .ui-accordion-content {
    padding: 0;
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;
        li {
            border-color: $white;
            border-style: solid;
            border-width: 0 0 3px 0;
            &.ui-state-active {
                border-bottom-color: $citrus;
                a {
                    color: $black;
                    font-weight: 700;
                }
            }
            a {
                color: $nobel;
            }
        }
    }
    .ui-tabs-panel {
        background-color: $white;
        border: 1px solid $white;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;
        h3 {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }
}

#vertical-carousel {
    height: 19rem;
    position: relative;
    overflow: hidden;
    ul {
        height: 10000em;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        li {
            float: left;
            height: 19rem;
            width: 100%;
            .capture-product-id {
                display: none;
            }
        }
    }
    .jcarousel-nav {
        color: $nobel;
        cursor: pointer;
        font-size: 0.9em;
        font-weight: 700;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        &:hover {
            color: $citrus;
        }
        &.inactive {
            display: none;
        }
    }
    .jcarousel-prev {
        bottom: 30px;
    }
    .jcarousel-next {
        bottom: 0;
    }
}

body {
    &.shipping-method-list-err {
        position: fixed;

        .ui-widget-overlay {
            opacity: 0.7;
        }
    }
}
