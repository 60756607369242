#einstein-carousel {
    div.row {
        margin: -39px -20px 20px -20px;
    }

    div.row.tiles-container.recommender.carousel.slick-initialized.slick-slider,
    div.tiles-container.carousel.slick-initialized.slick-slider {
        justify-content: center;
        margin: auto;

        .slick-prev, .slick-next {
            display: flex;
        }

        .slick-dots li.slick-active button:before {
            opacity: 1;
        }
        
    }

    .product-tile {
        padding-top: 0;

        .product-image {
            @include breakpoint(large) {
                margin-bottom: 14px;
            }
        }
    }

    .thumb-link img {
        margin: 0 auto;
        width: 350px;
        height: 350px;
    }
}

ul.slick-dots {
    text-align: center;
    bottom: -30px;
}

.cart-recommendations,
.nohits-einstein-recomm,
.empty-cart-einstein-recomm,
.tiles-container {

    .product-slot.product-listing {
        display: none;
    }

    .product-badge {
        height: auto;

        .product-badge-inner {
            height: 20px;

            @include breakpoint(large) {
                margin-bottom: 12px;
            }
        }
    }

    .badge-row  {
        .badge {
            img {
                display: inline-block;
                width: 15px;
                height: 15px;
            }
    
            sup {
                top: -0.3em;
            }
        }
    }

    .product-info,
    .product-pricing {
        text-align: start;

    }
}
