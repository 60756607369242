.category-tile {
    position: relative;

    a {
        display: block;
    }

    h4 {
        @include position(absolute, null 0 $ctile-header-padding-bottom 0);
        padding: 0 #{$grid-small-padding/2} 0 #{$grid-small-padding/2 + $ctile-header-padding-left};

        @include breakpoint(medium) {
            padding: 0 #{$grid-medium-padding/2} 0 #{$grid-medium-padding/2 + $ctile-header-padding-left};
        }
        @include breakpoint(large) {
            padding: 0 #{$grid-large-padding/2} 0 #{$grid-large-padding/2 + $ctile-header-padding-left};
        }
        @include breakpoint(xlarge) {
            padding: 0 #{$grid-xlarge-padding/2} 0 #{$grid-xlarge-padding/2 + $ctile-header-padding-left};
        }
    }
}