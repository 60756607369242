
.c-banner {
    margin-left: calc(-1 * (100vw - 100%) / 2);
    max-width: initial;
    overflow: hidden;
    position: relative;
    width: 100vw;
    z-index: 1;

    &:not(.has-video) { // Prevents parts of the video player from being cut off
        clip-path: $banner-clip-path;

        @include breakpoint(large) {
            clip-path: $banner-clip-path-md;
        }
    }

    &-wrap {
        background-color: $c-black;
    }

    &-image,
    &-video {
        height: auto;
        min-height: 320px;
        object-fit: cover;
        vertical-align: top;
    }

    &-image {
        @at-root img#{&} { // Stronger selector needed to override broader styles
            width: 100%;
        }
    }

    &-video {
        aspect-ratio: 17 / 10;
        width: 100%;
    }

    &-svg-overlay {
        position: absolute;
            bottom: 59px;
            left: 9px;
            z-index: 1;
        width: MIN(982px, 81.2%); // All caps so SCSS doesn't compile

        @include breakpoint(medium) {
            bottom: 79px;
            left: 0;
        }
    }

    &-content {
        box-sizing: border-box;
        color: $white;
        position: absolute;
            left: 50%;
            top: MAX(43%, 160px);
        transform: translate(-50%, -50%);
        width: 100%;

        &--fifthMember {
            display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            top: auto;
            bottom: 0;
            left: 0;
            padding-bottom: 50px;
            padding-left: 30px;
            padding-right: 30px;
            transform: initial;
            text-align: right;
            background: $brand-error;
            clip-path: polygon(100% 0%, 9% 23%, 54% 29%, -100% 55%, 100% 110%, 100% 0);

            @include breakpoint(medium) {
                padding-bottom: 44px;
            }

            @include breakpoint(large) {
                clip-path: polygon(100% 0%, 61% 19%, 72% 27%, -2% calc(100% - 102px), 100% 100%, 100% 0);
                min-height: 315px;
            }

            .c-banner-buttons-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                margin-left: auto;
            }
        }
    }

    &-eyebrow {
        color: $white;
        font-family: $f-subheader;
        font-size: 21px;
        font-weight: $f-weight-regular;
        line-height: 1.29;
        letter-spacing: 1px;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;

        @include breakpoint(large) {
            margin-bottom: 20px;
        }
    }

    &-heading {
        color: $cat-banner-h1-color;
        font-size: 32px;
        letter-spacing: 0.5px;
        line-height: 1.13;
        text-align: center;

        @include breakpoint(large) {
            font-size: 60px;
            line-height: 1;
        }

        &--fifthMember {
            display: inline-block;
            margin-bottom: 10px;
            max-width: 600px;
            padding-top: 100px;
            text-align: right;

            @include breakpoint(large) {
                margin-bottom: 20px;
            }

            &:only-child {
                @include breakpoint(large) {
                    margin-bottom: 25px;
                }
            }
        }
    }

    &-buttons-wrap {
        display: none;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;

        @include breakpoint(medium) {
            display: flex;
            padding-top: 20px;
        }

        &.banner-events-cta {
            display: flex;
        }
    }

    &-button {
        border-radius: $button-border-radius;
        border: $button-border;
        border-color: $white;
        color: $white;
        cursor: pointer;
        font-size: $button-f-size;
        text-transform: $button-text-transform;
        font-weight: $f-weight-bold;
        line-height: $button-line-height;
        padding: 12px 20px 11px;
        letter-spacing: $button-f-letter-spacing;
        text-align: center;
        transition: all 0.2s ease;
        position: relative;

        &:hover:not(.disabled):not(:disabled) {
            background-color: $white;
            color: $c-black;
        }

        &:disabled,
        &.disabled {
            opacity: .35;
        }
    }

    &-articles {
        padding-top: 61px;

        @include breakpoint(medium) {
            padding-top: 224px;
        }
    }

    .cat-banner {
        max-width: 100%;
    }

    + .accent {
        overflow: hidden;
        pointer-events: none;
        position: relative;
            z-index: 0;

        &.accent-nowrap {
            white-space: nowrap;
        }

        &:not(.accent--featured) {
            display: none;
            margin-top: -175px;
            margin-bottom: -130px;

            @include breakpoint(large) {
                display: block;
                margin-left: calc((100% - 100vw) / 2);
                margin-bottom: -170px;
                margin-top: -100px;
            }
        }

        &--featured {
            margin-top: 30px;
            margin-bottom: -75px;
        }

        .overlay-header & {
            z-index: -1;
        }
    }

    &-arrows {
        display: flex;
            justify-content: space-between;
        position: absolute;
            left: 31px;
            right: 31px;
            top: calc(50% - 20px);
        transform: translateY(-50%);

        @include breakpoint(medium) {
            left: calc(((100vw - 100%) / -2) + 84px);
            left: calc(((100dvw - 100%) / -2) + 84px);
            right: calc(((100vw - 100%) / -2) + 84px);
            right: calc(((100dvw - 100%) / -2) + 84px);
            top: calc(50% - 40px);
        }
    }

    &-arrow {
        svg {
            height: 24px;
            margin: 0 10px;
            width: 14px;
        }

        a {
            color: $white;
            display: flex;
                    align-items: center;
            font-size: 13px;
            font-weight: $f-weight-bold;
            line-height: 1.08;
            letter-spacing: 2.5px;
            overflow: visible;
            text-transform: uppercase;
        }

        &--prev {
            a {
                &::before {
                    border-bottom: 3px solid currentColor;
                    border-left: 3px solid currentColor;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin-right: 14px;
                    transform: rotate(45deg);
                    vertical-align: top;
                    width: 12px;

                    @include breakpoint(medium) {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }

        &--next {
            a {
                &::after {
                    border-right: 3px solid currentColor;
                    border-top: 3px solid currentColor;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin-left: 10px;
                    transform: rotate(45deg);
                    vertical-align: top;
                    width: 12px;

                    @include breakpoint(medium) {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }
    }

    + .c-banner-release {
        position: relative;
        width: 100%;

        .accent {
            display: none;
            margin-left: calc((100vw - 100%) / -2);
            position: absolute;
                left: 0;
                top: -120px;
                z-index: -1;
            @include breakpoint(large) {
                display: block;
            }
        }
    }

    &__page-btn {
        color: $white;
        font-size: 13px;
        font-weight: $f-weight-bold;
        letter-spacing: 2.5px;
        line-height: 1.08;
        overflow: visible;
        position: absolute;
            top: 50%;
            z-index: 1;
        text-transform: uppercase;
        transform: translateY(-50%);

        @include breakpoint(medium) {
            top: calc(50% - 75px);
        }

        &::before {
            content: '';
            height: 13px;
            position: absolute;
                top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 13px;
        }

        &__label {
            @include bp(max-width, $bp-medium) {
                @include visually-hidden();
            }
        }
    }

    &__page-prev {
        left: 3px;
        padding-left: 32px;

        @include breakpoint(medium) {
            left: calc(((100vw - 100%) / -2) + 100px);
        }

        &::before {
            border-bottom: 3px solid $white;
            border-left: 3px solid $white;
            left: 0;
        }
    }

    &__page-next {
        padding-right: 32px;
        right: 3px;

        @include breakpoint(medium) {
            right: calc(((100vw - 100%) / -2) + 100px);
        }

        &::before {
            border-right: 3px solid $white;
            border-top: 3px solid $white;
            right: 0;
        }
    }

    &--slider {
        background-color: $c-black;

        .c-banner {
            &-image {
                min-height: 650px;
            }

            &-content {
                padding-left: 40px;
                padding-right: 40px;
                top: 50%;

                @include breakpoint(medium) {
                    padding-left: 75px;
                    padding-right: 75px;
                }

                @include breakpoint(large) {
                    padding-left: 95px;
                    padding-right: 95px;
                }
            }
        }
    }
    
    &--fifthMember {
        margin-bottom: 50px;

        &:not(.has-video) { 
            clip-path: polygon(0 0, 0 calc(100%), 0% calc(100% - 102px), 0% calc(100% - 40px), 100% 100%, 100% 0);

            @include breakpoint(large) {
                clip-path: polygon(0 0, 0 calc(100%), 0% calc(100% - 102px), 30% calc(100% - 75px), 100% 100%, 100% 0);
            }
        }

        .c-banner-image {
            min-height: 550px;
        }
    }
}

.banner-container {
    position: relative;
    width: 100%;

    .c-scroll-overlay {
        margin-left: calc(-1 * (100vw - 100%) / 2);
        width: 100vw;
    }

    .banner-header__date {
        padding-bottom: 10px;
        text-align: center;
        @include breakpoint(large) {
            padding-bottom: 20px;
        }

        span,
        a {
            color: $white;
            font-family: $f-subheader;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include breakpoint(large) {
                font-size: 21px;
            }
        }
    }

    .c-banner {
        .c-banner-buttons-wrap {
            display: flex;
            padding-top: 20px;
        }
    }
}

.s-banner-slider {
    .slick-list {
        padding-left: 0 !important; // Override JS inline styles
        padding-right: 0 !important; // Override JS inline styles
    }

    .slick-slide {
        opacity: 0;
        transition: opacity 150ms;

        &.slick-current {
            opacity: 1;
            transition: opacity 600ms;
        }
    }

    .slick-arrow {
        background-color: transparent;
        margin-left: 3px;
        margin-right: 3px;

        &::before {
            background: none;
            height: 14px;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 14px;
        }
    }

    .slick-prev {
        @include breakpoint(medium) {
            left: 60px;
        }

        @include breakpoint(large) {
            left: 70px;
        }

        &::before {
            border-bottom: 3px solid $white;
            border-left: 3px solid $white;
        }
    }

    .slick-next {
        @include breakpoint(medium) {
            right: 60px;
        }

        @include breakpoint(large) {
            right: 70px;
        }

        &::before {
            border-right: 3px solid $white;
            border-top: 3px solid $white;
        }
    }

    .slick-dots {
        bottom: 100px;
        right: 0;

        @include breakpoint(medium) {
            bottom: 122px;
        }

        @include breakpoint(large) {
            bottom: 172px;
        }

        li {
            box-sizing: border-box;
            height: 22px;
            padding: 10px 3px;
            width: 23px;

            @include bp(max-width, $bp-medium) {
                margin-left: 0;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                height: 19px;
                padding: 8px 3px;
                width: 40px;
            }

            button {
                background-color: $c-medium-gray;
                height: 100%;
                padding-bottom: 0;
                padding-top: 0;
                width: 100%;

                &::before {
                    content: none;
                }
            }

            &:hover,
            &.slick-active {
                button {
                    background-color: $c-bright-red;
                }
            }
        }
    }

    .is-dark > & {
        .slick-prev {
            &::before {
                border-bottom-color: $c-black;
                border-left-color: $c-black;
            }
        }

        .slick-next {
            &::before {
                border-right-color: $c-black;
                border-top-color: $c-black;
            }
        }
    }
}

.s-banner-content {
    text-align: center;

    h2 {
        @include h1;

        color: inherit;

        + p {
            @include bp(max-width, $bp-medium) {
                margin-top: 19px;
            }
        }
    }

    p {
        font-family: $f-subheader;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1.29;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: 21px;
        }
    }

    .button {
        background-color: transparent;
        border-color: currentColor;
        color: inherit;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 16px;

        @include breakpoint(medium) {
            margin-top: 10px;
        }

        &:hover {
            background-color: $white;
            color: $c-black;
        }
    }
}
