.c-html-embed {
    padding-bottom: 50px;
    padding-top: 40px;
    position: relative;
        z-index: 0;

    @include breakpoint(medium) {
        margin-bottom: 25px;
        padding-bottom: 145px;
        padding-top: 100px;
    }

    @include breakpoint(large) {
        padding-bottom: 155px;
        padding-top: 170px;
    }

    &__header {
        margin-bottom: 20px;

        @include breakpoint(medium) {
            margin-bottom: 14px;
        }
    }

    &__heading {
        @include h2;

        @include breakpoint(medium) {
            @include h3;
        }
    }
}

.s-html-embed {
    .c-scroll-anim {
        display: flex;
            flex-direction: column;
            justify-content: space-between;
        min-height: 100%;
        overflow: hidden;
        position: absolute;
            top: 0;
            z-index: -1;
        width: 100%;

        &__accent-left-text,
        &__accent-right-text {
            position: relative;
        }
    }
}