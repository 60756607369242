.c-photo-gallery {
    margin-bottom: 56px;

    @include breakpoint(medium) {
        margin-bottom: 104px;
    }

    &__heading {
        @include h3;

        @include bp(max-width, $bp-medium) {
            margin-bottom: 20px;
        }

        @include breakpoint(medium) {
            @include h4;
        }
    }

    &__imgs {
        display: grid;
            gap: 7px;
            grid-auto-rows: auto;
            grid-template-columns: repeat(2, 1fr);
        list-style: none;
        margin-bottom: 32px;
        padding-left: 0;

        @include breakpoint(medium) {
            gap: 8px;
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 44px;
        }
    }

    &__item {
        &:nth-child(3n + 1) {
            @include bp(max-width, $bp-medium) {
                grid-column-end: span 2;
            }
        }

        &:first-child,
        &:nth-child(5) {
            @include breakpoint(medium) {
                grid-column-end: span 2;
                grid-row-end: span 2;
            }
        }
    }

    &__wrapper-link {
        height: 100%;
        position: relative;

        &__credit {
            color: $white;
            font-size: 10px;
            font-weight: $f-weight-bold;
            line-height: 1;
            letter-spacing: 1px;
            margin: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            text-transform: uppercase;
            box-sizing: border-box;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
            min-height: 30px;
            padding: 20px 16px 5px;
            width: 100%;
            
            @include breakpoint(medium) {
                min-height: 50px;
            }

            &::before {
                @include svg-view-camera;
                content: '';
                display: inline-block;
                margin-right: 5px;
                transform: scale(.8947);
                vertical-align: bottom;

                @include breakpoint(medium) {
                    transform: scale(1)
                }
            }
        }
    }

    & &__img {
        min-height: initial;
        object-fit: cover;
        aspect-ratio: 16/9;
    }

    &__load-more {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &__lightbox {
        &__info {
            border-bottom: 1px solid $white;
            margin-bottom: 39px;

            @include breakpoint(medium) {
                margin-bottom: 60px;
            }
        }

        &__credit {
            font-size: 10px;
            font-weight: $f-weight-bold;
            letter-spacing: 1px;
            line-height: 1;
            margin-top: 11px;
            text-transform: uppercase;

            &::before {
                @include svg-view-camera;

                content: '';
                display: inline-block;
                margin-right: 5px;
                vertical-align: bottom;
            }
        }

        &__caption {
            font-size: 12px;
            line-height: 1.33;
            margin-bottom: 18px;

            @include breakpoint(medium) {
                margin-bottom: 12px;
            }
        }
    }

    &--full {
        // alterations for photo gallery full page
        margin-top: 40px;

        .c-photo-gallery {
            &__item {
                &:nth-child(3n + 1) {
                    @include bp(max-width, $bp-medium) {
                        grid-column-end: span 1;
                    }
                }

                &:nth-child(9n + 1) {
                    @include bp(max-width, $bp-medium) {
                        grid-column-end: span 2;
                    }
                }

                &:first-child,
                &:nth-child(5) {
                    @include breakpoint(medium) {
                        grid-column-end: span 1;
                        grid-row-end: span 1;
                    }
                }

                &:first-child,
                &:nth-child(11),
                &:nth-child(27),
                &:nth-child(35)
                {
                    @include breakpoint(medium) {
                        grid-column-end: span 2;
                        grid-row-end: span 2;
                    }
                }

                &:nth-child(19),
                &:nth-child(44)
                {
                    @include breakpoint(medium) {
                        grid-column-end: span 3;
                        grid-row-end: span 2;
                    }
                }
            }
        }
    }
}
