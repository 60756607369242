// mixins
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/* mixin for multiline */

@mixin multiLineEllipsis($lineHeight: 1em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;

    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

// Applies transition to an element
// Defaults to default vars, set above
@mixin transitioned($property: $default-transition-property, $duration: $default-transition-duration, $function: $default-transition-function) {
    transition: $property $duration $function;
}

// Font source mixin
@mixin font-source($fontName: false, $fontSize: false, $color: false, $lineHeight: false, $fontWeight: false) {
    @if $fontSize and $lineHeight and $fontName {
        @if $fontWeight {
            font: $fontWeight #{$fontSize}/#{$lineHeight} $fontName;
        }
        @else {
            font: #{$fontSize}/#{$lineHeight} $fontName;
        }
    }
    @if $color {
        color: $color;
    }
}

@mixin headerlabel() {
    font-style: normal;
    margin-left: 8px;
    font-weight: $f-weight-bold;
    font-size: $f-size;
    color: $black;
    text-transform: none;
}

@mixin heading() {
    letter-spacing: .5px;
    margin: 0;
}

// H1 mixin
@mixin h1() {
    @include font-source($f-header, $f-header-h1-size, $c-heading, $f-header-h1-lh, $f-weight-bold);
    @include heading();

    @include breakpoint(medium) {
        font-size: $f-header-h1-size-md;
        line-height: $f-header-h1-lh-md;
    }
}

// H2 mixin
@mixin h2() {
    @include font-source($f-header, $f-header-h2-size, $c-heading, $f-header-h2-lh, $f-weight-bold);
    @include heading();

    @include breakpoint(medium) {
        font-size: $f-header-h2-size-md;
        letter-spacing: 1px;
    }
}

// H3 mixin
@mixin h3() {
    @include font-source($f-header, $f-header-h3-size, $c-heading, $f-header-h3-lh, $f-weight-bold);
    @include heading();


    @include breakpoint(medium) {
        font-size: $f-header-h3-size-md;
        letter-spacing: normal;
        line-height: $f-header-h3-lh-md;
    }
}

// H4 mixin
@mixin h4() {
    @include font-source($f-header, $f-header-h4-size, $c-heading, $f-header-h4-lh, $f-weight-medium);
    @include heading();

    @include breakpoint(medium) {
        font-size: $f-header-h4-size-md;
        letter-spacing: normal;
        line-height: $f-header-h4-lh-md;
    }
}

// H5 mixin
@mixin h5() {
    @include font-source($f-header, $f-header-h5-size, $c-heading, $f-header-h5-lh, $f-weight-bold);

    @include breakpoint(medium) {
        font-size: $f-header-h5-size-md;
    }
}

// H6 mixin
@mixin h6() {
    @include font-source($f-header, $f-header-h6-size, $c-heading, $f-header-h6-lh, $f-weight-bold);
    @include heading();
}

@mixin subheading() {
    letter-spacing: 1px;
}

// S1 mixin
@mixin s1() {
    @include font-source($f-subheader, 21px, $c-heading, $f-header-h5-lh, $f-weight-regular);
    @include subheading();

    @include breakpoint(medium) {
        font-size: 28px;
        line-height: 1.07;
    }
}

// S2 mixin
@mixin s2() {
    @include font-source($f-subheader, $f-header-h5-size, $c-heading,$f-header-h5-lh, $f-weight-regular);
    @include subheading();

    @include breakpoint(medium) {
        font-size: $f-header-h5-size-md;
    }
}

// S3 mixin
@mixin s3() {
    @include font-source($sans-serif, $f-size-xxs, $c-heading, 1.33, $f-weight-semibold);
    @include subheading();

    @include breakpoint(medium) {
        font-size: $f-size-xs;
        line-height: 1.43;
    }
}

@mixin link1() {
    @include font-source($sans-serif, $f-size-xs, $c-black, 1, $f-weight-bold);

    letter-spacing: 3px;
}

@mixin cta1() {
    @include font-source($sans-serif, 11px, $c-black, 1, $f-weight-bold);

    letter-spacing: 2.5px;
}

@mixin cta2() {
    @include font-source($sans-serif, $f-size-s, $c-black, 1, $f-weight-bold);

    letter-spacing: 3px;
}

@mixin link2() {
    @include font-source($sans-serif, 11px, $c-black, 1.08, $f-weight-bold);

    letter-spacing: 2.5px;
}

// Accent mixin
@mixin accent() {
    @include font-source($f-accent, 140px, $c-light-gray, 0.71, $f-weight-regular);

    letter-spacing: 50px;
    opacity: .5;
    text-transform: uppercase;

    @include breakpoint(medium) {
        font-size: 260px;
        letter-spacing: 130px;
        line-height: 1;
    }
}

@mixin primary-button() {
    background-color: $c-primary-button;
    border-radius: $button-border-radius;
    border: $button-border;
    color: $c-primary-button-f-color;
    cursor: pointer;
    font-size: $button-f-size;
    text-transform: $button-text-transform;
    font-weight: $f-weight-bold;
    line-height: $button-line-height;
    padding: $c-button-padding;
    letter-spacing: $button-f-letter-spacing;
    text-align: center;
    transition: all 0.2s ease;
    position: relative;

    &:hover:not(.disabled):not(:disabled) {
        background-color: $c-primary-button-hover;
        color: $c-primary-button-f-color-hover;
        text-decoration: none;
    }

    &:disabled,
    &.disabled {
        opacity: .35;
    }
}

@mixin secondary-button () {
    background-color: $c-secondary-button;
    border-radius: $button-border-radius;
    border: $button-border;
    color: $c-secondary-button-f-color;
    cursor: pointer;
    font-size: $button-f-size;
    text-transform: $button-text-transform;
    font-weight: $f-weight-bold;
    line-height: $button-line-height;
    padding: $c-button-padding;
    letter-spacing: $button-f-letter-spacing;
    text-align: center;
    transition: all 0.3s ease;
    position: relative;

    &:hover:not(.disabled):not(:disabled) {
        background-color: $c-secondary-button-hover;
        color: $c-secondary-button-f-color-hover;
    }

    &:disabled,
    &.disabled {
        opacity: .35;
    }
}

@mixin attribute-button() {
    border: $variation-swatch-border;
    color: $black;
    background: $white;
    font-weight: $f-weight-bold;
    border-radius: $variation-swatch-border-radius;
    min-height: $variation-swatch-size;
    min-width: 28px;
    margin: 0 $variation-swatch-spacing--mobile $variation-swatch-spacing--mobile 0;
    padding: $variation-swatch-padding;
    display: flex;
    font-size: 13px;
    letter-spacing: 2.5px;
    line-height: 1.5;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(medium) {
        margin: 0 $variation-swatch-spacing $variation-swatch-spacing 0;
    }

    &:hover {
        background-color: $variation-swatch-bg-selected;
        color: $variation-swatch-c-selected;
    }

    br {
        @include breakpoint(small) {
            content: '' !important; // basically wipes out line break
            display: inline-block !important;

            &::before {
                // create slash in place of <br>
                content: '/';
                display: inline;
                margin: 0 .25em;
            }
        }

        @include breakpoint(medium) {
            content: initial !important;

            &::before {
                display: none;
            }
        }

        @include breakpoint(xlarge) {
            content: '' !important;

            &::before {
                display: inline;
            }
        }
    }
}

@mixin donation-button() {
    &:before {
        content: '';
        width: 0px;
        height: 0px;
        bottom: 0px;
        right: 0px;
    }
    &:after {
        content: '';
        width: 0px;
        height: 0px;
        bottom: 0px;
        right: 0px;
    }
}

@mixin cta-button() {
    border-color: rgba(116, 24, 32, 0.70);
    color: $white;
    background: $c-red;
    &:before,
    &:after {
        background: $c-dark-red;
    }
}

@mixin hollow-button() {
    border-color: rgba(255, 255, 255, 0.75);
    background: rgba(0, 0, 0, 0.25);
    color: $white;
    font-size: 13px;
    letter-spacing: 2.5px;
    line-height: 1.5;
    padding: $c-button-padding;

    &:hover:not(.disabled):not(:disabled) {
        background-color: $white;
        color: $c-black;
    }
}

@mixin simple-button() {
    background-color: $white;
    border: none;
    padding: 0;
    letter-spacing: 0;
    text-transform: none;
    color: $c-primary-button;
    font-weight: $f-weight-regular;
}

@mixin see-more-button {
    border: none;
    padding: 0;
    overflow-x: hidden;
    font-weight: $f-weight-regular;
    font-size: $f-size-xs;
    text-transform: none;
    letter-spacing: 0;
    >span {
        padding: 6px 15px;
        display: block;
        transform: translateX(-100%);
        @include transitioned;
        text-align: left;
    }
    &:before {
        top: 50%;
        bottom: initial;
        left: 0;
        right: initial;
        transform: translateY(-50%);
        @include transitioned;
    }
    &:after {
        top: 50%;
        bottom: initial;
        left: 3px;
        right: initial;
        transform: translateY(-50%);
        @include transitioned;
    }
    @include breakpoint(medium) {
        &:hover {
            >span {
                transform: translateX(0);
            }
            &:before {
                width: 6px;
                top: calc(50% - 1px);
                transform: translateY(-50%) rotate(43.5deg);
            }
            &:after {
                height: 6px;
                top: calc(50% + 3px);
                transform: translateY(-50%) rotate(46.5deg);
                left: 2px;
            }
        }
    }
}

// horizontally center a box
@mixin width($maxWidth: $bp-max, $width: 100%) {
    width: $width;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
}

@mixin width-with-padding($maxWidth: $bp-max-content-width, $width: 100%) {
    width: $width;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(medium) {
        padding: 0 $l-medium-padding;
    }
    @include breakpoint(large) {
        padding: 0 $l-large-padding;
    }
    @include breakpoint(xlarge) {
        padding: 0 $l-xlarge-padding;
    }
}

@mixin content-width() {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - #{$l-small-padding*2});
    @include breakpoint(medium) {
        max-width: calc(100% - #{$l-medium-padding*2});
    }
    @include breakpoint(large) {
        max-width: calc(100% - #{$l-large-padding*2});
    }
    @include breakpoint(xlarge) {
        max-width: calc(#{$bp-max} - #{$l-xlarge-padding*2});
    }
}

// layout content padding
@mixin l-padding() {
    padding: 0 $l-small-padding;
    @include breakpoint(medium) {
        padding: 0 $l-medium-padding;
    }
    @include breakpoint(large) {
        padding: 0 $l-large-padding;
    }
    @include breakpoint(xlarge) {
        padding: 0 $l-xlarge-padding;
    }
}

@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin h-visually-hidden() {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

//
// Mixins: vendor prefixes
// --------------------------------------------------
// Box sizing
@mixin box-sizing($box-model) {
    box-sizing: $box-model;
}

// Single side border-radius
@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
    box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
    transition: $transition;
}

@mixin transition-delay($transition-delay) {
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration) {
    transition-duration: $transition-duration;
}

// Transformations
@mixin rotate($degrees) {
    transform: rotate($degrees);
}

@mixin scale($ratio) {
    transform: scale($ratio);
}

@mixin translate($x, $y) {
    transform: translate($x, $y);
}

@mixin skew($x, $y) {
    transform: skew($x, $y);
    @include backface-visibility(hidden);
}

@mixin translate3d($x, $y, $z) {
    transform: translate3d($x, $y, $z);
}

// Backface visibility
@mixin backface-visibility($visibility) {
    backface-visibility: $visibility;
}

// User select
@mixin user-select($select) {
    user-select: $select;
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity-ie})";
}

// Optional At Root
// Don't load unrequired styles
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

// Placeholder text
@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }
    @include optional-at-root(':-moz-placeholder') {
        @content;
    }
    @include optional-at-root('::-moz-placeholder') {
        @content;
    }
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

// Resize anything
@mixin resizable($direction) {
    resize: $direction; // Options: horizontal, vertical, both
    overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($width, $count, $gap) {
    column-width: $width;
    column-count: $count;
    column-gap: $gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    hyphens: $mode;
}

// Gradients
@mixin gradient-horizontal($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-image: linear-gradient(to right, $startColor, $endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{argb($startColor)}', endColorstr='#{argb($endColor)}', GradientType=1)"; // IE9 and down
}

@mixin gradient-vertical($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{argb($startColor)}', endColorstr='#{argb($endColor)}', GradientType=0)"; // IE9 and down
}

// Reset filters for IE
//
// Useful for when you want to remove a gradient from an element.
@mixin reset-filter() {
    filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

//
// Mixins: utilities
// --------------------------------------------------
@mixin clearfix {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

// Center-align a block level element
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin square($size) {
    @include size($size, $size);
}

// Text overflow
//
// Requires inline-block or block for proper styling
@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Retina images
//
// Retina background-image support with non-retina fall back
@mixin retina-image($file-1x, $file-2x, $width-1x, $height-1x) {
    background-image: url("${file-1x}");
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        background: url("${file-2x}");
        background-size: $width-1x $height-1x;
    }
}

// Creates a no padding type list, example in the footer
@mixin ul-no-padding() {
    padding: 0;
    line-height: initial;
    margin: 6px 0;
    li {
        list-style: none;
        line-height: initial;
        a {
            line-height: 31px;
        }
    }
}

@mixin refinement-header() {
    @include h6;
    font-weight: $f-weight-bold;
}

@mixin responsive-table($break: $bp-small, $center: true, $titles: true) {
    @include bp(max-width, $break) {
        display: block;
        tbody,
        thead,
        tfoot,
        tr,
        td,
        th {
            display: block; // I hate to do it, but there is some hyper specificity up the chain,
            // and this makes the use of the mixin much tidier
            width: 100% !important;
            box-sizing: border-box;
            @if ($center) {
                text-align: center;
            }
        } // Requires a data-title attribute added to the td element to properly display
        // see orders.isml:84 for an example
        @if ($titles) {
            th {
                display: none;
            }
            td {
                &:before {
                    content: attr(data-title);
                }
            }
        }
    }
}

@mixin forum-tab-content {
    .fifth-member-comm-block {
        overflow: visible;
        position: relative;
        @include content-width;

        h3,
        .description span {
            color: $white;
        }

        .content img {
            max-width: 99px;

            @include breakpoint(medium) {
                max-width: 204px;
            }

            @include breakpoint(medium) {
                max-width: 268px;
            }
        }

        .content {
            position: absolute;
            z-index: 2;
            margin-top: 15px;

            @include breakpoint(medium) {
                position: relative;
            }

            @include breakpoint(large) {
                margin-top: 41px;
            }
        }

        .info {
            margin-right: 25px;

            .description,
            .ctas {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        .mobile-info {
            margin-top: 145px;
            font-size: $f-size-xs;

            .description {
                margin-bottom: 16px;
            }

            .ctas {
                margin-bottom: 13px;
            }

            @include breakpoint(medium) {
                display: none;
            }
        }

        .counter {
            z-index: 1;
            font-size: 90px;
            opacity: .10;
            right: -18px;
            top: 22px;

            @include breakpoint(medium) {
                font-size: 180px;
                right: -30px;
                top: 72px;
            }

            @include breakpoint(large) {
                font-size: 300px;
                top: -7px;
                right: -45px;
            }
        }
    }
}

@mixin navPsuedo($psuedoWidth, $background, $bottom) {
    &:after {
        content: '';
        height: 1px;
        width: calc(100% + #{$psuedoWidth});
        background: $background;
        position: relative;
        bottom: $bottom;
        right: 0;
        display: block;
        left: -$psuedoWidth;
    }
}

/// Transforms shorthand that can range from 1-to-4 values to be 4 values.
///
/// @argument {list} $shorthand
///
/// @example scss
///   .element {
///     margin: _unpack-shorthand(1em 2em);
///   }
///
///   // CSS Output
///   .element {
///     margin: 1em 2em 1em 2em;
///   }
///
/// @access private

@function _unpack-shorthand($shorthand) {
    @if length($shorthand) == 1 {
      @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
    } @else if length($shorthand) == 2 {
      @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
    } @else if length($shorthand) == 3 {
      @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
    } @else {
      @return $shorthand;
    }
  }

/// Provides a concise, one-line method for setting an element’s positioning
/// properties: `position`, `top`, `right`, `bottom` and `left`. Use a `null`
/// value to “skip” an edge of the box.
///
/// @argument {string} $position
///   A CSS position value.
///
/// @argument {list} $box-edge-values
///   List of lengths; accepts CSS shorthand.
///
/// @example scss
///   .element {
///     @include position(relative, 0 null null 10em);
///   }
///
///   // CSS Output
///   .element {
///     left: 10em;
///     position: relative;
///     top: 0;
///   }
///
/// @example scss
///   .element {
///     @include position(absolute, 0);
///   }
///
///   // CSS Output
///   .element {
///     position: absolute;
///     top: 0;
///     right: 0;
///     bottom: 0;
///     left: 0;
///   }
///
/// @require {function} _is-length
///
/// @require {function} _unpack-shorthand

@mixin position(
  $position,
  $box-edge-values
) {
  $box-edge-values: _unpack-shorthand($box-edge-values);
  $offsets: (
    top:    nth($box-edge-values, 1),
    right:  nth($box-edge-values, 2),
    bottom: nth($box-edge-values, 3),
    left:   nth($box-edge-values, 4),
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}
