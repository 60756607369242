.dw-apple-pay-button,
.dw-apple-pay-button:hover {
    margin: 0;
}

.cart-content-container {
    margin-left: -$l-small-padding;
    margin-right: -$l-small-padding;

    @include breakpoint(large) {
        border-right: 1px solid $c-medium-gray;
        flex-basis: $cart-content-width-lg;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 34px;
    }
}

.cart-bottom-divider {
    border-bottom: none;
    border-color: $c-medium-gray;
    display: none;
    margin-left: calc((100vw - 100%) / -2);
    margin-right: calc((100vw - 100%) / -2);
    margin-top: 0;
    width: 100vw;

    @include breakpoint(large) {
        display: block;
        margin-bottom: 22px;
    }
}

#primary {
    @include bp(max-width, $bp-medium) {
        // padding-bottom: 10%;
    }
}

.cart-primary {
    display: flex;
        flex-wrap: wrap;

    .cart-empty {
        margin-top: 15px;
    }
}

#cart-table {
    // if this $bp-medium value is changed here, you will want to change
    // all other occurrences of it throughout this file
    @include responsive-table($bp-medium, true, false);

    margin-top: 0;
    padding-left: 0;

        @include bp(max-width, $bp-medium) {
            margin: 0 auto;

            .item-total {
                float: none;
            }

            .item-user-actions,
            .item-total .promo-adjustment {
                width: auto;
            }
        }

    * {
        box-sizing: border-box;
    }

    .header-total-price {
        text-align: right;
    }

    .item-image {
        padding: $plist-table-tbody-td-image-padding-small;
        flex-basis: 80px;
        flex-shrink: 0;

        @include breakpoint(medium) {
            padding: $plist-table-tbody-td-image-padding-medium;
        }

        &:not(.item-image--blank) {
            background-color: $white;
        }
    }

    .cart-row {
        border: 1px solid $c-stroke-gray;
        display: flex;

        &.cart-row-shipment {
            border-width: 1px 0 0 0;

            &.first {
                border: 0;
            }
        }

        &:not(:last-child) {
            border-bottom: none;
        }

        &:last-child {

            td.item-details {

                @include breakpoint(medium) {
                    padding-bottom: 6px;
                }

                @include breakpoint(large) {
                    padding-bottom: 14px;
                }
            }
        }
    }

    .header-product-details,
    .item-image {
        text-align: center;
    }

    .header-total-price {
        text-align: right;
    }

    .item-info {
        display: flex;
            align-content: space-between;
            flex-wrap: wrap;
        width: 100%;

        @include breakpoint(large) {
            padding-bottom: 10px;
            padding-top: 10px;
        }

        &-row {
            display: flex;
                justify-content: space-between;
            padding-right: 15px;
            width: 100%;

            @include breakpoint(large) {
                padding-right: 4px;
            }

            &--wrap {
                flex-wrap: wrap;
            }
        }

        &-col {
            padding: $cart-info-col-padding-y 12px;

            @include breakpoint(large) {
                padding: $cart-info-col-padding-y-lg 20px;
            }

            &--messaging {
                font-size: 12px;
                font-weight: $f-weight-regular;
                line-height: 1.33;
                padding-bottom: 0;
                padding-top: 0;
                width: 100%;

                .product-availability-list {
                    .not-available {
                        color: $c-red;
                        text-transform: uppercase;
                    }
                }
            }

            &--error {
                padding-bottom: 0;
            }
            // Prevent empty col from taking up space by moving padding onto children
            .product-availability-list:not(:empty),
            .refill-li-message {
                padding-bottom: $cart-info-col-padding-y;
                padding-top: $cart-info-col-padding-y;

                @include breakpoint(large) {
                    padding-bottom: $cart-info-col-padding-y-lg;
                    padding-top: $cart-info-col-padding-y-lg;
                }
            }
        }
    }

    .item-details {
        text-align: left;

        .product-list-item {
            .sku {
                display: none;
            }

            .name a {
                color: $black;
            }
        }

        .home-delivery {
            display: none;

            @include breakpoint(medium) {
                display: none;
            }

            @include breakpoint(large) {
                display: none;
            }

            label {
                @include breakpoint(medium) {
                    color: $black;
                }
            }
        }

        .item-price, .item-stock, .item-qty {
            text-align: left;
            display: block;
        }

        .item-qty {
            font-weight: $f-weight-bold;
        }

        .item-qty, .item-stock {
            float: left;
        }

        .item-stock {
            margin-left: 15px;
        }

        .item-price {
            clear: both;
            font-weight: $f-weight-bold;
        }

        @include breakpoint(medium) {
            margin-left: 0;

            .item-price, .item-stock, .item-qty {
                display: none;
            }
        }

        .item-edit-details {
            display: table-cell;
        }

        .button-text {
            color: $c-red;
            font-size: 16px;
            line-height: 24px;
            font-weight: $f-weight-regular;
            text-transform: capitalize;
            letter-spacing: 0;

            &:before, &:after {
                display: none;
            }
        }

        .product-availability-list {
            display: inline-block;
            margin: 8px 11px 0 12px;

            @include breakpoint(small){
                margin: 8px 13px 0 12px;
            }
        }

        .item-user-actions {
            display: inline-block;
        }
    }

    .item-delivery-options {
        order: 6;
        text-align: center;
        flex-basis: 50%;

        @include breakpoint(medium){
            text-align: left;
            width: 8rem;
        }

        .form-row {
            margin-top: 0;
        }
    }

    .item-quantity {
        text-align: center;
        flex-basis: 33.3333%;
        font-weight: $f-weight-bold;∂∂

        input[type=number] {
            -moz-appearance: textfield;
            padding:0;
        }

        @include breakpoint(medium) {
            text-align: left;
        }

        @include breakpoint(large){
            text-align: right;
        }

        .product-quantity-error{
            display: block;
            padding-bottom: 5px;
        }
    }

    .item-quantity-details:not(.item-gc-ctas) {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 3;
        text-align: center;
        flex-basis: 33.3333%;
        display: none;

        @include breakpoint(medium){
            display: table-cell;
            float: none;
            text-align: left;
        }

        .product-availability-list {
            margin: 0;
            padding: 0;
            max-width: 170px;

            @include breakpoint(medium){
                width: 5em; //for IE
                width: max-content;
                text-align: left;
                padding-left: 12px;
            }
        }
    }

    .item-price {
        order: 5;
        text-align: center;
        flex-basis: 33.3333%;

        @include breakpoint(medium){
            display: table-cell;
            text-align: left;
        }
    }

    .price-promotion {
        .price-sales {
            color: $sangria;
        }
        .price-standard {
            margin: 0 1em 0 0;
            text-decoration: line-through;
        }
    }
    .gift-cert-delivery {
        color: $sangria;
    }
}

.cart {
    &-heading,
    &-item-count {
        display: inline-block;

        @media only screen and (max-width: #{$bp-medium}) {
            margin-bottom: 20px;
        }
    }

    &-heading {
        font-size: 40px;
        
        @include breakpoint(medium) {
            margin-bottom: 20px;
        }
    }

    &-item-count {
        font-family: $f-header;
        font-size: 20px;
        line-height: 1.1;
        margin-left: 8px;

        @include breakpoint(medium) {
            @include h2;

            margin-left: 8px;
        }
    }

    &-coupons {
        list-style: none;
    }
}

.pt_cart #cart-table {
    &.item-list thead tr {
        @include breakpoint(medium){
            border-bottom: 1px solid $c-light-gray;
        }
    }

    th {
        text-align: left;
    }

    th.section-header {

        @include breakpoint(medium){
            font-size: 14px;
            padding-bottom: 3px;
            padding-left: 8px;
        }

        &.header-total-price {
            text-transform: uppercase;
        }
    }

    th.header-total-price {
        text-align: right;

        @include breakpoint(medium){
            padding: 0 9px 3px 17px;
        }

        @include breakpoint(large) {
            padding-right: 18px;
            padding-left: 0;
        }

        @include breakpoint(xlarge) {
            padding-right: 24px;
        }
    }

    th.header-product-details {
        width: 33%;

        @include breakpoint(large){
            padding: 3px 15px 7px 15px;
        }

        @include breakpoint(xlarge){
            padding-left: 25px;
        }
    }

    th.header-delivery-options {
        width: 13%;
        text-align: center;
        display: none;

        @include breakpoint(large){
            padding-right: 9px;
            text-align: right;
            width: 16%;
        }

        @include breakpoint(xlarge){
            text-align: center;
            padding-left: 21px;
        }
    }

    th.header-product-price {
        text-align: center;

        @include breakpoint(medium){
            text-align: right;
            padding-right: 23px;
            padding-left: 0;
        }

        @include breakpoint(large){
            text-align: center;
            padding-right: 15px;
        }

        @include breakpoint(xlarge){
            text-align: right;
            padding-left: 31px;
        }
    }

    th.header-product-qty {
        padding-left: 0;

        @include breakpoint(medium){
            padding-right: 28px;
            padding-left: 0;
            text-align: left;
        }

        @include breakpoint(large){
            padding-right: 87px;
        }

        @include breakpoint(xlarge){
            padding-right: 0;
            padding-left: 5px;
        }
    }

    td {
        @include breakpoint(medium){
            padding-top: 33px;
        }
    }

    .item-details {
        input {
            width: 26px;
            height: 26px;
            text-align: center;

            &.awmh-donation {
                background-color: transparent;
                border: 0;
            }
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .item-quantity {
        text-align: right;

        input {
            text-align: center;
            display: inline-block;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"].valid {
            background: none;
            background-color: $white;
        }
    }

    td.item-delivery-options {
        display: none;
        text-align: center;
        padding: $cart-table-tbody-td-padding;

        @include breakpoint(large){
            padding-top: 33px;
            text-align: right;
            padding-right: 10px;
        }

        @include breakpoint(xlarge){
            text-align: center;
            padding-left: 22px;
        }

        .home-delivery label {
            color: $black;
        }
    }

    td.item-price {
        flex-basis: 48%;
        text-align: right;
        padding: $cart-table-tbody-td-padding;
        font-weight: $f-weight-bold;
        font-size: 14px;
        display: none;

        @include breakpoint(medium) {
            padding: 34px 15px 15px 0;
        }

        @include breakpoint(large){
            text-align: center;
            padding-left: 7px;
            padding-right: 22px;
        }

        @include breakpoint(xlarge) {
            text-align: right;
            padding-right: 8px;
        }
    }

    .mobile-details-item-price, .mobile-details-item-total {
        font-weight: $f-weight-bold;
        font-size: 14px;
        padding-top: 9px;
        padding-right: 20px;
        display: inline-block;

        @include breakpoint(medium) {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .item-details {
            .input-text,
            .product-availability-list {
                display: none;
            }
        }

        td.item-price {
            display: table-cell;
        }
    }

    @include breakpoint(large) {
        td.item-delivery-options,
        th.header-delivery-options {
            display: table-cell;
        }
    }

    tr.cart-row.rowbundle {
        padding: 10px 0;

        @include breakpoint(medium) {

            .item-quantity {
                padding-right: 10px;
            }

            .item-quantity-details .product-availability-list {
                padding-left: 0;
            }
        }
    }
}

.pt_cart {
    .cart-actions {
        text-align: center;
        width: 100%;

        @include breakpoint(medium) {
            text-align: right;
        }
    }

    .login-box-content.returning-customers {

        .form-row-button {
            display: inline-block;
        }

        .login-rememberme {
            margin: 0;
        }
    }
    
    .cart-table-container,
    .error-form {
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        margin-bottom: 20%;

        @include breakpoint(large) {
            display: inline-block;
            padding-right: 54px;
        }
    }

    .login-rememberme {
        margin-top: 0
    }

    .refill-li-message {
        font-size: inherit;
        font-weight: inherit;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.cart-order-totals {
    margin: 9px 0;

    @include breakpoint(medium) {
        float: right;
        margin-top: 16px;
        margin-bottom: 6px;
    }

    @include breakpoint(large) {
        width: auto;
    }
}

.order-totals-table {
    color: $c-charcoal;
    display: flex;
        flex-direction: column;
    font-size: 14px;
    font-weight: $f-weight-semibold;
    letter-spacing: 1px;
    line-height: 1.43;
    margin-top: 20px;
    padding-left: 27px;
    padding-right: 27px;
    text-transform: uppercase;

    @include breakpoint(medium) {
        margin-top: 14px;
        padding-left: 0;
    }

    @include breakpoint(large) {
        padding-right: 0;
    }

    &-row {
        display: flex;
            gap: 28px;
            justify-content: space-between;

        @include breakpoint(medium) {
            gap: 140px;
        }
    }

    dt,
    dd {
        margin-bottom: 10px;
    }

    dd {
        margin-left: 0;
        text-align: right;
    }

    dt {
        text-align: left;
    }

    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    .order-subtotal-value,
    .order-subtotal-label,
    .order-total-value,
    .order-total-label {
        color: $c-black;
        font-family: $f-subheader;
        font-size: 21px;
        font-weight: $f-weight-regular;
        line-height: 1.29;
    }

    .order-subtotal-value,
    .order-subtotal-label {
        margin-bottom: 20px;
    }

    .order-total-value,
    .order-total-label {
        padding-top: 10px;
    }

    .order-shipping, .order-sales-tax, .order-shipping-discount {
        font-size: 14px;
    }

    .order-discount.discount {
        td {
            font-size: 14px;
        }
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;

    .dw-apple-pay-button {
        @include breakpoint(medium) {
            margin: 7px 20px 7px auto;
        }

        &:hover, &:active {
            // setting to normal padding to override a padding: 0;
            padding: .5em 2em;
            margin-right: 20px;
        }

        &:after {
            content:'';
            visibility: visible;
        }
    }
}

.cart-action-continue-shopping {
    @include bp(max-width, $bp-medium) {
        border-top:1px solid;
        padding-top: 24%;
        width: 100%;

        fieldset {
            text-align: center;
        }
    }
    button.button-text {
        margin-top: .2rem;
        margin-right: 1rem;

        @include breakpoint(medium){
            margin-top: .6rem;
        }
    }

    @include breakpoint(medium) {
        margin-right: 40px;
        float: left;
        margin-top: 5px;
        margin-bottom: 40px;
    }

    .cart-empty & {
        margin-right: 0;
    }
}

.cart-recommendations {
    .product-listing {
        @extend %clearfix;

        h2 {
            text-align: center;
        }

        .search-result-items {
            margin-top: 1em;
        }
    }
}

.cart-actions-top {
    margin-top: -1px;
    margin-bottom: 1rem;

    @include breakpoint(medium){
        margin-bottom: 0;
    }

    .cart-action-continue-shopping {
        float: none;
    }
}

.cart-empty {
    @extend %clearfix;

    h1 {
        text-align: center;
    }

    .cart-action-continue-shopping {
        float: none;
        text-align: center;
        margin-bottom: 4rem;
    }
}

.promo-adjustment {
    color: $sangria;
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

.has-preorder.row {
    margin-top: 0;

    .preorder-warning-summary {
        padding-top: 0;
        margin-bottom: 0;
    }
}

.preorder-warning {
    color: $c-red;
    text-align: center;
    font-weight: $f-weight-bold;

}

.preorder-warning-summary {
    @include breakpoint(small) {
        margin-top: 13px;
    }
    @include breakpoint(large) {
        margin-bottom: 13px;
        margin-top: 0px;
    }
    margin-top: 13px;
}

.product-availability-list {
    margin: 0;
    padding: 0;
    text-align: left;

    li {
        list-style: none outside none;
    }

    .not-available {
        display: inline;
        font-size: 0.9em;
        line-height: 18px;
    }

    .on-order {  
        display: inline-block;

        &--label {
            font-size: 10px;
            font-weight: $f-weight-bold;
            letter-spacing: 1px;
            line-height: 1;
            text-transform: uppercase;
        }

        &:first-child {
            color: $c-gray;
        }
    }
}

#password-reset{
    white-space: nowrap;
}

.cart__header {
    padding-top: $cart-page-padding-top;
    margin-bottom: 16px;

    @include breakpoint(medium) {
        padding-top: 0;
        margin-bottom: 0;
    }

    @include breakpoint(large) {
        margin-left: 0;
        margin-right: 0;
    }

    &__content {
        @include breakpoint(medium) {
            padding-top: 80px;
        }

        @include breakpoint(large) {
            border-right: 1px solid $c-medium-gray;
            flex-basis: $cart-content-width-lg;
            padding-bottom: 13px;
            padding-left: 0;
            padding-top: 100px;
        }
    }

    h2 {
        margin-bottom: 15px;
        @include breakpoint(large) {
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 35px;
        }
    }

    .col.col--md-4 {
        @include breakpoint(large) {
            padding-top: 13px;
        }

        @include breakpoint(xlarge) {
            padding-top: 23px;
        }
    }

    .col.col--md-8 {
        @include breakpoint(large) {
            padding-top: 22px;
        }

        @include breakpoint(xlarge) {
            padding-top: 32px;
        }
    }
}
.dw-apple-pay-button,
.dw-apple-pay-button:hover {
    margin: 0;
}

#cart-table {
    // if this $bp-medium value is changed here, you will want to change
    // all other occurrences of it throughout this file
    @include responsive-table($bp-medium, true, false);

    margin-bottom: 0;
    padding-bottom: 0;

    @include breakpoint(medium){
        margin-bottom: 18px;
        padding-bottom: 10px;
    }

    @include bp(max-width, $bp-medium) {
        margin: 0 auto;

        thead {
            display: none;
        }

        .item-total {
            float: none;
        }

        .item-user-actions,
        .item-total .promo-adjustment {
            width: auto;
        }
    }

    thead {
        th {
            padding: $plist-table-thead-td-padding;
            text-align: $plist-table-thead-td-text-align;

            &:first-child {
                text-align: left;
            }

            &.header-qty {
                text-align: center;
            }
        }
    }


    td {
        box-sizing: border-box;
        display: block;
        padding: $plist-table-tbody-td-padding;

        @include breakpoint(medium) {
            display: table-cell;
        }

        &.cart-promo {
            text-align: left;
            padding: 20px 27px 0;

            @include breakpoint(medium) {
                padding-left: 0;
            }
        }
    }

    .header-total-price {
        text-align: right;
    }

    .item-image {
        border-right: 1px solid $c-stroke-gray;
        padding: $plist-table-tbody-td-image-padding-small;
        flex-basis: 141px;

        @include breakpoint(medium) {
            flex-basis: 160px;
            padding: $plist-table-tbody-td-image-padding-medium;
        }
    }

    .cart-row {
        display: flex;

        &:last-child {

            td.item-details {

                @include breakpoint(medium) {
                    padding-bottom: 6px;
                }

                @include breakpoint(large) {
                    padding-bottom: 14px;
                }
            }
        }
    }

    .rowcoupons {
        .item-quantity-details {
            display: block;
            float: none;
        }
        .item-total {
            &.h-mobile-only {
                display: inline-block;
                font-size: 16px;
                @include breakpoint(medium) {
                    display: none;
                }
            }
        }
    }

    .header-product-details,
    .item-image {
        text-align: center;
    }

    .header-total-price {
        text-align: right;
    }

    .item-details {
        flex-shrink: 1;
        text-align: left;
        width: 100%;

        .product-list-item {
            .sku {
                display: none;
            }

            .name {
                font-size: $f-size-xxs;
                margin-bottom: 4px;

                @include breakpoint(medium) {
                    font-size: $f-size-xs;
                    line-height: 1.14;
                }

                a {
                    color: $black;
                    font-weight: $f-weight-semibold;
                    letter-spacing: 1px;
                }
            }

            .attribute {
                color: $c-charcoal;

                .value {
                    font-weight: $f-weight-regular;
                }
            }
        }

        .home-delivery {
            display: none;

            @include breakpoint(medium) {
                display: none;
            }

            @include breakpoint(large) {
                display: none;
            }

            label {
                @include breakpoint(medium) {
                    color: $black;
                }
            }
        }

        .item-price, .item-stock, .item-qty {
            text-align: left;
            display: block;
        }

        .item-qty {
            font-weight: $f-weight-bold;
        }

        .item-qty, .item-stock {
            float: left;
        }

        .item-stock {
            margin-left: 15px;
        }

        .item-price {
            clear: both;
            font-weight: $f-weight-bold;
        }

        @include breakpoint(medium) {
            margin-left: 0;

            .item-price, .item-stock, .item-qty {
                display: none;
            }
        }

        .item-edit-details {
            display: table-cell;
        }

        .button-text {
            color: $c-red;
            font-size: 16px;
            line-height: 24px;
            font-weight: $f-weight-regular;
            text-transform: capitalize;
            letter-spacing: 0;

            &:before, &:after {
                display: none;
            }
        }

        .product-availability-list {
            display: inline-block;
            margin: 8px 11px 0 12px;

            @include breakpoint(small){
                margin: 8px 13px 0 12px;
            }
        }

        .item-user-actions {
            display: inline-block;
        }
    }

    .item-delivery-options {
        order: 6;
        text-align: center;
        flex-basis: 50%;

        @include breakpoint(medium){
            text-align: left;
            width: 8rem;
        }

        .form-row {
            margin-top: 0;
        }
    }

    .item-quantity {
        text-align: center;
        flex-basis: 124px;
        font-weight: $f-weight-bold;

        @include breakpoint(medium) {
            flex-basis: 160px;
            text-align: left;
        }

        @include breakpoint(large){
            text-align: right;
        }

        .product-quantity-error{
            display: block;
            padding-bottom: 5px;
        }
    }

    .c-product-quantity {
        margin-right: 0;

        @media screen and (max-width: $bp-medium) {
            width: 100px;
        }

        &__input {
            @media screen and (max-width: $bp-medium) {
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        &__button {
            &--down {
                @media screen and (max-width: $bp-medium) {
                    padding-left: 16px;
                }
            }

            &--up {
                @media screen and (max-width: $bp-medium) {
                    padding-right: 16px;
                }
            }
        }
    }

    .item-quantity-details:not(.item-gc-ctas) {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 3;
        text-align: center;
        flex-basis: 33.3333%;
        display: none;

        @include breakpoint(medium){
            display: table-cell;
            float: none;
            text-align: left;
        }

        .product-availability-list {
            margin: 0;
            padding: 0;
            max-width: 170px;

            @include breakpoint(medium){
                width: 5em; //for IE
                width: max-content;
                text-align: left;
                padding-left: 12px;
            }

            .not-available {
                color: $sangria;
                font-size: 0.9em;
            }
        }
    }

    .item-user-actions {
        text-align: right;

        @include breakpoint(large) {
            margin-top: -5px;
        }

        a,
        button {
            background: transparent;
            border: none;
            display: block;
            letter-spacing: 0;
            color: $brand-primary;
            font-size: $f-size-xxs;
            font-weight: $f-weight-regular;
            line-height: 1.43;
            text-decoration: underline;
            text-transform: none;

            @include breakpoint(large) {
                font-size: $f-size-xs;
            }

            &:not(:last-child) {
                margin-bottom: 7px;

                @include breakpoint(large) {
                    margin-bottom: 10px;
                }
            }
        }

        button {
            margin-left: auto;
        }
    }

    .item-price {
        order: 5;
        text-align: center;
        flex-basis: 33.3333%;

        @include breakpoint(medium){
            display: table-cell;
            text-align: left;
        }
    }

    .price-promotion {
        .price-sales {
            color: $sangria;
        }
        .price-standard {
            margin: 0 1em 0 0;
            text-decoration: line-through;
        }
    }

    .item-total {
        font-size: $f-size-xxs;
        font-weight: 700;
        line-height: 1.14;
        order: 7;
        word-wrap: break-word;
        text-align: right;

        @include breakpoint(medium){
            font-size: $f-size-xs;
            padding-top: 14px;
        }

        .promo-adjustment {
            @include breakpoint(medium){
                width: 100%;
                float: right;
            }
        }
    }

    .price-unadjusted,
    .price-adjusted-total {
        letter-spacing: 1px;
    }
    
    .price-unadjusted {
        color: $c-medium-gray;
        display: block;
        font-size: 10px;

        @include breakpoint(large) {
            font-size: 12px;
        }

        span {
            text-decoration: line-through;
        }
    }

    .price-adjusted-total {
        color: $dim-gray;
        display: block;
        margin-bottom: 2px;

        span {
            color: $c-red;
        }
    }

    .gift-cert-delivery {
        color: $sangria;
    }
    .gift-cert-user-actions {
        display:block;
        @include breakpoint(medium){
            display:none;
        }
    }
    
    .rowgiftcert {
        .item-info {
            .item-info-row {
                &:last-child {
                    align-items: center;
                }
            }
        }
    }
}

.pt_cart #cart-table {
    &.item-list thead tr {
        @include breakpoint(medium){
            border-bottom: 1px solid $c-light-gray;
        }
    }

    th {
        text-align: left;
    }

    th.section-header {

        @include breakpoint(medium){
            font-size: 14px;
            padding-bottom: 3px;
            padding-left: 8px;
        }

        &.header-total-price {
            text-transform: uppercase;
        }
    }

    th.header-total-price {
        text-align: right;

        @include breakpoint(medium){
            padding: 0 9px 3px 17px;
        }

        @include breakpoint(large) {
            padding-right: 18px;
            padding-left: 0;
        }

        @include breakpoint(xlarge) {
            padding-right: 24px;
        }
    }

    th.header-product-details {
        width: 33%;

        @include breakpoint(large){
            padding: 3px 15px 7px 15px;
        }

        @include breakpoint(xlarge){
            padding-left: 25px;
        }
    }

    th.header-delivery-options {
        width: 13%;
        text-align: center;
        display: none;

        @include breakpoint(large){
            padding-right: 9px;
            text-align: right;
            width: 16%;
        }

        @include breakpoint(xlarge){
            text-align: center;
            padding-left: 21px;
        }
    }

    th.header-product-price {
        text-align: center;

        @include breakpoint(medium){
            text-align: right;
            padding-right: 23px;
            padding-left: 0;
        }

        @include breakpoint(large){
            text-align: center;
            padding-right: 15px;
        }

        @include breakpoint(xlarge){
            text-align: right;
            padding-left: 31px;
        }
    }

    th.header-product-qty {
        padding-left: 0;

        @include breakpoint(medium){
            padding-right: 28px;
            padding-left: 0;
            text-align: left;
        }

        @include breakpoint(large){
            padding-right: 87px;
        }

        @include breakpoint(xlarge){
            padding-right: 0;
            padding-left: 5px;
        }
    }

    td {
        @include breakpoint(medium){
            padding-top: 33px;
        }
    }

    .item-details {
        input {
            width: 26px;
            height: 26px;
            text-align: center;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .item-quantity {
        text-align: center;

        input {
            text-align: center;
            display: inline-block;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"].valid {
            background: none;
            background-color: $white;
        }
    }

    .mobile-details-item-price, .mobile-details-item-total {
        font-weight: $f-weight-bold;
        font-size: 14px;
        padding-top: 9px;
        padding-right: 20px;
        display: inline-block;

        @include breakpoint(medium) {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .item-details {
            .input-text,
            .product-availability-list {
                display: none;
            }
        }

        td.item-price {
            display: table-cell;
        }
    }

    @include breakpoint(large) {
        td.item-delivery-options,
        th.header-delivery-options {
            display: table-cell;
        }
    }

    .rowbundle {
        .item-info-col {
            display: flex;
            width: 100%;
        }
    }

    .name {
        font-weight: $f-weight-semibold;
    }
}

.pt_cart {
    background-image: url('../images/bg-textured-mobile-white.png');
    background-size: 100% auto;
    
    @include breakpoint(medium) {
        background-image: url('../images/bg-textured-white.png');
        background-size: 75% auto;
    }

    &.registered {
        @include breakpoint(large) {
            .error-form {
                width: 100%;
                margin-bottom: 20%;
            }
        }
    }

    .cart-actions {
        text-align: center;
        width: 100%;

        @include breakpoint(medium) {
            text-align: right;
        }

        .form-row-button {
            @include bp(max-width, $bp-medium) {
                margin-bottom: 0;
            }
        }

        button {
            width: 100%;

            &.button--secondary {
                width: auto;
            }
        }
    }

    .login-box-content.returning-customers {

        .form-row-button {
            display: inline-block;
        }

        .login-rememberme {
            margin: 0;
        }
    }
}

.cart-footer {
    margin: 0 0 35px 0;
    text-align: center;

    @include bp(max-width, $bp-medium) {
        border-bottom: 1px solid; 
        margin-bottom: 10px;
        padding-bottom: 4px;
    }

    .update-cart {
        display: inline-block;
        margin-bottom: 25px;

        @include breakpoint(medium){
            margin-top: 23px;
            float: right;
        }
    }

    @include breakpoint(medium) {
        text-align: left;
    }
}

.cart-coupon-code {
    background-color: $c-light-gray;
    padding: 25px 27px;
    width: 100%;

    @include breakpoint(medium) {
        padding: 20px 24px;
        padding-top: 20px;
    }

    &-inputs,
    &-messaging {
        width: 100%;
    }

    &-inputs {
        display: flex;
    }

    &-messaging {
        grid-column-start: 2;
    }

    .field-wrapper {
        display: flex;
            align-items: center;
            flex-wrap: wrap;

        @include breakpoint(large) {
            display: grid;
                grid-template-columns: auto minmax(55%, 1fr);
                grid-template-rows: auto auto;
        }
    }

    label {
        margin-bottom: 9px;
        padding-top: 1px;
        text-align: center;
        width: 100%;

        @include breakpoint(medium) {
            margin-bottom: 0;
            margin-right: 25px;
            width: auto;
        }
    }

    input {
        flex: 1 0 0;
        height: 40px;

    }

    input[type="text"].valid {
        display: inline-block;
    }

    .error {
        padding-top: 0.4em;
        text-align: left;
        font-size: 12px;
        line-height: 20px;

        @include breakpoint(medium) {
            margin-top: 20px;
        }
    }

    #add-coupon {
        height: 40px;
        width: 120px;

        @include breakpoint(medium) {
            width: 110px;
        }
    }
}

.rowcoupons {
    padding-top: 18px;
}

.cart-coupons {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;

    &-item-details {
        display: grid;
            align-items: center;
            gap: 8px 0;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            justify-content: space-between;
        text-align: left;
    }

    &-code {
        font-family: $sans-serif;
        font-size: 15px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        line-height: 1;
        text-transform: uppercase;
    }

    &-remove,
    &-discount {
        text-align: right;
    }

    &-discount {
        color: $c-red;
        grid-column-start: 2;
    }
}

.order-totals-table {
    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }
    .order-shipping, .order-sales-tax, .order-shipping-discount,.order-handling {
        font-size: 14px;
    }

    .order-discount.discount {
        td {
            font-size: 14px;
        }
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;

    .dw-apple-pay-button {
        font-size: 1.35em;
        top: -4px;
        padding: 15px 32px;
        display: inline-block;
        border-radius: 0;
        border: 2px solid rgba(0, 0, 0, 0.7);
        background-color: #444;

        @include breakpoint(medium) {
            margin: 7px 20px 7px auto;
        }

        &:hover, &:active {
            // setting to normal padding to override a padding: 0;
            padding: .5em 2em;
            margin-right: 20px;
        }

        &:after {
            content:'';
            visibility: visible;
        }
    }
}

.cart-action-continue-shopping {
    button.button-text {
        margin-top: .2rem;
        margin-right: 1rem;

        @include breakpoint(medium){
            margin-top: .6rem;
        }
    }

    @include breakpoint(medium) {
        margin-right: 40px;
        float: left;
        margin-top: 5px;
    }

    .cart-empty & {
        margin-right: 0;
    }
}

.cart-recommendations {
    .product-listing {
        @extend %clearfix;

        h2 {
            text-align: center;
        }

        .search-result-items {
            margin-top: 1em;
        }
    }
}

.cart-actions-top {
    margin-top: -1px;
    margin-bottom: 1rem;

    @include breakpoint(medium){
        margin-bottom: 0;
    }

    .cart-action-continue-shopping {
        float: none;
    }
}

.cart-empty {
    @extend %clearfix;

    h1 {
        text-align: center;
    }

    .cart-action-continue-shopping {
        float: none;
        text-align: center;
        margin-bottom: 4rem;
    }
}

.promo-adjustment {
    color: $sangria;
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

.preorder-warning {
    color: $c-red;
    text-align: center;
    font-weight: $f-weight-bold;

}

.preorder-warning-summary {
    @include breakpoint(small) {
        margin-top: 13px;
    }
    @include breakpoint(large) {
        margin-bottom: 13px;
        margin-top: 0px;
    }
    margin-top: 13px;
}

.product-availability-list {
    margin: 0;
    padding: 0;

    li {
        list-style: none outside none;
    }
}

#password-reset{
    white-space: nowrap;
}

.sign-in-details {
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    width: 100%;
    .error-form {
        margin-bottom: 20%;
        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }

    @include breakpoint(large) {
        flex-basis: 100% - $cart-content-width-lg;
        padding-left: 54px;
        display: inline-block;
        padding-bottom: 80px;
    }
    .sign-in-radio {
        display:flex;
        
        label {
            letter-spacing: normal;
        }
    }
    input.inactive[type="radio"]:checked:after {
        position: relative;
        border: none;
        background: none;
    }
    input.inactive[type="radio"]:checked:before{
        border: 2px solid $c-gray;
    }
    h4 {
        padding-top: 35px;
        padding-bottom: 25px;
        @include breakpoint(medium) {
            padding-top: 20px;
            padding-bottom: 80px;
        }
        @include breakpoint(large) {
            padding-top: 0;
        }
        &.login-header {
            padding-bottom:30px;
            padding-top:20px;
            @include breakpoint(medium) {
                padding-bottom:20px;
            }
        }
    }
    &:not(:root:root) {
        position:relative;

        @include breakpoint(large) {
            margin-top: -66px;
        }
    } 
    .button-mobile-checkout {
        text-align: center;
        margin-top: 8%;
        @include breakpoint(medium) {
            display: none;
        }
    }
    .error-message {
        margin-top: -15px;
        @include breakpoint(large) {
            margin-top: 70px;
        }       
    }
}

.login-action-row {
    // Position as if it were inside the form-row
    margin-bottom: $form-row-margin-bottom;
    margin-top: -$form-row-margin-bottom;
}

.login-radio {
    display: flex;
    width: 90%;
    align-items: center;
}

.help-text {
    font-size: 14px;
}

.registered {
    width: unset;
    border: none;
}

.top {
    position: absolute;
    top: 8%;
    right: 45%;
}
.bottom {
    position: absolute;
    top: 90&;
    right: calc(100% - 180px);
}

label.clear-input {
    display:inline-block;
    color: $black;
}

.sign-in-help {
    display: block;
    width: 100%;
    align-items: center;
    @include breakpoint(large){
        display: flex;
        margin-bottom: -30px;
    }
}

.login-rememberme.cart {
    width: 60%;
}

.button.checkout {
    @include breakpoint(medium) {
        margin-top: 20px;
    }
}

.checkout {
    &.login,
    &.guest-checkout {
        position: relative;
    }
}

.cart-checkout-btns {
    background-color: $c-light-gray;
    padding: 30px 27px 28px;
    position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

    @include breakpoint(medium) {
        background-color: transparent;
        margin-bottom: 20px;
        padding: 0;
        position: static;
        z-index: unset;
    }
}

.cart-button {
    letter-spacing: 3px;
    width: 100%;
}

.checkout.sign-up {
    position: relative;
    @include breakpoint(large) {
        top: 130px;
        float: right;
    }
}

.email-capture {
    position:relative;
    padding:0;

    @include breakpoint(large) {
        width: 100%;
        margin:0;
    }

    .form-row {
        .dialog-required {
            margin-bottom: 25px;
        }
    }
    &.hidden {
        visibility: hidden;
        height:0;
        width:0;
    }
}

.cart-recommendations {
    display: block;
    margin-top:10%;
    &.adjust-margin {
        margin-top:0;
        &:not(:root:root) {
            margin-top:0;
        }
    }
    &:not(:root:root) {
        margin-top:0;
    }
}

.form-row-button {
    margin-bottom: 0;
}

.cart-header {
    @include bp(max-width, $bp-medium) {
        padding-bottom: 30px;
    }

    &--sidebar {
        display: flex;
            align-items: flex-end;
        
        @include bp(max-width, $bp-medium) {
            padding-bottom: 16px;
        }

        @include breakpoint(medium) {
            margin-bottom: 20px;
        }

        .cart-sub-header {
            flex-basis: 1 0 0;
            padding-right: 30px;
        }
    }

    h2 {
        font-size: 48px;
    }

    .dialog-required {
        margin-bottom: 4px;
    }
}

.cart-sub-header {
    font-size: 26px;
    letter-spacing: 0.5px;
    line-height: 1.42;
    margin-top: 20px;

    @include breakpoint(medium) {
        flex-basis: auto;
        flex-grow: 1;
        font-size: 28px;
        letter-spacing: normal;
        line-height: 1.14;
    }

    &--desktop {
        @include bp(max-width, $bp-medium) {
            display: none;
        }
    }

    &--mobile {
        @include breakpoint(medium) {
            display: none;
        }
    }
}

.pt_cart {
    * {
        box-sizing: border-box;
    }

    .form-row-button {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}


.pt_cart {
    .cart-recommendations, .empty-cart-einstein-recomm {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        overflow: hidden;
    }
    .cart-bonus-row {
        .add-to-wishlist,
        .add-to-registry,
        .select-bonus {
            display: none !important; // override js
        }
        .item-quantity-mobile {
            margin: 0 25px 0 0;
            font-weight: bold;
            @include breakpoint(medium) {
                display: none;
            }
        }
        .bonus-item-mobile {
            margin: 0 0 0 20px;
        }
    }
    .cart-promo .select-bonus,
    .bonusproducts a{
        &[title^='Update Bonus Product'] {
            display: none;
        }
    }
    .recommendations {
        max-width: 95vw;
        @include breakpoint(medium) {
            max-width: 97vw;
        }
        @include breakpoint(xlarge) {
            max-width: 1280px;
        }
    }
}

.checkout-guest-wrap {
    display: none;
}

.is-guest-checkout {
    .checkout-guest-wrap {
        display: block;
    }

    .checkout-login-wrap {
        display: none;
    }
}

.credit-card-acceptance {
    display: flex;
        align-items: center;
        justify-content: center;

    @include breakpoint(medium) {
        justify-content: space-between;
    }

    @include bp(max-width, 374px) {
        display: block;
    }

    h4 {
        font-family: $sans-serif;
        font-size: 14px;
        font-weight: $f-weight-semibold;
        letter-spacing: 1px;
        margin-right: 8px;
        text-align: right;
        padding-bottom: 0;
        padding-right: 3%;
        padding-top: 0;
        white-space: nowrap;

        @include bp(max-width, 374px) {
            text-align: left;
        }

        @include bp(max-width, $bp-xsmall) {
            letter-spacing: 0;
            padding-right: 0;
        }

        @include breakpoint(medium) {
            margin-right: 0;
        }
    }

    p {
        margin-bottom: 0;
        margin-top: 0;

        @include bp(max-width, 374px) {
            display: block;
        }
    }

    img {
        height: auto;
        margin-right: -1px;
        text-align: right;
        width: 41px;

        @include breakpoint(large) {
            width: 47px;
        }
    }
}

.authorized-btn-checkout {
    
}