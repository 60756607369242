.s-sizechart-dialog {
    &.ui-dialog {
        height: 100vh;
        max-width: none;
        position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        width: 100%;

        @include breakpoint(medium) {
            position: static;
            margin: 27px;
            max-width: 972px;
        }

        @include breakpoint(xlarge) {
            max-width: 1168px;
        }

        .ui-dialog-titlebar {
            box-sizing: border-box;
            display: block;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;

            .ui-dialog-titlebar-close {
                position: static;

                .ui-button-icon-primary {
                    display: none;
                }
            }
        }

        .ui-dialog-content {
            width: 100% !important;
        }
    }
}

.c-sizechart {
    &__btn {
        &, &:hover {
            // to override base styles
            &:not(.disabled):not(:disabled) {
                background-color: transparent;
                border: none;
                color: inherit;
                font-family: inherit;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.43;
                letter-spacing: normal;
                margin-bottom: 24px;
                padding: 0;
                text-decoration: underline;
            }
        }
    }

    &__modal {
        display: none;

        &, * {
            box-sizing: border-box;
        }

        &__close {
            background-color: transparent;
            padding: 20px;

            &::before {
                @include svg-view-close-white-v2;
            }
        }
    }

    &__header {
        background-color: #000;
        height: auto;
        min-height: 100px;
        color: #acacac;

        &__top {
            display: flex;
                justify-content: flex-end;
        }
    }

    &__body {
        height: calc(100vh - 100px);
        overflow: auto;

        @include breakpoint(medium) {
            height: auto;
        }
    }

    &__tabs {
        display: flex;
            justify-content: center;
        padding-bottom: 17px;

        a {
            color: inherit;
            font-family: 'futura-bold', sans-serif;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.1;
            letter-spacing: 0.5px;
            padding: 1px 10px 0;
            text-transform: uppercase;

            &.is-active {
                color: #fff;
            }

            &:hover {
                text-decoration: none;

                .rollover {
                    display: block;
                }
            }
        }
    }

    &__content {
        display: none;
        overflow-x: auto;
        padding: 17px 27px;
        font-family: $sans-serif;

        .disclaimer {
            clear: left;
            text-align: center;
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &.is-active {
            display: block;
        }

        @include breakpoint(large) {
            padding: 40px 100px;
        }

        table {

            tr {

            }

            th, td {
                text-align: center;
            }

            th {
                font-family: 'futura-bold', sans-serif;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 1.29;
                text-transform: uppercase;
            }

            thead {
                th {
                    padding: 0 20px 17px;
                }
            }

            tbody {
                tr {
                    margin-bottom: 4px;
                }

                th, td {
                    padding: 20px;
                }

                td {
                    border: 1px solid #dcdcdc;
                    border-bottom-width: 9px;
                    min-width: 120px;
                    position: relative;

                    @include breakpoint(xlarge) {
                        min-width: 194px;
                    }

                    &::after {
                        // creates virtual gap between table rows
                        background-color: #fff;
                        content: '';
                        display: block;
                        height: 7px;
                        position: absolute;
                            bottom: -8px;
                            left: -1px;
                            right: -1px;
                        width: calc(100% + 2px);
                    }

                    br {
                        @include breakpoint(xlarge) {
                            content: '' !important; // basically wipes out line break
                            display: inline-block !important;

                            &::before {
                                // create slash in place of <br>
                                content: '/';
                                display: inline;
                                margin: 0 .25em;
                            }
                        }
                    }

                }

                tr:last-child {
                    td {
                        &::after {
                            height: 8px;
                            bottom: -9px;
                        }
                    }
                }
            }
        }

        &--measure {
            h2 {
                font-family:  'futura-bold', sans-serif;
                font-size: 32px;
                font-weight: bold;
                line-height: 1.13;
                letter-spacing: 0.5px;
                margin-bottom: 20px;

                &:first-of-type {
                    margin-top: 100px;
                }
            }

            h3 {
                font-size: 14px;
                font-family: inherit;
                font-weight: 600;
                line-height: 1.43;
                letter-spacing: 1px;

            }

            img {
                max-width: 100%;

                @include breakpoint(medium) {
                    float: left;
                    margin-right: 50px;
                    max-width: 50%;
                }
            }
        }
    }
}
