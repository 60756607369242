.c-songs-nav {
    display: flex;
    list-style: none;
    margin-bottom: -14px;
    margin-left: -16px;
    margin-right: -15px;
    max-width: calc(100% + 31px);
    overflow-y: hidden; // Also sets "x" to auto
    padding-left: 0;

    @include breakpoint(medium) {
        margin-bottom: 110px;
        margin-left: -17px;
        margin-right: -17px;
        max-width: calc(100% + 34px);
    }

    &__item {
        flex-grow: 1;
    }

    &__link {
        border: none;
        box-sizing: border-box;
        display: inline-block;
        font-size: 14px;
        font-weight: $f-weight-semibold;
        line-height: 1.43;
        min-width: 100%;
        padding: 10px 16px 10px 15px;
        vertical-align: top;

        @include breakpoint(medium) {
            padding-left: 17px;
            padding-right: 17px;
        }
    }
}