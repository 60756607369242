/******************************************************************
Grid System
Based on http://flexboxgrid.vivid-websolutions.nl/
******************************************************************/
@import "grid/variables";

// Mixins
@import "grid/mixins/helpers";
@import "grid/mixins/grid-framework";

// Components
@import "grid/components/col";
@import "grid/components/grid";
@import "grid/components/row";
@import "grid/components/text";

@include init($breakpoints, $columns);
