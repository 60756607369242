.pt_content-metclub.exclusives {
    .metclub-exclusives .tabs__content .tabs__content_wrapper {
        padding-top: 0;

        .product-container {
            @include grid-row;
        }

        .product-tile {
            @include grid-col;
            width: 50%;
            flex: none;
            color: $white;

            a {
                color: $white;
            }

            .product-pricing {
                .product-sales-price {
                    color: $white;
                }
            }

            @include breakpoint(medium) {
                width: 33.3333%;
            }

            @include breakpoint(large) {
                width: 25%;
            }
        }
    }

    .tab__content.content-width {
        @include content-width;
    }

    .tabs__content .tab--videos .tab__content {
        .pagination {
            @include content-width;
        }

        #videogrid .video-content-list {
            @media (max-width: $bp-small) { // yes.... ask Ruben
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                max-width: calc(100% - 23px);

                .col {
                    padding: 12px 6px 0 6px;
                }
            }
        }

        #videogrid .video-content-list .col {
            .video-info a,
            .video-count {
                color: $white;
            }
        }
    }

    .tabs__content .tab--photos .tab__content {
        #photogrid .video-content-list .col {
            .video-info a,
            .video-count {
                color: $white;
            }
        }
    }

    .tabs__content .tab--forums .tab__content {
        @include width;
        @include forum-tab-content;
    }

    .metclub-exclusives {
        margin-top: 0;
    }

    .metclub-page-title {
        margin-left: 0;

        @include breakpoint(large) {
            margin-bottom: 36px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 41px;
        }
    }

    .pagination {
        margin-top: 13px;

        @include breakpoint(medium) {
            margin-top: 31px;
        }

        @include breakpoint(large) {
            margin-top: 39px;
            margin-bottom: 32px;
        }

        @include breakpoint(xlarge) {
            margin-top: 39px;
        }

        .current-page {
            border-color: $white;
            background-color: $white;
            color: $black;
        }

        li a {
            color: $white;
        }

        li.first-last a {
            &:after {
                @include svg-view-slider-next-white;
                display: inline-block;
            }
        }
    }
}

.presale-wrapper {
    @include content-width;
    padding: 10px 0 25px;

    @include breakpoint(medium) {
        padding: 32px 0 40px;
    }

    @include breakpoint(large) {
        padding: 33px 0 64px;
    }

    p {
        margin: 0;
    }

    .no-presale {
        padding: 20px 0 0;

        @include breakpoint(medium) {
            padding: 10px 0 0;
        }

        @include breakpoint(large) {
            padding: 30px 0 0;
            font: $f-weight-medium 42px/45px $f-header;
            color: $white;
        }
    }

    .presale-event {
        border-bottom: 1px solid $white;
        display: flex;
        flex-direction: column;
        padding: 0 8px;
        margin-top: 29px;

        &:first-child {
            margin-top: 0;
        }

        @include breakpoint(medium) {
            flex-direction: row;
            margin-left: 71px;
            margin-right: 71px;
        }

        @include breakpoint(large) {
            margin-right: 0;

            .info {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }
        }

        @include breakpoint(xlarge) {
            margin-left: 103px;
            margin-right: 103px;
        }
    }

    .show-date-venue {
        display: flex;
        margin-bottom: 27px;

        .date-numbers {
            font-weight: $f-weight-bold;
            margin-right: 16px;
            line-height: 20px;

            @include breakpoint(medium) {
                margin-top: 1px;
            }
        }

        .venue-city {
            font-weight: $f-weight-bold;
        }

        .venue {
            line-height: 20px;

            @include breakpoint(medium) {
                line-height: 23px;
            }
        }

        @include breakpoint(medium) {
            margin-right: 26px;
            flex-basis: 51%;
        }

        @include breakpoint(large) {
            flex-basis: 30%;
        }
    }

    .link {
        .button {
            line-height: 10px;
            margin-bottom: 10px;
            padding: 6px 20px
        }

        @include breakpoint(large) {
            order: 2;
        }
    }

    .event-info {
        margin: 0;

        .content {
            margin-bottom: 33px;

            &:last-child {
                margin-bottom: 25px;
            }
        }

        @include breakpoint(large) {
            width: 67%;

            .content {
                margin-right: 26px;
            }
        }
    }
}
