nav[role="navigation"] {
    @extend .clearfix;
    color: $f-body-color;
    z-index: 102;
    transform: translateX(-100%);
    height: 100vh;
    opacity: 0;
    position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: opacity 500ms ease-in-out;
    display: flex;
    width: 100%;
    flex-direction: column;

    @include breakpoint(large) {
        flex-wrap: nowrap;
        max-width: none;
    }

    .euro-store {
        margin-top: 36px;

        span, a {
            display: block;
        }

        span {
            color: $c-dark-gray;
        }

        a {
            font-weight: $f-weight-bold;
            line-height: 24px;
        }
    }

    .navigation-close {
        display: flex;
        line-height: 28px;
        width: auto;

        span {
            font-weight: $f-weight-bold;
            margin-left: 22px;
        }

        i.close-icon {
            line-height: 22px;
        }

        @include svg-view-close-gray-element {
            fill: $c-gray;
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    .navigation-close--desktop {
        display: none;
        top: 9px;

        @include breakpoint(large) {
            display: flex;
        }

        span.close-text {
            font-size: 15px;
            text-transform: uppercase;
            margin-left: 8px;
            line-height: 1.6;
        }
    }

    .social-links {
        display: flex;
            align-items: flex-start;
        border-bottom: 1px solid $c-black;
        box-sizing: border-box;
        flex-direction: column;
        margin-bottom: 35px;
        margin-top: 36px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 30px;
        @include transitioned;
    
        @include breakpoint(large) {
            border-bottom: none;
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    
        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
    
            li {
                line-height: 24px;
                display: flex;
                align-items: center;
    
                &:first-child {
                    margin-left: 0;
                }

                a {
                    display: block;
                    height: 26px;
                    width: 26px;
                }
    
                i {
                    display: block;
                }
    
                svg {
                    display: block;
                }
            }
        }
    }
}

.menu-toggle {
    background-color: transparent;
    border: none;
    color: inherit;
    display: block;
    padding: 0;
    line-height: 20px;

    @include breakpoint(xlarge) {
        position: relative;
            z-index: 1;
    }

    .menu-icon, .menu-white-icon {
        display: flex;
        font-style: normal;
        font-size: $f-size;
        color: inherit;
        text-transform: none;
        align-items: center;

        svg {
            order: 1;
        }

        @include breakpoint(medium) {
            .visually-hidden {
                margin-left: 8px;
                order: 2;
                width: auto;
                height: auto;
                clip: auto;
                position: relative;
            }
        }
    }

    &:before,
    &:after {
        display: none;
    }

    &:hover {
        &:not(:disabled):not(.disabled) {
            background-color: transparent;
            color: inherit;
        }
    }
}

.menu-category {
    @extend %clearfix;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    transition: $menu-reveal-transition;
    width: 100%;
    flex-grow: 1;

    @include breakpoint(large) {
        margin-top: 0;
        width: auto;
    }

    &.level-1 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(large) {
            min-width: 620px;
            flex-direction: row;
        }

        &.column-1,
        &.column-2 {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            min-width: auto;
        }

        &.column-1 {
            width: 100%;

            @include breakpoint(large) {
                width: 35%;
            }
        }

        &.column-2 {
            width: 100%;

            @include breakpoint(large) {
                padding-left: 70px;
                width: 65%;
            }

            .menu-nav-item {
                &:last-child {
                    order: 2;
                }
    
                &.fan-club {
                    order: 1;
                }
            }
        }
    }

    &.level-3-active {
        transform: translate(-200%);

        @include breakpoint(large) {
            transform: translate(0);
        }
    }

    > li > a {
        color: $c-nav-links-color-small;
        font-weight: $nav-links-font-weight-small;
        display: inline-block;
        font-size: $nav-links-font-size;
    }

    .level__parent {
        margin: $nav-links-padding-small;
        color: $black;
        font-weight: $f-weight-bold;
        position: relative;
        line-height: 32px;

        i {
            @include position(absolute, 50% null null 0);
            transform: translateY(-50%);
            font-size: 11px;
        }

        &:hover {
            cursor: pointer;
        }

        @include breakpoint(large) {
            display: none;
            position: relative;
            padding-left: 18px;
        }

        span {
            position: relative;
            display: inline-block;

            @include navPsuedo(18px, $c-light-gray, 3px);
        }
    }
}

.level-1 > li {
    @extend %clearfix;
    // override background-color for small viewport
    background-color: transparent;

    @include breakpoint(xlarge) {
        margin-bottom: 16px;
    }

    .menu-item-toggle {
        color: $citrus;
        cursor: pointer;
        float: left;
        line-height: 1.1;
        padding: 1rem 0.8rem;
        transition: background-color $animation-base-timing;
        width: 2.1rem;

        &:hover {
            background-color: darken($night-rider, 10%);
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    a {
        transition: background-color $animation-base-timing, color $animation-base-timing;

        @include breakpoint(xlarge) {
            line-height: $b-line-height;
        }
    }

    > a {
        float: left;

        @include breakpoint(xlarge) {
            float: none;
            font-weight: $f-weight-bold;
        }
    }
}

.level-2 {
    display: none;

    @include breakpoint(xlarge) {
        display: block;
    }
}

.level-3 {
    @include transitioned;

    position: absolute;
        left: calc(100vw - 100%); // Account for the fact that the parent is shifted by more than just its width
        top: -48%;
    text-align: center;
    transform: translateX(100%);
    visibility: hidden;
    width: 100%;

    @include breakpoint(xlarge) {
        display: none;
        transform: translateX(0);
        position: relative;
        top: initial;
        visibility: visible;
    }

    .menu-vertical .euro-store {
        margin-top: 12px;

        span {
            padding: 0 26px 0 18px;
        }
    }

    > ul {
        @extend .clearfix;
        padding-left: 0;

        @include breakpoint(xlarge) {
            display: flex;
                flex-direction: column;
        }
    }

    .menu-category > .active &,
    .menu-sub-nav > .active & {
        visibility: visible;
    }

    .menu-back {
        background-color: transparent;
        border: none;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        position: relative;
        text-transform: none;
        letter-spacing: 0;
        font-size: $f-size;

        span {
            line-height: 23px;
            display: inline-block;
        }

        &::before {
            border-bottom: 3px solid $c-black;
            border-left: 3px solid $c-black;
            content: '';
            display: block;
            height: 11px;
            position: absolute;
                left: 3px;
                top: 50%;
            line-height: 1;
            transform: translateY(-50%) rotate(45deg);
            width: 11px;
        }

        &::after {
            display: none;
        }
    }

    .banner {
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }

        img {
            vertical-align: top;
        }
    }
}

.svg-user-dims {
    height: 18px;
    vertical-align: top;
    width: 17px;
}

// =============================================
// Megamenu Style navigation
// =============================================

.megaMenu {
    background-color: $white;
    background-image: var(--megamenu-bg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include breakpoint(large) {
        flex-wrap: wrap;
    }

    .mega-menu {
        &-header {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto 24px auto;
            padding: 22px 27px 0;
            width: 100%;

            @include breakpoint(medium) {
                padding: 45px 24px 0;
            }

            @include breakpoint(large) {
                padding: 33px 49px 0;
            }

            @include breakpoint(xlarge) {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 22px 54px 0;
            }

            &.account {
                .mega-menu-logo-link {
                    @include bpmm($bp-medium, $bp-large) {
                        transform: translate(27%, 4%);
                    }
                }
            }

            &-left {
                box-sizing: border-box;
                padding-top: 0;

                .navigation-close {
                    padding: 0;
                }
            }

            &-right {
                box-sizing: border-box;
                display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                padding-top: 0;

                @include breakpoint(xlarge) {
                    padding-top: 8px;
                }

                .mini-cart-link {
                    padding-bottom: 0;
                    padding-right: 0;
                    padding-top: 0;

                    .minicart-quantity {
                        top: -12px;
                    }
                }

                .user-account {
                    padding-bottom: 0;
                    padding-top: 0;
                }
            }
        }

        &-search-wrapper {
            position: relative;
                top: 0;

            .search-active & {
                top: -4px;

                @include breakpoint(large) {
                    top: -5px;
                }
            }
        }

        &-cat-links {
            @include transitioned;
            
            // Set mobile padding without disrupting shared desktop padding
            @media only screen and (max-width: $bp-large) {
                padding-left: $megamenu-padding-x;
                padding-right: $megamenu-padding-x;
            }

            @include breakpoint(large) {
                margin-bottom: 26px;
            }
        }

        &-divider {
            border-bottom-width: 0;
            border-color: $c-black;
            transition: $menu-reveal-transition;

            @include breakpoint(large) {
                border-bottom-width: 1px;
                margin-top: 25px;
            }

            &--mobile {
                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }

        &-logo-link {
            display: inline-block;
            text-align: center;
            transform: translate(-5%, 14%);
            width: $header-logo-size-small;

            @include breakpoint(medium) {
                transform: translate(27%, -2%);
            }

            @include breakpoint(large) {
                transform: translate(-12%, -2%);
                width: $header-logo-size-large;
            }

            @include breakpoint(xlarge) {
                margin-top: 0;
                transform: translate(-33%, 0);
            }

            svg {
                width: 68px;
                height: 68px;

                @include breakpoint(large) {
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }

    .svg-search-dims {
        height: 18px;
        width: 18px;
    }

    .svg-close-dims {
        height: 18px;
        width: 18px;
    }

    .menu-nav {
        &-item {
            @include breakpoint(large) {
                display: inline-block; // Prevents the columns from splitting up a given item's content
                margin-bottom: 14px;
                vertical-align: top;
                width: 100%; // Ensure elements take up the total column width
            }

            &__fifth-member {
                margin-bottom: 15px;

                @include breakpoint(large) {
                    margin-bottom: 10px;
                }

                a {
                    display: inline;
                    font-weight: 300;
                    text-decoration: underline;
                }

                h4 {
                    font-size: 18px;
                    letter-spacing: 1px;
                    margin: 8px 0 8px 0;
                }
            }

            .level-2 {
                margin-bottom: 10px;
                padding-top: 11px;

                @include breakpoint(large) {
                    display: block !important; // Important needed as "slideToggle" JS toggles an inline style
                    margin-bottom: 0;
                    padding-top: 14px;
                }

                &.fan-club-category {
                    padding-left: 12px;

                    @include breakpoint(large) {
                        padding-left: 0;
                    }
                }
            }
        }

        &-link {
            background: transparent;
            border: none;
            color: $c-nav-links-color-small;
            display: flex;
                align-items: center;
            font-family: $f-header;
            font-size: 40px;
            font-weight: $nav-links-font-weight-small;
            letter-spacing: 0.5px;
            line-height: 1.05;
            margin-bottom: 8px;
            overflow: visible;
            padding: 0;
            text-transform: uppercase;
            width: 100%;

            &--l3 {
                justify-content: center;
            }

            &:hover {
                &:not(:disabled):not(.disabled) {
                    background: transparent;
                    color: $c-nav-links-color-small;
                }
            }

            &.hidden {
                display: none;
            }

            &.has-sub-menu {
                position: relative;
                float: none;

                i {
                    display: inline-block;
                    transition-duration: 0.5s;
                    @include position(absolute, auto 0 0 auto);

                    @include svg-view-menu-carrot-element {
                        fill: $c-gray;
                    }

                    @include breakpoint(large) {
                        display: none;
                    }

                    &.expanded {
                        transform: rotate(180deg);
                        transition-duration: 0.5s;
                    }
                }
            }

            @include breakpoint(large) {
                font-size: 48px;
                margin-bottom: 0;
            }

            &.menu-back {
                margin-bottom: 29px;

                @include breakpoint(large) {
                    margin-bottom: 0;
                }
            }

            &--mobile {
                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }
    }

    .menu-sub-nav {
        padding-left: 0;

        @include breakpoint(large) {
            display: flex;
                flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -16px;
            overflow: hidden;
        }

        &:hover {
            .menu-sub-nav-link {
                @include breakpoint(large) {
                    opacity: 0.5;
                }
    
                &:hover {
                    @include breakpoint(large) {
                        opacity: 1;
                    }
                }
            }
        }

        &-item {
            &--l2 {
                @include bp(max-width, $bp-large) {
                    display: flex;
                    justify-content: space-between;

                    .thirdSubCatList {
                        .menu-back {
                            position: relative;
                            margin-bottom: -20px;
                        }

                        .menu-nav-link--l3 {
                            .menu-nav-link {
                                display: inline-block;
                                margin-left: 20px;
                                margin-right: 20px;
                                position: relative;
                                width: initial;
                            }
                        }
                    }
                }

                .has-sub-menu {
                    text-align: right;
                    width: 15px;
                    @include breakpoint(large) {
                        display: none;
                    }

                    i {
                        display: block;
                        transform: rotate(-90deg);
                    }
                }
            }

            &--Fan-Club {
                &:last-child {
                    order: 1;
                }

                &.contests {
                    order: 2;
                }
            }
        }

        &-link {
            display: inline-block;
            font-size: $nav-links-font-size;
            font-weight: $nav-links-font-weight-small;
            letter-spacing: 0.5px;
            line-height: 1.86;
            margin-bottom: 10px;
            overflow: visible;
            text-transform: uppercase;
            width: 100%;

            @include breakpoint(large) {
                margin-bottom: 8px;
                padding-left: 8px;
                padding-right: 16px;
                position: relative;
                width: auto;
            }

            &--l2 {
                display: inline-block;
            }

            &::before {
                @include breakpoint(large) {
                    content: '/';
                    position: absolute;
                        left: -4px;
                        top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .nav-external-links {

        transition: $menu-reveal-transition;
        width: 100%;

        .content-asset {
            align-items: center;
            display: grid;
                column-gap: clamp(15px, 5.31vw, 40px);
                grid-template-columns: repeat(3, auto);
                justify-content: space-evenly;
            padding-right: 8px;

            @include breakpoint(large) {
                gap: 85px;
                grid-template-columns: repeat(6, auto);
                padding-right: 0;
            }
        }

        .awmh {
            @include breakpoint(large) {
                margin-right: -20px; // For whatever reason, this gap isn't an equal distance
            }
        }
    }

    .social-links-list {
        display: flex; 
            justify-content: space-between;

        @include breakpoint(large) {
            justify-content: space-evenly;
        }
        @include breakpoint(large) {
            gap: 20px;
            justify-content: flex-start;
        }
    }

    .social-link {
        padding-left: 0;
        padding-right: 0;
    }

    .user-icon {
        height: 22px;
    }
}

// =============================================
// External Links Styling
// =============================================
.nav-external-links {
    @include transitioned;

    img {
        display: block;
        margin-left: 0;
        margin-right: 0;
        height: auto;
        width: auto;

        @include breakpoint(xlarge) {
            margin: 0 auto 8px auto;
            height: 96px;
        }
    }

    a {
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $f-size-xs;

        @include breakpoint(xlarge) {
            display: block;
        }
    }
}

.nav-external-link {
    margin-bottom: 24px;
    text-align: center;
    width: auto;

    @include breakpoint(xlarge) {
        margin-bottom: 0;
    }

    &.fifth-member{
        img {
            height: 60px;

            @include breakpoint(xlarge) {
                height: 80px;
            }
        }
    }

    &.blknd-whiskey {
        img {
            height: 44px; 
            width: 110px;

            @include breakpoint(xlarge) {
                height: 93px;
                width: auto;
            }
        }
    }

    &.awmh {
        img {
            height: 46px;

            @include breakpoint(xlarge) {
                height: 60px;
            }
        }
    }

    &.blknd {
        img {
            height: 50px; 

            @include breakpoint(xlarge) {
                height: 60px;
            }
        }
    }

    &.vinyl-club {
        img {
            height: 42px;
            width: 120px;

            @include breakpoint(xlarge) {
                height: 60px;
                width: auto;
            }
        }
    }

    &.black-box {
        img {
            height: 50px; 

            @include breakpoint(xlarge) {
                height: 70px
            }
        }
    }
}

.level-2-active {
    .mega-menu-cat-links,
    .nav-external-links {        
        transform: translateX(-100vw);

        @include breakpoint(xlarge) {
            transform: translate(0);
        }
    }
}

// =============================================
// Store Category Styling
// =============================================
#navigation .store-categories {
    display: none;
    list-style: none;
    margin: 0;
    background: $c-light-gray;

    a {
        color: $black;
        line-height: 32px;
    }

    .has-sub-menu {
        color: $c-dark-gray;
        font-weight: $f-weight-bold;
        line-height: 1;


    }

    > li > a.has-sub-menu {
        @include breakpoint(xlarge){

            &:first-of-type {
                position: relative;
                color: $black;

                @include navPsuedo(40px, $white, -1px);
            }

        }
    }

    @include breakpoint(xlarge) {
        padding: 56px 40px 40px;
        min-width: 310px; // magic number... needs to stay
        display: block;

        .level-2 > ul {
            list-style: none;
            display: block;
        }

        .level-2 {
            margin-top: 0;
        }

        a {
            line-height: 32px;
        }
    }
}

.primary-nav {
    display: flex;
    width: 100%;

    @include breakpoint(xlarge) {
        align-items: center;
    }

    &.has-open {
        color: $white;
    }
}

.header-nav {
    display: none;
    list-style: none;

    @include breakpoint(xlarge) {
        display: flex;
        margin-top: 0;
        padding-left: 0;
        position: absolute;
            top: $header-nav-top--desktop;
            z-index: 1;
    }

    &:hover {
        @include breakpoint(xlarge) {
            z-index: 0; // Prevent sibling navs from being overshadowed
        }
    }

    &--left {
        @include breakpoint(medium) {
            left: calc(50% - #{$header-logo-size-small / 2} + 20px);
            transform: translateX(-100%);
        }

        @include breakpoint(xlarge) {
            left: calc(50% - #{$header-logo-size-large / 2} + 20px);
        }

        .header-nav-l2 {
            @include breakpoint(medium) {
                left: calc(100% + #{$header-logo-size-small / 2} - 20px);
                transform: translateX(-50%);
            }

            @include breakpoint(xlarge) {
                left: calc(100% - #{$header-logo-size-large / 2} + 57%);
                top: -52px;
            }
        }

        li:first-child > a {
            @include breakpoint(xlarge) {
                padding-left: 0;
            }
        }
    }

    &--right {
        @include breakpoint(medium) {
            right: calc(50% - #{$header-logo-size-small / 2} + 20px);
            transform: translateX(100%);
        }

        @include breakpoint(xlarge) {
            right: calc(50% - #{$header-logo-size-large / 2} + 20px);
        }

        li:last-child > a {
            @include breakpoint(xlarge) {
                padding-right: 0;
            }
        }

        .header-nav-l2 {
            @include breakpoint(medium) {
                right: calc(100% + #{$header-logo-size-small / 2} - 20px);
                transform: translateX(50%);
            }

            @include breakpoint(xlarge) {
                right: calc(100% - #{$header-logo-size-large / 2} + 30.5%);
                top: -52px;
            }
        }
    }

    &-item {
        &:hover {
            .header-nav-link-rollover {
                display: flex;
                    align-items: flex-end;
            }
        }
    }

    &-link {
        background-color: transparent;
        border: none;
        display: inline-block;
        padding: $nav-mobile-padding;
        vertical-align: top;

        @include breakpoint(medium) {
            padding: $nav-padding;
        }

        &--l1 {
            color: inherit;
            font-family: $f-header;
            font-size: $f-size-xxs;
            font-weight: $f-weight-bold;
            letter-spacing: 1px;
            line-height: 1.25;
            position: relative;
                z-index: 102;
            text-transform: uppercase;

            &:hover:not(:disabled):not(.disabled) {
                background-color: transparent;
                color: inherit
            }
        }

        &-rollover {
            display: none;
            min-height: 28px;
            position: absolute;
                left: 50%;
                top: 0;
                z-index: 1;
            transform: translateX(-50%);

            svg {
                fill: currentColor;
            }
        }

        &.menu-toggle {
            @include breakpoint(xlarge) {
                padding-left: 0;
            }
        }
    }

    &-l2 {
        display: flex;
            align-items: center;
            justify-content: center;
        border: none;
        min-height: 350px;
        padding-bottom: 12px;
        pointer-events: none;
        position: absolute;
            top: -$header-nav-top--desktop;
            z-index: 101;
        visibility: hidden;
        width: 100vw;    

        &.is-open {
            @include transitioned;

            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }
    }

    &-sub-menu {
        display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
        list-style: none;
        padding-left: 0;

        &--contained {
            @include breakpoint(xlarge) {
                gap: 50px;
                grid-auto-columns: auto;
                justify-content: space-evenly;
            }
        }
        
        &-l2-wrapper {
            width: 100%;

            @include breakpoint(xlarge) {
                // Lack of fallback is intentional, as we don't want to conflict with child
                background: var(--l2-menu-bg);
                background-size: cover;

                .menu-vertical:not(.header-nav-sub-menu--contained) {
                    .header-nav-sub-menu-item {
                        & > span {
                            display: flex;
                            position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            height: 100%;
                            
                            .header-nav-sub-menu-link {
                                display: flex;
                                    align-items: center;
                                    justify-content: center;
                                height: 100%;
                                vertical-align: middle;
                                width: 100%;

                                &:hover {
                                    border-color: transparent;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }

            &:hover:not(#{&}--store) {
                --l2-menu-bg-color: linear-gradient(rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77));
            }

            &--contained {
                display: grid;
                    gap: 54px;
                    grid-auto-columns: auto;
                    grid-auto-flow: column;
                padding-left: calc(((100vw - #{$bp-max-content-width}) / 2) + #{$l-xlarge-padding});
                padding-right: calc(((100vw - #{$bp-max-content-width}) / 2) + #{$l-xlarge-padding});
            }

            &--store {
                --l2-menu-bg-color: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
            }
        }

        &-item {
            &--l2 {
                // Lack of fallback is intentional, as we don't want to conflict with parent
                background: var(--l3-menu-bg); // Use of linear gradient allows us to put the color over the image
                background-size: cover;
                display: flex;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    justify-content: center;
                min-height: 350px;
                padding-bottom: 50px;
                padding-top: 185px;
                position: relative;

                &:hover {
                    .nav-bg::before {
                        opacity: 0.77;
                    }
                }

                .nav-bg {
                    position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        top: 0;
                        z-index: 0;

                    &::before {
                        background-color: $c-black;
                        content: '';
                        opacity: .5;
                        position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            top: 0;
                            z-index: 1;
                    }
                }

                .nav-bg-img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }

                .level-3 {
                    @include breakpoint(xlarge) {
                        display: block;
                        left: initial;
                        padding-top: 9px;
                        transition: none;
                        visibility: inherit;  
                    }

                    + .level-3 {
                        @include breakpoint(xlarge) {
                            padding-top: 14px;
                        } 
                    }

                    .menu-back {
                        @include breakpoint(xlarge) {
                            display: none;
                        }
                    }
                }
            }

            &--l3 {
                letter-spacing: 0.1px;
                margin-bottom: 5px;
                text-align: left;
            }

            &--store {
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 150px;

                .header-nav-sub-menu-item {
                    justify-content: flex-start;
                }
            }
        }

        &-list {
            list-style: none;
            padding-left: 0;
        }

        &-link {
            position: relative;
                z-index: 1;

            @include breakpoint(xlarge) {
                border-bottom: 1px solid transparent;
                font-family: $sans-serif;
                font-size: $f-size-xs;
            }

            &:hover {
                @include breakpoint(xlarge) {
                    border-bottom-color: currentColor;
                    text-decoration: none;
                }
            }

            &--l2 {
                @include breakpoint(xlarge) {
                    font-weight: $f-weight-bold;
                    letter-spacing: 0.5px;
                    line-height: 1.86;
                    text-transform: uppercase;
                }
            }

            &--l3 {
                @include breakpoint(xlarge) {
                    font-weight: $f-weight-medium;
                    letter-spacing: normal;
                    line-height: 1.43;
                }
            }

            &--nested-eu-store {
                padding-top: 10px;
            }
        }

        &-banner {
            padding-bottom: 50px;
            padding-top: 150px;
        }
    }
}

.menu-sub-nav {
    list-style: none;
}