.museum-page {
    .c-icon-grid {
        margin-top: 30px;

        @include breakpoint(large) {
            margin-top: 70px;
        }
    }

    .c-tile-slider {
        .c-tile-slider__slider {
            .c-featured-article {
                height: 560px;
                
                @include breakpoint(large) {
                    height: 700px;
                }
            }

            .c-featured-article__img-wrap.no-img-attributes {
                &::after {
                    content: "";
                    background: rgba(0, 0, 0, 0.7);
                    display: block;
                    position: absolute;
                        left: 0;
                        top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .c-gallery-tray_galleries {
        @include breakpoint(medium) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
        }

        .gallery-item {
            margin-bottom: 20px;

            @include breakpoint(medium) {
                margin-bottom: 10px;
            }
        }

        .gallery-img {
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            border: solid 1px #555555;
            background: $white;

            & img {
                aspect-ratio: 1/1;
            }
        }

        .gallery-date {
            .darker {
                color: $black;
            }
        }

        .gallery-collection {
            color: #555;
            font-size: 10px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 1px;
            margin-top: -2px;
            text-transform: uppercase;
        }
    }

    .js-banner-carousel {
        .js-banner {
            .slick-prev {
                left: calc(((100vw - 100%) / -2) + 30px);
                z-index: 10;

                @include breakpoint(medium) {
                    left: calc(((100vw - 100%) / -2) + 45px);
                }

                @include breakpoint(large) {
                    left: calc(((100vw - 100%) / -2) + 100px);
                }
            }

            .slick-next {
                right: calc(((100vw - 100%) / -2) + 30px);

                @include breakpoint(medium) {
                    right: calc(((100vw - 100%) / -2) + 45px);
                }

                @include breakpoint(large) {
                    right: calc(((100vw - 100%) / -2) + 100px);
                }
            }

            .slick-dots {
                bottom: 4%;
                left: 50%;
                transform: translateX(-50%);

                @include breakpoint(medium) {
                    bottom: 6%;
                }

                @include breakpoint(large) {
                    bottom: 80px;
                }
            }
        }
    }
}

// Museum Collection Page
.pt_museum {
    .amplanding-header {
        box-sizing: border-box;

        @include breakpoint(medium) {
            padding-bottom: 18px;
        }

        &__heading {
            color: $black;
            font-size: 20px;
            line-height: 22px;
            letter-spacing: 3px;
            opacity: 1;
            display: inline-block;

            @include breakpoint(medium) {
                font-size: 34px;
                line-height: 32px;
            }
        }

        &__sort {
            margin: 0;
        }
    }

    .c-tile-slider {
        &__grid {
            .c-tile-slider {
                &__header {
                    flex-wrap: nowrap;
                }

                &__cta {
                    &.einstein-link {
                        overflow: hidden;
                        white-space: nowrap;
                        width: 82px;

                        @include breakpoint(medium) {
                            overflow: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    #content-sort-select,
    #release-nav-mobile {
        white-space: normal;
        line-height: normal;
        min-width: auto;
    }

    #release-nav-mobile {
        box-sizing: border-box;
        height: auto;
        padding: 8px 16px;
        text-align: center;
    }

    .js-blazy-wrapper {
        background-color: $white;
        display: flex;
        align-items: center;

        .museum-tile {
            border: 1px solid $c-charcoal;
            display: inline-block;
            margin: 0;
            padding: 0;
        }
    }

    .museum-tiles-container {
        display: flex;
        flex-flow: row wrap;
        position: relative;
        margin-left: -10px;
        max-width: calc(100% + 10px);
    }

    .pagination {
        ul {
            @include breakpoint(xsmall) {
                flex-wrap: wrap;
            }
        }
    }
    
    .tiles-container-m {
        padding: 0;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 24px 0 0 10px;

        @include breakpoint(medium) {
            width: calc(25% - 10px);
        }

        @include breakpoint(large) {
            width: calc(33.33% - 10px);
        }

        .tile-name p {
            margin: 0;
        }

        .tile-name,
        .tile-type {
            margin: 0;
        }

        .tile-release-date {
            margin-bottom: 0;
        }
    }
}
