.c-tile-slider {
    flex-shrink: 0;
    margin-left: calc(50% - 50vw - 4px); // Ensure slider (accounting for tile spacing, is aligned with the page content
    margin-left: calc(50% - 50dvw - 4px);
    position: relative;
    width: calc(100vw + 4px);
    width: calc(100dvw + 4px);

    &__bg-content {
        display: flex;
            flex-direction: column;
            justify-content: space-between;
        min-height: 100%;
        overflow: hidden;
        position: absolute;
            top: 0;
        width: 100%;

        &__top-text,
        &__bottom-text {
            position: relative;
        }

        &__top-text {
            padding: 0 0 100px 0;
            text-align: left;
        }

        &__bottom-text {
            text-align: right;

            @include breakpoint(large) {
                margin-left: auto;
            }
        }

        .accent {
            @include bp(max-width, $bp-medium) {
                line-height: 0.8;
            }
        }
    }

    &__grid {
        position: relative;
    }

    &__header {
        display: flex;
            align-items: flex-end;
            justify-content: space-between;
        margin-bottom: 18px;
        width: 100%;

        @include breakpoint(medium) {
            margin-bottom: 14px;
        }

        .einstein-link {
            white-space: initial;
        }

        &--mobile-wrap {
            position: relative;

            .c-tile-slider {
                &__heading {
                    max-width: MIN(70%, 320px); // All caps to prevent SCSS from compiling as function

                    @include breakpoint(small) {
                        max-width: 65%;
                    }

                    @include breakpoint(medium) {
                        max-width: 100%;
                    }
                }

                &__ctas {
                    position: absolute;
                        bottom: 3px;
                        right: 0;
                    
                    @include breakpoint(medium) {
                        position: static;
                    }
                }
            }
        }
    }

    &__cta,
    &__cta-divider {
        font-size: 11px;
        font-weight: $f-weight-bold;
        margin-bottom: 3px;
        padding-left: 7px;

        @include breakpoint(medium) {
            font-size: 14px;
            margin-bottom: 5px;
            padding-left: 13px;
        }
    }

    &__ctas {
        flex-shrink: 0;
    }

    &__cta {
        flex-shrink: 0;
        letter-spacing: 2.5px;
        text-align: right;
        text-transform: uppercase;

        @include breakpoint(medium) {
            letter-spacing: 3px;
            white-space: nowrap;
        }
    }

    &__cta-divider {
        padding-right: 4px;

        @include breakpoint(medium) {
            padding-right: 3px; 
        }
    }

    &__slider {
        width: calc(100% + ((100vw - 100%) / 2) + 4px);

        &:not(.slick-initialized) { // Make initialization cleaner
            display: flex;

            .c-tile-slider__featured-article {
                flex-basis: 60%;
                flex-shrink: 0;

                @include breakpoint(medium) {
                    flex-basis: 25%;
                }
            }
        }
    }

    &--padded {
        box-sizing: border-box;
        margin-top: 32px;
        min-height: 475px;
        padding-bottom: 60px;
        padding-top: $tile-slider-padded-padding-top;

        @include breakpoint(medium) {
            padding-bottom: 145px;
            padding-top: $tile-slider-padded-padding-top-md;
        }

        @include breakpoint(large) {
            margin-top: 43px;
            padding-top: $tile-slider-padded-padding-top-lg;
        }
    }

    * {
        box-sizing: border-box !important;
    } 
}

.s-tile-slider {
    .slick-slider {
        position: static;
    }

    .slick-list {
        padding-left: 0 !important;
        padding-right: $l-small-padding !important;

        @include breakpoint(medium) {
            padding-right: $l-medium-padding !important;
        }

        @include breakpoint(large) {
            padding-right: $l-large-padding !important;
        }

        @include breakpoint(xlarge) {
            padding-right: calc(((100vw - #{$bp-xlarge + 1px}) / 2) + #{$l-xlarge-padding}) !important;
        }
    }

    .slick-dots {
        display: flex;
        bottom: -38px;
        left: $l-small-padding + 4px;
        right: $l-small-padding + 82px;
        width: auto;

        @include breakpoint(medium) {
            bottom: -44px;
            left: $l-medium-padding + 4px;
            right: $l-medium-padding + 79px;
        }

        @include breakpoint(large) {
            bottom: -38px;
            left: $l-large-padding + 4px;
            right: $l-large-padding + 82px;
        }

        li {
            flex: 1 0 0;
            margin-left: 0;
            margin-right: 0;

            &.slick-active {
                button {
                    &::before {
                        background-color: $c-black;
                    }
                }
            }

            button {
                padding-bottom: 2px;
                padding-top: 3px;
                width: 100%;

                &:hover:not(:disabled):not(.disabled) {
                    background-color: transparent;
                }

                &::before {
                    border-radius: 0;
                    height: 3px;
                    width: 100%;
                }
            }
        }
    }

    .slick-arrow {
        &, &:hover:not(.disabled):not(:disabled) {
            background: none;
            left: auto;
            top: auto;
            bottom: -52px;

            @include breakpoint(medium) {
                bottom: -70px;
            }

            @include breakpoint(large) {
                bottom: -72px;
            }
        }

        &::before {
            height: 24px;
            width: 14px;

            @include bp(max-width, $bp-medium) {
                transform: scale(.9); // Due to the nature of svg BGs on this site, scaling appears to be the best option to shrink it
            }
        }
    }

    .slick-prev {
        right: $l-small-padding + 36px;

        @include breakpoint(medium) {
            right: 56px;
            color: red;
        }

        @include breakpoint(large) {
            right: 83px;
        }

        &::before {
            @include svg-view-album-arrow-left;
        }
    }

    .slick-next {
        right: $l-small-padding;

        @include breakpoint(medium) {
            right: 16px;
        }

        @include breakpoint(large) {
            right: 43px;
        }

        &::before {
            @include svg-view-album-arrow-right;
        }
    }

    .product-tile {
        padding-top: 0;
    }

    .show--upcoming {
        padding-top: 0;
    }
    
    &--more-articles {
        margin-top: 40px;

        @include breakpoint(medium) {
            margin-top: 64px;
        }

        @include breakpoint(large) {
            margin-top: 105px;
        }
        
        & + & {
            margin-top: -4px;
    
            @include breakpoint(medium) {
                margin-top: 20px;
            }

            @include breakpoint(large) {
                margin-top: 55px;
            }
        }

        .c-tile-slider__slider {
            &:not(.slick-initialized) {
                .more-article-item {
                    flex-basis: 100%;
                    flex-shrink: 0;

                    @include breakpoint(medium) {
                        flex-basis: 50%;
                    }

                    @include breakpoint(large) {
                        flex-basis: 33.3%;
                    }
                }
            }
        }

        .slick-slider {
            margin-right: -4px;

            @include breakpoint(medium) {
                margin-left: -3px;
                width: calc(100% + ((100vw - 100%) / 2) + 7px);
            }
        }

        .slick-list {
            @include breakpoint(medium) {
                @include bp(max-width, $bp-large) {
                    padding-right: 0 !important; // Override importanted style
                }
            }
        }
    }

    &--no-center {
        .slick-track {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--equal-height {
        .slick-track {
            display: flex;
        }

        .slick-slide {
            float: none;
            height: auto;
        }
    }

    &--chapters {
        .slick-list {
            @include bp(max-width, $bp-medium) {
                padding-right: 115px !important;
            }
        }
    }
}
