.c-scroll-anim {
    --slidetext-toptext-offset: 400px;

    &__accent-left-text {
        left: MAX(0px, var(--slidetext-toptext-offset)); // All uppercase so scss doesn't compile as function
    }

    &__accent-right-text {
        right: MAX(-9%, calc(var(--slidetext-toptext-offset) - 9%)); // All uppercase so scss doesn't compile as function, -9% on calc keeps it synced with left slide text

        @include breakpoint(large) {
            margin-left: auto;
            max-width: 80%;
            overflow: visible;
        }
    }
}

.c-scroll-overlay {
    position: relative;
    padding: 20px 0;

    @include breakpoint(large) {
        padding: 50px 0;
    }

    .c-scroll-anim {
        position: absolute;
            top: 0;
        --slidetext-toptext-offset: 400px;

        &:not(.no-index) {
            z-index: -1;
        }
    }

    &--category {
        padding: 10px 0;

        @include breakpoint(large) {
            margin-top: -40px;
            padding: 0;
        }

        .c-scroll-anim {
            top: -20px;
        }

        & + .breadCrumb-wrapper {
            position: relative;
                z-index: 1;
        }
    }
}