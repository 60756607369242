.accordion-item {
    .ui-accordion-header {
        padding-right: 18px;

        &::after {
            background: url(../images/svg/intermediate-svg/pdp-arrow-detail.svg) no-repeat;
            content: "";
            display: inline-block;
            height: 9px;
            margin: 0;
            position: absolute;
                right: 4px;
                top: 3px;
            transform-origin: 50% 50%;
            transition: all 0.3s linear;
            width: 10px;

            @include breakpoint(medium) {
                right: 6px;
                top: calc(50% - 2px);
                transform: translateY(-50%);
            }
        }

        &.ui-state-active {
            @include bp(max-width, $bp-medium) {
                padding-bottom: 21px;
            }

            &::after {
                transform: rotate(180deg);

                @include breakpoint(medium) {
                    top: calc(50% - 6px); 
                    transform: rotate(180deg) translateY(-50%);
                }
            }
        }
    }
}