.amp-landing-tiles-container {
    list-style: none;
    margin-left: -4px;
    margin-right: -4px;

    @include breakpoint(large) {
        margin-top: -24px;
    }

    .tiles-container {
        margin-bottom: 2px;
        padding-left: 4px;
        padding-right: 4px;

        @include breakpoint(large) {
            padding-top: 24px;
        }
    }

    .thumb-link {
        display: block;
        padding-left: 2px;
        padding-right: 2px;
    }

    .tile {        
        &-release-date,
        &-name {
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &-release-date {
            color: $c-charcoal;
            font-size: 12px;
            line-height: 1.17;
            margin-bottom: 6px;
            margin-top: 7px;
        }

        &-name {
            margin-top: 4px;
        }
    }
}

.release-tiles-container {
    .tile {
        &-img {
            border: 1px solid $c-stroke-gray;
        }

        &-name {
            line-height: 1.14;
        }
    }
}

.media-tiles-container {
    .tile {
        &-name {
            font-size: 14px;
            font-weight: $f-weight-bold;
            letter-spacing: 0.5px;
            line-height: 1.29;

            @include breakpoint(medium) {
                font-size: 18px;
                line-height: 1.22;
            }
        }

        &-release-date {
            font-weight: $f-weight-semibold;

            @include bp(max-width, $bp-medium) {
                font-size: 10px;
                line-height: 1;
            }
        }
    }
}