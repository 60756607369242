.prSnippetWriteReview {
    font-weight: $f-weight-bold;
    display: inline-block;
}

.prSnippetRatingUnclickable {
    display: inline-block;

    .rating {
        display: inline-block;
    }
}

#write-review-form {
    button {
        width: 100%;
        margin-top: $l-small-padding;

        @include breakpoint(medium) {
            width: initial;
            margin-top: 0;
        }
    }
}

.prSnippetNumberOfRatingsText {
    display: inline-block;
}

.prSnippetRatingDecimal {
    float:left;
    display: block;
}

.product-tile .product-review {
    .prSnippetWriteReview,
    .prSnippetNumberOfRatingsText {
        display: none;
    }
}

.write-review-product {
    clear: both;
}

.prSummaryWriteReview {
    margin-top: 5px;
}

.prReviewsContainer {
    margin-top: 20px;
}

.prContents {
    border: 1px solid grey;
    margin-bottom: 10px;
}

.prReviewRating, .prReviewAuthor, .prReviewText {
    margin-left: 5px;
}

.prReviewAuthor {
    display: inline;
}

.prOwnIt {
    font-weight: $f-weight-bold;
}

.prReviewHeadlineText {
    float: left;
}

.bold {
    font-weight: $f-weight-bold;
}

.prReviewText {
    margin-top: 10px;
}