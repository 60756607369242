.video-banner {
    width: 100%;
    overflow: hidden;
    display: block;
    position: relative;
}

.video-fancybox-wrapper {
    .fancybox-inner {
        box-sizing: border-box;
        width: auto !important;
        height: auto !important;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        position: relative;

        @supports (aspect-ratio: 16 / 9) {
            aspect-ratio: 16 / 9;
            padding-bottom: 0;
        }
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.fancybox-exclusive-title {
    color: $white;
    padding: 40px;
    max-width: 450px;
    text-align: center;

    h3 {
        color: $white;
        margin-bottom: 20px;
    }
}

.pt_video-landing {
    .main-header {
        width: 100%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 25px;
        opacity: 0.15;
        color: $black;
        font-size: 32px;
        letter-spacing: 1px;
        line-height: 22px;

        @include breakpoint(medium) {
            opacity: 0.15;
            font-size: 56px;
            letter-spacing: 3px;
            line-height: 37px;
            padding: 0 38px;
        }

        @include breakpoint(large) {
            padding: 0 116px;
        }

        @include breakpoint(xlarge) {
            display: block;
            font-size: 96px;
            line-height: 66px;
            margin-top: -1px;
            letter-spacing: 6px;
            padding: 0 136px;
        }
    }

    .refinement {
        width: 100%;
        padding-bottom: 13px;

        @include breakpoint(medium) {
            margin-bottom: -47px;
        }

        @include breakpoint(large) {
            display: block;
            width: 100%;
        }

        @include breakpoint(xlarge) {
            margin-top: -18px;
            padding-bottom: 0;
            height: 40px;
        }

        #category-level-1 {
            display: none;
            @include breakpoint(large) {
                display: block;
                padding: 0;
                text-align: center;
            }

            li {
                @include breakpoint(large) {
                    display: inline-block;
                }

                a.selected {
                    color: $black;
                }

                &:last-child {
                    @include breakpoint(large) {
                        margin-right: 0;
                    }
                }

                a {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    color: $c-gray;
                    text-transform: uppercase;
                    @include breakpoint(large) {
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 12px;
                    }
                }
            }
        }

        .select-outterwrapper {
            width: 100%;
            text-align: center;
            margin-top: 15px;

            @include breakpoint(medium) {
                width: auto;
                text-align: inherit;
                padding-left: 5%;
            }

            @include breakpoint(large) {
                display: none;
            }

            h6 {
                text-transform: none;
                font-family: $sans-serif;
                font-size: 14px;
                color: $c-dark-gray;
                line-height: 24px;
                display: inline-block;
                margin-right: 12px;
            }

            .select-wrapper {
                width: 202px;
                display: inline-block;

                #refinement-level-dropdown {
                    border: 2px solid $c-gray;
                    text-transform: uppercase;
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 12px;
                    color: $black;
                    letter-spacing: 1px;
                    width: 100%;
                }
            }
        }
    }

    #primary {
        margin: 0;

        .search-controls {
            width: 100%;
            position: relative;
            overflow: hidden;
            min-height: 28px;
            margin-bottom: 30px;
        }
    }

    .refinement.Related.Events {
        display: none;
    }
}

#videogrid {
    width: 100%;

    .video-content-list {
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            position: relative;
            @include breakpoint(large) {
                overflow: hidden;
            }

            .image-wrapper {
                display: block;
                width: 100%;
                z-index: 0;
                position: relative;
                overflow: hidden;

                .opacity {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 35px;
                    opacity: 0.7;
                    z-index: 1;
                    @include breakpoint(large) {
                        height: 72px;
                    }
                }

                .top {
                    top: 0;
                    background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
                }

                .bottom {
                    bottom: 0;
                    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
                }
            }

            .badge-wrapper {
                padding: 8px 0 0 8px;
                @include breakpoint(large) {
                    position: absolute;
                    top: 15px;
                    left: 18px;
                    padding: inherit;
                }

                .exclusive-badge {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 10px;
                    color: $c-red;
                    letter-spacing: 0.83px;
                    line-height: 16px;
                    text-transform: uppercase;
                    z-index: 2;
                    @include breakpoint(large) {
                        color: $white;
                        text-align: left;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 16px;
                        padding: 0;
                    }
                }
            }

            .video-info {
                padding: 0 8px 0 8px;
                z-index: 2;

                @include breakpoint(large) {
                    display: block;
                    @include position(absolute, null 0 17px 0);
                    margin: auto;
                    width: 85%;
                    text-align: center;
                    padding: 0;
                }

                .content-title {
                    color: $black;
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 14px;
                    line-height: 20px;
                    @include breakpoint(large) {
                        color: $white;
                    }
                }
            }

            .video-count {
                font-family: $sans-serif;
                font-size: 12px;
                color: $black;
                line-height: 16px;
                padding: 0 0 12px 8px;
                z-index: 2;
                @include breakpoint(large) {
                    display: block;
                    text-align: center;
                    padding: 0;
                    position: absolute;
                    top: 13px;
                    right: 15px;
                }

                .media,
                .number {
                    display: inline-block;
                    line-height: 29px;
                    @include breakpoint(large) {
                        display: block;
                        font-family: $f-header;
                        color: $white;
                    }
                }

                .media {
                    @include breakpoint(large) {
                        font-family: $sans-serif;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: center;
                        line-height: 16px;
                        text-transform: uppercase;
                    }
                }

                .number {
                    text-align: center;
                    line-height: 29px;
                    @include breakpoint(large) {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

.pt_video-album {
    .main-header {
        opacity: 0.15;
        color: $black;
        font-size: 32px;
        letter-spacing: 1px;
        line-height: 56px;
        margin: -17px auto 0;
        width: 100%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 25px;
        @include breakpoint(medium) {
            opacity: 0.15;
            font-size: 56px;
            letter-spacing: 3px;
            line-height: 46px;
            margin: -6px auto 0;
            padding: 0 38px;
        }
        @include breakpoint(large) {
            margin: -4px auto 0;
            font-size: 56px;
            line-height: 44px;
            letter-spacing: 3px;
            padding: 0 116px;
        }
        @include breakpoint(xlarge) {
            margin: -4px auto 0;
            display: block;
            font-size: 96px;
            line-height: 69px;
            letter-spacing: 6px;
            padding: 0 136px;
        }
    }

    .breadcrumb {
        display: none;
    }

    .album-title {
        text-align: center;
        text-transform: uppercase;
        font-family: $f-header;
        font-size: 24px;
        color: $black;
        line-height: 32px;
        font-weight: $f-weight-medium;
        margin: 0;
        @include breakpoint(medium) {
            padding: 7px 0 10px;
        }
        @include breakpoint(large) {
            font-size: 40px;
            line-height: 56px;
        }
    }

    #main .event-video {
        position: relative;
        overflow: hidden;

        .video-title {
            font-family: $sans-serif;
            font-weight: $f-weight-bold;
            font-size: 14px;
            color: $black;
            line-height: 20px;
            padding: 3px 0 17px;
            @include breakpoint(medium) {
                padding: 17px 0;
            }
            @include breakpoint(large) {
                font-weight: normal;
                position: absolute;
                top: 39px;
                left: 36px;
                color: $white;
                z-index: 2;
                font-size: 16px;
            }
        }

        .video-image {
            position: absolute;
            z-index: 1;
            width: 100%;
        }

        .content-asset {
            position: relative;
            @include breakpoint(large) {
                margin-bottom: 39px;
            }

            iframe {
                display: none;
            }
        }
    }

    #main .content-asset {
        position: relative;
        padding-bottom: 53%;
        padding-top: 3.2%;
        height: 0;
        margin-bottom: 7px;

        p {
            margin: 0;
            padding: 0;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .bottom-results {
        .row {
            li {
                position: relative;

                .image-wrapper {
                    overflow: hidden;
                    position: relative;

                    a {
                        display: block;
                    }

                    .play {
                        height: 20px;
                        width: 28px;
                        @include breakpoint(large) {
                            height: 46px;
                            width: 66px;
                        }
                    }

                    .play::after {
                        border-width: 6px 0 6px 12px;
                        margin: -6px 0 0 -5px;
                        @include breakpoint(large) {
                            font-size: 12px;
                            border-width: 12px 0 12px 23px;
                            margin: -12px 0 0 -8px;
                        }
                    }
                }

                .video-title {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 14px;
                    color: $black;
                    line-height: 20px;
                    padding: 10px 0 0;
                    @include breakpoint(large) {
                        color: $white;
                        position: absolute;
                        z-index: 2;
                        text-align: center;
                        bottom: 10px;
                        width: 80%;
                        margin: auto;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

#photogrid {
    width: 100%;

    .video-content-list {
        .group-pict,
        .photo {
            position: relative;

            .image-wrapper {
                overflow: hidden;
                position: relative;
            }

            .opacity {
                display: block;
                position: absolute;
                width: 100%;
                height: 35px;
                opacity: 0.7;
                z-index: 1;
                @include breakpoint(large) {
                    height: 72px;
                }
            }

            .top {
                top: 0;
                background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
            }

            .bottom {
                bottom: 0;
                background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
            }

            .badge-wrapper {
                padding: 8px 0 0 8px;

                @include breakpoint(large) {
                    @include position(absolute, 40px null null 32px);
                    padding: inherit;
                }

                @include breakpoint(xlarge) {
                    @include position(absolute, 57px null null 40px);
                }

                .exclusive-badge {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 10px;
                    color: $c-red;
                    letter-spacing: 0.83px;
                    line-height: 16px;
                    text-transform: uppercase;
                    z-index: 2;
                    @include breakpoint(large) {
                        color: $white;
                        text-align: left;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 16px;
                        padding: 0;
                    }
                }
            }

            .video-info {
                padding: 0 0 0 8px;
                z-index: 2;
                @include breakpoint(large) {
                    display: block;
                    @include position(absolute, null 0 17px 0);
                    margin: auto;
                    width: 85%;
                    text-align: center;
                    padding: 0;
                }

                .content-title {
                    color: $black;
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: 14px;
                    line-height: 20px;
                    @include breakpoint(large) {
                        color: $white;
                    }
                }
            }

            .video-count {
                font-family: $sans-serif;
                font-size: 12px;
                color: $black;
                line-height: 16px;
                padding: 0 0 12px 8px;
                z-index: 2;
                @include breakpoint(large) {
                    position: absolute;
                    top: 40px;
                    right: 31px;
                }
                @include breakpoint(xlarge) {
                    display: block;
                    text-align: center;
                    padding: 0;
                    top: 57px;
                    right: 40px;
                }

                .media,
                .number {
                    display: inline-block;
                    line-height: 29px;
                    @include breakpoint(large) {
                        display: block;
                        font-family: $f-header;
                        color: $white;
                    }
                }

                .media {
                    @include breakpoint(large) {
                        font-family: $sans-serif;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: center;
                        line-height: 16px;
                        text-transform: uppercase;
                    }
                }

                .number {
                    text-align: center;
                    line-height: 29px;
                    @include breakpoint(large) {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

#pagination-container {
    margin-bottom: 0;

    .pagination {
        @include breakpoint(large) {
            margin-bottom: 39px;
            margin-top: 49px;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 39px;
            margin-top: 31px;
        }
    }
}

.upper-pagination {
    width: 100%;
    @include breakpoint(medium) {
        margin-right: 42px;
    }
    @include breakpoint(large) {
        margin-top: 38px;
    }
    @include breakpoint(xlarge) {
        margin-top: 0;
    }
}

.pagination {
    margin-right: 18px;
    margin-bottom: 32px;
    @include breakpoint(xlarge) {
        margin-top: 32px;
    }
    @include breakpoint(medium) {
        margin-right: 42px;
    }
}

.play {
    background: $black;
    border-radius: 50% / 10%;
    color: $white;
    font-size: 32px;
    height: 46px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-indent: 1px;
    transition: all 150ms ease-out;
    width: 66px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    opacity: 0.7;
}

.play:hover {
    background: $c-red;
    opacity: 10;
}

.play::before {
    background: inherit;
    border-radius: 5% / 50%;
    bottom: 9%;
    content: "";
    left: -5%;
    position: absolute;
    right: -5%;
    top: 9%;
}

.play::after {
    border-style: solid;
    border-width: 12px 0 12px 23px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
    content: ' ';
    font-size: 12px;
    height: 0;
    margin: -12px 0 0 -8px;
    top: 50%;
    position: absolute;
    width: 0;
}

.fancybox-nav {
    top: 0;
    bottom: 0;
    margin: auto;
    width: 7%;
    height: 30%;
}

.rowransform {
    display: inline-block;
    width: 50%;
    @include breakpoint(large) {
        display: inherit;
        width: auto;
    }

    .col {
        padding: 0;
        @include breakpoint(large) {
            padding: 40px 20px 0;
        }

        &.photo {
            @include breakpoint(large) {
                padding: 32px 16px 0;
            }

            @include breakpoint(xlarge) {
                padding: 40px 20px 0;
            }
        }
    }
}

.left-tr {
    padding: 0 0 0 6px;
    @include breakpoint(large) {
        padding: 0;
    }
}

.right-tr {
    float: right;
    padding: 0 6px 0 0;
    @include breakpoint(large) {
        float: none;
        padding: 0;
    }
}

.image-box.double-title{
	background-color: red;
}

.album-landing{
    .fancybox-overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
	.image-wrapper{
		.preview-image-artist{
			font-size: 12px;
			text-align: center;
			
			@include breakpoint(medium) {
	            font-size: 16px;
	            
	        }
	    }
	}
	
	.fancybox-title {
		width: 100%;
		right: 0;
		
		.child{
			width: 100%;
			margin-right: 0;
			padding-left: 0;
            padding-right: 0;
            border-radius: 0;
            background: transparent;
		}
		
		.image-credits{
			display: flex;                  /* establish flex container */
			flex-direction: row;            /* default value; can be omitted */
			flex-wrap: nowrap;              /* default value; can be omitted */
			justify-content: space-between; /* switched from default (flex-start, see below) */
			
			span{
				padding: 0 15px;
				max-width: 50%;
                white-space: initial;
                .camera {
                    vertical-align: text-top;
                    margin-right: 5px;
                }
            }
		}
	}
}

.fancybox-opened {
    .fancybox-title {
        font-family: $f-body;
    }

    .fancybox-wrap {
        margin-top: 0;
        min-width: 300px;
    }

    .fancybox-inner {
        margin-left: auto;
        margin-right: auto;
    }

    .fancybox-close {
        top: -39px;
        width: 18px;

        &::before {
            margin-right: 0;
            transform: scale(1.5); // Use scale rather than height/width to handle how the sprites are used for BG
            transform-origin: left top;
        }

        &::after {
            @include visually-hidden;
        }
    }

    .fancybox-skin,
    .fancybox-outer {
        position: static;
    }

    .fancybox-nav {
        bottom: -12px;
        color: $white;
        font-size: 13px;
        font-weight: $f-weight-bold;
        height: auto;
        letter-spacing: 2.5px;
        line-height: 1.08;
        overflow: visible;
        padding-bottom: 12px;
        padding-top: 12px;
        text-transform: uppercase;
        top: initial;
        width: auto;

        span {
            display: inline-block;
            height: auto;
            margin-top: 0;
            position: static;
            width: auto;

            &::before {
                display: none;
            }
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    .fancybox-prev::before,
    .fancybox-next::after {
        content: '';
        display: inline-block;
        height: 12px;
        transform: rotate(45deg);
        vertical-align: top;
        width: 12px;
    }

    .fancybox-prev {
        left: 3px;
        padding-right: 4px;

        @include breakpoint(medium) {
            left: clamp(3px, calc((100% - 325px) * 100), 16px); // With a parent width of 325px or lower, we get 3px to prevent bunching.  Otherwise 16
        }

        &::before {
            border-bottom: 3px solid currentColor;
            border-left: 3px solid currentColor;
            margin-right: 16px;
        }
    }

    .fancybox-next {
        right: 3px;
        padding-left: 4px;

        @include breakpoint(medium) {
            right: clamp(3px, calc((100% - 325px) * 100), 16px); // With a parent width of 325px or lower, we get 3px to prevent bunching.  Otherwise 16
        }

        &::after {
            border-right: 3px solid currentColor;
            border-top: 3px solid currentColor;
            margin-left: 16px;
        }
    }
}

