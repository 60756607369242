.pt_amp_landing {
    select#content-sort-select {
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 3px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            min-width: 360px;
        }
    }

    #main {
        margin-top: 32px;

        @include breakpoint(medium) {
            margin-top: 60px;
        }
    }

    .amplanding-header {
        display: flex;
            align-items: flex-end;
            justify-content: space-between;

        @include breakpoint(medium) {
            border-bottom: 1px solid $black;
            padding-bottom: 12px;
            margin-bottom: 14px;
        }

        &__heading {
            margin-bottom: 16px;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }

        &__sort {
            margin: 0;

            @include breakpoint(medium) {
                margin-bottom: 8px;
            }

            &.desktop-view {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }

            &.mobile-view {
                display: block;
                padding-bottom: 20px;

                @include breakpoint(medium) {
                    display: none;
                }
            }

            .select-wrapper {
                background: $white;

                select {
                    background: initial;
                    z-index: 1;

                    &::after {
                        z-index: 0;
                    }
                }
            }

        }
    }

    .page-title {
        color: $black;
        font-family: $f-header;
        font-size: 20px;
        font-weight: $f-weight-bold;
        line-height: 1.1;
        letter-spacing: 0.5px;

        @include breakpoint(medium) {
            font-size: 34px;
            line-height: 1.12;
            letter-spacing: normal;
        }
    }

    .accent {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    .title-bar {
        border-bottom: 1px solid $c-black;
        padding-bottom: 11px;

        @include breakpoint(medium) {
            display: flex;
                align-items: flex-end;
                justify-content: space-between;
            padding-bottom: 12px;
        }
    }

    .primary-content {
        position: relative;
    }


    .amplanding-tiles-container {
        margin-left: -4px;
        margin-right: -4px;

        * {
            box-sizing: border-box;
        }
    }

    .amplanding-tile {
        display: block;
        padding-right: 4px;
        padding-left: 4px;

        &__link {
            &:hover .amplanding-tile__count {
                @include breakpoint(medium) {
                    opacity: 1;
                }
            }
        }

        &__img-wrap {
            position: relative;
            margin-bottom: 10px;

            &__fifth-label {
                color: $white;
                font-size: 10px;
                font-weight: $f-weight-bold;
                line-height: 1;
                letter-spacing: 1px;
                position: absolute;
                    left: 15px;
                    top: 15px;
                text-transform: uppercase;

                @include breakpoint(large) {
                    font-size: 12px;
                    line-height: 1.17;
                    left: 20px;
                    top: 20px;
                }
            }
        }

        &__img {
            @include breakpoint(medium) {
                aspect-ratio: 16/9;
            }
        }

        &__count {
            background-color: rgba($black, 0.6);
            color: $white;
            display: flex;
                justify-content: center;
                align-items: center;
            font-family: $f-header;
            font-size: 28px;
            font-weight: $f-weight-bold;
            height: 100%;
            line-height: 1.14;
            letter-spacing: normal;
            position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s;
            width: 100%;
        }

        &__date {
            @include font-source($sans-serif, 12px, #555555, 14px, 600);
            letter-spacing: 1px;
            margin: 0;
            text-transform: uppercase;
        }

        &__name {
            color: $black;
            font-family: $f-header;
            font-size: 18px;
            font-weight: $f-weight-bold;
            line-height: 1.22;
            letter-spacing: 0.5px;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .folder-wrapper {
        @include breakpoint(medium) {
            margin-bottom: 22px;
        }
    }

    .folder-refinement {
        &.has-scrolled-right {
            ul {
                margin-left: 0;
                margin-right: -17px;

                @include breakpoint(medium) {
                    margin-right: -25px;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0 0 0 -17px;
            display: none;
            list-style: none;

            @include breakpoint(medium) {
                margin-left: -25px;
            }

            @include breakpoint(large) {
                display: flex;
            }

            li {
                padding: 0 9px;

                @include breakpoint(xlarge) {
                    padding: 0 16px;
                }
            }

            li a {
                font-weight: $f-weight-bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-size: 12px;
                color: $c-gray;

                &.selected {
                    color: $black;
                }
            }
        }

        .select-outterwrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            h6 {
                font-family: $sans-serif;
                font-size: $f-size-xs;
                color: $c-dark-gray;
                line-height: 24px;
                text-transform: none;
                margin-right: 18px;
            }

            .select-wrapper {
                width: 100%;

                @include breakpoint(large) {
                    max-width: 203px;
                }
            }

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    .pagination ul {
        justify-content: flex-start;
    }

}
