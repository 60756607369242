.clearfix {
    @extend %clearfix;
}

.h-color {
    &--white {
        color: $white;
    }
}

.h-strong {
    font-weight: $f-weight-bold;
}

.h-strong--p {
    @include font-source($f-body, $f-size, null, 24px, $f-weight-bold);
}

.h-strong--link {
    @include font-source($f-body, $f-size, null, 1, $f-weight-bold);

    letter-spacing: 3px
}

.h-strong-small--link {
    @include font-source($f-body, 11px, null, 1, $f-weight-bold);

    letter-spacing: 2.5px
}

.h-large--p {
    @include font-source($sans-serif, $f-size-xs, $c-heading, 1.43, $f-weight-regular);
   
    @include breakpoint(medium) {
        font-size: $f-size;
        line-height: 1.5;
    }
}

.h-x-large--p {
    @include font-source($f-subheader, $f-header-h5-size, $c-heading,$f-header-h5-lh, $f-weight-regular);
   
    @include breakpoint(medium) {
        font-size: $f-header-h5-size-md;
    }
}

.h-emphasis {
    text-transform: uppercase;
    color: $c-gray;
}

.h-caption {
    font-size: 12px;
    line-height: 16px;

    @include breakpoint(large) {
        font-size: $f-size-xs;
        line-height: $f-size;
    }
}

.full-width {
    @include width(100%);
}

.h-visually-hidden {
    @include h-visually-hidden;
}

.h-hide--mobile {
    display: none;
}

.h-hide--tablet {
    display: block;

    &.h-hide--mobile {
        display: none;
    }
}

.h-hide--desktop {
    display: block;
}

.h-text--right {
    text-align: right;
}

.h-text--left {
    text-align: left;
}

.h-text--center {
    text-align: center;
}

.h-show {
    display: block;

    &--row {
        display: flex !important;
    }
}

.h-overflow--visible {
    overflow: visible !important;

    &.h-animate--slideup {
        overflow: hidden !important;
    }
}

.visually-hidden {
    @include visually-hidden;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.w-100 {
    width: 100% !important;
}

.w-mobile-100 {
    @include bp(max-width, $bp-medium) {
        width: 100% !important;
    }
}

.h-desktop-only {
    @include bp(max-width, $bp-large) {
        display: none !important;
    }
}

.h-mobile-only {
    @include breakpoint(large) {
        display: none !important;
    }
}

.dialog-open {
    @include breakpoint(large) {
        overflow: hidden;
    }
}
