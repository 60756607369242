$footer-break-mobile: 540px;
$footer-break-tablet-sm: 834px;
$footer-break-tablet: 1024px;
$footer-break-desktop: 1600px;
$footer-content-max-width: 1168px;
$footer-margin: 27px;

#main {
    margin-bottom: $footer-vertical-spacer;

    @media screen and (min-width: $footer-break-mobile) {
        margin-bottom: $footer-vertical-spacer-mobile;
    }

    @media screen and (min-width: $footer-break-desktop) {
        margin-bottom: $footer-vertical-spacer-desktop;
    }
}

.footer {
    color: #fff;
    font-family: $sans-serif;
    font-size: $f-size-xs;
    margin-top: 100px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    * {
        box-sizing: border-box;
    }

    &__btn {
        border: 1px solid #fff;
        display: inline-block;
        font-size: $f-size-s;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 3px;
        padding: 12px 20px;
        text-transform: uppercase;
    }
}

.footer-top {
    // redhook background with fifth member signup info
    background-color: transparent;
    background-image: url('../images/footer/fish-hooks/red-full-mobile.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 100% auto;
    display: flex;
        align-items: center;
    height: 120.77294686vw;
    margin: -19vw 0;
    margin-top: -$footer-vertical-spacer;
    padding: 0 $footer-margin;
    position: relative;
    width: 100%;

    @media screen and (min-width: $footer-break-mobile) {
        background-image: url('../images/footer/fish-hooks/red-full-desktop.svg');
        background-size: 1600px auto;
        height: 500px;
        margin: -$footer-vertical-spacer-mobile 0;
    }

    @media screen and (min-width: $footer-break-desktop) {
        background-size: 100% auto;
        height: 31.25vw;
        margin: -$footer-vertical-spacer-desktop 0;
    }

    &__brow,
    &__heading {
        text-transform: uppercase;
    }

    &__heading {
        font-family: 'futura-bold', sans-serif;
        font-size: 17.2px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin-bottom: 20px;

        @media screen and (min-width: $footer-break-mobile) {
            font-size: 25px;
            font-weight: bold;
            letter-spacing: -0.6px;
            line-height: 1.25;
            margin-bottom: 22px;
        }
    }

    &__brow {
        font-size: $f-size-xs;
        line-height: 1.29;
        letter-spacing: 1px;
        margin-bottom: 8px;
    }

    &__intro {
        font-size: $f-size;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-top: -20px;
        margin-bottom: 20px;
        width: calc(100% + 100px); // allows intro text to extend under logo
    }

    &__body {
        background-image: url('../images/footer/fifth-member-red-bg.png');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 262px 264px;
        display: flex;
            align-items: center;
            justify-content: flex-end;
            vertical-align: middle;
        height: 262px;
        margin: 0 auto;
        max-width: $footer-content-max-width;
        width: 100%;

        @media screen and (min-width: $footer-break-mobile) {
            height: 440px;
            margin-top: 60px;
        }

        &__inner {
            max-width: 385px;
            padding-right: 100px;
        }
    }
}

.footer-middle {
    // black background section with logo, menu, copyright, etc.
    background-color: #000;
    color: #fff;
    padding: 0;
    padding-top: 24.15458937vw;

    @media screen and (min-width: $footer-break-mobile) {
        // For most screens, will display as 250px.  Starting at 2000px it gets bigger, making sure the red hook above doesn't cover content
        padding-top: MAX(250px, 12.5vw); // Use all uppercase so SCSS doesn't compile as function
    }

    &__heading {
        font-family: 'futura-bold', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__container {
        margin: 0 auto;
        max-width: $footer-content-max-width + ($footer-margin * 2);

        @media screen and (min-width: $footer-break-tablet) {
            display: flex;
            padding: 0 0 0 $footer-margin;
            vertical-align: baseline;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        padding: 0 $footer-margin;

        @media screen and (min-width: $footer-break-mobile) {
            max-width: 360px;
            margin: 0 auto 40px;
        }

        @media screen and (min-width: $footer-break-tablet-sm) {
            padding: 0;
            width: 360px;
        }

        @media screen and (min-width: $footer-break-desktop) {
            margin-right: 0;
            margin-left: 0;
        }

        & > div {
            width: 100%;
        }
    }

    &__logo {
        display: block;
        margin: 0 auto 20px;
    }
}

.footer-newsletter {
    text-align: center;

    &__lead-in {
        margin: 0 0 10px;
    }

    &__form {
        position: relative;

        &__label {
        }

        &__submit {
            background-color: transparent;
            background-image: url('../images/footer/newsletter-input-arrow.svg');
            background-repeat: no-repeat;
            background-position: right center;
            border: none;
            height: 48px;
            position: absolute;
                top: 0;
                right: 0;
        }
    }


    input[type="email"] { // needed for specifity
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #fff;
        color: inherit;
        margin: 0 0 20px;
        padding: 12px 0;
        padding-right: 30px;

        &::placeholder {
            color: inherit;
            font-family: inherit;
            font-size: $f-size-s;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 3px;
            text-transform: uppercase;
        }
    }
}

.footer-menu {
    width: 100vw;

    @media screen and (min-width: $footer-break-tablet-sm) {
        padding: 0 $footer-margin;
        margin-right: auto;
        margin-left: auto;
        width: 785px;
    }

    @media screen and (min-width: $footer-break-tablet) {
        padding-right: 0;
    }

    ul {
        border-top: 1px solid #555;
        display: flex;
            flex-wrap: wrap;

        @media screen and (min-width: $footer-break-tablet-sm) {
            flex-wrap: nowrap;
            border-top: none;
            padding-bottom: 47px;
        }

        li {
            border-style: solid;
            border-color:  #555;
            border-width: 0;
            border-bottom-width: 1px;
            display: block;
            height: 50vw;
            width: 50vw;

            &:nth-child(2n){
                border-left-width: 1px;
            }

            &:last-child {
                border-bottom-width: 0;
                height: auto;
                width: 100%;
            }

            @media screen and (min-width: $footer-break-tablet-sm) {
                flex: 1;

                &,
                &:last-child {
                    border-left-width: 1px;
                    border-bottom-width: 0;
                    height: auto;
                    width: auto;
                }

                &:first-child {
                    border-left-width: 0;
                }
            }

            > a {
                display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                height: 100%;
                padding: 25px $footer-margin;
                width: 100%;
                text-align: center;

                @media screen and (min-width: $footer-break-tablet-sm) {
                    padding: 25px 10px;
                }
            }

            @media screen and (max-width: #{$footer-break-tablet-sm - 1px}) {
                &:last-child {
                    .footer-menu__item-top,
                    .footer-menu__item-bottom {
                        height: auto;
                    }

                    .footer-menu__item-top {
                        margin-bottom: 0;
                    }

                    > a {
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &__item {

        &-top {
            height: calc(75% - 20px);
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }

        &-bottom {
            height: 25%;
        }

        &__img-wrap {
            &--blackened-recordings {
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                overflow: hidden;
                height: 70px;
                width: 70px;
            }
        }

        &__img {
            display: block;
            margin: 0 auto;
            width: 80px;

            &--fifth-member {

            }

            &--black-box {
                width: 96px;
            }

            &--blackened-whiskey {
                width: 70px;
            }

            &--blackened-recordings {
                height: 48px;
                width: 48px;
            }

            &--foundation {
                width: 102px;
            }
        }

        &:last-child {
            .footer-menu__item-bottom {
                @include bp(max-width, $footer-break-tablet-sm) {
                    margin-left: 10px;
                }
            }
        }
    }
}

.footer-bottom {
    background-color: #000;
    border-top: 1px solid #555;
    padding-top: 48px;

    @media screen and (min-width: $footer-break-tablet-sm) {
        padding-top: 30px;
    }

    @media screen and (min-width: $footer-break-desktop) {
        display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
        padding-top: 0;
        min-height: 130px;
    }

    &__container {
        margin: 0 auto;
        max-width: $footer-content-max-width + ($footer-margin * 2);
        padding: 30px $footer-margin 58px;

        @media screen and (min-width: $footer-break-desktop) {
            display: flex;
                align-content: center;
                justify-content: space-between;
                align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            width: 100%;
        }
    }
}

.footer-social {
    background-color: #000;
    margin-bottom: 23px;

    @media screen and (min-width: $footer-break-desktop) {
        margin-bottom: 0;
        order: 1;
    }

    ul {
        display: flex;
            align-items: center;
            justify-content: space-between;
        width: 320px;
        margin: 0 auto;
    }

    &__item {
        background-color: transparent;

        &--facebook {
            @include svg-view-facebook;
        }

        &--twitter {
            @include svg-view-twitter;
        }

        &--instagram {
            @include svg-view-instagram;
        }

        &--youtube {
            @include svg-view-youtube;
        }

        &--apple {
            @include svg-view-apple;
        }

        &--spotify {
            @include svg-view-spotify;
        }
    }
}

.footer-support {
    background-color: #000;

    ul {
        display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 auto;
            max-width: 360px;

            @media screen and (min-width: $footer-break-tablet-sm) {
                max-width: none;
            }

        li {
            color: #acacac;
            display: inline;
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            letter-spacing: 1px;
            margin-bottom: 5px;
            text-transform: uppercase;
            vertical-align: middle;

            &::before {
                content: '|';
                margin: 0 10px;
            }

            &:first-child,
            &.line-wrap-point {
                &::before {
                    display: none;

                }
            }

            &.line-wrap-point {
                &::before {
                    display: inline;

                    @include bpmm($bp-xsmall, $bp-medium) {
                        display: none;
                    }
                }
            }

            &.metallicaclub {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: flex;
                    align-items: center;
                    height: 10px;
                    font-size: 16px;
                }
            }
        }
    }
}

.footer-top,
.footer-middle,
.footer-bottom,
.footer-social,
.footer-support {
    * {
        color: inherit;
    }
}
