.c-media-carousel {
    background-color: $black;
    color: $white;
    clip-path: $polygon-clip-path;
    padding-top: 100px;
    padding-bottom: 130px;

    @include breakpoint(medium) {
        clip-path: $polygon-clip-path-md;
        padding-bottom: 200px;
        padding-top: 160px;
    }

    @include breakpoint(large) {
        clip-path: $polygon-clip-path-lg;
        padding-bottom: 183px;
    }

    * {
        box-sizing: border-box !important;
        color: inherit;
    }

    &__nav {
        display: flex;
            justify-content: space-between;
            align-items: end;
        margin-bottom: 17px;
    }

    &__more {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 2.5px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: $f-size-xs;
            letter-spacing: 3px;
        }
    }

    &__more-link {
        display: none;

        &.is-active {
            display: inline-block;
        }
    }

    &__heading {
        font-size: 20px;
        font-family: $f-header;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.5px;
        margin-right: 10px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: 34px;
            margin-right: 35px;
        }
    }

    &__tabs {
        line-height: 1;
    }

    &__tab {
        color: $c-medium-gray;
        display: inline-block;

        &.is-active {
            color: inherit;
        }
    }

    &__section {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__item {
        display: block;
        margin-right: 8px;

        iframe {
            width: 100%;
        }

        .slick-initialized & {
            max-width: calc(100vw - #{$l-small-padding});
            width: 360px;

            @include breakpoint(medium) {
                width: 482px;
            }
        }
    }

    &__video-wrap {
        position: relative;

        &::after {
            @include svg-view-media-play-button-white;
            position: absolute;
                top: 50%;
                left: 50%;
            translate: -50% -50%;
        }
    }

    &__credit {
        font-size: $f-size-xs;
        font-weight: $f-weight-medium;
        line-height: 1.43;
        letter-spacing: normal;
        margin-bottom: 0;

        @include breakpoint(medium) {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &--theme-light {
        background-color: transparent;
        color: $c-black;
    }
}

.s-media-carousel {
    // force equal height slides
    .slick-track {
        display: flex !important;
    }

    &:not(#{&}--theme-light) {
        .slick-dots {
            li {
                &.slick-active {
                    button {
                        &::before {
                            background-color: $c-light-red;
                        }
                    }
                }
            }
        }
    
        .slick-prev {
            &::before {
                @include svg-view-media-carousel-prev;
            }
        }
    
        .slick-next {
            &::before {
                @include svg-view-media-carousel-next;
            }
        }
    }

    .c-note-cards__item {
        background-color: $white;
        color: $white;
    }
}
