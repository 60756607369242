.c-amp-category-nav {
    @include breakpoint(medium) {
        margin-bottom: 17px;
    }

    &__control {
        background-color: transparent;
        border: none;
        display: none;
            align-items: center;
            justify-content: center;
        height: 32px;
        padding: 0;
        position: absolute;
            top: calc(50% - 2px);
            z-index: 1;
        transform: translateY(-50%);
        width: 24px;

        @include breakpoint(large) {
            display: flex;
        }

        &::before {
            content: '';
            height: 13px;
            transform: rotate(45deg);
            width: 13px;
        }

        &:hover:not(.disabled):not(:disabled) {
            background-color: transparent;
            border: none;
        }
    
        &--scroll-left {
            left: 0;

            &::before {
                border-left: 3px solid $c-black;
                border-bottom: 3px solid $c-black;
            }
        }

        &--scroll-right {
            right: 0;

            &::before {
                border-right: 3px solid $c-black;
                border-top: 3px solid $c-black;
            }
        }
    }

    &__categories-wrap {
        position: relative;

        &::before {
            @include breakpoint(large) {
                content: '';
                height: 100%;
                pointer-events: none;
                position: absolute;
                    top: 0;
                    z-index: 1;
                width: 100px;
            }
        }

        &:not(.has-scrolled-right) {
            &::before {
                right: 0;
                background: linear-gradient(to left, white, transparent);
            }

            .c-amp-category-nav__control--scroll-left {
                display: none;
            }
        }

        &.has-scrolled-right {
            &::before {
                left: 0;
                background: linear-gradient(to right, white, transparent);
            }

            .c-amp-category-nav {
                &__control--scroll-right {
                    display: none;
                }

                &__categories {
                    margin-left: 0;
                    margin-right: -17px;

                    @include breakpoint(medium) {
                        margin-right: -25px;
                    }
                }
            }
        }
    }

    &__categories {
        display: none;
        list-style: none;
        margin: 0 0 0 -17px;
        overflow: hidden;
        padding: 0;

        li {
            .release-category {
                font-size: 14px;
                white-space: nowrap;
                font-size: 14px;
            }
        }

        @include breakpoint(medium) {
            margin-left: -25px;
        }     

        @include breakpoint(large) {
            display: flex;
                justify-content: flex-start;
        }
    }

    &__selector-wrap {
        display: flex;
            justify-content: center;
            align-items: center;

        @include breakpoint(large) {
            display: none;
        }
    }
}