.pt_content-metclub {

    .pagination {
        @include breakpoint(large) {
            margin-bottom: 15px;
        }

        @include breakpoint(xlarge) {
            margin: 0;
        }
    }

	.chapter-news-button{
		margin: 10px auto 10px auto;
	}
	
    .metclub-exclusives {
        margin-top: 16px;
        @include forum-tab-content;

        @include breakpoint(medium) {
            margin-top: 26px;
        }

        @include breakpoint(large) {
            margin-top: 52px;
        }

        @include breakpoint(xlarge) {
            margin-top: 86px;
        }

        #videogrid .video-content-list .video-info a,
        #videogrid .video-content-list .video-count {
            color: $white;
        }

        #videogrid .video-content-list {
            @media (max-width: $bp-small) { // yes.... ask Ruben
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                max-width: calc(100% - 23px);

                .col {
                    padding: 12px 6px 0 6px;
                }
            }
        }

        .metclub-heading {

            @include breakpoint(medium) {
                padding-top: 20px;
            }

            @include breakpoint(large) {
                padding-top: 40px;
            }

            @include breakpoint(xlarge) {
                padding-top: 9px;
            }
        }

        .thumbnail-counter {
            display: block;
            text-align: center;
            font-size: $f-size;
            line-height: 24px;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 5px;

            .count {
                font-weight: $f-weight-bold;
            }

            @include breakpoint(medium) {
                margin-bottom: 0;
                margin-top: 21px;
                line-height: 16px;
            }

            @include breakpoint(large) {
                display: none;
            }
        }

        .select-outterwrapper {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;

            h6 {
                font-size: $f-size-xs;
                font-family: $sans-serif;
                text-transform: none;
                color: $c-dark-gray;
                margin-right: 15px;
            }

            .select-wrapper {
                width: 100%;
            }

            @include breakpoint(medium) {
                display: none;
            }
        }

        .tabs-container {
            margin-top: 0;

            @include breakpoint(medium) {
                padding-top: 15px;
            }

            @include breakpoint(large) {
                padding-top: 32px;
            }

            @include breakpoint(xlarge) {
                padding-top: 0;
            }
        }

        .tabs__heading {
            display: none;

            .tab {
                flex-basis: 19%;

                @include breakpoint(large) {
                    flex-basis: 25%;
                }
            }

            @include breakpoint(medium) {
                display: flex;
                margin-left: auto;
                margin-right: auto;
                max-width: 88%;
                position: relative;
                z-index: 2;
            }

            @include breakpoint(xlarge) {
                max-width: 1077px;
            }
        }

        .tabs__content {
            background: $black;
            margin-left: -18px;
            margin-right: -18px;
            color: $white;
            padding: 18px 0;

            @include breakpoint(medium) {
                padding: 20px 0;
            }

            @include breakpoint(medium) {
                padding: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .tab.active .tab__content {
                display: block;
            }
        }

        .tabs {
            border-bottom: none;

            .tab {
                background-color: $white;
                border-left: 2px solid $white;

                @include breakpoint(large) {
                    background-color: $c-light-gray;
                    min-height: 48px;
                }

                h6 {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-size: $f-size;
                    color: $c-gray;
                    line-height: 41px;

                    @include breakpoint(large) {
                        line-height: 48px;
                    }
                }

                &.active {

                    @include breakpoint(large) {
                        background-color: $black;
                    }

                    h6 {
                        color: $black;

                        @include breakpoint(large) {
                            color: $white;
                        }
                    }
                }
            }
        }

        .tabs__heading .tab {
            margin-bottom: 0;
        }


        .tabs__content_wrapper {
            display: none;

            &.active {
                display: block;
            }
        }

        .see-more-exclusives {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 30px;

            @include breakpoint(large) {
                margin-bottom: 50px;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 66px;
            }
        }

        .tab--photos.tabs__content_wrapper .tab__content {
            @include content-width;
        }
    }

    .content-hero {
        position: relative;
    }

    .metclub-logo {
        position: relative;
        background-image: url("../images/fifth-member-logo.svg");
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        margin: 0 auto;
        width: 22%;
        padding-top: 100%;
        @include position(absolute, null null 97px 50%);
        transform: translateX(-50%);

        @include breakpoint(medium) {
            bottom: 138px;
            width: 10.5%;
        }

        @include breakpoint(large) {
            width: 120px;
            height: 120px;
            bottom: 101px;
        }

        @include breakpoint(xlarge) {
            width: 12.9%;
            height: auto;
            bottom: 109px;
        }

        &.news {
            bottom: 23px;

            @include breakpoint(medium) {
                bottom: 64px;
            }

            @include breakpoint(large) {
                bottom: 101px;
            }
        }
    }

    .metclub-login {
        margin-top: 40px;
        text-align: center;

        .button:first-child {
            margin-right: 30px;
        }

        @include breakpoint(large) {
            @include position(absolute, null null 27px 50%);
            transform: translateX(-50%);
        }

        @include breakpoint(xlarge) {
            bottom: 35px;
        }

        &.logged-in {

            h2 {
                @include h5;
                line-height: 20px;

                @include breakpoint(large) {
                    @include h2;
                    color: $white !important;
                }
            }

            @include breakpoint(large) {
                bottom: 13px;
            }

            @include breakpoint(xlarge) {
                bottom: 23px;
            }
        }

    }

    .start-chapter {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }

        .metclub-start-chapter {
            padding-top: 58px;


            .metclub-heading {
                text-align: center;

                @include breakpoint(large) {
                    h3 {
                        font-size: 40px;
                        margin: 32px 0 8px 0;
                    }
                }
            }

            .start-chapter-desc {
                padding-top: 10px;

                .metclub-desc {
                    font-size: $f-size;
                    color: $black;
                    letter-spacing: 0;
                    line-height: 20px;
                }

                ul {
                    list-style: none;
                    padding-left: 0;
                }
            }
        }
    }

    .metclub-page-title {
        font-size: 32px;
        font-weight: $f-weight-medium;
        opacity: 0.15;
        color: $black;
        letter-spacing: 1px;
        line-height: 22px;
        width: 100%;
        margin-bottom: 8px;

        @include breakpoint(medium) {
            font-size: 56px;
            line-height: 37px;
        }

        @include breakpoint(xlarge) {
            font-size: 96px;
            margin-bottom: 42px;
            line-height: 64px;
        }
    }

    .metclub-content {
        section {
            margin-top: 50px;

            @include breakpoint(xlarge) {
                margin-top: 0;
            }

            .metclub-heading {
                text-align: center;
                padding-top: 9px;

                h3 {
                    font-size: 32px;
                    color: $black;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 40px;

                    @include breakpoint(medium) {
                        font-size: 40px;
                    }
                }

                @include breakpoint(large) {
                    padding-top: 9px;
                }

                @include breakpoint(xlarge) {
                    padding-top: 49px;
                }
            }

            .metclub-desc {
                font-size: $f-size;
                color: $black;
                letter-spacing: 0;
                line-height: 23px;
            }

            &.metclub-login {
                position: absolute;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
                top: 70px;
                margin-top: 0;

                @include breakpoint(medium) {
                    top: 100px;
                }

                @include breakpoint(xlarge) {
                    top: 85px;
                }

                .metclub-login-btns {
                    text-align: center;
                    margin-top: 100px;

                    @include breakpoint(medium) {
                        margin-top: 50px;
                    }

                    @include breakpoint(large) {
                        margin-top: -22px;
                    }

                    @include breakpoint(xlarge) {
                        margin-top: 1px;
                    }

                    .button:first-child {
                        margin-right: 30px;
                    }
                }

                .metclub-heading {
                    margin-top: 300px;

                    h3 {
                        color: $white;
                    }
                }
            }

            &.metclub-benefits {
                margin-top: 37px;

                @include breakpoint(medium) {
                    margin-top: 49px;
                }

                @include breakpoint(large) {
                    margin-top: 70px;
                }

                @include breakpoint(xlarge) {
                    margin-top: 38px;
                }

                .metclub-desc {
                    margin: -5px 0 0;
                    padding: 0;

                    @include breakpoint(medium) {
                        margin: -16px 0 0;
                        line-height: 24px;
                    }

                    @include breakpoint(large) {
                        margin: -16px 0 0;
                    }

                    @include breakpoint(xlarge) {
                        margin-top: -21px;
                    }
                }

                ul.metclub-benefits-list {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    list-style-type: none;
                    margin: 5px 0 0;
                    padding: 0;

                    @include breakpoint(medium) {
                        margin: -8px 0 0;
                    }

                    @include breakpoint(large) {
                        margin: 9px 0 0;
                    }

                    @include breakpoint(xlarge) {
                        margin: 0;
                        justify-content: space-between;
                    }

                    li.metclub-benefit {
                        list-style: none;
                        width: 100%;
                        margin-bottom: 19px;

                        @include breakpoint(medium) {
                            width: 50%;
                            margin: 0 0 20px;
                        }

                        @include breakpoint(xlarge) {
                            width: 49%;
                            margin: 0 0 35px 1%;

                            &:nth-child(odd) {
                                margin-left: 0;
                            }
                        }

                        .benefit-image {
                            display: inline-block;
                            max-width: 64px;
                            height: auto;
                            vertical-align: top;
                            border: 1px solid black;
                            background-color: black;

                            @include breakpoint(medium) {
                                margin-right: 11px;
                            }

                            @include breakpoint(large) {
                                max-width: 80px;
                                margin-right: 18px;
                                margin-left: 20px;
                            }

                            @include breakpoint(xlarge) {
                                max-width: 80px;
                                margin-right: 0;
                                margin-left: 0;
                            }
                        }

                        .benefit-summary {
                            display: inline-block;
                            max-width: 70%;
                            margin-left: 12px;

                            @include breakpoint(medium) {
                                margin-left: 0px;
                            }

                            @include breakpoint(large) {
                                max-width: 68%;
                            }

                            @include breakpoint(xlarge) {
                                margin-left: 19px;
                            }

                            .benefit-heading {
                                font-family: $sans-serif;
                                font-weight: $f-weight-bold;
                                font-size: $f-size;
                                color: $black;
                                letter-spacing: 0;
                                line-height: 25px;
                                text-transform: none;
                            }

                            .benefit-desc {
                                font-size: $f-size-xs;
                                color: $black;
                                letter-spacing: 0;
                                line-height: 24px;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &.metclub-benefits + .metclub-exclusives {
                @include breakpoint(xlarge) {
                    margin-top: 46px;
                }
            }

            &.metclub-so-what {
                background-color: #F7F6F6;
                margin-top: 0;
                padding-top: 44px;
                padding-bottom: 51px;

                @include breakpoint(medium) {
                    padding-top: 53px;
                    padding-bottom: 26px;
                }

                @include breakpoint(large) {
                    padding: 83px 19px 19px;
                }

                @include breakpoint(xlarge) {
                    padding: 51px 19px 27px;
                }

                .metclub-heading h3 {
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    font-style: italic;

                    @include breakpoint(medium) {
                        font-size: 40px;
                    }
                }

                .metclub-sowhat-content {
                    .content-tile {
                        margin-bottom: 20px;

                        @include breakpoint(medium) {
                            &:last-child {
                                display: none;
                            }
                        }

                        @include breakpoint(large) {
                            &:last-child {
                                display: flex;
                            }
                        }

                        .content-info {
                            flex-grow: 1;
                            background-color: white;
                            display: flex;
                            flex-direction: column;
                        }

                        .content-title {
                            margin: 0;
                            padding: 12px 8px 16px 8px;

                            @include breakpoint(xlarge) {
                                margin: 0;
                                padding: 20px 16px 0;
                            }

                            .content-title__name {
                                font-size: 20px;
                                line-height: 24px;
                                margin-top: 0;

                                @include breakpoint(xlarge) {
                                    font-size: 24px;
                                    line-height: 32px;
                                    margin-top: 4px;
                                }
                            }

                            .content-title__category {
                                display: none;

                                @include breakpoint(xlarge) {
                                    display: block;
                                    margin: 20px 16px 0 0;
                                }
                            }
                        }

                        .content-body {
                            display: none;

                            @include breakpoint(small) {
                                display: block;

                                p {
                                    font-size: 12px;
                                    display: none;

                                    @include breakpoint(large) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            @include breakpoint(medium) {
                                margin-left: 0;
                                padding: 0 24px 6px 8px;
                            }

                            @include breakpoint(xlarge) {
                                padding: 0 28px 6px 16px;
                            }
                        }
                    }
                }

                .see-more-so-what-content {
                    text-align: center;
                    margin-top: 19px;

                    @include breakpoint(medium) {
                        margin-top: 20px;
                    }
                }
            }

            &.metclub-club-news {
                margin-top: 42px;
                margin-bottom: 50px;

                @include breakpoint(medium) {
                    margin-top: 52px;
                }

                @include breakpoint(large) {
                    margin-top: 81px;
                    padding: 0 26px;
                }

                @include breakpoint(xlarge) {
                    margin-top: 51px;
                    padding: 0 26px;
                }

                .metclub-heading {

                    @include breakpoint(medium) {
                        font-size: 32px;
                    }
                }

                .metclub-news-content {

                    .content-tile {
                        .content-title__name {
                            font-size: 20px;
                            line-height: 24px;
                            margin-top: 0;

                            @include breakpoint(large) {
                                font-size: 24px;
                                line-height: 32px;
                                margin-top: 3px;
                            }
                        }

                        .content-info {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                        }

                        .content-body {
                            .button--see-more {
                                border: none;
                                padding: 6px 2px;
                            }

                            p {
                                font-size: 12px;
                                display: none;

                                @include breakpoint(large) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .see-more-news-content {
                    text-align: center;
                    margin-top: 12px;

                    @include breakpoint(medium) {
                        margin-top: 47px;
                    }

                    @include breakpoint(large) {
                        margin-top: 58px;
                    }
                }
            }
        }
    }

    .metclub-sowhat-wrapper {
        .content-wrapper {
            position: relative;
        }

        .pagination-wrapper {
            @include breakpoint(xlarge) {
                margin: 0;
                @include position(absolute, 40px 42px null null);

                .col {
                    padding: 0;
                }
            }
        }

        .featured-tile {
            position: relative;

            .flag {
                @include position(absolute, 2px null null 8px);
                font-weight: $f-weight-bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $white;
                font-size: 12px;

                @include breakpoint(large) {
                    top: 8px;
                    left: 18px;
                }
            }

            h6 {
                font-size: 12px;
                font-family: $sans-serif;
                line-height: 10px;

                @include breakpoint(large) {
                    display: none;
                }
            }

            h5 {
                @include breakpoint(large) {
                    @include font-source($f-header, $f-header-h2-size, $white, $f-header-h2-lh, $f-weight-medium);
                    margin-bottom: 5px;
                }
            }

            .body {
                margin-top: 14px;

                @include breakpoint(medium) {
                    margin: 14px 16px 0 16px;
                }

                @include breakpoint(large) {
                    color: $white;
                    position: absolute;
                    bottom: 27px;
                    max-width: 460px;
                    left: 50%;
                    transform: translate(-50%);
                    text-align: center;
                }

                @include breakpoint(xlarge) {
                    bottom: 35px;
                }
            }

            p {
                font-size: $f-size-xs;
                margin: 0 0 11px 0;

                @include breakpoint(large) {
                    font-size: $f-size;
                    margin: 0 0 20px 0;
                }
            }

            .button {
                display: none;

                @include breakpoint(large) {
                    display: inline-block;
                }
            }
        }

        .items-per-page {
            display: none;
        }

        .content-tile {

            .content-title {
                .content-title__name {

                    @include breakpoint(xlarge) {
                        font-size: 20px;
                    }
                }
            }

            .content-body p {
                font-size: $f-size-xs;
                margin: 0;
                display: none;
            }
        }
    }

    .metclub-news-wrapper {
        .content-wrapper {
            position: relative;
        }

        .pagination-wrapper {
            @include breakpoint(xlarge) {
                margin: 0;
                @include position(absolute, 40px 42px null null);

                .col {
                    padding: 0;
                }
            }
        }

        .items-per-page {
            display: none;
        }

        .row:first-child {
            justify-content: space-between;
        }

        .content-tile {
        	display: flex;
        	    
            .content-title__name {
                line-height: 24px;
                margin-top: 1px;
            }

            @include breakpoint(large) {
                flex-direction: row;
                margin-bottom: 0;

                .content-image {
                    flex: 1;
                }
				
				.content-info{
				    flex: 1;
				}
				
                .content-title {
                    margin-top: 0;
                }

                .content-title__name {
                    line-height: 24px;
                    font-size: 20px;
                }

                .content-title__date {
                    margin-top: -4px;
                }
            }

            @include breakpoint(xlarge) {
                .content-title, .content-body {
                    margin-left: 24px;
                }
            }
        }
    }

    .metclub-chapter-wrapper {
        background-color: $c-mostly-white;
        padding-bottom: 50px;

        .metclub-page-title {
            margin-bottom: 7px;

            @include breakpoint(medium) {
                margin-bottom: 30px;
            }

            @include breakpoint(large) {
                margin-bottom: 52px;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 26px;
            }
        }

        .metclub-local-chapters {

            .metclub-chapters-list {
                margin-bottom: 40px;

                .metclub-chapter {
                    display: flex;
                        flex-direction: column;
                    padding: 4px;

                    .chapter {
                        // These mobile only styles would be good candidates for container queries, so they don't need to be so contextually specific, once that becomes fully available
                        &-container {
                            @include bp(max-width, $bp-medium) {
                                min-height: 280px;
                            }
                        }

                        &-image {
                            @include bp(max-width, $bp-medium) {
                                margin-bottom: 16px;
                            }
                        }

                        &-heading {
                            display: block;

                            @include bp(max-width, $bp-medium) {
                                @include h4;

                                color: inherit;
                                margin-bottom: 7px;
                            }
                        }

                        &-state {
                            @include bp(max-width, $bp-medium) {
                                font-size: 12px;
                                line-height: 1.5;
                            }
                        }

                        &-links {
                            @include bp(max-width, $bp-medium) {
                                gap: 20px;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }

        }
    }

    .c-tile-slider {
        .c-tile-slider__slider {
            .c-featured-article {
                height: 560px;
                @include breakpoint(large) {
                    height: 700px;
                }
            }

            .c-featured-article__img-wrap {
                &::after {
                    content: "";
                    background: rgba(0, 0, 0, 0.7);
                    display: block;
                    position: absolute;
                        left: 0;
                        top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

.metclub-chapter {
    &--slider {
        padding-left: 4px;
        padding-right: 4px;
    }

    .chapter {
        &-container {
            background-image: url("../images/local-chapter-bg.jpg");
            background-size: cover;
            box-sizing: border-box;
            color: $white;
            display: flex;
                flex-direction: column;
            height: 100%;
            min-height: 370px;
            padding: 20px 20px 25px;

            @include breakpoint(medium) {
                min-height: 440px;
                padding: 30px;
            }
        }

        &-image {
            flex-grow: 0;
            margin-bottom: 20px;
        }

        &-summary {
            display: flex;
                flex-direction: column;
            flex-grow: 1;
        }

        &-heading {
            @include h3;

            color: inherit;
            margin-bottom: 10px;

            @include breakpoint(medium) {
                @include h4;

                color: inherit;
                margin-bottom: 10px;
            }
        }

        &-state {
            font-size: 14px;
            line-height: 1.43;
            margin-bottom: 0;
            margin-top: 0;
        }

        &-links {
            display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                gap: 30px;
            flex-grow: 1;
            padding-top: 10px;

            @include breakpoint(medium) {
                flex-direction: column;
                gap: 0;
                justify-content: flex-end;
                padding-top: 14px;
            }
        }

        &-link {
            color: inherit;
            display: block;
            font-size: 11px;
            font-weight: $f-weight-bold;
            letter-spacing: 2.5px;
            line-height: 1;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 14px;
                letter-spacing: 3px;
                width: 100%;
            }

            &:not(:last-child) {
                @include breakpoint(medium) {
                    margin-bottom: 18px;
                }
            }

            &:last-child {
                @include breakpoint(medium) {
                    margin-bottom: 4px;
                }
            }
        }
    }
}