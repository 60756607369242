.styleguide-content {
}

.styleguide-banner {
    position: relative;
    width: 100%;
    @include content-width;

    img {
        height: auto;
        margin-left: calc(-1 * (100vw - 100%) / 2);
        max-width: initial;
        width: 100vw;
    }

    .page-title {
        position: relative;
        @include position(absolute, 50% null null null);
        transform: translateY(-50%);
    }

    h1 {
        color: $black;
    }
}

h3.steel {
    color: $c-gray;
}

.sg-h {
    margin-bottom: 50px;
    text-transform: uppercase;
    text-align: center;
    color: $brand-primary;
}

.cms-style-guide section {
    @include g(m, all);
    margin: 0 0 50px;
    &.active-section {
        display: block;
    }
}

// Colors
.sg-colors {
    @include g(m, all);

    ul {
        list-style: none;
        @include g-all;
        @include reset-g-padding();

        li {
            float: left;
            margin-bottom: 20px;
            @include g-item(max, $bp-large + 1, 5, 980, 19.5);
            @include g-item($bp-large, $bp-small + 1, 3, 600, 13);
            @include g-item($bp-small, 320px, 2, 320, 6.5);

            span {
                display: block;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                margin: 0 auto 20px;
                position: relative;
            }

            p {
                text-align: left;
                margin: 0 auto;
                width: 150px;
            }
        }
    }
}

@mixin build-brand-color($color, $var) {
    .#{$color} {
        span {
            background: $var;
        }
        span:before {
            background: lighten($var, 10%);
        }
        span:after {
            background: darken($var, 10%);
        }
    }
}

@include build-brand-color('c-red', $c-red);
@include build-brand-color('c-bright-red', $c-bright-red);
@include build-brand-color('c-light-red', $c-light-red);
@include build-brand-color('c-black', $c-black);
@include build-brand-color('c-charcoal', $c-charcoal);
@include build-brand-color('c-medium-gray', $c-medium-gray);
@include build-brand-color('c-stroke-gray', $c-stroke-gray);
@include build-brand-color('c-light-gray', $c-light-gray);
@include build-brand-color('white', $white);

// Typography
.styleguide-content .sg-typography {
    aside {
        margin-bottom: 30px;
        @include g(m, all);
        @include grid-media(t, min) {
            clear: none;
            width: 45%;
            &.headings {
                float: right;
            }
        }
        @include grid-media(d, min) {
            width: 45%;
            clear: none;
            &.headings {
                float: right;
            }
        }
        dl {
            @include g(m, all);
        }
        dt {
            @include g(m, all);
            text-align: left;
            color: $black-30;
            padding: 0 0 10px;
            margin: 0;
            @include grid-media(t, min) {
                text-align: right;
                width: percentage(1/5);
            }
        }
        dd {
            @include g(m, all);
            padding: 0 0 10px;
            margin: 0;
            @include grid-media(t, min) {
                float: right;
                width: percentage(3.5/5);
                padding-left: 20px;
                clear: none;
            }
            h1, h2, h3, h4, h5, h6 { line-height: normal; }

            p {
                margin: 0;
            }
        }
        &.bq {
            clear: both;
        }
        &.unordered {
            ul {
                clear: both;
                margin-left: 1em;
                list-style: disc;
            }
        }
        &.ordered {
            ol {
                list-style: decimal;
                margin-left: 1em;
            }
        }
    }

    dd {
        max-width: 70%;
    }

    p {
        margin: 0;
    }
}

.sg-link,
.sg-button {
    @include g(m, all);

    a,
    .button {
        clear: both;
        float: left;
    }
    ul {
        list-style: none;
        @include g-all-pad();
        @include grid-media(d, min) {
            @include reset-g-padding();
        }

        li {
            float: left;
            padding: 0 0 30px;
            margin: 0;
            width: 100%;
            @include g-item(max, $bp-large + 1, 5, 980, 19.5);
            @include g-item($bp-large, $bp-small + 1, 2, 600, 13);
            > small {
                @include g(m, all);
                margin-bottom: 10px;
            }

            &:before {
                display: none;
            }
        }
    }
    .code {
        @include g-all();
        margin-top: 10px;
    }
}

.sg-gridcontainer {
    clear: left;
    display: block;

    [class~="grid"],
    [class*="grid-"],
    [class*="grid_"] {
        padding-bottom: 1rem;
    }

    .col .block {
        background: lighten($brand-primary, 20%);
        padding: 15px;
    }
}

.sg-form {
    @include g-all-pad();
    padding-left: 0 !important;

    ul {
        list-style: none !important;
        @include g-all();
        padding: 0;
        li {
            @include g-all();
            .field {
                width: 50%;
                float: left;
                @include g-item(max, $bp-small + 1, 2, 600, 19.5);
                @include g-item($bp-small, 320px, 1, 320, 6.25);
            }
        }
    }

    li.control {
        float: left;
        width: 50%;
        margin-left: 0;
        clear: none;
        padding-left: 1.625%;
        &:nth-child(2n + 2) {
            padding-left: 0;
        }
        @include g-item($bp-small, 320px, 1, 320, 6.5);
        @include bp(max-width, $bp-small) {
            padding-left: 0;
        }
    }
}

.sg-messages {
    small {
        @include g(m, all);
        margin-bottom: 10px;
    }
}

ul li.fields, ul li.wide { margin-left: 0; }
