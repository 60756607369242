.pt_product-compare {
    .page-title {
        margin-top: 25px;
    }
}

.compare-items {
    clear: both;
    overflow: hidden;

    h6 {
        margin: 0.5em 0;
    }

    button {
    }
}

.compare-items-panel {
    @extend %clearfix;
    float: left;
}

.compare-item {
    background-color: $gainsboro;
    float: left;
    height: 55px;
    margin-right: 1.08em;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 55px;

    .compare-item-remove {
        cursor: pointer;
        display: none;
        position: absolute;
        padding: 5px;
        top: -3px;
        z-index: 1;
        line-height: initial;
        right: 1px;
    }

    &.active {
        .compare-item-remove {
            display: block;
        }
    }
    .compare-item-number {
        color: $very-light-gray;
        font-size: 3em;
        margin-top: .5em;
    }
}

.compare-item-image {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.compare-buttons {
    float: left;
    padding: 0.7em 0 0;

    #clear-compared-items {
        margin-left: 15px;
    }
}

.category-switch {
    @extend .clearfix;
    margin-bottom: 2em;
    label {
        float: left;
        margin: 0;
        width: 45%;
        @media (min-width: 410px) {
            width: 11em;
        }
    }
    select {
        float: left;
        margin: 0;
        width: 55%;
        @media (min-width: 410px) {
            margin-left: 1em;
            width: 15em;
        }
    }
}

#compare-table {
    @include clearfix;
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 32px;
    position: relative;
    display: block;
    padding: 0 8px;

    .tile-separator {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $c-light-gray;
        z-index: 2;
        display: none;

        @include breakpoint(medium) {
            display: block;
        }

        &.one {
            left: 25%;
        }

        &.two {
            left: 50%;
            display: block;

            @include breakpoint(medium) {
                display: none;
            }
        }

        &.three {
            left: 75%;
        }

        &.four {
            display: none;
        }
    }


    // on mobile show 2 separators split into 3rds
    @include bp(max-width, $bp-medium) {
        // also hide the 3rd and 4th compare items
        .comp-row > span {

            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }

    // Comparing 1, 2, 3 products
    &.compare-1,
    &.compare-2,
    &.compare-3 {

        .comp-row > span {
            width: 25%;
            padding: 25px;
            box-sizing: border-box;

            @include bp(max-width, $bp-medium) {
                width: 33.3333%;
            }
        }
    }

    // Comparing 4 products
    &.compare-4 {
        .comp-row > span {
            width: 20%;
            padding: 25px;
            box-sizing: border-box;

            @include bp(max-width, $bp-medium) {
                width: 33.3333%;
            }
        }
    }

    // Overriding product tile styling from product page
    .product-tile {
        width: unset;
        min-width: unset;
        text-align: unset;

        .tile-content {
            padding: 0;
        }

        @include bp(max-width, $bp-medium) {
            .product-tile-info {
                padding: 0 8px;
            }
        }
    }

    .tile-content {
        display: flex;
        flex-direction: column;
        background-color: transparent;
    }

    .quickview {
        display: none;
    }

    .comp-row {
        clear: left;
        line-height: 24px;
        padding-bottom: 0;
        display: flex;

        @include bp(max-width, $bp-medium) {
            font-size: 12px;
        }
    }

    .comp-row > span {
        overflow: hidden;
        padding: 0;
        position: relative;
        min-height: 36px;
        white-space: normal;
        box-sizing: border-box;

        .detail-link {
            margin-top: 24px;
            position: static;
            transform: translate(0,0);
            display: inline-block;

            @include bp(max-width, $bp-xsmall) {
                margin-top: 16px;
            }
        }

        .product-tile-info .detail-link {
            display: none;
    }

        &.value {
            text-align: center;
        }

    }


    a {
        &:hover {
            text-decoration: underline;
        }
    }

    .attribute-row {
        @include clearfix;
        background-color: $c-light-gray;
        display: flex;
        flex-direction: row;

        span {
            padding: 8px !important;
        }

        // headings the run down the left side of the compare table
        .product-attribute {
            color: $black;
            font-size: 15px;
            line-height: 24px;
            font-weight: $f-weight-bold;
            text-transform: none;
            padding-left: 25px !important;

            @include bp(max-width, $bp-medium) {
                font-size: 12px;
            }
        }

        &.first {
            padding-top: 10px;
        }
    }

    .tile-row { // top row with product image, price, etc
        display: flex;
        flex-direction: row;

        .detail-link {
            display: none; // hide detail link below description
        }

        > span {
            display: flex;
            flex-direction: column;

            .product-tile {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-bottom: 8px;
            }
        }
    }

    .action {
        display: block;
        padding: 0.5em 0;
    }

    .compare-product-actions {
        padding-top: 24px;

        @include bp(max-width, $bp-xsmall) {
            button {
                background: none;
                padding: 0;
                border: 0;
                text-shadow: none;
                font-weight: $f-weight-bold;

                &:before, &:after {
                    display: none;
                }
            }

            .detail-link {
                font-size: 13px;

                &:after {
                    display: none;
                }
            }
        }

        .where-to-buy {
            display: none;
        }
    }

    .remove-link {
        background: rgba($white, 0.9);
        float: none;
        height: 24px;
        width: 24px;
        position: absolute;
        top: 3px;
        right: 29px;
        z-index: 20;

        i {
            content: '';
            display: block;
            margin: 3px 0 0 3px;

            &:before {
                display: none;
            }
        }
    }

    .product {
        font-weight: normal;
        text-align: center;
    }

    .value {

        .value-item {

            &:after {
                content: ', ';
            }

            &:last-child {

                &:after {
                    content: '';
                }
            }
        }
    }

    .product-tile {
        .product-name {
            height: auto;
            margin: .2em 0 1em 0;

            @include bp(max-width, $bp-small) {
                font-size: 12px;
            }
        }

        .ba-bv-container {
            width: 100%;
            max-width: 82px;
            margin-right: auto;
            margin-left: auto;
        }

        .product-promo,
        .product-model-number {
            display: none
        }

        .product-info {
            display: block;
        }

        .flex-bottom {
            justify-content: center;
        }

        .swatch-list {
            display: inline-block;
        }
    }

    .product-image {
        height: auto;

        img {
            height: auto;
        }
    }

    .section-header {
        font-size: 0.9em;
        padding: 1em;
        text-transform: none;
    }
    .selectcategory {

        label {
            display: block;
            font-size: 0.9em;
            margin: 1em 0;
            text-transform: none;
        }

        select {
            margin: 0;
            width: 100px;
        }
    }

    .comparelists {
        padding: 0;
    }

    .compare-lists {
        label,
        select {
            float: none;
            text-align: left;
            width: 100%;
        }
    }
}

.product-tile .product-compare {

    input.compare-check {
        width: 12px;
        height: 12px;
    }

    label {
        text-transform: none;
        padding-left: 10px;
        font-size: 14px;
        line-height: 18px;
    }
}
