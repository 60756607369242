$footer-minimal-break-mobile: 540px;
$footer-minimal-break-tablet-sm: 834px;
$footer-minimal-break-tablet: 1024px;
$footer-minimal-break-desktop: 1600px;
$footer-minimal-content-max-width: 1168px;
$footer-minimal-margin: 27px;

.footer-minimal {
    background-image: url("../images/footer/footer-mini-mobile.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    background-color: #000;
    color: #fff;
    font-family: $sans-serif;
    font-size: $f-size-xs;
    padding: 120px $footer-minimal-margin 37px;

    @media screen and (min-width: $footer-minimal-break-tablet-sm) {
        background-image: url("../images/footer/footer-mini-desktop.svg");
        background-size: auto;
        padding-top: 165px;
    }

    @media screen and (min-width: $footer-minimal-break-tablet-sm) {
        background-size: 100%;
        padding-top: 10.3125vw;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    * {
        box-sizing: border-box;
    }

    &__container {
        max-width: $footer-minimal-content-max-width;
        text-align: center;

        @media screen and (min-width: $footer-minimal-break-tablet-sm) {
            margin: 0 auto;
            text-align: left;
        }
    }

    &__logo {
        margin-bottom: 33px;
        width: 240px;
    }

    .footer-support {
        ul {
            margin: 0 auto;
        }


        @media screen and (min-width: $footer-minimal-break-tablet-sm) {
            text-align: left;

            ul {
                margin: 0;
                max-width: none;
                justify-content: left;
            }
        }
    }
}

.pt_checkout {
    #main {
        margin-bottom: 10px;

        @media screen and (min-width: $footer-minimal-break-tablet-sm) {
            margin-bottom: 0;
        }
    }
}
