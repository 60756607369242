.grid {
    box-sizing: border-box;
    padding: 0 $l-small-padding;
    margin-bottom: $grid-small-padding;

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
        display: inline-block;
    }

    @include breakpoint(medium) {
        margin-bottom: $grid-medium-padding;
    }

    @include breakpoint(large) {
        margin-bottom: $grid-large-padding;
    }

    @include breakpoint(xlarge) {
        margin-bottom: $grid-xlarge-padding;
    }

  @include variant(container) {
    @include width-with-padding;
  }

  @include variant(no-margin) {
      margin-bottom: 0;
  }

  @include variant(no-padding) {
      padding: 0;
  }

  @include variant(no-gutters) {
    padding: 0;
    overflow: hidden;
  }

  @include variant(no-margin-bottom) {
      margin-bottom: 0 !important;
  }

  @include variant(desktop-no-max) {
    @include breakpoint(medium) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
  }

  &--header {
    @include breakpoint(large) {
        margin-left: auto;
        margin-right: auto;
    }
  }
}