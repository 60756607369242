@mixin grid-col() {
    box-sizing: border-box;
    flex: 0 0 100%;
    max-width: 100%;
    padding: $grid-small-padding #{$grid-small-padding/2} 0 #{$grid-small-padding/2};

    @include breakpoint(medium) {
        padding: $grid-medium-padding #{$grid-medium-padding/2} 0 #{$grid-medium-padding/2};
    }

    @include breakpoint(large) {
        padding: $grid-large-padding #{$grid-large-padding/2} 0 #{$grid-large-padding/2};
    }

    @include breakpoint(xlarge) {
        padding: $grid-xlarge-padding #{$grid-xlarge-padding/2} 0 #{$grid-xlarge-padding/2};
    }

    @include variant(no-gutters) {
        padding: 0;
    }

    @include variant(no-top-padding) {
        padding-top: 0;
    }
}

.col {
    @include grid-col;
}