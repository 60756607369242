.pt_event,
.pt_ampcontent {

    .juicer-feed-wrapper {
        background: $black;

        h2 {
            color: $white;
            padding: 0 20px;
            margin: 45px 0 29px;
        }
    }

    .upcoming-event-body {
        margin-bottom: 40px;

        @include breakpoint(large) {
            padding-top: 20px;
        }

        .upcoming-event-body__header__actstitle {
            padding-top: 30px;
        }

        .upcoming-event-body__header {
            margin-top: 40px;
            text-align: center;
            @include breakpoint(large) {
                text-align: left;
            }

            span {
                display: block;
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .upcoming-event-body__header__title {
                border-bottom: 1px solid $brand-primary;
                font-size: 34px;
                line-height: 1.12;
                padding-bottom: 30px;
                margin-bottom: 30px;

                @include breakpoint(large) {
                    padding-bottom: 36px;
                    margin-bottom: 50px;
                }
            }
        }

        .upcoming-event-body__tour__item {
            @include bp(max-width, $bp-large) {
                border-bottom: 1px solid $c-medium-gray;
                margin-bottom: 24px;

                @include breakpoint(large) {
                    margin-bottom: 60px;
                }
            }

            h3 {
                margin-bottom: 10px;

                @include breakpoint(large) {
                    font-size: 20px;
                }
            }

            h4 {
                font-size: 14px;
                margin-bottom: 4px;

                &.upcoming-event-body__tour__item__fun {
                    color: $c-red;
                }
            }

            p {
                font-size: 14px;
                margin: 0 0 20px;
            }
        }

        .upcoming-event-body__tour {
            .col {
                &:last-child {
                    .upcoming-event-body__tour__item {
                        border: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .excerpt-wrapper {
        margin-bottom: 23px;

        + .setlist-wrapper {
            margin-top: 0;
        }
    }

    .related-products {
        margin: 15px 0 0;
        text-align: center;

        h3 {
            color: $c-gray;
            margin-bottom: 14px;

            @include breakpoint(medium) {
                text-align: left;
            }
        }

        .product-tile {
            text-align: left;
            display: inline-block;
            width: 48%;
            vertical-align: top;
            margin: 0 0 32px 8px;

            &:first-of-type {
                margin-left: 0;
            }

            @include breakpoint(medium) {
                display: block;
                width: 100%;
                margin-left: 0;
            }
        }

        .button {
            display: none;
        }

        @include breakpoint(large) {
            margin: 15px 0 0 35px;

            .button {
                display: inline-block;
            }
        }
    }

    .event-past-top-content {
        margin-bottom: 45px;

        @include breakpoint(medium) {
            margin-bottom: 55px;
        }
    }

    .title {
        .col {
            padding-top: 0;
        }

        .page-title {
            line-height: 22px;
            letter-spacing: 3px;
            opacity: .15;
	    display: inline-block;
            @include breakpoint(medium) {
                line-height: 32px;
            }

            @include breakpoint(large) {
                line-height: 32px;
            }
        }
    }

    .show-notes {

        p {
            padding-top: 12px;
            border-top: 1px solid $c-light-gray;
            margin: 12px 0 0;

            &:nth-child(1) {
                border-top: none;
                padding: 0;
                margin: 0;

                strong {
                    margin-bottom: 8px;
                    display: inline-block;
                }
            }

            &:last-child {
                border-bottom: 1px solid $c-light-gray;
                padding-bottom: 12px;
            }
        }

        @include breakpoint(medium) {
            p {
                &:nth-child(-n + 2) {
                    border-top: none;
                    padding: 0;
                    margin: 0;
                }

                &:nth-child(1) {
                    strong {
                        margin-bottom: 24px;
                    }
                }

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    .event-videos {

        .button {
            margin: 20px 0 50px;

            @include breakpoint(large) {
                margin: 0 0 50px;
            }
        }

        .event-videos-title {
            background: $white;

            h2 {
                margin: 17px 0 14px 0;

                @include breakpoint(large) {
                    margin: 17px 0 29px 0;
                }
            }
        }

        .secondary-video-wrapper {
            margin-top: 12px;

            @include breakpoint(medium) {
                margin-top: $grid-medium-padding;
            }

            @include breakpoint(large) {
                margin-top: $grid-large-padding;
            }

            @include breakpoint(xlarge) {
                margin-top: $grid-xlarge-padding;
            }
        }
    }

    .event-video {
        position: relative;
        cursor: pointer;

        .show-name {
            @include position(absolute, null null -6px 50%);
            transform: translate(-50%, 0);
            color: $white;
            font-size: 14px;
            line-height: 24px;
            font-weight: $f-weight-bold;
            width: 100%;
            text-align: center;

            p {
                padding: 0 20px;

                @include breakpoint(large) {
                    padding-left: 52px;
                    padding-bottom: 26px;
                    max-width: 100%;
                }
            }
        }

        &.large {

            &:before {
                @include svg-view-btn_youtube_mobile;
                @include position(absolute, 50% null null 50%);
                transform: translate(-50%, -50%);
                cursor: pointer;

                @include breakpoint(large) {
                    @include svg-view-btn_youtube;
                }
            }

            .video-container {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                overflow: hidden;

                .video-image:before {
                    @include svg-view-btn_youtube_mobile;
                    @include position(absolute, 50% null null 50%);
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }

                & > a {

                    display: block;

                    @include breakpoint(medium) {
                        display: none;
                    }
                }
            }
        }

        &.small {

            .video-image {

                &:before {
                    @include svg-view-btn_youtube_mobile;
                    @include position(absolute, 50% null null 50%);
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }

            .show-name p {

                @include breakpoint(large) {
                    padding: 0 20px;
                    text-align: center;
                    max-width: none;
                }
            }
        }
    }

    .videos-button {
        margin: 32px auto 0 auto;

        @include breakpoint(large) {
            margin: 40px auto 0 auto;
        }
    }

    .thumb-link {
        display: block;
    }

    .event-header {
        margin-top: 0;

        .event-header-eventName {
            color: $white;
            font-size: 14px;
            text-align: center;
        }

        .c-banner-buttons-wrap {
            display: flex;
                gap: 8px;
                justify-content: center;

            @include bp(max-width, $bp-medium) {
                padding-top: 34px;
            }

            .btn-disabled {
                background: $black;
                border-color: $white;
                color: $white;
            }
        }

        .mobile-tablet {
            display: inline-block;

            @include bp(min-width, $bp-xlarge) {
                display: none;
            }
        }

        .large-desktop {
            display: none;

            @include bp(min-width, $bp-xlarge) {
                display: inline-block;
            }
        }

        h1 {
            margin-top: 18px;
            @include h4;

            a {
                color: $black;

                &:hover {
                    color: $c-red;
                }
            }

            @include breakpoint(large) {
                @include h2;
                margin-top: 24px;
            }
        }

        h2 a {
            color: $black;

            &:hover {
                color: $c-red;
            }
        }

        h4 {
            line-height: 25px;
            font-family: $f-body;
            font-size: 14px;
            font-weight: $f-weight-regular;
            margin-bottom: 2px;

            a {
                color: $black;

                &:hover {
                    color: $c-red;
                }
            }

            @include breakpoint(large) {
                @include h4;
                margin-bottom: 23px;
            }
        }

        .ctas {
            margin: -7px -7.5px 0;

            a {
                margin-top: 7px;
                margin-left: 7.5px;
                margin-right: 7.5px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .connected-events {

	    .hidden-mobile{
	    	display:none
	    }

        @include breakpoint(medium) {
            line-height: 32px;
        }

        @include breakpoint(large) {
            line-height: 32px;
            .hidden-mobile{
            	display:inline-block;
            }
        }

    }

    .grid-top {
        position: relative;
        z-index: 2;
    }

    .grid-bottom {
       position: relative;
        z-index: 1;
    }

    .primary-content {
        position: relative;
    }

    .top-banner {
        z-index: 2;
    }

    .content-hero {
        &.content-tour-header {
            .connected-events {
                .page-prev {
                    @include bpmm($bp-medium + 1, $bp-xlarge) {
                        left: calc(((100vw - 100%) / -2) + 50px);
                    }
                }

                .page-next {
                    @include bpmm($bp-medium + 1, $bp-xlarge) {
                        right: calc(((100vw - 100%) / -2) + 50px);
                    }
                }
            }

            .event-header {
                .page-title {
                    .h2 {
                        @include breakpoint(medium) {
                            max-width: 60%;
                        }

                        @include bpmm($bp-large, $bp-xlarge) {
                            max-width: 70%;
                        }
                    }
                }
            }
        }

        .accent {
            display: none;
            position: absolute;
                left: calc(50% - 10px);
                bottom: -150px;
                z-index: 0;
            max-width: 100vw;
            overflow: hidden;
            transform: translateX(-50%);

            @include breakpoint(large) {
                display: block;
            }
        }
        .event-header {
            .event-header__date {
                @include h5();

                text-transform: uppercase;
                color: $white;
                padding-bottom: 10px;
                text-align: center;
                @include breakpoint(large) {
                    padding-bottom: 20px;
                }
                a {
                    color: $white;
                    font-family: $f-subheader;
                    font-size: 14px;
                    line-height: 1.29;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include breakpoint(large) {
                        font-size: 21px;
                    }
                }
            }

            h2 {
                @include h1();
                max-width: 60%;
                margin: 0 auto;
                color: $white;
                padding-bottom: 10px;

                @include breakpoint(xlarge) {
                    max-width: 70%;
                }

                @include breakpoint(large) {
                    padding-bottom: 20px;
                }

                @include bp(max-width,$bp-large) {
                    max-width: 45%;
                }

                @include bp(max-width,$bp-medium) {
                    max-width: 100%;
                }
                a {
                    color: $white;
                    font-size: 40px;
                    margin-bottom: 10px;

                    @include breakpoint(large) {
                        font-size: 60px;
                        margin-bottom: 40px;
                    }
                }
            }

            h4 {
                color: $white;
                text-align: center;
                a {
                    color: $white;
                    font-family: $f-subheader;
                    font-size: 14px;
                    line-height: 1.29;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }

            .ctas {
                a {
                    margin: 0 0 10px;
                    min-width: 162px;
                    padding: 12px 20px;
                }
            }
        }
    }
}

.pt_event {
    p {
        font-size: $f-size-xs;

        @include breakpoint(large) {
            font-size: $f-size;
        }
    }
}

.event-past-top-content {
    .setlist-wrapper {
        margin-top: 30px;

        h4 {
            position: relative;
            @include font-source($f-body, $f-size-xs, $black, 24px, $f-weight-bold);

            &:after {
                @include svg-view-icon-expand;
                @include position(absolute, 50% 0 null null);
                transform: translateY(-50%);
            }

            &.expanded:after {
                @include svg-view-icon-collapse;
            }

            @include breakpoint(large) {
                font-size: $f-size;
            }
        }

        h5 {
            @include font-source($f-body, $f-size, $black, 24px, $f-weight-bold);
            color: $c-gray;
            margin-left: 46px;
        }
    }
}

.event-header {
    margin-top: 0;

    .event-header-eventName {
        color: $white;
        font-size: 14px;
        font-weight: $f-weight-semibold;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;

        @include bp(max-width,$bp-medium) {
            padding: 0 20px;
        }
    }
}

.event-photos {
    h2 {
        line-height: 29px;
        margin-bottom: 10px;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }

    .button {
        margin: 20px 0 14px;

        @include breakpoint(medium) {
            margin: 0;
        }
    }
}

.pt_tour {
    .pagination {
        margin-bottom: 0;
        margin-top: 0;

        @include breakpoint(medium) {
            align-self: center;
            margin-right: 0 !important; // Override importanted style
        }

        ul {
            @include bp(max-width, $bp-medium) {
                justify-content: flex-start;
            }
        }
    }

    .shows-list {
        &.shows-list--past {
            .show {
                padding: 20px 0 26px;
                border-bottom: 1px solid $c-stroke-gray;
                display: block;

                @include breakpoint(large) {
                    padding: 20px 0 30px;
                }

                &:last-child {
                    border-color: $brand-primary;
                }

                & > .past-show-item {
                    @include breakpoint(large) {
                        display: flex;
                            align-items: flex-end;
                            gap: 30px;
                            justify-content: space-between;
                    }

                    .ctas {
                        display: flex;
                        flex-basis: MAX(20%, 250px); // All uppercase so SCSS doesn't try and compile
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        &.shows-list--upcoming {
            .show-tourlist-banner,
            .show-tourlist-banner .c-banner-ad,
            .show-tourlist-banner .c-banner-ad__link {
                min-height: auto;
            }

            .show--upcoming {
                .show-date-venue {
                    .venue {
                        .venue-name {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .page-title {
        font-size: 32px;
        line-height: 1.13;
        letter-spacing: 0.5px;
        margin-bottom: 33px;

        @include breakpoint(large) {
            font-size: 32px;
            margin-bottom: 13px;
        }

        &.page-title--upcoming {
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    .top-bar {
        @include breakpoint(medium) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .year-refinements-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        label {
            margin: 0 16px 0 0;
        }

        .select-wrapper {
            width: 100%;
            max-width: 200px;
            select {
                background-color: transparent;
                z-index: 10;

            }
        }

        @include breakpoint(medium) {
            justify-content: flex-start;
            width: 100%;
        }
    }


    .ghost-year {
        font-family: $f-header;
        opacity: 0.05;
        font-size: 72px;
        color: $black;
        line-height: 80px;
        font-weight: $f-weight-light;
        cursor: default;
        @include position(absolute, 5px null null -18px);

        @include breakpoint(medium) {
            left: -25px;
        }

        @include breakpoint(large) {
            left: -118px;
        }

        @include breakpoint(xlarge) {
            left: -145px;
        }
    }

    .shows-list {
        margin-bottom: 28px;

        @include breakpoint(large) {
            margin-bottom: 32px;
        }

        .show {
            position: relative;

            &:first-child .ghost-year {
                top: -5px;
            }
        }

        .ctas .second-row-ctas {
            .enhanced + .tickets {
                margin-top: 0;
            }

            @include breakpoint(xlarge) {
                margin-top: 0;
            }
        }

        .date-numbers {
            a {
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                letter-spacing: 1px;
                margin-bottom: 6px;
                text-transform: uppercase;
            }
        }

        .show-venue-name {
            @include bp(max-width, $bp-large) {
                padding-top: 6px;
            }
        }

        .venue-name {
            font-size: 14px;
            line-height: 1.5;
            @include bp(max-width, $bp-large) {
                margin-bottom: 4px;
            }
            @include breakpoint(large) {
                display: block;
                font-size: 16px;
                padding-top: 25px;
            }
        }

        .ctas {
            @include breakpoint(large) {
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                flex-basis: initial;
                justify-content: flex-end;

                a {
                    position: static;
                    white-space: nowrap;
                }

                a + .second-row-ctas {
                    margin-top: 0;
                }

                > a {
                    margin-right: 10px;
                }
            }

            a {
                box-sizing: border-box;
                justify-content: center;
                margin: 0;
                min-width: 188px;
                padding: 12px 20px 11px;

                &:hover {
                    text-decoration: none;
                }
            }

        }
    }
    .tour-filter {
        display: none;
        position: absolute;
        top: 0;
        background-color: $white;
        z-index: 9;
        right: 0;
        min-width: 600px;
        padding: 50px;
        min-height: 600px;
        &.active {
            display: block;
        }
    }
    .past-tours-filter-wrap {
        .filter-button {
            padding: 10px 14px;
        }
    }
}

.tour-event{
	.fancybox-overlay {
        background-color: rgba(0, 0, 0, 0.9);
    }

	.image-wrapper{
		.preview-image-artist{
			font-size: 12px;
			text-align: center;

			@include breakpoint(medium) {
	            font-size: 16px;

	        }
	    }
	}

	.fancybox-title {
		width: 100%;
		right: 0;

		.child{
			width: 100%;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
			border-radius: 0;
            background: transparent;
		}

		.image-credits{
			display: flex;                  /* establish flex container */
			flex-direction: row;            /* default value; can be omitted */
			flex-wrap: nowrap;              /* default value; can be omitted */
			justify-content: space-between; /* switched from default (flex-start, see below) */

			span{
				padding: 0 15px;
				max-width: 50%;
				white-space: initial;
                .camera {
                    vertical-align: text-top;
                    margin-right: 5px;
                }
            }
		}
	}
}

.past-tours {
    &-top-bar {
        align-items: flex-end;
        border-bottom: 1px solid $c-black;
        padding-bottom: 15px;
        position: relative;
            z-index: 1;

        @include breakpoint(medium) {
            padding-bottom: 12px;
        }
    }

    &-heading {
        @include h2();

        flex-shrink: 0;

        @include breakpoint(medium) {
            @include h3();
        }
    }

    &-filter-pagination {
        display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        margin-top: 12px;

        @include bp(max-width, $bp-large) {
            justify-content: space-between;
        }

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            margin-bottom: 7px;
            margin-top: 0;
        }

        .filter-button {
            button {
                cursor: pointer;
                height: 36px;
                margin-right: 2px;
                padding: 10px 20px;
            }
        }
    }

    &-filter {
        height: 36px;
        margin-bottom: 4px;
        padding-right: 40px;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }
}

.show {
    &--upcoming {
        border: 0;
        min-height: 400px;
        position: relative;
        padding: 4px;
        order: 1;

        @include bp(min-width, $bp-large + 1) {
            min-height: 520px;
        }

        @include bp(min-width, $bp-xlarge) {
            min-height: 500px;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &.show-tourlist-banner {
            // This ordering allows us to ensure the banner comes after the second row while allowing for image tiles to fill spaces before it.
            // While not ideal, there doesn't appear to be a great alternative
            order: 0;
        }

        .mobile-only {
            display: inline-block;

            @include breakpoint(large) {
                display: none;
            }
        }

        .desktop-only {
            display: none;

            @include breakpoint(large) {
                display: inline-block;
            }
        }

        .show-plate {
            position: absolute;
                left: 0;
                top: 0;
            height: 100%;
            width: 100%;

            .c-banner-logo {
                text-align: center;

                a {
                    display: block;
                    margin-top: 20px;
                }
                .logo-element {
                    color: $white;
                }

                .svg-logo-dims {
                    width: 320px;
                    height: 120px;
                }
                .c-banner-buttons-wrap {
                    display: flex;
                        justify-content: center;
                    position: relative;
                        top: -65px;
                    @include breakpoint(large) {
                        top: -85px;
                    }
                }
            }

            .c-banner-text {
                color: $white;
                text-align: center;
                @include breakpoint(large) {
                    padding-left: 0;
                    text-align: left;
                }

                h4 {
                    color: $white;
                    font-size: 21px;
                    letter-spacing: 0.5px;
                    @include breakpoint(large) {
                        margin-top: 40px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                    @include bp(max-width, $bp-large) {
                        padding: 0 20px;
                    }
                    @include breakpoint(large) {
                        max-width: 275px;
                    }
                }
            }

            .show-plate-container {
                display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                height: 100%;
                padding: 30px 4px 0;
                @include breakpoint(medium) {
                    padding-top: 43px;
                }
            }

            .ctas,
            .show-date-venue {
                display: block;
                padding: 0 20px;
            }

            .ctas {
                padding-bottom: 27px;

                @include breakpoint(medium) {
                    padding-bottom: 50px;
                }

                .second-row-ctas {
                    justify-content: flex-start;
                    
                    .primary-link {
                        color: $white;
                        display: inline-block;
                        font-size: 11px;
                        line-height: 1.5;
                        letter-spacing: 2.5px;
                        padding: 0 0 4px;
                        margin: 0 0 20px;
                        min-width: initial;
                        text-transform: uppercase;
                        white-space: normal;

                        @include breakpoint(medium) {
                            font-size: 14px;
                            letter-spacing: 3px;
                            margin-bottom: 14px;
                        }

                        &:hover {
                            border-color: $white;
                        }
                    }

                    button.primary-link {
                        background: none;
                        color: $c-medium-gray;
                        cursor: default;
                        text-decoration: line-through;
                        opacity: 1;

                        &:hover {
                            border-color: transparent;
                        }

                        &.button--soldout {
                            text-decoration: none;
                        }
                    }

                    div {
                        width: 100%;
                    }
                }
            }

            .show-date-venue {
                color: $white;
                min-height: 200px;
                text-transform: uppercase;

                @include bp(min-width, $bp-large + 1) {
                    min-height: auto;
                }

                @include bp(min-width, $bp-xlarge) {
                    min-height: 200px;
                }

                .date-numbers {
                    a {
                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    a,
                    p {
                        color: $white;
                        font-family: $f-subheader;
                        font-size: 21px;
                        line-height: 1.29;
                        letter-spacing: 1px;
                        margin-bottom: 0;
                        text-decoration: none;

                        @include breakpoint(medium) {
                            margin-bottom: 1px;
                        }
                    }
                }

                .venue-city {
                    color: $white;
                    display: block;
                    font-size: 32px;
                    line-height: 1.13;
                    letter-spacing: 0.5px;
                    margin-bottom: 10px;

                    @include breakpoint(large) {
                        font-size: 34px;
                    }

                    span {
                        text-decoration: inherit;
                    }
                }

                .venue-name {
                    color: inherit;
                    font-family: $f-subheader;
                    font-size: 14px;
                    line-height: 1.29;
                    letter-spacing: 1px;
                    margin: 0;
                    padding: 0;
                    text-transform: uppercase;
                }
            }

            .date-numbers {
                margin: 0;
            }
        }

        &.show-explore {
            .show-plate {
                .show-plate-container {
                    padding: 60px;
                    display: flex;
                        align-items: center;
                        justify-content: space-around;

                    h3 {
                        margin-bottom: 20px;
                    }

                    .button {
                        background: none;

                        &:hover {
                            background: $brand-primary;
                        }
                    }
                }

                .show-date-explore {
                    text-align: center;
                }
            }
        }

        .show-plate-img {
            background-color: $c-black;
            height: 100%;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.past-tour-filter {
    @extend .clearfix;
    background: $nav-mobile-bg-color;
    color: $brand-primary;
    display: flex;
        flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: fixed;
        bottom: 0;
        right: 0;
        top: 0;
    padding-top: 40px;
    transition: transform $default-transition-duration ease-in-out;
    transform: translateX(100%);
    width: 100%;
    z-index: 105;
    @include breakpoint(medium) {
        max-width: 375px;
    }
    @include breakpoint(large) {
        max-width: 600px;
        padding-top: 50px;
    }

    .past-tour-filter-close {
        @include position(absolute, 12px 19px null null);
        color: $c-gray;
        display: flex;
        font-weight: $f-weight-bold;

        &:hover {
            border: 0;
            text-decoration: none;
        }

        span {
            margin-left: 9px;
        }

        i {
            line-height: 22px;
        }

        @include svg-view-close-gray-element {
            fill: $c-gray;
        }

        @include breakpoint(large) {
            right: 40px;
        }

        .svg-close-dims {
            height: 18px;
            width: 18px;
        }
    }

    .past-filter-active & {
        transform: translateX(0);
    }

    .past-tour-data {
        padding: 0 27px;

        @include breakpoint(large) {
            padding: 0 50px;
        }

        .scrolled-container {
            max-height: calc(100vh - 175px);
            overflow-y: scroll;
            overflow-x: hidden;

            @include breakpoint(large) {
                max-height: calc(100vh - 200px);
            }
        }

        .specific-year:not(.active),
        .specific-years-title:not(.active) {
            display: none;
        }

        .past-tour-filter-box {
            margin-bottom: 30px;

            .input-select {
                border-color: $c-black;
                font-size: 15px;
                font-weight: $f-weight-bold;
                line-height: 1;
                letter-spacing: 3px;
                text-transform: uppercase;

                &.song-img {
                    background-position: 5px 5px;
                    background-size: 60px 60px;
                    background-repeat: no-repeat;
                    height: 72px;
                    padding-left: 80px;
                }
            }

            .full-width {
                justify-content: space-between;
                .col {
                    width: calc(33.33% - 3px);
                    padding: 3px 0;
                }

                .field-wrapper {
                    display: flex;
                    position: relative;
                    width: 100%;
                }

                .form-row {
                    margin-bottom: 0;

                    input {
                        cursor: pointer;
                        height: 34px;
                        width: 100%;

                        &::before {
                            border-width: 1px;
                            border-radius: 0;
                            height: 34px;
                            width: 100%;
                        }

                        &:checked {
                            & + label {
                                color: $white;
                            }

                            &:hover {
                                &::after,
                                &::before {
                                    border-radius: 0;
                                    background-image: none;
                                    background-color: $c-black;
                                }
                            }
                        }

                        &:checked:after {
                            border-radius: 0;
                            height: 100%;
                            width: 100%;
                        }

                    }
                    label {
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: $f-weight-bold;
                        line-height: 1.08;
                        letter-spacing: 2.5px;
                        position: absolute;
                            left: 0;
                            top: 50%;
                            z-index: 11;
                        transform: translateY(-50%);
                        margin: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .specific-year {
                .col {
                    padding: 0 3px;
                }
            }

            .location-box {
                justify-content: space-between;

                .col {
                    padding: 3px 0;

                    @include breakpoint(large) {
                        width: 49.5%;
                    }
                }
            }

            .album-box {
                .col {
                    padding: 3px 0;
                }
            }
        }

        .past-tour-filter-by {
            font-size: 14px;
            font-weight: $f-weight-semibold;
            line-height: 1.29;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 12px;

            @include breakpoint(large) {
                line-height: 1.43;
                margin-bottom: 20px;
            }
        }

        .past-tour-filter-box-header {
            display: flex;
                justify-content: space-between;
                align-items: baseline;
            margin-bottom: 12px;

            .past-tour-filter-box-header-title {
                display: flex;
                align-items: center;

                .filter-title {
                    line-height: 1.14;
                    margin-right: 4px;

                    @include bp(max-width, $bp-large) {
                        font-size: 20px;
                        line-height: 1.1;
                        letter-spacing: 0.5px;
                    }
                }

                .filter-count {
                    border: 2px solid $brand-primary;
                    border-radius: 50%;
                    display: none;
                    font-size: 16px;
                    font-weight: $f-weight-bold;
                    line-height: 20px;
                    padding: 0px 6px;

                    &.active {
                        display: inline-block;
                    }
                }
            }

            .remove-filter-link {
                display: none;
                font-size: 14px;
                border: 0;
                color: $brand-primary;
                font-weight: $f-weight-medium;
                line-height: 1.43;
                letter-spacing: normal;
                text-transform: capitalize;
                text-decoration: underline;

                &.active {
                    display: inline-block;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .past-tour-filter-box-body {
            .past-tour-filter-box-body-title {
                margin-bottom: 16px;
                margin-top: 22px;

                @include bp(max-width, $bp-large) {
                    font-size: 16px;
                }
            }
        }

        .past-tour-filter-box-footer {
            .filter-submit {
                width: 100%;
            }

            .past-tour-filter-box-footer-remove-all {
                display: block;
                margin-top: 18px;
                text-align: center;

                a {
                    text-transform: uppercase;
                }
            }
        }
    }
}
