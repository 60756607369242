.c-icon-grid {
    max-width: clamp(768px, calc(100% - 80px),1092px);
    text-align: center;

    &__heading {
        @include h2;

        @include breakpoint(medium) {
            @include h3;
        }
    }

    &__desc {
        font-size: 14px;
        line-height: 1.43;
        margin: 10px auto 30px;
        max-width: 580px;

        @include breakpoint(medium) {
            margin-bottom: 40px;
        }
    }

    &__grid {
        list-style: none;
        text-align: left;

        @include breakpoint(medium) {
            text-align: inherit;
        }

        @include breakpoint(large) {
            margin-left: -30px;
            margin-right: -30px;
        }
    }

    &__tile {
        display: flex;
            align-items: flex-start;
            gap: 20px;
        margin-bottom: 10px;

        @include breakpoint(medium) {
            display: block;
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            padding-left: 30px;
            padding-right: 30px;
        }

        &__img {
            max-height: 30px;
            max-width: 30px;

            @include breakpoint(medium) {
                margin-bottom: 20px;
                max-height: none;
                max-width: 100%;
            }
            &.crop-mobile {
                display: block;
                @include breakpoint(medium) {
                    display: none;
                }
            }

            &.no-crop {
                display: none;
                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        &__title {
            font-family: $f-subheader;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 1.29;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-family: $f-body;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 1.33;
            margin-bottom: 0;
            margin-top: 8px;

            @include breakpoint(medium) {
                margin-top: 6px;
            }
        }
    }
}