// =============================================
// Paypal Components
// =============================================
.pt_cart {
    .cart-actions {
        .action-or {
            margin: -6px 14px 9px 14px;
            display: block;
            font-size: 14px;
            font-weight: $f-weight-bold;

            @include breakpoint(medium) {
                display: inline-block;
            }
        }

        .paypal-button {
            vertical-align: middle;
            clear: both;

            @include breakpoint(medium) {
                clear: none;
                display: inline-block;
            }

            &.mobile {
                display: inline-block;

                @include breakpoint(medium) {
                    display: none;
                }
            }
        }

        .paypal-button-container-cart2, .paypal-button-container-cart1 {
            position: relative;
            display: inline-block;

            @include breakpoint(medium) {
                display: inline-block;
                vertical-align: text-top;
            }
        }

        .paypal-applepay {

            @include breakpoint(medium) {
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
}
