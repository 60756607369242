.c-amp-banner-carousel {
    clip-path: polygon(0 0, 0 calc(100% - 25px), 100% 100%, 100% 0);

    @include breakpoint(medium) {
        clip-path: polygon(0 0, 0 calc(100% - 46px), 100% 100%, 100% 0);
    }

    @include breakpoint(large) {
        clip-path: polygon(0 0, 0 calc(100% - 90px), 100% 100%, 100% 0);
    }

    .grid--container & {
        margin-left: calc((100% - 100vw) / 2);
        margin-left: calc((100% - 100dvw) / 2);
        width: 100vw;
        width: 100dvw;
    }
    

    &__slider {
        background-color: $c-black;
    }
}

.s-amp-banner-carousel {
    .c-banner {
        clip-path: none;
        margin-left: 0;

        &-content {
            bottom: 45px;
            left: initial;
            right: 25px;
            text-align: right;
            top: initial;
            transform: none;

            @include bp(max-width, $bp-medium) {
                width: calc(100% - 50px);
            }

            @include breakpoint(medium) {
                bottom: 50px;
                right: 30px;
            }

            p {
                margin-top: 5px;

                @include breakpoint(medium) {
                    margin-top: 15px;
                }
            }
        }

        &-heading {
            text-align: inherit;

            @include bp(max-width, $bp-medium) {
                font-size: 32px;
            }
        }

        &-buttons-wrap {
            display: flex;
            justify-content: flex-end;

            @include breakpoint(medium) {
                padding-top: 0;
            }
        }
    }

    .banner-image {
        clip-path: polygon(0 0, 0 calc(100% - 155px), 65% calc(100% - 190px), 17% calc(100% - 210px), 100% calc(100% - 250px), 100% 0);
        min-height: 450px;

        @include breakpoint(medium) {
            clip-path: polygon(0 0, 0 calc(100% - 180px), 52% calc(100% - 228px), 25% calc(100% - 240px), 100% calc(100% - 325px), 100% 0);
        }
        
        @include breakpoint(large) {
            clip-path: polygon(0 0, 0 calc(100% - 90px), 55% calc(100% - 228px), 40% calc(100% - 240px),100% calc(100% - 325px), 100% 0);
        }

        @include breakpoint(xlarge) {
            clip-path: polygon(0 0, 0 calc(100% - 90px), 75% calc(100% - 228px), 61% calc(100% - 240px),100% calc(100% - 325px), 100% 0);
        }
    }

    .slick-list {
        padding-left: 0 !important; // Override JS inline styles
        padding-right: 0 !important; // Override JS inline styles
    }

    .slick-slide {
        opacity: 0;
        transition: opacity 150ms;

        &.slick-current {
            opacity: 1;
            transition: opacity 600ms;
        }
    }

    .slick-dots {
        bottom: 80px;
        left: 25px;
        width: max-content;

        @include breakpoint(medium) {
            left: 55px;
            bottom: 102px;
        }

        @include breakpoint(large) {
            left: 50%;
            transform: translateX(-50%);
        }

        li {
            box-sizing: border-box;
            height: 22px;
            padding: 10px 3px;
            width: 23px;

            @include bp(max-width, $bp-medium) {
                margin-left: 0;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                height: 19px;
                margin-left: 4px;
                margin-right: 4px;
                padding: 8px 0;
                width: 40px;
            }

            button {
                background-color: $c-medium-gray;
                height: 100%;
                padding-bottom: 0;
                padding-top: 0;
                width: 100%;

                &::before {
                    content: none;
                }
            }

            &:hover,
            &.slick-active {
                button {
                    background-color: $c-bright-red;
                }
            }
        }
    }
}