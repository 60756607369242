.c-whats-included {
    &__header {
        border-bottom: 1px solid $c-stroke-gray;

        @include bp(max-width, $bp-medium) {
            margin-left: -$l-small-padding;
            margin-right: -$l-small-padding;
            padding-bottom: 19px;
            padding-left: $l-small-padding;
            padding-right: $l-small-padding;
        }
        
        @include breakpoint(medium) {
            border-bottom: 2px solid $c-black;
            display: flex;
                justify-content: space-between;
        }
    }

    &__heading {
        @include bp(max-width, $bp-medium) {
            margin-bottom: 10px;
        }
    }

    &__tab-btns {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__tab-selector {
        @include breakpoint(medium) {
            display: none;
        }
    }

    &__accordion-heading {
        display: flex !important; // Override overly specific style
            align-items: center;

        @include breakpoint(medium) {
            align-items: flex-end;
        }

        &__value {
            flex-shrink: 0;
        }

        &__subtitle {
            font-family: $f-subheader;
            letter-spacing: 1px;
            margin-left: 20px;
    
            @include breakpoint(medium) {
                margin-left: 45px;
            }
        }
    }

    & &__accordion-content {
        overflow: visible;
        padding-bottom: 6px;
        
        @include breakpoint(medium) {
            padding-bottom: 25px;
            padding-top: 22px;
        }
    }

    &__accordion-content {
        display: flex;
            align-items: flex-start;
            justify-content: space-between;

        &__img {
            height: auto;
            margin-top: -8px;
            max-width: clamp(120px, 32%, 286px);
            object-fit: cover;

            @include breakpoint(medium) {
                margin-top: -70px;
            }
        }
    }
}

.s-whats-included {
    &__btn {
        .ui-tabs .ui-tabs-nav & {
            border-bottom: none;
            margin-bottom: 8px;
            margin-left: 5px;
            margin-right: 5px;

            &:hover {
                background-color: transparent;
            }

            &:not(.ui-state-active) a,
            &.ui-state-active a {
                letter-spacing: 2.8px;
            }

            &:not(.ui-state-active) a {
                @include primary-button;
            }
    
            &.ui-state-active a {
                @include secondary-button;
            }
        }
    }

    &__content {
        ul,
        ol {
            color: $c-charcoal;
            font-size: 14px;
            margin-bottom: 23px;

            @include breakpoint(medium) {
                margin-bottom: 35px;
                margin-top: 15px;
            }

            &:last-child {
                margin-bottom: 16px;
            }

            li {
                margin-bottom: 6px;
                padding-left: 1px;
            }
        }
    }

    .ui-tabs-panel {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        h3 {
            display: block;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 1.1;
            padding-bottom: 24px;
            padding-left: 0;
            padding-right: 20px;

            @include breakpoint(medium) {
                font-size: 28px;
                letter-spacing: normal;
                line-height: 1.14;
                padding-bottom: 8px;
            }

            &::after {
                right: 0;
                top: calc(50% - 6px);
                transform: translateY(-50%);

                @include breakpoint(medium) {
                    right: 20px;
                    top: 17px;
                }
            }

            &.ui-state-active {
                @include bp(max-width, $bp-medium) {
                    padding-bottom: 21px;
                }

                &::after {
                    top: calc(50% - 14px);

                    @include breakpoint(medium) {
                        top: 31px;
                    }
                }
            }
        }

        h4 {
            font-family: $sans-serif;
            font-size: 14px;
            font-weight: $f-weight-semibold;
            letter-spacing: 1px;
        }

        .accordion-item {
            border-bottom: 1px solid $c-stroke-gray;
            margin-bottom: 2px;
            padding-top: 11px;

            @include breakpoint(medium) {
                margin-bottom: 13px;
                padding-top: 13px;
            }
        }

        .ui-accordion-content {
            @include breakpoint(large) {
                padding-left: 99px;
                padding-right: 99px;
            }
        }
    }
}