.c-article-card {
    padding-left: 0;

    &:first-child {
        .c-article-card__row {
            margin-top: 0;
        }
    }

    &__row {
        border: 1px solid $c-stroke-gray;
        margin-left: 0;
        margin-right: 0;
        position: relative;

        &,
        &:last-child {
            margin-bottom: $grid-small-padding + 20px;

            @include breakpoint(medium) {
                margin-bottom: $grid-medium-padding + 20px;
            }

            @include breakpoint(large) {
                margin-bottom: $grid-large-padding + 20px;
            }

            @include breakpoint(xlarge) {
                margin-bottom: $grid-xlarge-padding + 20px;
            }
        }
    }

    &__img {
        min-height: 100%;
        object-fit: cover;

        &__wrap {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
        }
    }

    &__details {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-top: 0;

        @include bp(max-width, $bp-medium) {
            padding-left: 21px;
        }

        &__inner {
            padding-bottom: 20px;
            padding-right: 21px;
            padding-top: 14px;

            @include breakpoint(medium) {
                padding-bottom: 25px;
                padding-right: 72px;
                padding-top: 25px;
            }
        }
    }

    &__date {
        color: $c-charcoal;
        font-weight: $f-weight-bold;
        letter-spacing: 1px;
        line-height: 1.43;
        margin-bottom: 3px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            margin-bottom: 6px;
        }
    }

    &__title {
        @include h3;

        @include breakpoint(medium) {
            @include h4;
        }
    }

    &__desc {
        line-height: 1.5;
        margin-bottom: 17px;
        margin-top: 10px;

        @include breakpoint(medium) {
            line-height: 1.43;
            margin-bottom: 20px;
        }
    }

    &__cat {
        background-color: $c-black;
        color: $white;
        display: flex;
            align-items: center;
            justify-content: center;
        width: 26px;

        &__value {
            display: inline-block;
            font-size: 10px;
            font-weight: $f-weight-bold;
            letter-spacing: 1px;
            white-space: nowrap;
            text-transform: uppercase;
            transform: rotate(90deg);
        }
    }

    &__link {
        position: static;

        @include bp(max-width, $bp-medium) {
            font-size: 13px;
            letter-spacing: 2.5px;
            line-height: 1.08;
            padding: 9px 10px;
        }

        &:before {
            content: '';
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
        }
    }
}