@import '../components/whats_included';

.pt_product-details {
    .product-breadcrumbs {
        background: $c-mostly-white;

        .breadcrumb {
            margin-bottom: 15px;

            @include breakpoint(medium) {
                margin-bottom: 23px;
            }

            @include breakpoint(large) {
                margin-bottom: 15px;
            }
        }
    }


    .gated-flag {
        margin-top: 8px;

        @include breakpoint(medium) {
            margin-top: 0;
            margin-bottom: 8px;
        }

        @include breakpoint(large) {
            margin-bottom: 13px;
        }
    }

    .bundle-description {
        p {
            margin: 0;
        }

        ul {
            list-style: initial;
            padding: 0 0 0 19px;
            margin: 0;
        }
    }

    .product-actions-wrapper {
        .add-to-cart-wrapper {
            .back-in-stock-form {
                background-color: $c-light-blue;
                border: 1px solid $black;
                margin-top: 30px;
                max-width: 300px;
                padding: 16px;

                h5 {
                    font-size: 21px;
                    margin: 0 0 30px;
                }

                button {
                    &::after,
                    &::before {
                        display: none;
                    }
                }

                .bisFormStatus.success {
                    display: block;
                    margin-top: 16px;
                }
            }
        }
        .short-description {
            color: black;
            margin: 3% 0 5% 0;
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
        }
        &.productset {
            display:block;
            text-align: center;
            @include breakpoint(medium) {
                text-align: left;
            }
        }
        &.bottom-cta {
            width: 90%;
            text-align: right;
        }
        a,button {
            &.joinnow,&.add-all-to-cart {
                border-color: rgba(116, 24, 32, 0.7);
                color: #fff;
                background: #B52532;
            }
            &.button {
                &:before {
                    position: absolute;
                    content: '';
                    background: #741820;
                    width: 8px;
                    height: 2px;
                    bottom: -2px;
                    right: -5px;
                }
                &:after {
                    position: absolute;
                    content: '';
                    background: #741820;
                    width: 2px;
                    height: 8px;
                    bottom: -5px;
                    right: -2px;
                }
            }
        }
    }

    .recommendations .product-tile {
        .product-image a {
            border: 1px solid $c-light-gray;
        }
    }

    .short-description {
        color: inherit;
        font-size: $f-size-xs;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        margin-top: 4px;

        @include breakpoint(medium) {
            display: block;
            font-size: $f-size;
            line-height: 1.5;
        }
    }

    .product-top-content {
        background: $c-mostly-white;
        padding-bottom: 28px;

        @include breakpoint(medium) {
            padding-bottom: 16px;
        }

        @include breakpoint(large) {
            padding-bottom: 34px;
        }

        .grid {
            position: relative;

            @include breakpoint(medium) {
                > .row {
                    flex-wrap: nowrap;
                }
            }
        }


    }

    .recommendations {
        margin-bottom: 33px;

        .tiles-container {
            justify-content: center;
        }

        @include breakpoint(large) {
            margin-bottom: 56px;
        }
    }

    .product-details {
        margin-bottom: 34px;

        h2 {
            margin-top: 32px;
            margin-bottom: 13px;

            @include breakpoint(large) {
                margin-top: 49px;
                margin-bottom: 16px;
            }
        }

        @include has(body) {
            .col {
                padding-top: 0;

                p:first-of-type,
                div:first-of-type,
                ul:first-of-type,
                ol:first-of-type {
                    margin-top: 0;
                    padding-top: 0;
                }

                @include breakpoint(large) {
                    padding-top: 4px;
                }
            }
        }
    }

    .product-detail > h1 {
        @include h3;
        display: block;
    }
}

// Product Inscription
.personalized-inscription {
    background-color: $very-light-gray;
    box-sizing: border-box;
    border: 1px solid $black;
    margin-bottom: 20px;
    padding: 20px;

    .fullname-details {
        font-family: $f-body-bold;
        font-size: 16px;
        margin-bottom: 16px;
        text-transform: uppercase;

        @include breakpoint(large) {
            font-size: 18px;
        }
    }

    .fullname-field {
        label {
            text-transform: uppercase;
        }

        input {
            &.error {
                padding-right: 48px;
            }
        }
    }
}

.product-image-container {
    .badge-row {
        @include breakpoint(medium) {
            display: none;
        }
    }

    > h3 {
        margin-bottom: 14px;
    }

    &.col--lg-offset-1 .product-primary-image {
        display: none;
    }

    @include breakpoint(medium) {
        > h3 {
            display: none;
        }
    }

    @include breakpoint(large) {
        min-height: 492px;

        &.col--lg-offset-1 {
            margin-left: 103px;
        }

        &.col--lg-offset-1 .product-primary-image {
            display: block;
        }
    }

    @include breakpoint(xlarge) {
        min-height: 618px;
    }

}

#product-nav-container {
    overflow: hiddenproduct-image-containe;
    display: none;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}

.pdp-main {
    @extend %clearfix;

    .recommendations {
        &#einstein-carousel {
            position: relative;
            z-index: 1;

            .row {
                background: initial;
            }
        }
    }

    label {
        padding: 0;
        text-align: left;
        width: auto;
    }

    .availability-web {
        label {
           display: block;
           margin-left: 0;
        }

        .value {
            display: block;
        }
    }

    .product-number {
        margin: $product-number-margin;
    }

    .product-quantity-error {
        color: $c-red !important; // To override overly specific theme selector
        display: block;
        font-size: $f-size-xxs;
        font-weight: $f-weight-semibold;
        letter-spacing: 1px;
        line-height: 1.17;
        margin: 20px 0 16px;

        @include breakpoint(medium) {
        font-size: $f-size-xs;
        line-height: 1.14;
            margin-bottom: 20px;
        }
    }

    .product-top-content .product-price {
        @include font-source($f-body, $product-price-f-size, $f-body-color, $product-price-f-lh, $f-weight-bold);
        margin: $product-price-margin-top 0 $product-price-margin-bottom 0;

        .price-standard {
            color: $c-gray;
            text-decoration: line-through;
            font-weight: $f-weight-regular;
            margin-right: 10px;

            + .price-sales {
                color: $c-red;
            }
        }

        .price-tiered {
            color: $sangria;
            font-size: .6em;
            line-height: 1.5em;
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: .5rem;
    }
    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }

    .product-primary-image {
        max-width: 562px;
        text-align: center;

        @include breakpoint(medium) {
            margin-right: 25px;
        }

        @include breakpoint(large) {
            margin-right: 0;
        }
    }

    .product-info {
        clear: both;
        // Height for PDP Tabs
        ul {
            font-size: .8rem;
            list-style: square;
            padding: 0 5em;
            @media screen and (min-width: 768px) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
    }

    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        display: block;
        margin: 0;
        width: 100%;

        .c-product-action {
            display: flex;

            .c-product-buttons {
                display: block;
                width: 100%;
                max-width: 300px;

                #add-to-cart {
                    height: 40px;
                    letter-spacing: 3px;
                    width: 100%;

                    @media (min-width: 320px) and (max-width: 368px) {
                        padding-top: 5px;
                    }

                    @media (min-width: $bp-medium) and (max-width: $bp-large) {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }

                    #main & {
                        #{$dark-theme-selector} & {
                            background-color: $dark-theme-color;
                            border-color: $dark-theme-color;
                            color: $brand-primary;

                            &:hover:not(.add-to-cart-disabled) {
                                background-color: $brand-primary;
                                color: $dark-theme-color;
                            }
                        }
                    }

                    &.add-to-cart-disabled {
                        background-color: $c-medium-gray;
                        border-color: $c-medium-gray;
                        color: $white;

                        @include bp(max-width, $bp-large) {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }

                .button-pdp-login {
                    background-color: $brand-primary;
                    color: $white;
                    display: block;
        
                    &::before,
                    &::after {
                        display: none;
                    }
        
                    &:hover {
                        background: $white;
                        border-color: $brand-primary;
                        color: $brand-primary;
                        display: block;
                    }
                }
            }

            .c-product-quantity {
                & +.c-product-buttons {
                    width: calc(100% - 140px);
                    max-width: initial;
                }
            }
        }

        .availability-web {
            display: flex;
                align-items: baseline;

            label {
                font-family: $f-body-bold;
                font-size: 16px;
                margin-right: 10px;

                @include breakpoint(large) {
                    font-size: 18px;
                }
            }

            .value {
                @include breakpoint(large) {
                    font-size: 16px;
                }
            }
        }

        .product-detail-link {
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: $f-weight-regular;
            label {
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .inventory {
            display: inline-block;
            margin-right: 14px;
            vertical-align: bottom;
            white-space: nowrap;

            label {
                display: block;
                float: none;
                margin-left: 0;
            }

            input {
                margin-right: .4em;
                width: 51px;
                text-align: left;
                padding-left: 15px;
            }
        }

        button {
            &.add-all-to-cart {
                width: auto;
            }
        }

         a {
             &.button--cta {
                padding: 6px 42px;
             }
         }

        .dw-apple-pay-button {
            margin-left: 1.5rem;
            color: #f8f6f7;
        }
    }
    .promotion {
        overflow: hidden;
        margin-bottom: 15px;

        .promotion-title {
            font-size: 14px;
            color: $c-dark-gray;
            line-height: 24px;
        }

        .promotion-callout {
            color: $c-red;
            font-size: 1rem;

            .tooltip {
                font-size: .7rem;
                margin-left: .2rem;
            }
        }
    }
    .product-actions {
        @extend %clearfix;
        margin: 34px 0 0;

        @include breakpoint(large) {
            margin-top: 26px;
        }

        @include breakpoint(xlarge) {
            margin-top: 0;
            margin-bottom: 6px;
            width: 50%;
        }

        .socialsharing {

            .label {
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                color: $c-gray;
                text-transform: uppercase;
                margin-right: 20px;

                @include breakpoint(large) {
                    margin-right: 12px;
                }
            }

            @include breakpoint(xlarge) {
                float: right;
            }
        }

        a {
            margin-right: 20px;
            vertical-align: middle;
            display: inline-block;
            height: 24px;
            width: 24px;

            @include breakpoint(xlarge) {
                margin-right: 12px;
            }

            &.share-icon {

                i {
                    background: #3A569D;
                    border-radius: 50%;
                    height: 24px;
                    width: 24px;
                    display: inline-block;
                    position: relative;

                    > svg {
                        @include position(absolute, 50% null null 50%);
                        transform: translate(-50%, -50%);
                    }

                    @include svg-view-facebook-follow-white-element {
                        fill: $white;
                    }

                    @include svg-view-twitter-follow-white-element {
                        fill: $white;
                    }

                    @include svg-view-pinterest-follow-white-element {
                        fill: $white;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &[data-share="twitter"] i {
                background: #1DA1F2;
            }

            &[data-share="pinterest"] i {
                background: #BD081C;
            }
        }
    }
    // Product Set Display
    .product-set {

        .product-number {
            margin-bottom: 25px;
        }

        .product-set-list {
            margin-bottom: 17px;

            .products-label {
                text-align: center;
                margin-bottom: 5%;
            }

            .product-set-item {
                clear: both;
                @include breakpoint(medium) {
                    margin-left:20%;
                }
            }

            .product-set-image {
                img {
                    width: 100%;
                }
            }

            .product-name {
                float: none;
                margin-bottom: 0;
                width: auto;
                @include h4;
                a {
                    color: black;
                }
            }

            .product-price {
                margin: .5em 0;
            }

            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: 25%;
                    white-space: nowrap;
                }
            }
        }

        .product-add-to-cart {
            border-top: none;
            padding-top: 0;
            width: 100%;
            white-space: nowrap;
        }
    }

}

.main-image {
    display: block;
    border: 1px solid $c-light-gray;
}

.primary-image {
    max-width: 562px;
}

.product-thumbnails {

    @include breakpoint(medium) {
        margin-right: 25px;
    }

    @include breakpoint(large) {
        margin-right: 0;
    }

    ul {
        @extend %clearfix;
        padding: $product-thumb-ul-padding;
        margin: 0;
    }

    .slick-arrow {
        background: none;
        top: calc(100% + 26px);
        padding: 10px;

        &.slick-disabled {
            display: none !important;

            @include breakpoint(large) {
                display: block !important;
            }
        }
    }

    .slick-prev {
        left: calc(50% - 105px);

        &:before {
            @include svg-view-nav-left;
            transform: scale(0.5);
            transform: translate(-50%, -50%) scale(0.5);
        }
    }

    .slick-next {
        right: calc(50% - 105px);

        &:before {
            @include svg-view-nav-left;
            transform: translate(-50%, -50%) rotate(180deg) scale(0.5);
        }
    }

    // thumbnail
    .thumb {
        padding: 0;
        display: none;
        box-sizing: border-box;
        border: none;

        &:first-child {
            display: block;
        }

        a,
        .productthumbnail {
            display: block;
            border: 1px solid $c-light-gray;
        }

        &.selected a,
        &.selected .productthumbnail {
            @include breakpoint(large) {
                border-color: $black;
            }
        }
    }

    ul.slick-initialized .thumb {
        display: block;
    }

    .thumbnail-counter {
        display: block;
        text-align: center;
        text-transform: uppercase;
        margin-top: 14px;

        .count {
            font-weight: $f-weight-bold;
            margin: 0 5px;
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    // thumbnail image
    img {
        box-sizing: border-box;
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }

    h2 {
        margin: 1rem 0 .3rem;
    }

    @include breakpoint(large) {
        @include position(absolute, 16px null null 34px);

        &.tablet-slick-enabled {
            @include position(absolute, 56px null null 34px);
        }

        .thumb {
            padding: $product-thumb-li-margin;
            display: block;

            img {
                height: 78px;
                width: 78px;
                object-fit: cover;
            }
        }

        ul {
            width: 80px;
            overflow: hidden;
            max-height: 385px;

            &.slick-initialized {
                overflow: visible;
                max-height: none;
            }
        }

        .slick-arrow {
            left: 50%;
            transform: translateX(-50%);
        }

        .slick-prev {
            top: -31px;

            &:before {
                transform: translateY(-50%) translateX(-50%) rotate(90deg) scale(1);
            }
        }

        .slick-next {
            top: calc(100% + 8px);

            &:before {
                transform: translateY(-50%) translateX(-50%) rotate(-90deg) scale(1);
            }
        }
    }

    @include breakpoint(xlarge) {
        @include position(absolute, 24px null null 42px);

        &.tablet-slick-enabled {
            @include position(absolute, 24px null null 42px);
        }

        &.desktop-slick-enabled {
            @include position(absolute, 80px null null 42px);
        }

        ul {
            max-height: 485px;
        }
    }
}

.rating {
    color: $rain-forest;

    .product-info & {
        font-size: 2em;
    }

    .full-star-icon {
        @include svg-view-full-star;
        display: inline-block;
    }

    .half-full-star-icon {
        @include svg-view-half-full-star;
        display: inline-block;
    }

    .empty-star-icon {
        @include svg-view-empty-star;
        display: inline-block;
    }
}

.product-variations,
.product-options {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        &.swatches.has-subscription-year {
            grid-template-columns: repeat(2, 1fr);

            @include breakpoint(small) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include breakpoint(xlarge) {
                grid-template-columns: repeat(3, 1fr);
            }

            li a {
                flex-wrap: wrap;

                @include breakpoint(xsmall) {
                    padding: 5px 6px;
                }

                @include bp (max-width, $bp-large) {
                    padding: 5px 10px;
                }

                br {
                    content: '' !important; // basically wipes out line break
                    display: inline-block !important;

                    &::before {
                        // create slash in place of <br>
                        content: '/';
                        display: inline; 
                        margin: 0 .25em;
                    }
                }
            }
        }
    }

    .attribute {
        margin: 16px 0 7px 0;
        overflow: hidden;
        padding: 0;
        position: relative;

        h3,
        .label {
            color: inherit;
            font-size: $variation-label-f-size;
            font-family: $variation-label-f-family;
            font-weight: $variation-label-f-weight;
            letter-spacing: 0.5px;
            line-height: $variation-label-f-lh;
            margin-bottom: $variation-label-margin-bottom;
            text-transform: uppercase;
        }

        &__selected-value {
            color: inherit;
            font-size: 14px;
            font-family: $sans-serif;
            font-weight: normal;
            line-height: 1.43;
            letter-spacing: normal;
            margin-left: 4px;
            text-align: left;
            text-transform: none;

            @include breakpoint(medium) {
                font-size: 16px;
                margin-left: 6px;
            }
        }

        .value {
            width: 100%;
        }

        .swatches {
            display: flex;
            flex-wrap: wrap;
        }

        .selected-value {
            border: 0 none;
            color: $f-body-color;
            display: none;
            font-weight: $f-weight-bold;
            padding: 1.3em 0 0 .4em;
            text-transform: uppercase;

            @media screen and (min-width: 768px)  {
                padding-top: .8em;
            }
        }

        .size-chart-link {
            clear: both;
            float: none;

            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        width: 33.33333%;

        &.swatches-two-items {
            width: 50%;

            a {
                display: block;
            }
        }

        &.selected {
            a {
                background: $variation-swatch-bg-selected;
                color: $variation-swatch-c-selected;
                margin: 0 $variation-swatch-spacing--mobile $variation-swatch-spacing--mobile 0;

                @include breakpoint(medium) {
                    margin: 0 $variation-swatch-spacing $variation-swatch-spacing 0;
                }
            }
        }

        &.unselectable {
            a {
                &, &:hover {
                    background-color: transparent;
                    border-color: $c-charcoal;
                    color: $c-charcoal;
                    cursor: not-allowed;
                    text-decoration: line-through;
                }
            }
        }

        a {
            @include attribute-button();
        }
    }

    .swatches.size {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        li:not(.selected-value) {
            display: flex;
            width: 100%;

            a {
                display: flex;
                width: 100%;
            }
        }
    }

    .swatches.swatches--two-col {
        grid-template-columns: repeat(2, 1fr);
    }

    .color li {
        &:hover, &.selected {
            a {
                background: none;
            }
        }

        a {
            line-height: 0;
            padding: 0;
            width: $variation-swatch-color-size;
            height: $variation-swatch-color-size;
            border: $variation-swatch-border;
            border-radius: $variation-swatch-color-radius;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.product-attribute-button {
    @include attribute-button();
}

.format-info {
    margin-top: 14px;
    margin-bottom: 20px;

    @include breakpoint(medium) {
        margin-bottom: 28px;
    }

    p {
        color: $c-charcoal;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.43;
        margin-top: 0;
    }
}

.product-detail.digital {

    .availability-web {
        padding-bottom: 33px;
    }

    .digital-notice {
        color: $c-gray;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        font-weight: $f-weight-bold;
        margin: 20px 0 -4px 0;
        max-width: 325px;

        @include breakpoint(large) {
            margin-top: 32px;
            max-width: 375px;
        }

        @include breakpoint(xlarge) {
            max-width: 460px;
        }
    }

    .pdpForm {
        @include breakpoint(large) {
            display: block;
        }
    }

    .product-actions {
        @include breakpoint(large) {
            display: inline-block;
            margin-left: 8px;
        }
    }
}

//setlist theming only for on pdps
.setlist-block {
    background: $black;
}

.pdp-setlist {
    padding-top: 32px;
    margin-bottom: 0;
    padding-bottom: 24px;

    @include breakpoint(large) {
        padding-top: 54px;
        padding-bottom: 48px;
    }

    @include breakpoint(xlarge) {
        padding-top: 42px;
    }

    h4 {
        font-size: 32px;
        line-height: 40px;
        color: $white;
        text-align: center;

        @include breakpoint(medium) {
            margin-bottom: 4px;
        }

        @include breakpoint(large) {
            margin-bottom: 29px;
        }

        @include breakpoint(xlarge) {
            font-size: 40px;
            line-height: 56px;
            margin-bottom: 24px;
        }
    }

    .setlist {

        h5 {
            color: $c-gray;
            font-weight: $f-weight-bold;
            font-family: $sans-serif;
            font-size: 16px;
            line-height: 24px;
            padding-left: 46px;
        }

        ul {

            &:first-child {
                margin-top: 4px;
            }

            .setNum {
                font-size: 16px;
                @include position(absolute, 8px null null null);
            }
            .songName {
                font-size: 16px;
                color: $white;
                margin-left: 29px;
            }

            .songDescription{
	            font-size: 12px;
                color: $white;
	        }
        }

        .play-audio-clip {
            @include svg-view-play-white;
            display: inline-block;
            vertical-align: middle;

            &.is-playing {
                @include svg-view-pause-white;
                display: inline-block;
            }
        }
    }
}

.badge-row  {
    display: inline-block;
    vertical-align: top;
}

.badge {
    background-color: $c-black;
    box-sizing: border-box;
    color: $white;
    font-size: 10px;
    font-weight: $f-weight-bold;
    display: inline-flex;
        align-items: center;
    letter-spacing: 1px;
    line-height: 1;
    min-height: 20px;
    padding: 5px 7px 5px 5px;
    text-transform: uppercase;
    vertical-align: top;

    &--gated {
        background-color: $c-red;
    }

    img {
        max-height: 15px;
        max-width: 15px;
    }

    i {
        line-height: inherit;
    }

    svg {
        fill: currentcolor;
        // Allow SVG to fully fill space while still allowing for padding
        margin: -5px 1px -5px -5px;
    }
}

.product-badge-inner,
.product-sold-out-badge-inner {
    margin-bottom: 12px;

    @include breakpoint(large) {
        margin-bottom: 15px;
    }

    .badge-row,
    .sold-out-badge-row {
        .badge,
        .sold-out-badge {
            padding: 2px 7px 2px 5px;

            sup {
                font-size: 10px;
                line-height: normal;
                position: initial;
                padding-left: 5px;
            }
        }
    }
}

.product-badge-mobile {
    display: block;
    height: 15px;
    @include breakpoint(large) {
        display: none;
    }
}

.yotpo-ratings {
    display: none;
    &.h-mobile-only {
        display: block;
        @include breakpoint(medium) {
            display: none;
        }
    }
    @include breakpoint(medium) {
        display: block;
    }

}

.pt_product-details {
    .product-slot {

        &.product-listing {
            overflow: hidden;
        }
    }
    .add-to-cart-disabled {
        padding: 6px 29px;
    }
}

.d-none {
    display: none !important;
}

#main {
    .product-breadcrumbs,
    .product-top-content {
        background-color: $dark-theme-color;

        #{$dark-theme-selector} & {
            background-color: $brand-primary;
            margin-top: 0;
        }
    }

    .product-top-content {
        padding-top: 39px;

        @include breakpoint(large) {
            margin-top: 20px;
        }

        .badge-row {
            .gated-flag {
                margin: 0 0 15px 0;

                @include breakpoint(large) {
                    margin-top: 15px;
                }
            }
        }
    }

    .c-more-from-artist,
    .about-artist {
        #{$dark-theme-selector} & {
            clip-path: initial;
        }
    }

    .video-container {
        #{$dark-theme-selector} & {
            margin-top: 0;
            clip-path: polygon(0 0, 0 calc(100% - 65px), 88% 100%, 53% calc(100% - 65px), 100% calc(100% - 41px), 100% 0%, 100% 0, 100% 0, 0 0);

            @include breakpoint(large) {
                clip-path: polygon(0 0, 0 calc(100% - 152px), 69% 100%, 53% calc(100% - 96px), 100% 100%, 100% 0, 100% 0, 0 0);
            }
            
        }
    }

    .product-image-container,
    .product-detail {
        padding-top: 0;
    }
}

#main {
    #{$dark-theme-selector} & {
        .product-breadcrumbs {
            .breadcrumb-element {
                color: $c-medium-gray;

                &:last-child {
                    color: $dark-theme-color;
                }
            }
        }

        #product-content {
            .button-pdp-login {
                color: $brand-primary;
                display: block;

                &::before,
                &::after {
                    display: none;
                }

                &:hover {
                    background: $brand-primary;
                    display: block;
                    color: $dark-theme-color;
                    border-color: $dark-theme-color;
                }
            }
        }

        .product-name {
            color: $dark-theme-color;
        }

        #product-content * {
            color: $dark-theme-color;

            .attribute {
                .swatches {
                    .selectable {
                        .swatchanchor {
                            background-color: $brand-primary;
                            border-color: $dark-theme-color;
                        }

                        &.selected {
                            .swatchanchor {
                                background-color: $dark-theme-color;
                                border-color: $brand-primary;
                                color: $brand-primary;
                            }
                        }
                    }

                    .unselectable {
                        .swatchanchor {
                            background-color: $brand-primary;
                            border-color: $c-medium-gray;
                            color: $c-medium-gray
                        }
                    }
                }
            }

            .quantity-select {
                -webkit-text-fill-color: $white;
                background-color: $brand-primary;
                color: $white;
                opacity: 1;
            }
        }

        .sizechartCTA {
            a {
                background: none;
                color: $dark-theme-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.43;
                padding: 0;
                text-decoration: underline;
                text-transform: capitalize;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

#accordionPDP {
    &.ui-accordion {
        margin-top: 30px;

        .accordion-item {
            border-bottom: 1px solid $c-stroke-gray;
        }

        h3 {
            display: block;
            font-size: 15px;
            letter-spacing: 3px;
            line-height: 1;
            padding: 25px 0;
            text-transform: uppercase;

            #{$dark-theme-selector} & {
                color: $dark-theme-color;
            }

            &::after {
                background: url("../images/svg/intermediate-svg/pdp-arrow-detail.svg") no-repeat;
                content: "";
                display: inline-block;
                height: 9px;
                margin: 0;
                position: absolute;
                right: 20px;
                top: 28px;
                transition: all 0.3s linear;
                width: 10px;

                #{$dark-theme-selector} & {
                    background: url("../images/svg/intermediate-svg/pdp-arrow-detail-white.svg") no-repeat;
                }
            }

            &.ui-state-active {
                &::after {
                    transform-origin: 50% 50%;
                    transform: rotate(180deg);
                    transition: all 0.3s linear;
                }
            }
        }

        .ui-accordion-content {
            padding-bottom: 20px;

            #{$dark-theme-selector} & {
                color: $dark-theme-color;
            }
        }
    }
}

.recommendations-pdp {
    display: block;
    overflow: hidden;
    width: 100%;

    .recommendations {
        padding: 0;

        .einstein-header {
            display: flex;
                justify-content: space-between;
                align-items: flex-end;
            margin-bottom: 20px;
            padding: 0 20px;

            h2 {
                text-align: left;
            }

            a {
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                letter-spacing: 3px;
                text-transform: uppercase;
            }
        }

        .slick-list {
            overflow: initial;
        }

        .slick-prev, .slick-next {
            bottom: -70px;
            top: auto !important;

            &:hover {
                background-color: initial;
            }
        }

        .slick-prev {
            left: auto;
            right: 60px;

            &::before {
                background: url(../images/interface/left-arrow-white.png) no-repeat;
            }
        }

        .slick-next {
            &::before {
                background: url(../images/interface/right-arrow-white.png) no-repeat;
            }
        }

        .slick-dots {
            display: flex !important;
            left: 0;
            margin-left: 20px;
            width: 80%;

            li {
                height: 3px;
                margin: 0;
                width: 100%;

                button {
                    height: 3px;
                    width: 100%;

                    &:hover {
                        background-color: initial;
                    }

                    &::before {
                        border-radius: 0;
                        height: 3px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@keyframes fade-right {
    0% {
      transform: translateX(30vw);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

@keyframes fade-left {
    0% {
      transform: translateX(-40vw);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

.recommendations-pdp,
.video-container {
    background-image: url('../images/Background_Texture.jpg');
    background-repeat: round;
    clip-path: $polygon-clip-path;
    height: 500px;
    margin: 40px 0 40px 0;
    padding: 30px 0 30px 0;
    overflow: hidden;

    @include breakpoint(medium) {
        clip-path: $polygon-clip-path-md;
        height: 900px;
        margin: 80px 0 80px 0;
    }

    @include breakpoint(large) {
        clip-path: $polygon-clip-path-lg;
    }

    .background-content {
        overflow: hidden;
        position: absolute;
        width: 100%;

        .top-text {
            padding: 75px 0 250px 0;
            text-align: left;
        }

        .bottom-text {
            text-align: right;
        }

        .top-text,
        .bottom-text {
            position: relative;
        }
    }

    .video-section {
        height: 204px;
        margin-left: 27px;
        margin-right: 27px;
        padding: 148px 0 148px 0;
        position: relative;
        align-items: center;
        width: auto;

        @include breakpoint(small) {
            margin-left: auto;
            margin-right: auto;
            width: 360px;
        }

        @include breakpoint(medium) {
            height: 438px;
            padding: 231px 0 231px 0;
            width: 776px;
        }

        p, div {
            height: 100%;
        }

        iframe {
            height: 100%;
            width: 100%;
        }
    }
}

.video-container {
    .background-content {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }

        .top-text .top-text-content, .bottom-text .bottom-text-content{
            color: $c-light-gray;
            opacity: 0.08;
        }
    }
}

.recommendations-pdp {
    background-image: url('../images/bg-textured-white.png');
    clip-path: initial;
    position: relative;
    padding-top: 0;
    margin-top: 0;

    @include breakpoint(large) {
        padding-top: 30px;
        margin-top: 40px;
    }

    .background-content {
        .top-text {
            padding: 0 0 250px 0;
            text-align: left;
        }
    }
    #einstein-carousel div.tiles-container.carousel.slick-initialized.slick-slider,
    #einstein-carousel .product-tile {
        background: none;

        .product-image {
            background-color: $white;
        }
    }
}
