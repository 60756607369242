.c-full-width-module {
    box-sizing: border-box;
    display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    margin: 0 calc((100% - 100vw) / 2);
    min-height: 400px;
    position: relative;
        z-index: 0;
    width: 100vw;
    width: 100dvw;

    @include breakpoint(medium) {
        margin-bottom: 10px;
        margin-top: 10px;
        min-height: 550px;
    }

    &__banner-image {
        display: inline-block;
        height: auto;
        object-fit: cover;
        width: 100%;
    }

    &__image-wrap {
        height: 100%;
        position: absolute;
            z-index: -1;
        width: 100%;

        picture {
            display: flex;
                align-items: stretch;
            height: 100%;
        }
    }

    & &__image {
        background-color: $white;
        clip-path: $polygon-clip-path;
        height: 100%;

        @include breakpoint(medium) {
            clip-path: $polygon-clip-path-md;
        }

        @include breakpoint(xlarge) {
            clip-path: $polygon-clip-path-lg;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
    }

    &__content {
        box-sizing: border-box;
        color: $white;
        position: static;
        padding-bottom: 135px;
        padding-top: 151px;
        text-align: center;

        @include breakpoint(medium) {
            padding-bottom: 174px;
            padding-top: 214px;
        }

        @include breakpoint(xlarge) {
            padding-bottom: 254px;
            padding-top: 294px;
        }
    }

    &__heading {
        @include h2;

        color: inherit;
        margin-bottom: 13px;

        @include breakpoint(medium) {
            @include h1;

            color: inherit;
            margin-bottom: 20px;
        }
    }

    &__copy {
        font-family: $f-subheader;
        font-size: 18px;
        line-height: 1.33;
        margin-bottom: 25px;
        margin-left: auto;
        margin-right: auto;
        max-width: 777px;

        @include breakpoint(medium) {
            font-size: 21px;
            line-height: 1.29;
            margin-bottom: 37px;
        }
    }

    &__button {
        background-color: transparent;
        border-color: currentColor;
        color: currentColor;

        &:hover:not(.disabled):not(:disabled) {
            border-color: $c-black;
        }
    }
}

.s-full-width-module {
    &__copy {
        p {
            @include bp(max-width, $bp-medium) {
                margin-top: 15px;
            }
        }
    }

    &__buttons {
        display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;

        a {
            @include primary-button;

            background-color: transparent;
            border-color: $white;
            color: $white;
            display: inline-block;

            &:hover:not(.disabled):not(:disabled) {
                background-color: $white;
                color: $c-black;
            }
        }
    }
}