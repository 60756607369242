.c-featured-article {
    height: auto;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;

    &__details {
        color: $white;
        display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-end;
        min-height: 100%;
        padding-bottom: 20px;
        padding-left: 24px;
        padding-right: 24px;
        position: absolute;
            bottom: 0;
            left: 50%;
            z-index: 1;
        text-align: left;
        transform: translateX(-50%);
        width: 100%;

        @include breakpoint(medium) {
            padding-bottom: 30px;
            padding-left: 34px;
            padding-right: 34px;
        }
    }

    &__img-wrap {
        height: 100%;
        position: relative;
    }

    & &__img {
        height: 100%;
        width: 100%;
    }

    &__img,
    .js-blazy-wrapper--finished &__img {
        min-height: 350px;

        @include breakpoint(medium) {
            min-height: 400px;
        }
    }

    &__img {
        object-fit: cover;
        object-position: center center;
    }

    &__author {
        font-family: $f-subheader;
        font-size: 14px;
        font-weight: $f-weight-semibold;
        letter-spacing: 1px;
        line-height: 1.43;
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    &__name {
        color: inherit;
        display: block !important; // Override overly specific tab style as needed
        font-family: $f-subheader;
        font-size: 21px;
        letter-spacing: 1px;
        line-height: 1.29;

        @include breakpoint(medium) {
            font-size: 28px;
            line-height: 1.07;
        }
    }

    &__desc {
        font-size: 14px;
        line-height: 1.43;
        margin-bottom: 14px;
        margin-top: 9px;
    }

    &__read-more {
        background-color: transparent;
        border-color: $white;
        color: $white;
        position: static;

        &::before {
            content: '';
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
        }
    }
}

.s-featured-article {
    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: auto;
    }
}