.c-category-card {
    margin-top: 80px;
    position: relative;

    @include breakpoint(medium) {
        margin-top: 68px;
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;

        @include breakpoint(medium) {
            gap: 8px;
        }

        @include breakpoint(large) {
            justify-content: space-between;
        }
    }

    &__card {
        width: 48%;

        @include bp(min-width, $bp-xsmall) {
            width: 49%;
        }

        @include breakpoint(medium) {
            width: 24%;
        }
        

        &__link {
            border: 1px solid rgba(172, 172, 172, 0.3);
            display: block;
            height: 100%;
            position: relative;
            width: auto;

            h4 {
                box-sizing: border-box;
                padding: 20px;
                position: absolute;
                left: 0;
                bottom: 0;
                text-align: center;
                width: 100%;
            }

            img {
                display: block;
                height: 100%;
                margin: 0;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    &__heading {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 14px;

        @include breakpoint(medium) {
            margin-bottom: 18px;
        }

        &__link,
        &__link * {
            font-size: 11px;
            font-weight: $f-weight-bold;
            line-height: 1;
            letter-spacing: 2.5px;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 14px;
                letter-spacing: 3px;
            }
        }
    }
}