@import "nav";
@import "country_selector";

.top-banner {
    background-color: $c-black;
    border-bottom: 1px solid $white;
    position: relative;
        z-index: 3;
    color: $white;

    .svg-logo-dims {
        aspect-ratio: 512 / 189;
        height: auto;
        width: 100%;
    }
    
    a {
        color: inherit;
    }

    .header {
        box-sizing: border-box;
        display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
        margin-bottom: 0;
        min-height: 110px;
            
        @include breakpoint(medium) {
            min-height: 130px;
            padding-top: 22px;
        }

        @include breakpoint(xlarge) {
            min-height: auto;
            padding-bottom: 22px;
        }

        .header-center {
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(xlarge) {
                width: 80%;
            }

            .header-nav {
                @include breakpoint(xlarge) {
                    margin-bottom: 0;
                    position: static;
                    transform: translate(0 ,0);
                }
            }      
        }

        .header-left {
            display: flex;
            width: 50%;
            margin-left: -$nav-padding-x;
            position: relative;

            @include breakpoint(large) {
                margin-left: -$nav-padding-x-desktop;
            }

            @include breakpoint(xlarge) {
                margin-right: 0;
                margin-left: 0;
                width: 10%;
            }
        }

        .header-right {
            display: flex;
                align-items: center;
            margin-left: auto;
            margin-right: -$nav-padding-x;
            padding-top: 2px;

            @include breakpoint(large) {
                margin-right: -$nav-padding-x-desktop;
            }

            @include breakpoint(xlarge) {
                justify-content: flex-end;
                margin-left: 0;
                margin-right: 0;
                width: 10%;
            }

            .mini-cart-total .mini-cart-link {
                @include breakpoint(xlarge) {
                    padding-right: 0;
                }
            }
        }
    }

    .search-toggle,
    .search-field {
        border: none;
        border-bottom: 1px solid;
        background: none;
        letter-spacing: 3px;
        max-width: 100%;
        text-align: left;
        transition: none;
        width: $header-search-width;
    }

    .search-toggle {
        cursor: pointer;
        margin-bottom: 32px;
        padding: 9px 0 9px;

        @include breakpoint(xlarge) {
            margin-bottom: 42px;
        }

        &:hover:not(.disabled):not(:disabled) {
            background-color: transparent;
            color: $c-black;
        }

        i {
            display: inherit;
        }

        &:after {
            display: none;
        }

        .search-icon {
            display: flex;
            position: absolute;
                right: 0;
                top: 50%;
            transform: translateY(-50%);
        }
    }

    .search-form {
        position: relative;
        width: min-content;
    }

    .search-field {
        color: $c-black;
        font-size: 15px;
        font-weight: $f-weight-bold;
        height: auto;
        line-height: 1;
        margin-bottom: 30px;
        padding: 9px 0 6px;

        @include breakpoint(large) {
            margin-bottom: 21px;
        }

        &:placeholder-shown {
            text-transform: uppercase;
        }

        &::placeholder {
            color: currentColor;
            font-size: inherit;
            font-weight: inherit;
            text-transform: uppercase;
        }
    }

    .search-submit {
        background-color: transparent;
        border: none;
        color: $icon-base-color;
        padding: 0;
        line-height: 33px;
        position: absolute;
            right: 0;
            top: 2px;

        &:hover:not(:disabled):not(.disabled) {
            background-color: transparent;
            color: $c-black;
        }

        &:after,
        &:before {
            display: none;
        }

        &:hover {
            color: $c-action-blue;
        }

        i {
            vertical-align: middle;
            display: inline-block;
            height: 20px;
            padding-top: 2px;
            width: 18px;

            svg {
                vertical-align: top;
            }
        }
    }

    .search-close {
        border: 2px solid $c-black;
        border-radius: 50%;
        display: flex;  
            align-items: center;
            justify-content: center;
        height: 20px;
        margin-top: 11px;
        padding: 2px;
        position: absolute;
            left: $header-search-width + 18px;
            top: 0;
        width: 20px;

        @include breakpoint(large) {
            left: $header-search-width + 22px;
        }

        span {
            color: $c-gray;
            font-weight: $f-weight-bold;
            margin-left: 8px;
        }

        i.close-icon {
            font-size: 14px;
            line-height: 22px;
        }

        svg {
            height: 8px;
            width: 8px;
        }
    }

    .no-results-suggestions {
        max-width: 360px;

        p {
            margin-top: 0;
        }
    }

    @include svg-view-search-element {
        fill: currentColor;
    }

    .user-account {
        min-height: 16px;
        padding-left: 16px;
        position: relative;

        .user-icon {
            line-height: 1;
        }

        @include breakpoint(medium) {
            display: flex;
        }
    }

    .header-right .awmh {
        position: absolute;
        @include position(absolute, 54% 226px null null);
        transform: translateY(-50%);

        @include breakpoint(large) {
            right: 261px;
        }

        @include breakpoint(xlarge) {
            right: 292px;
        }
    }

    .header-right .fifth-member {
        position: absolute;
        @include position(absolute, 60% 62px null null);
        transform: translateY(-50%);

        @include breakpoint(medium) {
            right: 188px;
        }

        @include breakpoint(large) {
            right: 216px;
            top: 58%;
        }

        @include breakpoint(xlarge) {
            right: 237px;
        }
    }

    .met-store {
        position: absolute;
        @include position(absolute, 50% 99px null null);
        transform: translateY(-50%);
        @include headerlabel;
        margin-left: 0;

        @include breakpoint(large) {
            right: 120px;
        }

        @include breakpoint(xlarge) {
            right: 134px;
        }
    }

    .user-account,
    .met-store,
    .mobile-hidden {
        display: none;

        @include breakpoint(medium) {
            display: flex;
        }
    }

    .user-account,
    .search-toggle {
        align-items: center;
    }

    .user-account {
        span {
            @include headerlabel;
        }
    }

    * {
        box-sizing: border-box;
    }

    &--minimal {
        background-color: $white;
        color: $c-black;
        
        .header {
            display: grid;
                grid-template-columns: 1fr auto 1fr;
            margin-left: auto;
            margin-right: auto;
            max-width: 1330px;
            padding: 17px 27px 10px;

            @include breakpoint(large) {
                padding-bottom: 7px;
                padding-top: 16px;
            }
        }
        
        .primary-logo {
            position: static;
            transform: none;
        }

        .cart-link {
            @include link2;

            padding-top: 20px;
            text-transform: uppercase;

            @include breakpoint(large) {
                @include link1;

                padding-top: 24px;
            }

            .label {
                &--mobile {
                    @include breakpoint(large) {
                        display: none;
                    }
                }

                &--desktop {
                    display: none;

                    @include breakpoint(large) {
                        display: inline;
                    }
                }
            }
        }
    }
}

.header-right-icons-homepage {
    display: none;
}

.pt_storefront {

    .header-right-icons {
        display: none;
    }

    .header-right .header-right-icons-homepage {
        display: block;

        .awmh {
            @include breakpoint(medium) {
                top: 52%;
            }
        }
    }
}

.primary-logo {
    color: inherit;
    margin: 0;
    text-align: center;
    transform: translate(-50%, 0);
    width: 68px;
    z-index: 1;

    @include breakpoint(xlarge) {
        transform: translate(-20%, 0);
    }

    &.primary-logo-full {
        width: $header-logo-size-small;
    }

    &:not(.primary-logo-full){
        .svg-m-logo-dims {
            width: 68px;
            height: 68px;

            @include breakpoint(large) {
                width: 90px;
                height: 90px;
            }
        }
    }

    a {
        color: inherit;
        display: block;
    }

    img {
        width: 100%;
    }

    p {
        margin: 0;
        padding: 0;
    }

    @include breakpoint(large) {
        width: 90px;

        &.primary-logo-full {
            width: $header-logo-size-large;
        }
    }
    
    svg {
        fill: currentColor;
    }

    .primary-logo-m {
        display: block;
        width: 100%;

        .svg-m-logo-dims {
            margin-top: -40px;
            width: 100%;

            @include breakpoint(large) {
                margin-top: -30px;
            }
        }
    }
}

.header-banner {
    color: $c-headerbanner-text;
    min-height: $headerbanner-height;
    display: block;
    background: $c-headerbanner-color;

    .header-promotion {
        font-weight: $f-weight-medium;

        span, p {
            line-height: 1;
            padding: 9px 0;
            margin: 0;
            text-align: center;

            @include breakpoint(medium) {
                text-align: left;
            }
        }
    }

    .utility-menu {
        display: flex;
        justify-content: flex-end;
        display: none;

        @include breakpoint(medium) {
            display: flex;
        }
    }
}

.quick-links h3,
.suggestions-heading {
    color: $black;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin-bottom: 12px;
    position: relative;
    text-transform: uppercase;

    @include breakpoint(large) {
        margin-bottom: 11px;
    }
}

#search-wrapper {
    @extend .clearfix;
    transform: translateX(-100vw);
    height: max-content;
    position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 103;
    display: block;
    visibility: hidden;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 63px;

    @include breakpoint(large) {
        overflow: hidden;
    }

    .quick-links {
        transition: all 0.5s ease;

        &.hideAnim {
            max-height: 0;
            opacity: 0;
            display: none;
        }

        a {
            color: $black
        }

        ul {
            list-style: none;
            margin-top: 0;
            padding: 0;

            li {
                font-weight: $f-weight-medium;
                margin-bottom: 5px
            }
        }
    }
}

#user-account-wrapper {
    @extend .clearfix;
    color: $f-body-color;
    z-index: 102;
    transform: translateX(-100%);
    height: 100vh;
    position: fixed;
    background: $nav-mobile-bg-color;
    overflow: hidden;
    left: 0;
    bottom: 0;
    top: 0;
    transition: transform $default-transition-duration ease-in-out;
    display: block;
    max-width: 360px;
    width: 100%;
    box-sizing: border-box;
    padding: 63px 18px 63px;

    .navigation-close {
        display: none;
    }

    ul {
        list-style: none;
        flex-direction: column;

        .has-sub-menu {
            color: $black;
            font-weight: $f-weight-bold;
            position: relative;
            overflow: visible;
            display: inline-block;

            @include navPsuedo(64px, $c-light-gray, 2px);

            i {
                display: none;
            }
        }

        .menu-back {
            display: none;
        }

        .user-panel {
            transform: translateX(0);
            position: relative;
            visibility: visible;
        }

        li {
            display: block;

            a {
                line-height: 30px;
            }
        }
    }

    .account-close {
        @include position(absolute, 9px 18px null null);
        display: flex;

        span {
            color: $c-gray;
            font-weight: $f-weight-bold;
            margin-left: 8px;
        }

        i.close-icon {
            line-height: 22px;
        }

        @include svg-view-close-gray-element {
            fill: $c-gray;
        }

        @include breakpoint(xlarge) {
            right: 40px;
        }
    }

    @include breakpoint(large) {
        padding: 46px 41px 63px 40px;
        max-width: 528px;
    }

    @include breakpoint(xlarge) {
        max-width: 685px;
        padding: 63px 41px 63px 40px;
    }
}

.header-search {
    position: relative;

    .search-clear {
        display: none;
        @include position(absolute, 50% 13px null null);
        transform: translateY(-50%);
    }

    &.searching {
        border-color: $c-gray;

        .search-clear {
            display: block;
        }
    }
}

// =============================================
// Search Slider Styling
// =============================================
.search-active #navigation {
    .header-search {
        display: block;
    }

    .store-categories,
    .menu-category,
    .mega-menu-divider,
    .nav-external-links,
    .search-toggle {
        display: none;
    }

    .navigation-close {
        span {
            color: $black;
        }

        @include svg-view-close-element {
            fill: $black;
        }
    }
}

.pt_checkout, .pt_cartlogin {

    .top-banner {
        margin-bottom: 0;
    }

    .header > .html-slot-container,
    .utility-menu {
        @include position(absolute, 50% 0 null null);
        transform: translateY(-50%);
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    .header > .html-slot-container {
        right: 225px;
        text-align: right;
        max-width: 50%;

        @include breakpoint(xlarge) {
            right: 260px;
        }
    }

    .utility-menu {
        right: 130px;

        @include breakpoint(large) {
            right: 140px;
        }

        @include breakpoint(xlarge) {
            right: 150px;
        }

        .menu-utility-user li {
            margin: 0;
        }
    }

    .mini-cart {
        display: block;

        .mini-cart-link span:first-of-type {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }
        }
    }
}

@include svg-view-logo-element {
    fill: currentColor;
}

@include svg-view-menu-search-element {
    fill: currentColor;
}

@include svg-view-icon-bag-element {
    fill: currentColor;
}

@include svg-view-user-element {
    fill: currentColor;
}

.header-inverted .top-banner {
    background-color: $white;
    border-bottom-color: $c-medium-gray;
    color: $c-black;
}

.header-inverted.page-bg .top-banner {
    background-color: transparent;
}

.overlay-header {
    .top-banner {
        border: none;
        z-index: 2;
    }

    .header {
        position: absolute;
            left: 0;
            right: 0;
            top: 0;
    }
}