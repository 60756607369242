svg,
symbol { // Use both svg and symbol to properly handle different browsers
    path, 
    g {
        fill: inherit !important;
    }
}

// overwrite for fifth member icon, which is different colors
svg path[id*="Fill-44"] {
    fill: #C00602 !important;
}

@include svg-view-menu-element {
    fill: currentColor;
}

@include svg-view-icon-expand-element {
    fill: $icon-icon-expand-color;
}

// Cart icon sizes
@include svg-view-cart-element {
    fill: $icon-cart-color;
}

// Search Marker icon sizes
@include svg-view-location-marker-element {
    fill: $icon-base-color;
}

// Search box button icon sizes
@include svg-view-search-element {
    fill: $icon-base-color;
}

// User Account icon sizes
@include svg-view-user-element {
    fill: $icon-base-color;
}
