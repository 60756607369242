.pt_releases {
    .title {
        margin-bottom: 0;
        position: relative;
            z-index: 0;

        @include breakpoint(medium) {
            margin-bottom: 15px;
        }
    }

    .page-title {
        line-height: 22px;
        letter-spacing: 3px;
        padding-left: 0;
        padding-right: 0;

        .title {
            float: left;
        }
    }

    .title-bar {
        border-bottom: 1px solid $c-black;
        padding-bottom: 11px;
        
        @include breakpoint(medium) {
            display: flex;
                align-items: flex-end;
                justify-content: space-between;
            padding-bottom: 12px;
        }
    }

    .c-banner-content {
        @include bp(max-width, $bp-large) {
            .c-banner-button {
                margin-top: 20px;
            }
        }
    }

    .primary-content {
        position: relative;
    }

    .release-tile {
        display: block;
        position: relative;

        .hover {
            opacity: 0;
            @include position(absolute, 0 0 0 0);
            background: rgba(181,37,50,0.90);
            transition: opacity $default-transition-duration $default-transition-function;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            p {
                display: block;
                margin: 0 15px;
            }
        }

        &.js-blazy-wrapper--finished:hover .hover{
            opacity: 1;
        }

        .tile-release-date,
        .tile-name {
            font-weight: $f-weight-semibold;
        }
    }

    .folder-refinement {
        ul { 
            li {
                padding: 0 9px;

                @include breakpoint(xlarge) {
                    padding: 0 16px;
                }
            }

            li a {
                font-weight: $f-weight-bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-size: 12px;
                color: $c-gray;

                &.selected {
                    color: $black;
                }
            }
        }

        .select-outterwrapper {
            h6 {
                font-family: $sans-serif;
                font-size: $f-size-xs;
                color: $c-dark-gray;
                line-height: 24px;
                text-transform: none;
                margin-right: 18px;
            }

            .select-wrapper {
                width: 100%;
                
                @include breakpoint(large) {
                    max-width: 203px;
                }
            }
        }
    }

    .pagination ul {
        justify-content: flex-start;
    }

    .contentSortby {
        &.desktop-view {
            display: none;
            float: right;
            width: 360px;
        }

        &.mobile-view {
            display: block;
            padding-bottom: 20px;
        }

        @include breakpoint(medium) {
            margin-bottom: 8px;

            &.desktop-view {
                display: block;
            }
            &.mobile-view {
                display: none;
            }
        }
    }
}

.pt_musicalbum {
    .page-title {
        line-height: 22px;
        letter-spacing: 1px;
        opacity: .15;
        margin-bottom: 18px;
        margin-right: 8px;

        @include breakpoint(medium) {
            margin-bottom: 21px;
            line-height: 37px;
            font-size: 56px;
            letter-spacing: 3px;
        }

        @include breakpoint(large) {
            margin-right: 0;
            margin-bottom: 40px;
        }

        @include breakpoint(xlarge) {
            line-height: 32px;
            font-size: 48px;
        }
    }

    .top-content {
        h2 {
            line-height: 44px;
            @include h4;
            margin-right: 8px;

            @include breakpoint(large) {
                @include h2;
                margin-right: 0;
            }
        }

        h4 {
            margin-bottom: 8px;
            font-family: $sans-serif;
            font-size: $f-size-xs;
            color: $black;
            line-height: 24px;
            font-weight: $f-weight-regular;
            margin-top: 15px;

            @include breakpoint(medium) {
                margin-top: 0;
            }

            @include breakpoint(large) {
                @include h4;
                color: $c-gray;
                margin-bottom: 8px;
            }
        }
    }

    .buy-buttons {
        border-bottom: 1px solid #B4B4B4;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .button {
            margin-bottom: 24px;

            &:first-child {
                margin-right: 10px;
            }
        }

        &.tablet {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }

            @include breakpoint(large) {
                display: none;
            }
        }

        @include breakpoint(medium) {
            display: none;
        }

        @include breakpoint(large) {
            display: block;

            .button:first-child {
                margin-right: 19px;
            }
        }


        @media (max-width: 360px) { // yes.... I need this
            .button {
                padding: 6px 21px;
            }
        }
    }

    .primary-info {
        h4 {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    .secondary-buttons {
        h4 {
            @include breakpoint(medium) {
                display: none;
            }

            @include breakpoint(large) {
                display: block;
            }
        }
    }

    .hide-tablet {
        display: none;

        @include breakpoint(xlarge) {
            display: block;
        }
    }

    .liner-notes {
        h6 {
            @include font-source($sans-serif, $f-size, $black, 24px, $f-weight-bold);
        }
    }

    .release-cover {
        margin-top: -80px;
        display: none;

        .thumb-link {
            position: relative;
        }

        .spotify-wrapper,
        .deezer-wrapper,
        .apple-wrapper,
        .youtube-wrapper {
            @include position(absolute, 0 0 0 0);
            border: 16px solid $white;

            iframe {
                height: 100% !important;
                width: 100% !important;
            }
        }

        @include breakpoint(large) {
            display: block;
        }
    }

    .release-cover-mobile {
        display: block;
        margin-top: -48px;

        @include breakpoint(large) {
            display: none;
        }
    }

    .listen-on {
        border-bottom: 1px solid #B4B4B4;
        padding: 18px 0 11px 0;

        @include has(buttons) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            .button {
                margin: 0 10px 16px;
            }

            @include breakpoint(medium) {
                display: block;
                margin-left: -10px;
            }

            @include breakpoint(large) {
                display: flex;
                justify-content: flex-start;
            }
        }

        .listen-wrapper {
            @include breakpoint(medium) {
                display: flex;
            }
        }

        .spotify-wrapper,
        .deezer-wrapper,
        .apple-wrapper,
        .youtube-wrapper {
            margin-bottom: 10px;

            iframe {
                margin: 0 auto;
                display: block;
            }

            @include breakpoint(medium) {
                width: 60%;
                order: 2;
            }

            @include breakpoint(large) {
                width: 100%;
            }
        }

        h5 {
            font-family: $sans-serif;
            font-size: $f-size;
            line-height: 24px;
            font-weight: $f-weight-bold;
            margin-bottom: 9px;
        }
    }

    .setlist {
        margin-top: 6px;

        @include breakpoint(large){
            margin-top: 0;
        }

        ul {
            margin-top: 3px;

            @include breakpoint(large){
                margin-top: 8px;
            }
        }

        h5 {
            position: relative;
            @include font-source($f-body, $f-size-xs, $black, 24px, $f-weight-bold);
            margin-top: 18px;
            padding-right: 35px;

            &:first-child {
                margin-top: 0;
            }

            &:after {
                @include svg-view-icon-expand;
                @include position(absolute, 50% 0 null null);
                transform: translateY(-50%);
            }

            &.expanded:after {
                @include svg-view-icon-collapse;
            }

            @include breakpoint(large) {
                font-size: $f-size;
                margin-top: 39px;
            }
        }

        .play-audio-clip {
            margin-left: 24px;
        }

        .setNum {
            bottom: 0;
            top: 8px;
        }

        a.songName {
            display: inline-block;
            width: auto;
            max-width: 56%;
            vertical-align: top;

            @include breakpoint(small) {
                max-width: 60%;
            }
        }

        .song-link {
            display: inline-block;
            vertical-align: middle;
            margin-left: 26px;
            margin-bottom: 3px;

            &:after {
                @include svg-view-lyrics;
            }

        }
    }

    .bottom-content {

        h2 {
            line-height: 29px;
        }
    }

    .performances-wrapper {
        background: $c-mostly-white;
        padding-bottom: 44px;

        @include breakpoint(large) {
            padding-bottom: 70px;
        }

        h3 {
            margin: 1px 0 4px 0;

            @include breakpoint(large) {
                margin: 24px 0 14px 0;
            }
        }

        p.subtitle {
            font-size: 12px;
            margin: 0 0 5px;

            @include breakpoint(large) {
                font-size: $f-size-xs;
                line-height: 24px;
            }
        }

        .num-performances {
            margin: 20px 0 27px 0;

            @include breakpoint(medium) {
                margin-top: 1px;
            }

            @include breakpoint(large) {
                margin-top: 27px;
            }

            h1 {
                font-family: $sans-serif;
                font-weight: $f-weight-bold;
                font-size: 72px;
                line-height: 56px;
            }
        }

        .first-performance, .last-performance {
            float: right;
            width: calc(50% - 8px);

            @include breakpoint(medium) {
                float: none;
                width: 100%;
            }

            p.subtitle {
                margin-bottom: 4px;
            }

            .location {
                font-size: $f-size-xs;
                line-height: 20px;

                @include breakpoint(large) {
                    font-weight: $f-weight-bold;
                    font-size: $f-size;
                }
            }

            h4 {
                font-family: $sans-serif;
                font-weight: $f-weight-bold;
                line-height: 24px;
                font-size: $f-size;

                @include breakpoint(large) {
                    @include h4;
                    font-family: $sans-serif;
                    font-weight: $f-weight-bold;
                    line-height: 28px;
                }
            }
            a {
                color: $black;
            }
        }

        .first-performance {
            float: left;

            @include breakpoint(medium) {
                margin-bottom: 25px;
            }
        }

        .shows-list {

            .col {
                padding-top: 6px; //yes we need to overwrite
            }

            .show {
                border-color: $c-gray;
                padding-bottom: 10px;
                padding-left: 8px;

                .show-date-venue {
                    font-size: $f-size-xs;
                    width: 100%;

                    p {
                        color: $black;
                    }

                    .date-numbers {
                        flex: 0 0 20%;

                        @include breakpoint(medium) {
                            flex: 0 0 33%;
                        }
                    }

                    .venue {
                        margin-bottom: 0;
                        margin-right: 14px;
                        flex: 0 0 34%;

                        .venue-name {
                            white-space: initial;
                        }
                    }

                    .venue-name {
                        margin: 0;
                    }
                }
            }
        }
    }

    .song-page {

        .infinite-scroll-placeholder {
            margin-top: 20px;

            @include breakpoint(large) {
                margin: 0;
            }
        }

        .lyrics {
            font-size: $f-size-xs;

            pre {
                font-family: $sans-serif;
                font-size: $f-size-xs;
                margin-top: 23px;

                @include breakpoint(large) {
                    margin-top: 40px;
                    font-size: $f-size;
                    padding-bottom: 50px;
                }
            }

            @include breakpoint(medium) {
                font-size: $f-size;
                padding-bottom: 50px;
            }
        }

        h2 {
            @include h4;
            position: relative;
            display: inline-block;
            padding-right: 40px;

            @include breakpoint(large) {
                @include h2;
            }

            .play-audio-clip {
                @include position(absolute, 50% 0 null null);
                transform: translateY(-50%);
            }
        }

        h2 + h4 {
            margin-bottom: 8px;
            font-family: $sans-serif;
            font-size: $f-size-xs;
            color: $black;
            line-height: 24px;
            font-weight: $f-weight-regular;

            @include breakpoint(large) {
                @include h4;
                color: $c-gray;
                margin-bottom: 8px;
            }
        }

        .main-video {
            margin-top: 16px;

            @include breakpoint(medium) {
                display: none;
                margin-top: -48px;
            }

            @include breakpoint(medium) {
                margin-top: -80px;
            }

            @include variant(desktop) {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        .related-releases, .related-videos {
            margin-bottom: 42px;

            h3 {
                margin: 20px 0 14px;

                @include breakpoint(medium) {
                    text-align: left;
                    margin: 39px 0 14px;
                }
            }
        }

        .related-releases .release-list {
            margin: -13px -8px 40px -8px;

            a {
                display: block;
            }

            .col {
                padding: 16px 8px 0 8px;
            }
        }
    }
}

.infinite-scroll-placeholder {
    transition: none;

    &.infinite-scroll-loading {
        background: url('../images/loading.svg') center center no-repeat;
        background-size: 18px;
        background-position: 97% center;
    }
}

.pt_songlanding {
    .search-title {
        padding-bottom: 28px;
        @include breakpoint(medium) {
            padding-bottom: 12px;
        }
    }

    .title-container {
        border-bottom: 1px solid $c-black;
        display: flex;
            justify-content: space-between;

        .grid-list-tab {
            align-self: center;
        }

        .list-view-icon,
        svg {
            display: block;
            height: 32px;
            width: 88px;
        }
    }

    .grid-view {
        display: none;
    }

    &.grid-view-active {
        .grid-view {
            display: block;
        }

        .list-view {
            display: none !important;
        }

        ul.song-list {
            li {
                .song-letter {
                    border: 0;

                    .song-letter-item {
                        @include visually-hidden;
                    }
                }
            }
        }

        .song-list {
            .song-list-songs {
                column-count: 1;

                li {
                    break-inside: initial;
                    width: 100%;
                    padding-bottom: 0;
                }
            }
        }

        .grid-view-main-title {
            display: block;
            width: 100%;

            margin-bottom: 16px;
            @include breakpoint(large) {
                margin-bottom: 24px;
            }

            h2 {
                font-size: 40px;
                @include breakpoint(large) {
                    font-size: 48px;
                }
            }
        }

        .grid-view-main-description {
            display: flex;
                justify-content: space-between;
            border-bottom: 1px solid $black;
            column-count: 1;
            margin-bottom: 20px;
            padding-bottom: 4px;
            width: 100%;

            @include breakpoint(large) {
                margin-bottom: 40px;
            }

            .grid-view-main-data {
                flex: 0 0 80%;
                margin-right: 3%;
                width: 80%;

                @include breakpoint(large) {
                    flex: 0 0 87%;
                    width: 87%;
                    display: flex;
                        justify-content: space-between;
                }

                .grid-view-main-data-info {
                    @include breakpoint(large) {
                        flex: 0 0 30%;
                        width: 30%;
                    }

                    .grid-view-title {
                        color: $c-charcoal;
                        font-size: 13px;
                        font-weight: $f-weight-bold;
                        line-height: 1.08;
                        letter-spacing: 2.5px;
                        margin-bottom: 6px;
                        text-transform: uppercase;

                        @include breakpoint(large) {
                            font-size: 15px;
                            line-height: 1;
                            letter-spacing: 3px;
                            margin-bottom: 10px;
                        }
                    }
                    
                    .grid-view-data {
                        .grid-view-data-link {
                            display: inline-block;
                            color: $black;
                            font-size: 20px;
                            font-weight: $f-weight-bold;
                            line-height: 1.1;
                            letter-spacing: 0.5px;
                            margin: 0 0 16px;
                            padding: 0;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .grid-view-main-image {
                flex: 0 0 17%;
                    align-self: flex-end;
                padding-bottom: 16px;
                text-align: right;
                width: 17%;

                @include breakpoint(large) {
                    flex: 0 0 10%;
                    width: 10%
                }

                img {
                    width: 60px;
                }
            }
        }
    }

    .c-songs {
        padding: 0 20px;
        .c-songs-nav {
            display: flex;
            justify-content: flex-start;
        }

        .c-amp-category-nav__control {
            display: flex;
            @include breakpoint(large) {
                display: none;
            }

            &::before {
                content: '';
                height: 13px;
                transform: rotate(45deg);
                width: 13px;
            }
    
            &:hover:not(.disabled):not(:disabled) {
                background-color: transparent;
                border: none;
            }
        }

        &:not(.has-scrolled-right) {
            &::before {
                right: 0;
                background: linear-gradient(to left, white, transparent);
            }

            .c-amp-category-nav__control--scroll-left {
                display: none;
            }
        }

        &.has-scrolled-right {
            &::before {
                left: 0;
                background: linear-gradient(to right, white, transparent);
            }

            .c-amp-category-nav {
                &__control--scroll-right {
                    display: none;
                }

                &__categories {
                    margin-left: 0;
                    margin-right: -17px;

                    @include breakpoint(medium) {
                        margin-right: -25px;
                    }
                }
            }
        }
    }
    
    ul.song-list {
        padding: 0;

        li {
            display: block;
            margin-bottom: 6px;
            position: relative;
                z-index: 0;

            a:not(.h2) {
                color: $c-charcoal;
                display: inline-block;
                font-size: 13px;
                font-weight: $f-weight-bold;
                letter-spacing: 2.5px;
                line-height: 1.08;
                padding: 8px 0;
                text-transform: uppercase;
                vertical-align: top;

                @include breakpoint(medium) {
                    font-size: 15px;
                    letter-spacing: 3px;
                    line-height: 1;
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
            }

            .accent {
                position: absolute;
                    left: -40px;
                    top: -10px;
                    z-index: -1;

                @include breakpoint(medium) {
                    top: -30px;
                }
                @include breakpoint(large) {
                    left: -65px;
                    top: -50px;
                }
            }

            .song-letter {
                border-bottom: 1px solid $black;
                margin-bottom: 16px;
                margin-top: 44px;
                padding-bottom: 12px;

                @include breakpoint(large){
                    padding-bottom: 8px;
                    margin: 100px 0 20px 0;
                }
            }

            .song-list-songs {
                column-count: 2;
                padding: 0;

                li {
                    break-inside: avoid-column; // Prevent padding from wrapping on Safari
                    margin-bottom: 0; // While we want a margin, use padding to avoid Safari but related to column-count
                    padding-bottom: 8px; 
                    padding-right: 24px;

                    @include breakpoint(medium) {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

.release {
    &-category {
        border: none;
        padding-left: 9px;
        padding-right: 9px;
    
        &:hover:not(.disabled):not(:disabled) {
            background-color: transparent;
            color: $c-black;
        }
    
        &.selected,
        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    }

    &-nav-mobile {
        background-color: $c-black;
        border-color: $c-black;
        color: $white;
        font-size: 15px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        margin-bottom: 10px;
        padding-right: 16px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include bp(max-width, $bp-large) {
            min-width: 330px; 
        }

        @include bp(max-width, 374px) {
            font-size: 12px;
            min-width: initial;
        }
    }
}