.svg-album-arrow-left-dims {
	width: 14px;
	height: 24px;
}

.svg-album-arrow-right-dims {
	width: 14px;
	height: 24px;
}

.svg-apple-dims {
	width: 18px;
	height: 20px;
}

.svg-apple-follow-dims {
	width: 18px;
	height: 20px;
}

.svg-apple-follow-white-dims {
	width: 12px;
	height: 14px;
}

.svg-arrow-next-dims {
	width: 6px;
	height: 10px;
}

.svg-arrow-next-white-dims {
	width: 14px;
	height: 24px;
}

.svg-arrow-prev-dims {
	width: 6px;
	height: 10px;
}

.svg-arrow-prev-white-dims {
	width: 14px;
	height: 24px;
}

.svg-arrow-right-dims {
	width: 16px;
	height: 12px;
}

.svg-arrow-right-white-dims {
	width: 16px;
	height: 12px;
}

.svg-audio-dims {
	width: 20px;
	height: 20px;
}

.svg-audio-icon-dims {
	width: 20px;
	height: 20px;
}

.svg-awmh-dims {
	width: 24px;
	height: 12px;
}

.svg-awmh-white-dims {
	width: 24px;
	height: 12px;
}

.svg-breadcrumb-dims {
	width: 5px;
	height: 8px;
}

.svg-btn_youtube-dims {
	width: 132px;
	height: 92px;
}

.svg-btn_youtube_mobile-dims {
	width: 66px;
	height: 46px;
}

.svg-camera-dims {
	width: 19px;
	height: 16px;
}

.svg-cart-dims {
	width: 16px;
	height: 16px;
}

.svg-cart-white-dims {
	width: 16px;
	height: 16px;
}

.svg-check-dims {
	width: 8px;
	height: 6px;
}

.svg-checkbox-check-dims {
	width: 12px;
	height: 9px;
}

.svg-checkbox-rollover-graphic-dims {
	width: 22px;
	height: 22px;
}

.svg-circle-ellipse-white-dims {
	width: 20px;
	height: 20px;
}

.svg-circle-open-white-dims {
	width: 20px;
	height: 20px;
}

.svg-close-dims {
	width: 12px;
	height: 12px;
}

.svg-close-gray-dims {
	width: 12px;
	height: 12px;
}

.svg-close-white-dims {
	width: 12px;
	height: 12px;
}

.svg-close-white-v2-dims {
	width: 18px;
	height: 18px;
}

.svg-dialog-close-dims {
	width: 20px;
	height: 20px;
}

.svg-divider-dims {
	width: 2px;
	height: 25px;
}

.svg-empty-star-dims {
	width: 12px;
	height: 12px;
}

.svg-facebook-dims {
	width: 20px;
	height: 20px;
}

.svg-facebook-follow-dims {
	width: 20px;
	height: 20px;
}

.svg-facebook-follow-white-dims {
	width: 6px;
	height: 12px;
}

.svg-fifth-member-dims {
	width: 20px;
	height: 24px;
}

.svg-fifth-member-all-white-dims {
	width: 20px;
	height: 20px;
}

.svg-fifth-member-white-dims {
	width: 20px;
	height: 24px;
}

.svg-first-dims {
	width: 12px;
	height: 10px;
}

.svg-full-star-dims {
	width: 12px;
	height: 12px;
}

.svg-grid-dims {
	width: 18px;
	height: 18px;
}

.svg-grid-view-dims {
	width: 88px;
	height: 32px;
}

.svg-half-full-star-dims {
	width: 12px;
	height: 12px;
}

.svg-half-star-dims {
	width: 6px;
	height: 12px;
}

.svg-icon-bag-dims {
	width: 16px;
	height: 18px;
}

.svg-icon-collapse-dims {
	width: 14px;
	height: 8px;
}

.svg-icon-expand-dims {
	width: 14px;
	height: 8px;
}

.svg-icon-plus-dims {
	width: 8px;
	height: 8px;
}

.svg-input-error-dims {
	width: 18px;
	height: 18px;
}

.svg-input-success-dims {
	width: 14px;
	height: 10px;
}

.svg-instagram-dims {
	width: 20px;
	height: 20px;
}

.svg-instagram-follow-dims {
	width: 20px;
	height: 20px;
}

.svg-instagram-follow-white-dims {
	width: 12px;
	height: 12px;
}

.svg-last-dims {
	width: 12px;
	height: 10px;
}

.svg-linkedin-follow-dims {
	width: 16px;
	height: 16px;
}

.svg-list-dims {
	width: 18px;
	height: 18px;
}

.svg-list-view-dims {
	width: 88px;
	height: 32px;
}

.svg-location-marker-dims {
	width: 10px;
	height: 14px;
}

.svg-logo-dims {
	width: 270px;
	height: 92px;
}

.svg-lyrics-dims {
	width: 12px;
	height: 16px;
}

.svg-m-logo-dims {
	width: 400px;
	height: 400px;
}

.svg-mag-minus-gray-dims {
	width: 16px;
	height: 16px;
}

.svg-mag-minus-white-dims {
	width: 16px;
	height: 16px;
}

.svg-mag-plus-gray-dims {
	width: 16px;
	height: 16px;
}

.svg-mag-plus-white-dims {
	width: 16px;
	height: 16px;
}

.svg-mail-dims {
	width: 20px;
	height: 16px;
}

.svg-media-carousel-next-dims {
	width: 14px;
	height: 24px;
}

.svg-media-carousel-prev-dims {
	width: 14px;
	height: 24px;
}

.svg-media-play-button-white-dims {
	width: 64px;
	height: 64px;
}

.svg-menu-dims {
	width: 16px;
	height: 14px;
}

.svg-menu-back-dims {
	width: 8px;
	height: 14px;
}

.svg-menu-carrot-dims {
	width: 8px;
	height: 14px;
}

.svg-menu-search-dims {
	width: 28px;
	height: 16px;
}

.svg-menu-white-dims {
	width: 16px;
	height: 14px;
}

.svg-nav-left-dims {
	width: 8px;
	height: 14px;
}

.svg-navigate-left-dims {
	width: 4px;
	height: 7px;
}

.svg-navigate-left-medium-dims {
	width: 7px;
	height: 11px;
}

.svg-navigate-right-dims {
	width: 4px;
	height: 7px;
}

.svg-navigate-right-medium-dims {
	width: 6px;
	height: 11px;
}

.svg-pause-dims {
	width: 12px;
	height: 14px;
}

.svg-pause-white-dims {
	width: 12px;
	height: 14px;
}

.svg-pinterest-follow-white-dims {
	width: 10px;
	height: 12px;
}

.svg-play-dims {
	width: 10px;
	height: 14px;
}

.svg-play-white-dims {
	width: 10px;
	height: 14px;
}

.svg-rewind-gray-dims {
	width: 14px;
	height: 14px;
}

.svg-rewind-white-dims {
	width: 14px;
	height: 14px;
}

.svg-search-dims {
	width: 16px;
	height: 16px;
}

.svg-search-white-dims {
	width: 16px;
	height: 16px;
}

.svg-slider-next-dims {
	width: 15px;
	height: 24px;
}

.svg-slider-next-timeline-dims {
	width: 4px;
	height: 7px;
}

.svg-slider-next-white-dims {
	width: 4px;
	height: 7px;
}

.svg-slider-prev-dims {
	width: 15px;
	height: 24px;
}

.svg-slider-prev-timeline-dims {
	width: 4px;
	height: 7px;
}

.svg-slider-prev-white-dims {
	width: 4px;
	height: 7px;
}

.svg-snapchat-follow-dims {
	width: 12px;
	height: 12px;
}

.svg-snapchat-follow-white-dims {
	width: 12px;
	height: 12px;
}

.svg-soundcloud-follow-dims {
	width: 14px;
	height: 8px;
}

.svg-soundcloud-follow-white-dims {
	width: 14px;
	height: 8px;
}

.svg-spotify-dims {
	width: 20px;
	height: 20px;
}

.svg-spotify-follow-dims {
	width: 20px;
	height: 20px;
}

.svg-spotify-follow-white-dims {
	width: 14px;
	height: 10px;
}

.svg-tab-rollover-1-dims {
	width: 210px;
	height: 28px;
}

.svg-tab-rollover-2-dims {
	width: 53px;
	height: 55px;
}

.svg-tab-rollover-3-dims {
	width: 209px;
	height: 31px;
}

.svg-twitter-dims {
	width: 20px;
	height: 17px;
}

.svg-twitter-follow-dims {
	width: 20px;
	height: 17px;
}

.svg-twitter-follow-white-dims {
	width: 12px;
	height: 10px;
}

.svg-ul-dash-dims {
	width: 5px;
	height: 3px;
}

.svg-ul-disc-dims {
	width: 4px;
	height: 4px;
}

.svg-user-dims {
	width: 17px;
	height: 17px;
}

.svg-user-white-dims {
	width: 16px;
	height: 16px;
}

.svg-utility-icon-dims {
	width: 20px;
	height: 20px;
}

.svg-youtube-dims {
	width: 20px;
	height: 14px;
}

.svg-youtube-follow-dims {
	width: 20px;
	height: 14px;
}

.svg-youtube-follow-white-dims {
	width: 12px;
	height: 8px;
}

