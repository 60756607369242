.pt_cart, .pt_checkout {
    .primary-content {
        margin-top: $checkout-progress-margin-top-small;

        @include breakpoint(large) {
            margin-top: $checkout-progress-margin-top-large;
        }
    }

    fieldset.pay-now {
        margin-bottom: 20px;
    }
}

.checkoutmultishipping {
    th {
        text-align: center;
    }

    .item-list {
        @include responsive-table;
    }

    @include bp(max-width, $bp-small) {
        .item-list .shipment-headers {
            display: none;
        }

        .ba-select-container {
            margin-top: 16px;
        }
    }
}

.checkout-progress-indicator {
    color: $c-medium-gray;
    border-bottom: 1px solid $c-body-color;
    font-size: 10px;
    font-weight: $checkout-progress-f-weight;
    overflow: hidden;
    padding: 15px 0;
    text-align: center;
    margin: 0;

    @include breakpoint(large) {
        padding: 20px 0;
    }

    div {
        display: inline;
        text-transform: uppercase;

        &.active a {
            color: $white;
        }

        &.inactive a {
            color: $white;
        }
    }

    span.progress-bar {
        color: $c-medium-gray;
        font-size: 10px;
        font-weight: $f-weight-bold;
        line-height: 1;
        letter-spacing: 1px;
        margin: 0 20px;
    }

    .active {
        color: $c-body-color;
    }

    a {
        color: $c-medium-gray;
    }

    i {
        padding-left: .83em;
        color: $very-light-gray;
    }

    .step-3 {
        padding-right: 0;
    }
}

.order-shipment-gift-message-header {
    font-weight: $f-weight-bold;
    font-size: 1.2em;
    padding-bottom: 0px;
}

.order-shipment-gift-message {
    font-size: 1.1em;
}

.gift-message-text {
    overflow: visible;
    opacity: 1;
    transition: all 1s ease;
    float: left !important;

    &.hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
    }

    textarea {
        width: 100%;
    }
}


.redemption {
    color: $sangria;

    .success {
        color: $islamic-green;
    }

    span.error {
        margin-left: 0;
    }
}

.payment-options {
    padding-bottom: 15px;

    h3 {
        @include breakpoint(xlarge) {
            padding-bottom: 13px;
        }
    }
}

.payment-method-options {
    padding-top: 3px;

    .form-row {
        display: block;
        margin-bottom: 9px;
        
        &:not(:first-child) {
            padding-top: 12px;

            @include breakpoint(medium) {
                padding-top: 0;
            }
        }

        &.apple-pay {
            display: none;

            button {
                background-image: url('../images/type-applepay.svg');
                background-repeat: no-repeat;
                border-radius: 0;
                margin-left: 20px;
                min-width: initial;
                min-height: initial;
                -webkit-appearance: none;
                
                &:not(.payment-logo) {
                    background-size: 100% 100%;
                    height: 27px;
                    margin-top: 0;
                    width: 44px;
                }

                &.payment-logo {
                    background-color: transparent;
                    background-size: cover;
                    border: none;
                    margin: 0 0 0 20px;

                    &:hover {
                        margin: 0 0 0 20px;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            clear: none;
            float: left;
            width: 100%;
        }

        @include breakpoint(large) {
            width: auto;
            margin-right: MIN(12%, 62px); // Use all caps so SCSS doesn't compile as function
            margin-bottom: 0;

            &:first-child {
                margin-left: 0;
            }
        }

        .input-radio {
            margin-bottom: 4px !important;
            margin-right: 0;
        }

        label {
            padding-left: 8px;
        }
    }
}

.payment-method {
    clear: both;
    display: none;

    .form-row {
        @include breakpoint(large) {
            vertical-align: top;
            display: inline-block;
            margin-right: 20px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 0;
            margin-right: 52px;
        }
    }

    .cctype {

        @include breakpoint(large) {
            display: inline-block;
        }

        .select-wrapper {
            width: 210px;

            select {
                width: 210px;

                &:after {
                    right: 0;
                }
            }
        }

        .form-row {
            @include breakpoint(large) {
                margin-right: 0;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 0;
            }
        }
    }

    .cctoken {
        .form-row {
            margin: 0;
        }
    }

    input#dwfrm_billing_paymentMethods_creditCard_number {
        margin-right: 0;
        width: 100%;
        @include breakpoint(large) {
            width: 265px;
            }
        }
    }

    .month, .year {
        width: 45%;
        @include breakpoint(large){
            width: 88px;
        }
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 0;
        vertical-align: top;

        label {
            display: none;
        }

        .required-indicator {
            display: none;
        }

        @include breakpoint(large) {
            margin-right: 5px;
        }
    }

    .month {
        @include breakpoint(large){
        width: 125px;
        }
    }

    .cvn {
        input {
            width:46%;
            @include breakpoint(large) {
                width: 90px;
            }
        }
        label {
            @include breakpoint(large) {
                margin-left: 0;
            }
        }
    }

.payment-method-expanded,.vinylclub-renew {
    display: block;
    @include clearfix;
    max-width: 600px;

    .form-expiration {
        .form-row {
            float: left;
            width: calc(50% - 12px);

            &:nth-child(even) {
                float: right;
            }
        }
    }

    &.for-mobile {
        display: inline-block;
        margin:0;
        #paypal-button-container {
            margin: 15px 0 0;
        }
    }

    @include breakpoint(medium) {
        .form-expiration {
            float: right;
            width: calc(50% - 12px);
        }

        .form-row {

            &:nth-child(odd) {
                clear: both;
            }

            &.form-row--no-float {
                float: none;
                clear: both;
            }

            &.form-row-button {
                float: right;
                clear: both;
                text-align: right;
            }
        }
    }

    &[data-method="DW_APPLE_PAY"],
    &[data-method="Custom"] {
        .dw-apple-pay-button,
        #paypal-button-container {
            margin: 0 0 10px 40px;
        }
    }
}

.button-float-right {
    float: right;
}

.pt_checkout h3 {
    padding-bottom: 11px;
    font-size: 24px;
    line-height: 32px;

    @include breakpoint(large) {
        padding-bottom: 7px;
        font-size: 32px;
        line-height: 40px;
    }
    &.billing-address-header {
        padding:0;
    }
    &.gift-card-header {
        padding:0;
    }
}

.pt_checkout .nav {
    margin-top: 12px;
    margin-bottom: -21px;

    @include breakpoint(medium) {
        margin-top: 64px;
        padding-top: 21px;
        background: transparent;
    }

    @include breakpoint(large) {
        margin-top: 91px;
    }

    &__container {
        position: relative;
        z-index: 1;
        padding-top: 13px;
        padding-bottom: 1px;

        &:after {
            @include position(absolute, 0 50% null 50%);
            transform: translateX(-50%);
            content: "";
            background: $white;
            width: calc(100% + 36px);
            height: 100%;
            z-index: -1;

            @include breakpoint(medium) {
                width: 100%;
            }
        }

        > .section-header.section-header {
            padding-top: 5px;

            @include breakpoint(medium) {
                padding-top: 7px;
                padding-bottom: 8px;
            }

            @include breakpoint(large) {
                padding-top: 12px;
            }
        }

        @include breakpoint(medium) {
            padding: 8px 13px 0 16px;
        }

        @include breakpoint(large) {
            padding: 5px 13px 0 16px;
        }
    }

    .section-header {
        padding: $checkout-minisummary-header-padding;
        border-bottom: $checkout-minisummary-header-border;
        margin: 0;
        color: $c-gray;

        .section-header-note {
            font-size: $checkout-minisummary-header-edit-f-size;
            color: $c-red;
            font-family: $sans-serif;
            line-height: 39px;
        }
    }
}

.checkout-mini-cart {
    overflow: auto;
    padding: $checkout-minisummary-padding;

    @include breakpoint(medium) {
        padding-top: 21px;
        padding-bottom: 15px;
    }

    .mini-cart-product {
        padding: 0;
        padding: $checkout-minisummary-pli-margin;
        display: flex;
        border-bottom: none;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        .mini-cart-image {
            float: none;
        }

        .mini-cart-attributes .attribute {
            line-height: 24px;
        }

        .mini-cart-quantity {
            display: inline-block;
            padding-right: 20px;

            .label {
                display: inline-block;
                color: $c-gray;
            }

            .value {
                display: inline-block;
                font-weight: $f-weight-regular;
            }
        }
    }
}

table.item-list .section-header,
.order-shipment-table .section-header {
    background-image: none;
}

.checkout-order-totals {
    padding: $checkout-totals-table-padding;

    .order-totals-table td:nth-of-type(2) {
        width: 20%;
        @include breakpoint(medium) {
            padding-left: 21px;
        }

        @include breakpoint(large) {
            padding-left: 0;
        }
    }

    .order-totals-table td:first-child {
        text-align: left;

        @include breakpoint(large) {
            padding-right: 32px;
        }
    }

    .order-totals-table {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        &-row {
            @include breakpoint(medium) {
                // Once container queries gain more traction, this should be moved onto the main style in _cart.scss and be applied that way
                gap: 28px;
            }
        }
    }
}

.checkout-mini-cart + .checkout-order-totals {
    border-top: $checkout-minisummary-header-border;
    padding: $checkout-totals-table-padding;
    margin: $checkout-totals-table-margin;

    @include breakpoint(medium) {
        padding: 18px 13px 24px 6px;;
    }
}

.order-component-block {
    .payment-amount {
        color: $c-gray;

        span {
            color: $black;
        }
    }

    .details {
        padding: $checkout-minisummary-details-padding;
    }

    &:last-of-type {
        border-bottom: 0;
    }

    .minishipments-method {
        span:first-of-type {
            color: $c-gray;
        }
    }
}

.place-order-totals {
    clear: both;

    .order-totals-table {
        grid-gap: 0 50px;
        @include breakpoint(large) {
            max-width: 383px;
        }
    }
}

.order-summary-footer {
    position: relative;
    padding-top: 20px;
    @include breakpoint(large) {
        padding-top: 30px;
    }

    h2 {
        font-size: 2em;
        font-style: italic;
        font-weight: normal;
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.order-discount {
    color: $sangria;
}

.submit-order {
    clear: both;
    display: block;
    text-align: center;

    .form-row {
        align-items: flex-start;
    }

    .button, button {
        display: inline-block;
        max-width: 383px;
        width: 100%;

        &.loading {
            transition: none;
            background: url('../images/loading.svg') center center no-repeat;
            background-color: transparent;
            background-size: 18px;
            background-position: 97% center;
            color: $c-black;
        }
    }

    .back-to-cart {
        display: inline-block;
        margin-right: 28px;
    }

    @include breakpoint(medium) {
        text-align: right;

        .back-to-cart {
            float: left;
            margin-bottom: 0;
        }
    }

    @include breakpoint(large) {
        padding-top: 13px;
    }

    @include breakpoint(xlarge) {
        padding-right: 10px;
    }
}

.gift-cert-used {
    padding: .83em 0;
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_cart .breadcrumb {
    padding-left: 0;
}

.item-shipping-address {
    min-width: 120px;
}

.billing-error {
    color: red;
    margin: 0 0 .83em 24%;
}

.ui-dialog #listings-nav {
    float: right;
    margin-top: 2%;

    a {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;

        &.active {
            font-weight: $f-weight-bold;
        }
    }
}

.cvn-tooltip {
    font-weight: $f-weight-bold;
    margin: 5px;
    text-align: center;

    img {
        height: 150px;
        width: 110px;
    }
}

#shipping-method-list {
    position: relative;
    &.shipping-method-loaded {
        padding-top: 18px;
        padding-bottom: 10px;
        min-height: 50px;

        @include breakpoint(large) {
            padding-bottom: 20px;
        }
    }

    @include breakpoint(large) {
        padding-top: 1px;
    }

    .shipping-method-container {
        display:inline-block;
        box-sizing:border-box;
        
        .shipping-method-header {
            display:inline-block;
            width:100%;
            border-bottom: 1px solid;
            margin-bottom:3%;
        }
        .shipping-method-products {
            margin-bottom:3%;
            span {
                display: block;
            }
        }
    }
    
    .normal-shipping-method {
        border: 1px solid;
        padding: 20px;
        width: 100%;
    }
    .combined-shipping-method {
        width:100%;
        padding-right:20px;
    }


    h3 {
        padding-bottom: 15px;

        @include breakpoint(large) {
            padding-bottom: 13px;
        }
    }

    .loader-indicator {
        transform: translate(-50%, -50%)
    }

    .shipping-method {
        clear:both;
        float: left;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(large) {
            display: block;
        }

        .field-wrapper {
            margin-bottom: 0;
            order: -1;
        }

        .shipping-promos {
            margin: 0;
        }

        label {
            display: inline-block;
            float: none !important;
            font-size: 16px;
            line-height: 24px;
            max-width: 85%;

            @include breakpoint(large) {
                padding-right: .42em !important;
            }
        }

        .shipping-description {
            display: inline;
        }

        .method-description {
            font-size: 16px;
            line-height: 24px;
            color: $c-gray;
            padding-left: 22px;
        }

        .tooltip {
            padding-left: 22px;
        }
    }
}

.pre-order-messaging {
    #preorder-acknowledgement-error {
        display: none;

        &:not([style*="display: none"]) {
            margin-left: 10px;
        }
    }

    .preorder-reminder {
        border: 1px solid $brand-primary;
        box-sizing: border-box;
        margin-bottom: 20px;
        max-width: 482px;
        padding: 20px;
        width: 100%;

        p {
            color: $brand-primary;
            margin-top: 0;
            font-size: 14px;
            line-height: 1.43;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .shipping-checkbox {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 10px;

        label {
            color: $c-charcoal;
            font-size: 14px; 
            line-height: 1.43;
            letter-spacing: normal;
            max-width: 85%;

            @include breakpoint(small) {
                max-width: 375px;
            }

            @include breakpoint(large) {
                max-width: 400px;
            }

            &[for="preorder-acknowledgement"] {
                margin-bottom: 10px;
            }
        }

        &:has(span.error:not([style*="display: none"])) {
            .field-wrapper {
                width: 100%;
            }

            label[for="preorder-acknowledgement"] {
                margin-left: 35px;
            }
        }

        &:has(span.error:not([style*="display: inline"])) {
            .field-wrapper {
                width: auto;
            }

            label[for="preorder-acknowledgement"] {
                margin-left: 10px;
            }
        }
    }

    .preorder-error-msg {
        color: $brand-error;
        font-size: 12px;
        font-weight: $f-weight-semibold;
        line-height: 1.17;
        letter-spacing: 1px;
        margin: 0 0 20px;
        max-width: 450px;
        padding-left: 35px;
        text-transform: uppercase;

        @include breakpoint(large) {
            padding-left: 25px;
        }
    }
}

.bml-terms-and-conditions {
    border: 1px solid $dark-gray;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;
}

.bml-table-col-1 {
    width: 25%;
}

.order-shipment-details {
    .label,
    .firstname,
    .lastname {
        font-weight: $f-weight-bold;
    }
}


.pt_order-confirmation#wrapper {
    background: $c-mostly-white;
    overflow: hidden;

    .top-orders {
        .orderdetails {
            display: none;
        }
    }

    .header {
        .mini-cart {
            display: none;
        }
    }

    #main {
        background-image: url('../images/bg-textured-mobile-white.png');
        background-size: 100% auto;
        position: relative;
        z-index: 0;
        
        @include breakpoint(large) {
            background-image: url('../images/bg-textured-white.png');
            background-size: 75% auto;
        }

        .confirmation {
            .orderdetails .order-information {
                padding-top: .5em;
            }

            .orderdetails-content {
                padding-bottom: 40px;
                @include breakpoint(large) {
                    padding-bottom: 0;
                }
        
                @include bp(max-width, $bp-large) {
                    border-bottom: 1px solid $brand-primary;
                    padding-bottom: 30px;
                }
            }

            .order-billing-shipping {
                background-color: $c-light-gray;
                justify-content: center;

                @include bp(max-width, $bp-large) {
                    padding: 0 27px;
                }

                &:has(.order-gift-message) {
                    @include breakpoint(xlarge) {
                        padding: 0;
                    }
                }
                
                .row {
                    @include breakpoint(large) {
                        margin: 0 auto auto 10px;
                    }
                }
            }
        }
    }

    .top-banner {
        background: none;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .header {
            display: flex;
            justify-content: space-around;
        }
    }

    .slot-grid-header {
        .html-slot-container {
            background-color: initial;

            img {
                min-height: 450px;

                @include breakpoint(large) {
                    min-height: 550px;
                }
            }
        }
    }

    .confirmation-message {
        color: $white;
        margin-bottom: 0;
        padding-bottom: 1.67em;
        padding-top: 20px;
        position: absolute;
        top: 100px;
        left: 0;
        z-index: 2;
        text-align: center;
        width: 100%;
        @include breakpoint(large) {
            padding-top: 50px;
        }

        h1 {
            color: $white;
            font-family: $f-body;
            font-weight: $f-weight-bold;
            font-size: 32px;
            line-height: 1.13;
            letter-spacing: 0.5px;
            margin-bottom: 5px;

            @include breakpoint(large) {
                font-size: 48px;
                line-height: 1.13;
                letter-spacing: 1px;
            }
        }

        p {
            font-size: 16px;
            font-weight: $f-weight-semibold;
            line-height: 1.5;
            margin: 0 0 20px;
            padding: 0;

            @include breakpoint(large) {
                margin-bottom: 30px;
            }

            a {
                color: $white;
            }
        }

        .action-buttons {
            margin-bottom: 0;
            padding: 0;
            .button {
                background: none;
                border-color: $white;
                color: $white;
                width: auto;
            }
        }
    }

    .order-shipment-table {
        margin-bottom: 0;

        .order-shipment-details {
            min-width: 200px;
        }
    }

    .order-confirmation-details {
        padding-top: 10px;

        p.order-number {
            display: none;
        }
        .orderdetails {
            margin-top: 2.5em;

            @include breakpoint(large) {
                margin-top: 55px;
                position: relative;
                    z-index: 22;

                & > .row {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 0;
                    max-width: $bp-max-content-width;;
                    padding: 0 $l-small-padding;

                    @include breakpoint(medium) {
                        padding: 0 $l-medium-padding;
                    }
                    @include breakpoint(large) {
                        padding: 0 $l-large-padding;
                    }
                    @include breakpoint(xlarge) {
                        padding: 0 $l-xlarge-padding;
                    }
                }

                .order-information {
                    padding-bottom: 0;
                }
            }

            &:has(.order-gift-message) {
                & > .row {
                    @include breakpoint(large) {
                        padding: 0;
                    }
                }
            }

            & > .row {
                margin-top: 0;
                padding: 0 30px;

                @include breakpoint(large) {
                    padding: 0;
                }
            }

            .order-billing-shipping-content {
                .order-shipments {
                    @include breakpoint(large) {
                        padding-left: 0;
                    }
                }
            }
        }

        .line-item-attributes {
            .product-availability-list {
                .on-order:first-child {
                    color: $c-gray;
                }
            }
        }

        .product-availability-info {
            display: none;
        }
    }

    .order-payment-instruments,
    .order-downloads {
        .section-header {
            margin-top: 7px;
            width: 100%;

            @include breakpoint(medium) {
                margin-top: 0;
                padding-bottom: 3px;
            }
        }

        &>.label {
            display: none;
        }
    }

    .order-payment-summary {

        .section-header {
            @include breakpoint(medium) {
                padding-bottom: 3px;
            }
        }

        .order-detail-summary {
            @include breakpoint(medium) {
                padding-right: 18px;
            }
        }

        .order-totals-table {
            .order-subtotal td, .order-total td {
                font-weight: $f-weight-bold;
            }

            tr td:first-child {
                text-align: left;
            }
        }
    }

    #primary h2 {
        font-size: 2em;
        font-style: italic;
        font-weight: normal;
    }

    .print-page {
        float: right;
    }
    .order-history-h1-subtext {
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: normal;
    }
    .c-banner {
        .cat-banner img{
            min-height: 450px;
        }
    }
}

.order-detail-options {
    border-top: 1px solid $gainsboro;
    margin: 1.67em 0 0;
    padding: 1.67em 0 0;
    text-align: right;

    a {
        margin: 0 .83em;
    }
}

.pt_order-confirmation .order-confirmation-details .content-header {
    margin: .6em 0 !important;
}

.multishipaddressestable {
    margin: 9px 0px 20px 0px;
    width: 706px;
}

.multi-ship-shipments-table {
    margin: 0 0 20px;
    width: 706px;

    .section-header {
        font-size: 10px;
        font-weight: $f-weight-bold;
        padding: 9px 6px 11px;
    }

    .shipping-address .details {
        padding: 0 10px 0 22px;
    }

    .gift-options {
        label {
            float: left;
            padding: 0;
        }

        input {
            margin: 0 10px;
        }
    }
}

.multi-shipping-addresses {
    margin-bottom: 20px !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    .shipping-address {
        text-align: right;

        select {
            margin: 4px 0 0;
            width: 260px;
        }
    }
}

.header-product-shipping {
    width: 276px;
}

.header-qty-shipping {
    width: 45px;
}

.header-location-shipping {
    text-indent: 70px;
    width: 307px;
}

.header-shipment-index {
    width: 409px;
}

.header-shipment-qty {
    width: 27px;
}

.header-shipment-details {
    text-indent: 22px;
}

.multi-shipping-methods {
    position: absolute;
    top: 30px;

    .shipping-methods {
        .form-row {
            clear: none;
            float: left;
            display: none;
        }

        select {
            width: 250px;
            margin: -5px 0 0 15px;
        }
    }
}

.multi-shipping-option {
    border-bottom: 2px dotted $nobel;
    left: 0;
    padding: 0 0 12px;
    position: absolute;
    text-indent: 10px;
    top: 48px;
    width: 95%;
}

.multi-shipping-addresses .shipping-qty,
.multi-ship-shipments-table .shipping-qty {
    background: none repeat scroll 0 0 $white-smoke;
}

.multiship .label {
    font-weight: $f-weight-bold;
}

.hide {
    display: none;
}

.loading {
    background: url("../images/loading-progress-98x22.gif") no-repeat scroll left center transparent;
}

.instore-shipping-option {
    width: 100%;
}

legend {
    padding-top: 0;
}

.checkoutlogin-header {

    @include breakpoint(medium) {
        margin-bottom: 18px;
    }

    h1 {
        @include breakpoint(large) {
            font-size: 48px;
            line-height: 56px;
            padding-top: 10px;
        }
    }
}

.checkoutlogin {
    @include clearfix;
    width: 100%;
    padding-bottom: 12px;

    @include breakpoint(medium) {
        padding-bottom: 170px;
    }

    .col-1,
    .col-2 {
        @include bp(max-width, $bp-medium) {
            float: none;
            width: 100%;
            border-right: 0;
        }
    }

    .login-box {
        margin-top: 7px;

        @include breakpoint(medium) {
            margin-top: 0;
        }

        @include breakpoint(large) {
            margin-top: 2px;
        }

        &.login-account {
            margin-top: 20px;

            @include breakpoint(medium) {
                margin-top: 0;
            }

            @include breakpoint(large) {
                margin-top: 2px;
            }

            .login-box-content p {
                margin-bottom: 8px;
            }

            h3 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        h4 {
            padding-bottom: 2px;
        }

        .login-box-content {
            p {
                margin-bottom: 18px;
                max-width: 332px;

                @include breakpoint(large) {
                    max-width: 465px;
                }

                &:last-of-type {
                    margin-top: 15px;

                    @include breakpoint(medium) {
                        margin-top: 23px;
                    }
                }
            }

            &.returning-customers {
                max-width: 332px;

                p {
                    margin-top: 7px;

                    @include breakpoint(medium) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.pt_checkout {
    .checkout-grid-header {
        background-color: $brand-primary;
        max-width: initial;
        padding: 0;

        .awmh-donation-indicator {
            & > .col {
                padding: 0;
            }
        }
    }

    #main {
        .dialog-required {
            margin-top: 15px;
            margin-bottom: 0;
            @include breakpoint(large) {
                margin-top: 60px;
            }

            .required-indicator {
                font-family: $sans-serif;
            }
        }

        .shipping-header, .place-order-header {
            margin-bottom: 19px;

            @include breakpoint(large) {
                margin-bottom: 22px;
                margin-top: -6px;
            }

            @include breakpoint(xlarge) {
                margin-top: -14px;
            }

            h2 {
                @include breakpoint(large) {
                    font-size: 48px;
                    line-height: 56px;
                }
            }
        }

        .place-order-header {
            margin-bottom: -3px;

            @include breakpoint(large) {
                margin-bottom: 24px;
            }
        }

        .order-summary-footer {
            .place-order-totals {
                display: flex;
                justify-content: end;
                .order-totals-table {
                    margin: 0;
                    padding: 0;
                    
                    dd,
                    dt {
                        color: $c-charcoal;
                        font-size: 14px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.43;
                        letter-spacing: 1px;
                        padding: 0;
                        margin: 0 0 10px 0;

                        &.order-total-label,
                        &.order-total-value {
                            color: $brand-primary;
                            font-family: $f-subheader;
                            font-size: 21px;
                            line-height: 1.29;
                            letter-spacing: 1px;
                            margin-bottom: 30px;
                            padding-top: 10px;
                        }
                    }
                }
            }

            .submit-order {
                padding: 0;

                .form-row {
                    width: 100%;
                }
            }
        }
        .place-order-summary {
            margin: 0 0 0 -27px;
            width: calc(100% + 54px);

            @include breakpoint(large) {
                margin: 0;
                width: 100%;
            }
            .item-image {
                @include bp(max-width, $bp-medium) {
                    flex-basis: 100px;
                }
            }

            .name {
                @include breakpoint(large) {
                    padding-top: 10px;
                }
            }

            .item-details {
                padding: 12px;
                @include breakpoint(large) {
                    padding: 20px;
                }

                .product-list-item {
                    .name {
                        margin-top: 0;
                        padding-top: 0;
                    }
                }

                .product-list-item {
                    .attribute {
                        display: none;
                    }
                }

                .product-availability-info {
                    .product-availability-list {
                        margin: 0;

                        .on-order:first-child {
                            color: $c-gray;
                        }
                    }
                }

                .item-details-inline-item {
                    display: flex;
                    flex-wrap: wrap;

                    & > div {
                        color: $c-charcoal;
                        font-size: 12px;
                        line-height: 1.5;
                        margin-right: 10px;

                        @include breakpoint(large) {
                            font-size: 14px;
                            line-height: 1.43;
                        }
                    }

                    .personalize-name {
                        width: 100%;
                    }
                }
            }

            .item-qty {
                font-weight: $f-weight-regular;

                @include breakpoint(medium) {
                    display: inline-block;
                }
            }

            .item-stock {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }

            .item-total {
                padding: 12px;
                @include breakpoint(large) {
                    padding: 20px;
                }
            }

            .cart-row > .item-quantity {
                @include breakpoint(medium) {
                    display: none;
                }

                @include breakpoint(large) {
                    display: table-cell;
                    text-align: center;
                    padding: 33px 0 15px 57px;

                    .item-quantity-details {
                        padding-top: 0;
                        display: inline-block;

                        .is-in-stock {
                            font-weight: $f-weight-regular;
                        }
                    }
                }
            }
        }

        .dw-apple-pay-button {
            &:after {
                content:'';
                visibility: visible;
            }
        }
    }

    .instore-shipment-header {
        width: 100%;
    }

    .ship-to-multiple {
        margin: $checkout-shipto-margin;
        @include clearfix;
        display: none;

        button {
            float: left;
            margin-top: 10px;

            @include breakpoint(medium) {
                float: none;
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }

    .instore-shipment-method {
        width: 100%;
        padding: 1em;
        span {
            font-weight: $f-weight-bold;
        }
    }

    .instore-shipment-address {
        width: 40%;
        float: left;
        padding-left: 1em;
    }

    .instore-shipment-message {
        clear: both;
    }

    .instore-shipment-messagetxt {
        width: 50%;
        float: right;
    }
    .error-message {
        background: none;
        white-space: nowrap;
        font-size:12px;
        border:none;
        padding:0;
        color:#B52532 !important;
    }
}

.checkout-shipping,
.checkout-billing {

    .form-wrapper {
        display: flex !important;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .form-row {
            width: 100%;

            @include breakpoint(large) {
                width: calc(50% - 12px);
            }

            &.disabled {
                display: none;
            }

            &.enabled {
                display: block;
            }
        }
    }

    .select-address, .select-address .form-row {
        width: 100%;

        button {
            display: none;
        }
    }

    hr {
        display: none;
    }

    .form-row-button {
        text-align: left;
    }

    fieldset {
        @include clearfix;
    }

    @include breakpoint(large) {
        fieldset .form-wrapper .form-row {
            float: left;
            width: calc(50% - 4px);

             &:nth-child(odd) {
                clear: both;
            }

             &:nth-child(even) {
                float: right;
            }

            &.form-row--no-float {
                float: none;
                clear: both;
                width: 100%;
            }

            &.form-row-button {
                float: right;
                clear: both;
                text-align: right;
            }
        }
    }
}

.payment-mods {
    margin: 0 0 35px;

    @include breakpoint(medium) {
        margin: 18px 0 6px;
    }
    @include breakpoint(large) {
        margin: 9px 0 32px;
    }

    .form-row.coupon-code-field, .form-row.gift-certificate-field {
        width: 80%;
        float: left;
        margin: 0;
        @include breakpoint(large) {
            width: 76%;
        }
    }

    .gift-certificate-field {
        margin: 0;

        &.error {
            width:100%;
            clear: left;
            @include breakpoint(large) {
                width: auto;
            }
        }

        &.redemption.giftcert .success .remove img {
            display: none;
        }
    }

    .coupon-apply, .giftcert-apply {
        margin-top: 9%;
        margin-left: 2%;
        float: left;
        @include breakpoint(large) {
            margin-top: 6%;
        }
        @include breakpoint(large) {
            margin-top: 4%;
        }
    }

    .form-field-tooltip {
        clear:left;
    }

    .coupon-error, .redemption {
        float: none;
        width: 100%;
        display: block;
        clear: both;
    }

    #check-giftcert, #add-giftcert, #add-coupon {
        background: transparent;
        display: block;
        text-align: left;

        &:before, &:after {
            display: none;
        }
        span {
            color: $c-red;
            font-size: 16px;
            line-height: 24px;
        }
    }

    #check-giftcert {
        padding-left: 15px;
        span {
            color: $c-red;
        }
        width: 100%;
    }

    .billing-coupon-giftcard {
        display: none;
        &.active {
            display: block;
        }
        position: relative;
        margin-top: 1%;
    }
    .billing-coupon-giftcard-toggle {
        cursor: pointer;
        color: $c-red;
    }
}

.checkoutlogin-header {
    padding-top: 57px;
}


.custom-checkbox {
    .field-wrapper {
        margin-bottom: 0;
    }
    .input-checkbox.error {
        position: relative;

        &:after {
            @include svg-view-input-error;
            position: absolute;
            right: 19px;
            top: -6px;
        }
    }

    span.error {
        display: none !important
    }
}

.vinylclub-renew {
    margin-bottom: 0;
}

.sor-below-text {
    margin: 6px 8px 16px;
    font-size: 14px;
}

.sor-ctas .button {
	padding: 6px Min(32px, calc(24.5% - 81px));
}

.sor-ctas .button:not(:last-of-type) {
    margin-right: 2%;
}

@media (max-width: 368px) {
	.sor-ctas .button {
		padding: 6px Min(32px, calc(25% - 69px));
		font-size: 10px;
	}

	.sor-ctas .button:not(:last-of-type) {
	    margin-right: 0;
	}
}

.collapsed {
    display: none;
}

.add-to-address {
    margin-top: 4px;
    width: 100%;
}

#billing-address-form.hidden {
    display: none !important;
}

.billing-header-container {
    margin-bottom: 0;
}

.checkout-billing {
    .dialog-required,.select-address {
        margin-top:0 !important;
        &.hidden {
            display:none;

        }
    }
}

.checkout-shipping {
    .dialog-required {
        margin:0 !important;
    }

    .uk-prevent-rules-msg {
        color: red;
        margin-bottom: 3%;
        display: none;
    }
    
}

.payment-method .cctype {
    display: none;
}

.payment-method-container {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(large) {
        width: 110%; 
    }
    .form-row {
        margin-right: 10px;
    }
    .ccnumber {
        order: 1;
        width: 100%; 
        .form-row {
            margin:0;
            @include breakpoint(medium) {
                margin:0 10px 19px 0;
            }
        }
        @include breakpoint(large) {
            width: unset;
        }
    }
    .ccexpiration {
        order: 2;
        width: 100%; 
        @include breakpoint(large) {
            width: unset;
        }
    }
    .ccowner {
        margin-top:-3%;
        order: 4;
        width: 100%;

        .form-row {
            margin:0;
        }
        @include breakpoint(large) {
            width: 76%;
            .form-row {
                display: block;
                margin:0;
            }
        }
        label {
            @include breakpoint(large) {
                margin-top: unset;
            }
        }
    }
    .cccvn {
        height:auto;
        order: 3;
        width: 100%; 
        @include breakpoint(large) {
            width: unset;
        }
    }
    .cctoken {
        display: none;
    }
}
.pt_checkout {
    background: $c-body-color url("../images/checkout-bg.png") no-repeat;
    background-position: center top;

    #main {
        .checkout-grid-content {
            #primary {
                padding-top: 30px;
                @include breakpoint(large) {
                    padding-right: 50px;
                    padding-top: 50px;
                }

                .place-order-header,
                .billing-header {
                    h2 {
                        font-size: 32px;
                        line-height: 1.13;
                        letter-spacing: 0.5px;
                        margin-bottom: 20px;

                        @include breakpoint(large) {
                            font-size: 34px;
                            margin-bottom: 30px;
                        }
                    }
                }

                .place-order-header {
                    display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                    margin-bottom: 10px;

                    h2 {
                        margin: 0;
                        padding: 0;
                    }

                    .back-to-cart {
                        font-size: 11px;
                        font-weight: $f-weight-bold;
                        line-height: 1;
                        letter-spacing: 2.5px;
                        text-transform: uppercase;

                        @include breakpoint(large) {
                            font-size: 14px;
                            letter-spacing: 3px;
                        }
                    }
                }

                .checkout-billing {
                    .form-row.label-inline {
                        label:not(.custom-label) {
                            color: $c-charcoal;
                        }
                    }
                }

                .billing-coupon-giftcard-toggle {
                    color: $c-charcoal;
                    border-bottom: 1px solid $c-stroke-gray;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.43;
                    padding-left: 35px;
                    padding-bottom: 30px;
                    position: relative;

                    @include breakpoint(large) {
                        padding-left: 30px;
                    }

                    &:before {
                        background-color: $white;
                        border: $input-checkbox-border;
                        box-sizing: border-box;
                        content: '';
                        cursor: pointer;
                        display: block;
                        height: $input-checkbox-size;
                        position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                        width: $input-checkbox-size;
                
                        @include breakpoint(large) {
                            height: $input-checkbox-size-md;
                            width: $input-checkbox-size-md;
                        }
                    }
                
                    &::after {
                        content: '';
                        display: block;
                        height: $input-checkbox-size-inner;
                        position: absolute;
                            top: 4px;
                            left: 4px;
                            z-index: 2;
                        transition: background-image 0.2s ease, background-color 0.2s ease;
                        width: $input-checkbox-size-inner;
                
                        @include breakpoint(large) {
                            height: $input-checkbox-size-inner-md;
                            width: $input-checkbox-size-inner-md;
                        } 
                    }
                
                    &.active:after {
                        background-color: $c-black;
                    }
                
                    &:focus {
                        outline: none;
                    }
                
                    &:hover::after {
                        @include svg-view-checkbox-rollover-graphic();
                        top: -2px;
                        left: -2px;
                    }

                    &.active {
                        border: 0;
                        padding-bottom: 40px;
                        @include breakpoint(large) {
                            padding-bottom: 20px;
                        }
                    }
                }

                .billing-coupon-giftcard {
                    border-bottom: 1px solid $c-stroke-gray;
                    max-width: 482px;
                    width: 100%;

                    &.active {
                        display: inline-block;
                    }

                    &.form-indent {
                        .form-row {
                            margin-bottom: 0;

                            &.coupon-error {
                                margin: 16px 0;
                            }
                        }
                    }

                    .field-container-one-line {
                        display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                        width: 100%;

                        &:first-child {
                            margin-bottom: 10px;
                            @include breakpoint(large) {
                                margin-bottom: 16px;
                            }
                        }

                        .form-row {
                            flex: 0 0 69%;
                            margin-bottom: 0;
                            width: 69%;

                            &.balance,
                            &.giftcert-error {
                                flex: 0 0 50%;
                                margin-top: 0;
                                padding-top: 0;
                                width: 50%;
                                @include breakpoint(large) {
                                    flex: 0 0 69%;
                                    width: 69%;
                                }
                            }
                        }

                        .coupon-code-field.coupon-apply,
                        .giftcert-apply {
                            flex: 0 0 31%;
                            margin-left: 0;
                            margin-top: 24px;
                            width: 31%;
                        }

                        #check-giftcert {
                            flex: 0 0 50%;
                            margin-top: 16px;
                            text-align: right;
                            text-transform: uppercase;
                            width: 50%;
                            @include breakpoint(large) {
                                flex: 0 0 31%;
                                width: 31%;
                            }

                            @include breakpoint(xlarge) {
                                position: absolute;
                                    left: 490px;
                                    top: 15px;
                                text-align: left;
                                width: 100px;
                            }

                            span {
                                color: $c-charcoal;
                                font-size: 11px;
                                font-weight: $f-weight-bold;
                                line-height: 1;
                                letter-spacing: 2.5px;
                            }
                        }

                        .button {
                            background-color: $brand-primary;
                            height: 50px;
                            text-align: center;
                            width: 100%;

                            span {
                                color: $white;
                                font-size: 15px;
                                letter-spacing: 3px;
                            }

                            &:hover {
                                background-color: $white;

                                span {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }

                .billing-option-header {
                    display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                    margin-bottom: 20px;
                    width: 100%;

                    &.billing-address-header {
                        margin-bottom: 30px;
                    }

                    & > span {
                        font-size: 20px;
                        line-height: 1.1;
                        letter-spacing: 0.5px;
                        width: 100%;

                        @include breakpoint(large) {
                            font-size: 21px;
                            line-height: 1.29;
                        }
                    }

                    .required-item {
                        color: $c-charcoal;
                        font-family: $sans-serif;
                        font-size: 10px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.4;
                        letter-spacing: 1px;
                        white-space: nowrap;

                        em {
                            font-style: normal;
                        }
                    }
                }

                .payment-container {
                    .payment-method {
                        margin: 0;
                        max-width: initial;
                    }
                    .payment-method-options {
                        .form-row {
                            display: flex;
                                align-items: flex-start;

                            &:last-child {
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .payment-method-container {
                        width: 100%;

                        .ccowner {
                            width: 100%;

                            @include breakpoint(large) {
                                margin-top: -10px;
                            }
                        }

                        .ccnumber {
                            width: 100%;

                            .form-row {
                                margin-right: 0;
                                width: 100%;

                                input {
                                    width: 100%;
                                }
                            }
                        }
                        .ccexpiration {
                            width: 100%;

                            .ccexpiration-box {
                                display: flex;
                                    flex-wrap: wrap;

                                & > label {
                                    flex: 0 0 100%;
                                    width: 100%;
                                }
                            }

                            @include breakpoint(large) {
                                width: 66.66%;
                            }

                            .form-row {
                                box-sizing: border-box;
                                margin-right: 0;
                                padding-right: 8px;
                                width: 50%;
                                

                                &:last-child {
                                    padding-right: 0;
                                }

                                @include breakpoint(large) {
                                    &:last-child {
                                        padding-right: 8px;
                                    }
                                }

                                input {
                                    width: 100%;
                                }
                            }
                        }
                        .cccvn {
                            width: 100%;

                            @include breakpoint(large) {
                                width: 33.33%;
                            }

                            .form-row {
                                margin-right: 0;
                                width: 100%;
                            }

                            input {
                                width: 100%;
                            }
                        }
                    }
                }

                form {
                    .form-row-button {
                        button {
                            background-color: $brand-primary;
                            max-width: 482px;
                            width: 100%;

                            &:hover {
                                background-color: $white;
                            }
                        }
                    }
                    .form-row:not(.shipping-checkbox) {
                        margin-bottom: 16px;

                        > .form-row {
                            margin: 0;
                        }

                        &.gift-message-text {
                            width: 100%;
                            max-width: 482px;

                            textarea {
                                height: 120px;
                            }

                            .char-count {
                                color: $c-charcoal;
                                font-size: 14px;
                                font-weight: $f-weight-semibold;
                                line-height: 1.43;
                                letter-spacing: 1px;
                                margin-top: 10px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .form-field-tooltip {
                        a {
                            color: $brand-primary;
                            font-size: 10px;
                            font-weight: $f-weight-semibold;
                            line-height: 1.4;
                            letter-spacing: 1px;
                        }
                    }
                }

                #shipping-method-list {
                    h3 {
                        font-size: 20px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.1;
                        letter-spacing: 0.5px;
                        margin-bottom: 16px;
                        padding: 0;

                        @include breakpoint(large) {
                            font-size: 21px;
                        }
                    }

                    .shipping-method-container {
                        background: $c-light-gray;
                        border: 0;
                        max-width: 482px;
                        padding: 30px 40px;

                        .shipping-method-header {
                            font-size: 10px;
                            font-weight: $f-weight-bold;
                            line-height: 1;
                            letter-spacing: 1px;
                            margin-bottom: 10px;
                            padding-bottom: 7px;
                            text-transform: uppercase;

                            @include breakpoint(large) {
                                margin-bottom: 16px;
                            }
                        }

                        .shipping-method-products {
                            &:last-child {
                                margin-bottom: 20px;
                            }

                            .mainTitle {
                                font-size: 14px;
                                line-height: 1.43;
                            }
                        }

                        .shipping-method {
                            display: flex;
                                align-items: flex-start;
                                flex-wrap: initial;
                                justify-content: space-between;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .method-description {
                                padding-left: 12px;
                            }

                            .text-wrapper {
                                label {
                                    font-family: $f-subheader;
                                    font-size: 14px;
                                    line-height: 1.29;
                                    letter-spacing: 1px;
                                    margin-bottom: 8px;
                                }

                                .method-description {
                                    color: $c-charcoal;
                                    font-size: 14px;
                                    line-height: 1.43;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }

                .shipping-header  {
                    display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                    margin-top: 8px;
                    margin-bottom: 20px;
                    @include breakpoint(large) {
                        margin-bottom: 30px;
                    }

                    h2 {
                        font-size: 32px;
                        line-height: 1.13;
                        letter-spacing: 0.5px;

                        @include breakpoint(large) {
                            font-size: 34px;
                        }
                    }

                    .dialog-required {
                        margin: 0;
                        em {
                            color: $c-charcoal;
                            font-size: 14px;
                            font-weight: $f-weight-semibold;
                            line-height: 1.43;
                            letter-spacing: 1px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
            .summary {
                @include breakpoint(large) {
                    border-left: 1px solid $c-medium-gray;
                    margin: 0;
                    padding-left: 50px;
                    padding-top: 70px;
                }

                .minisummary {
                    background-color: initial;
                    padding: 0;
                    margin-bottom: 20px;

                    .checkout-order-box {
                        border: 1px solid $brand-primary;

                        .checkout-order-totals {
                            padding: 20px;

                            .order-totals-table {
                                td {
                                    color: $c-charcoal;
                                    font-size: 14px;
                                    font-weight: $f-weight-semibold;
                                    line-height: 1.43;
                                    letter-spacing: 1px;
                                    padding-bottom: 10px;
                                    text-transform: uppercase;
                                }

                                .order-total {
                                    td {
                                        color: $brand-primary;
                                        font-family: $f-subheader;
                                        font-size: 21px;
                                        line-height: 1.29;
                                        letter-spacing: 1px;
                                        padding-bottom: 0;
                                        padding-top: 10px;
                                    }
                                }
                            }
                        }

                        .checkout-mini-cart {
                            padding: 0;

                            .mini-cart-product {
                                border-top: 1px solid $c-stroke-gray;
                                padding: 0;
                                .mini-cart-image {
                                    border-right: 1px solid $c-stroke-gray;
                                    padding: 10px;

                                    img {
                                        height: 80px;
                                        object-fit: cover;
                                        width: 80px;
                                    }
                                }

                                .mini-cart-data {
                                    display: flex;
                                        justify-content: space-between;
                                    padding: 15px 20px 15px;
                                    margin: 0;
                                    width: 100%;

                                    .product-availability-list {
                                        .on-order {
                                            color: $brand-primary;
                                            font-size: 12px;
                    
                                            &:first-child {
                                                display: block;
                                                font-size: 10px;
                                                font-weight: $f-weight-bold;
                                                line-height: 1;
                                                letter-spacing: 1px;
                                                text-transform: uppercase;
                    
                                                @include breakpoint(large) {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }

                                    .mini-cart-name {
                                        a {
                                            display: block;
                                            font-size: 12px;
                                            font-weight: $f-weight-semibold;
                                            line-height: 1.17;
                                            letter-spacing: 1px;
                                            text-transform: uppercase;

                                            @include breakpoint(large) {
                                                font-size: 14px;
                                                line-height: 1.14;
                                            }
                                        }
                                    }

                                    .mini-cart-pricing {
                                        padding-left: 10px;
                                        span {
                                            display: block;
                                            font-size: 12px;
                                            font-weight: $f-weight-semibold;
                                            line-height: 1.17;
                                            letter-spacing: 1px;
                                            text-transform: uppercase;

                                            @include breakpoint(large) {
                                                font-size: 14px;
                                                line-height: 1.14;
                                            }
                                        }

                                        .mini-cart-remove {
                                            display: none;
                                        }
                                    }

                                    .mini-cart-quantity,
                                    .mini-cart-attributes,
                                    .mini-cart-inscription {
                                        display: inline-block;

                                        span {
                                            color: $c-charcoal;
                                            font-size: 12px;
                                            line-height: 1.5;

                                            @include breakpoint(large) {
                                                font-size: 14px;
                                                line-height: 1.43;
                                            }
                                        }
                                    }

                                    .mini-cart-inscription {
                                        width: 100%;
                                    }
                                    
                                    .mini-cart-attributes {
                                        .attribute {
                                            line-height: 1.43;
                                            &:last-child {
                                                padding-right: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .section-header {
                        color: $brand-primary;
                        border: 0;
                        display: flex;
                            align-items: baseline;
                            justify-content: space-between;
                        font-size: 20px;
                        font-weight: $f-weight-bold;
                        line-height: 1.1;
                        letter-spacing: 0.5px;
                        margin-bottom: 16px;
                        padding: 0;

                        @include breakpoint(large) {
                            font-size: 21px;
                            line-height: 1.29;
                            margin-bottom: 14px;
                        }

                        .section-header-note {
                            color: $brand-primary;
                            font-size: 13px;
                            font-weight: $f-weight-bold;
                            line-height: 1.08;
                            letter-spacing: 2.5px;
                            text-transform: uppercase;
                        }

                        &.shipment-number {
                            margin-bottom: -15px;
                        }
                    }

                    .order-component-block {
                        .section-header {
                            margin-top: 30px;
                        }

                        .checkout-order-box {
                            padding: 20px 20px 30px;

                            .payment-amount {
                                color: $c-gray;
                                span {
                                    color: $black;
                                }
                            }

                            .address {
                                font-size: 14px;
                                line-height: 1.43;
                                margin-bottom: 15px;
                                div {
                                    margin-bottom: 5px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                            &.address-order-box {
                                .address {
                                    margin-bottom: 0;
                                }
                            }

                            .minishipments-method {
                                span {
                                    color: $black;
                                    font-size: 14px;
                                    line-height: 1.43;
                                }
                            }
                        }
                    }
                }
            }
        }

        .awmh-banner {
            background-color: $brand-primary;
            display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
            position: relative;

            & > div {
                padding: 20px 0;
                text-align: center;

                @include breakpoint(large) {
                    padding: 45px 0;
                }
            }

            .js-awmh-donation-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 8px 0;
                width: 100%;
                
                button {
                    flex: 0 0 32%;
                    width: 32%;

                    &:last-child {
                        &.js-awmh-donation.donation {
                            margin: 6px 0 0 0;
                        }
                    }

                    &.js-awmh-donation.donation {
                        font-size: 1em;
                        overflow: initial;
                        margin: 6px 6px 0 0;

                        @include breakpoint(small) {
                            font-size: 13px;
                        }

                        @include breakpoint(large) {
                            white-space: nowrap;
                        }
                    }
                }
            }

            button {
                &.button--cta.js-awmh-donation {
                    border-width: 1px;
                    line-height: 12px;

                    &.active {
                        @include secondary-button;
                    }

                    .disabled {
                        padding: 10px;
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }

                &.js-awmh-donation.donation {
                    background-color: $brand-primary;
                    border: 1px solid $c-body-color;
                    color: $c-body-color;
                    font-size: 13px;
                    font-weight: $f-weight-bold;
                    line-height: 1.08;
                    letter-spacing: 2.5px;
                    margin: 0;
                    overflow: hidden;

                    &.active {
                        background-color: $c-body-color;
                        color: $brand-primary;
                    }
                }
            }

            .button-wrapper {
                display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                @include breakpoint(large) {
                    display: inline-block;
                }

                .js-awmh-donation {
                    padding: 12px 10px 11px;
                    width: 33.3%;
                }

                & > .js-awmh-donation {
                    width: 100%;
                }

                #donateButton {
                    background-color: $c-body-color;
                    border: 1px solid $c-body-color;
                    color: $brand-primary;
                    width: 100%;
                }
            }
            
            .donatevalue {
                max-width: 15%;
                vertical-align: top;
                float: right;
                font: 500 24px/32px "futura-pt-condensed", sans-serif;
                color: #000;
                text-transform: uppercase;
            }

            h3 {
                color: $c-body-color;
                font-size: 32px;
                line-height: 1.14;
                margin-bottom: 8px;
                position: relative;
                    z-index: 1;
                text-align: left;

                @include breakpoint(large) {
                    font-size: 28px;
                    margin-bottom: 14px;
                }
            }

            h4 {
                color: $c-body-color;
                font-size: 14px;
                line-height: 1.29;
                letter-spacing: 1px;
                margin-bottom: 8px;
                position: relative;
                    z-index: 1;
                text-align: left;

                @include breakpoint(large) {
                    margin-bottom: 14px;
                }
            }

            #donationcopy {
                color: $c-body-color;
                margin-bottom: 15px;
                position: relative;
                text-align: left;
                
                strong {
                    display: inline-block;
                    margin-bottom: 10px;

                    @include breakpoint(large) {
                        margin-bottom: 5px;
                    }
                }

                .spacing {
                    margin-top: 10px;
                    display: inline-block;
                    width: 100%;

                    @include breakpoint(large) {
                        margin-top: 5px;
                    }
                }
            }

            .awmhlogo-container {
                background-image: url("../images/awmh-logo-mobile.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center;
                height: 60%;
                position: absolute;
                    left: 0;
                    top: 0;
                width: 100%;

                @include breakpoint(medium) {
                    height: 100%;
                }

                @include bp(max-width, $bp-large) {
                    height: 62%;
                }

                @include breakpoint(large) {
                    background-size: 100% 100%;
                    background-position: center top;
                    left: -10%;
                    width: 70%;
                }
            }
        }

        .checkout-shipping {
            .shipping-header {
                margin-top: 30px;
            }

            .form-row.shipping-checkbox {
                flex-wrap: nowrap;
                margin-bottom: 0;

                & > .form-row {
                    @include breakpoint(large) {
                        width: auto;
                    }
                }
            }

            #shipping-method-list {
                margin-top: 30px;

                @include breakpoint(large) {
                    margin-top: 40px;
                }
            }
        }
    }
}

.select-saved-card {
    width: 100%;
    margin:0;
    @include breakpoint(medium) {
        margin: 0 0 19px;
    }
}

.credit-card-logo {
    max-width: 160px;
    margin-top: -2px;
}

.paypal-logo {
    max-width: 80px;
    margin-top: 5px;
}

button.payment-logo,
img.payment-logo {
    height: 30px;
    margin-right: -3px; // Accoun for extra horizontal white space in the logos themselves
    margin-top: -3px;
    object-fit: cover;
    width: 47px;

    @include breakpoint(medium) {
        margin-right: -2px;
        width: 50px;
    }

    &:active,
    &:hover { 
        margin-right: -4px;
        margin-top: -3px;
    }
}

div#paypal-button-container,
.payment-method-container {
    margin: 0;
    @include breakpoint(medium) {
        margin: 20px 0;
    }
}

#billingSubmitButton {
    width: 100%;
    @include breakpoint(large) {
        width: unset;
    }
}

.shipping-billing-address {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    .billing-label {
        margin-left: 5px;
        vertical-align: super;
    }
    .form-row {
        margin: 0 0 5px;
    }
}

.email-field-billing {
    display: none
}

.vat-disclaimer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .form-row {
        flex-wrap: nowrap;
        max-width: 382px;
        label {
            color: $c-charcoal;

            span {
                span {
                    color: $brand-primary;
                    font-weight: $f-weight-bold;
                }
            }
        }
    }
}

.awmhlogo.col.col--s {
    padding:15px 0 15px 15px;
}

.giftcert-container {
    margin: 15px 0 0 0;
}

body {
    .payment-container .apple-pay {
        display: none !important;
    }

    &.apple-pay-enabled {
        .payment-container .apple-pay {
            display: flex !important;
        }
    }
}
