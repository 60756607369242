$snapwidget-break-mobile: 540px;
$snapwidget-break-tablet-sm: 834px;
$snapwidget-break-tablet: 1024px;
$snapwidget-break-desktop: 1600px;
$snapwidget-content-max-width: 1168px;
$snapwidget-margin: 27px;

.snapwidget {
    // see app_blueacorn/cartridge/js/slideText.js for annimation logic
    --slidetext-toptext-offset: 100px;
    --slidetext-bottomtext-offset: 1px;

    background-image:
        url("../images/fishhook-white-top-left.svg"),
        url("../images/snapwidget/top-type.svg"),
        url("../images/snapwidget/bottom-type.svg"),
        url("../images/snapwidget/snapwidget-bg-mobile.png");
    background-position:
        center top -9px,
        left var(--slidetext-toptext-offset) top 60px,
        left var(--slidetext-bottomtext-offset) top 87.35825283vw,
        center;
    background-repeat:
        no-repeat,
        no-repeat,
        no-repeat,
        repeat;
    background-size:
        1600px,
        266px,
        266px,
        1242px;
    background-color: #000;
    color: #fff;
    margin-bottom: -66.8vw;
    padding: 0 $snapwidget-margin;
    padding-top: 130px;
    padding-bottom: 27vw;

    @media screen and (min-width: $snapwidget-break-mobile) {
        background-image:
            url("../images/fishhook-white-top-left.svg"),
            url("../images/snapwidget/top-type.svg"),
            url("../images/snapwidget/bottom-type.svg"),
            url("../images/snapwidget/snapwidget-bg-desktop.png");
        background-position:
            center top -9px,
            left var(--slidetext-toptext-offset) top 120px,
            left var(--slidetext-bottomtext-offset) top calc(87.35825283vw - 50px),
            center;
        margin-bottom: -500px;
        padding-bottom: 270px;
    }

    @media screen and (min-width: $snapwidget-break-tablet-sm) {
        --slidetext-toptext-offset: 300px;
        --slidetext-bottomtext-offset: 10px;
        background-size:
            1600px,
            auto,
            auto,
            4800px;
        padding-top: 203px;
    }
    @media screen and (min-width: $snapwidget-break-tablet) {
        background-position:
            center top -9px,
            left var(--slidetext-toptext-offset) top 120px,
            left var(--slidetext-bottomtext-offset) bottom 100px,
            center;
    }

    @media screen and (min-width: $snapwidget-break-desktop) {
        background-size:
            100% auto,
            auto,
            auto,
            4800px;

        margin-bottom: -31.25vw;
        padding-bottom: 15.5vw
    }

    &__container {
        margin: 0 auto;
        max-width: $snapwidget-content-max-width;
    }

    &__header {
        display: flex;
            align-items: flex-end;
            justify-content: space-between;
        margin-bottom: 18px;
    }

    &__heading,
    &__cta {
        text-transform: uppercase;
    }

    &__heading {
        font-family: 'futura-bold', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: normal;
        line-height: 1.12;

        @media screen and (min-width: $snapwidget-break-tablet-sm) {
            font-size: 34px;
        }
    }

    &__cta {
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        letter-spacing: 3px;

        @media screen and (min-width: $snapwidget-break-tablet-sm) {
            font-size: 14px;
        }
    }

    * {
        color: inherit;
    }


    // flockler uses same base styles as snapwidget
    &--flocker {
        box-sizing: border-box;
        margin-bottom: -70vw;
        padding-bottom: 70vw;

        @media screen and (min-width: $snapwidget-break-mobile) {
            margin-bottom: -251px;
            padding-bottom: 216px;
        }

        @media screen and (min-width: $snapwidget-break-tablet-sm) {
            background-position:
                center top -9px,
                left var(--slidetext-toptext-offset) top 120px,
                left var(--slidetext-bottomtext-offset) top 600px,
                center;
        }

        @media screen and (min-width: $snapwidget-break-desktop) {
            margin-bottom: -15.6875vw;
            padding-bottom: 13.5vw;

        }

        .grid & {
            margin-left: calc((100% - 100vw) / 2);
            width: 100vw;
        }

        .flockler-posts {
            margin: 0 -10px;

            .flockler-grid-item {
                width: calc(1 / 2 * 100%);

                @media screen and (min-width: $snapwidget-break-tablet-sm) {
                    width: calc(1 / 6 * 100%);
                }
            }
        }
    }
}
