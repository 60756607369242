.banner-overlay-text {
    position: relative;
    img {
        height: 100vh;
        object-fit: cover;
        max-height: 520px;
        @include breakpoint(medium) {
            max-height: 910px;
        }
    }
    &__text {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
    }
}
.video-with-callout {
    .video-text {
        margin: 0;
    }
}

.product-listing-3cols {
    .product-listing__header {
        @include h1;
        margin-top: 39px;
        margin-bottom: 12px;
        font-weight: 700;
        font-family: 'futura-bold';
        font-size: 24px;
        line-height: 32px;
        @include breakpoint(medium) {
            font-family: 'futura-bold';
            font-size: 50px;
            line-height: normal;
        }
    }
    .product-tile {
        text-align: center;
        .name-link, .product-sales-price {
            font-family: $f-body;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: .5px;
            line-height: 1.54;
        }
    }
    .product-badge {
        display: block;
        margin-bottom: 15px;
    }
}

.photos-showcase {
    &.grid--container {
        margin: 0;
        max-width: 100%;
        @include breakpoint(xlarge) {
            padding: 0 200px;
        }
    }
    .grid-container {
        display: grid;
        grid-gap: 12px;
        padding-bottom: 12px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas: "Area-1 Area-1" "Area-1 Area-1" "Area-2 Area-3" "Area-4 Area-5" "Area-6 Area-6" "Area-6 Area-6";
        @include breakpoint(large) {
            grid-template-columns: 1fr 1fr 1fr;
            // grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-template-areas: "Area-1 Area-1 Area-2" "Area-1 Area-1 Area-3" "Area-4 Area-6 Area-6" "Area-5 Area-6 Area-6";
            grid-gap: 40px;
            padding-bottom: 40px;
        }
        img {
            height: 100%;
        }
    }
    .Area-1 { grid-area: Area-1; }

    .Area-2 { grid-area: Area-2; }

    .Area-3 { grid-area: Area-3; }

    .Area-4 { grid-area: Area-4; }

    .Area-5 { grid-area: Area-5; }

    .Area-6 { grid-area: Area-6; }
}

.fancybox-wrap {
    .fancybox-title {
		width: 100%;
		right: 0;
		.child{
			width: 100%;
			margin-right: 0;
			padding-left: 0;
            padding-right: 0;
            border-radius: 0;
            background: transparent;
		}
		.image-credits{
			display: flex;                  /* establish flex container */
			flex-direction: row;            /* default value; can be omitted */
			flex-wrap: nowrap;              /* default value; can be omitted */
			justify-content: space-between; /* switched from default (flex-start, see below) */
			span{
				padding: 0 15px;
				max-width: 50%;
                white-space: initial;
                .camera {
                    vertical-align: text-top;
                    margin-right: 5px;
                }
            }
		}
	}
}

.videos-showcase {
    .video-image {
        height: 100%;
        img {
            height: 100%;
        }
    }
    .grid-container {
        .Area-1 { grid-area: Area-1; }

        .Area-2 { grid-area: Area-2; }

        .Area-3 { grid-area: Area-3; }

        .Area-4 { grid-area: Area-4; }

        .Area-5 { grid-area: Area-5; }
        display: grid;
        gap: 12px;
        margin-bottom: 12px;
        grid-template-columns: 1fr;
        grid-template-areas: "Area-1" "Area-2" "Area-3" "Area-4" "Area-5";
        @include breakpoint(large) {
            gap: 40px;
            margin-bottom: 40px;
            &.grid-size-2 {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                grid-template-areas: "Area-1 Area-2";
            }
            &.grid-size-3, &.grid-size-5 {
                grid-template-columns: 1fr 1fr;
                grid-template-areas: "Area-1 Area-1" "Area-1 Area-1" "Area-2 Area-3" "Area-4 Area-5";
            }
            &.grid-size-4 {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas: "Area-1 Area-2" "Area-3 Area-4";
            }
        }
        .Area-2, .Area-3, .Area-4, .Area-5 {
            > .video-image, iframe {
                display: none;
            }
        }
    }
}

.banner-article-heading {
    margin-bottom: 17px;
    max-width: 75%;

    @include breakpoint(medium) {
        margin-bottom: 13px;
    }
}