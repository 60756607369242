@supports (-webkit-appearance: -apple-pay-button) {
    .dw-apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }
    .dw-apple-pay-button-black {
        -apple-pay-button-style: black;
    }
    .dw-apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .dw-apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .dw-apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .dw-apple-pay-button-black {
        background-image: url('../images/applepay.png');
        background-color: black;
    }
    .dw-apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .dw-apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    }
}
.dw-apple-pay-button,
.dw-apple-pay-button:hover,
.dw-apple-pay-button:active {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
}