.bonus-product-list {
	.bonus-product-item {
		@extend %clearfix;
		border-bottom: 1px solid $gainsboro;
		.product-col-1,
		.product-col-2 {
			@media screen and (max-width: 768px) {
				float: left;
				clear: none;
			}
		}
	}
	.product-add-to-cart {
		padding-bottom: 0;
		.inventory {
			width: auto;
		}
	}
	.product-name {
		float: none;
		margin: 1em 0;
	}
	.quantity-error {
		color: red;
		text-transform: inherit;
	}
	.bonus-product-list-footer {
		@extend %clearfix;
		padding-top: 1em;
		padding-bottom: 1em;
		&.promotion {
			overflow: visible;
		}
		.add-to-cart-bonus {
			float: right;
			background-color: $c-red;
			color: $white;
			&::before,
			&::after {
				color: $black;
			}
		}
	}
	.selected-bonus-item {
		margin: 1em 0 1em 1em;
		position: relative;
		.item-name {
			font-weight: bold;
		}
		.remove-link {
			cursor: pointer;
			color: $sangria;
			left: -1em;
			position: absolute;
			&:hover {
				color: $charcoal;
			}
		}
	}
	.bonus-carousel {
		@include breakpoint(large) {
			max-width: 650px;
			margin-left: auto;
			margin-right: auto;
		}
		&.slick-slider {
			.slick-prev,
			.slick-next {
				background: none;
				&:hover {
					background: none;
				}
			}
			.slick-prev {
				left: -24px;
				@include breakpoint(large) {
					left: -50px;
				}
			}
			.slick-next {
				right: -24px;
				@include breakpoint(large) {
					right: -50px;
				}
			}
		}
		.product-col-1 {
			.product-primary-image {
				.primary-image {
					margin: 0 auto;
					max-height: 300px;
					@include breakpoint(large) {
						max-height: 350px;
					}
				}
			}
		}
		.product-col-2 {
			.product-name {
				margin: 0 0 20px 0;
			}
		}
	}
}

.bonus-discount-container {
	margin-top: 10%;
	z-index: 100;	
	&.promo-details {
		display: none;
		&.visible {
			display: block;	
		}
	}
	@media screen and (max-width: 767px) {
		.ui-dialog {
			top: 200px !important;
			left: 20px !important;
		}
		.ui-dialog .ui-dialog-buttonpane button {
			margin-left: 15px !important;
			padding-bottom: 1.2em !important;
		}
	}
	@media screen and (max-width: 767px) {
		.bonus-product-dialog {
			top: 0px !important;
		}
	}	
}

.ui-bonus-product {
	top: 50% !important;
}

.promo-details {
	display: none !important;
}

a.show-promo-details {
	display: none !important;
	}

.ui-dialog {
	#bonus-product-dialog {
		overflow: visible;
	}
}
