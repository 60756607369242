img.js-blazy {
    @include transition(opacity 500ms ease-in-out);
    max-width: 100%;
    opacity: 0;
    min-height: 1px;

    &.b-loaded {
        opacity: 1;
    }
}

.js-blazy-wrapper {
    background: url('../images/loading.svg') center center no-repeat;

    @include variant(finished) {
        background: none;
    }
}
