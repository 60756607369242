// CCA Specific Vars
$sidebarWidth: 172px;

// ================================================
// SG Resets/Overrides
// ================================================

// Controlled by Merchant Tools / Site Preferences / Custom Preferences / Checkout Configurations
.payment-mods {
    &.is-hidden {
        display: none;
    }

    &.gift-certificate-is-hidden .gift-certificate-field {
        display: none;
    }

    &.coupon-codes-is-hidden .coupon-code-field {
        display: none;
    }
}

// markup is generated by the ispagingbar custom tag and is used in both the
// order history and the category results pages
.pagination {
    @extend %clearfix;
    margin-top: 10px;
    width: 100%;
    text-align: center;

    @include breakpoint(medium) {
        text-align: right;
        margin-right: 10px;
    }

    @include breakpoint(xlarge) {
        margin-top: 20px;
    }

    .results-hits {
        display: none;
    }

    ul {
        display: flex;
            align-items: center;
            justify-content: flex-end;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        color: $f-body-color;
        border: 1px solid $f-body-color;
        display: inline-flex;
            align-items: center;
            justify-content: center;
        font-family: $f-header;
        font-size: 12px;
        font-weight: $f-weight-bold;
        height: 36px;
        margin: 0 2px 4px;
        letter-spacing: 1px;
        line-height: 1.25;
        padding: 0;
        text-align: center;
        box-sizing: border-box;
        vertical-align: top;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        &:hover {
            background-color: $f-body-color;

            a,
            button {
                color: $white;
                text-decoration: none;
            }
        }

        &:last-child {
            margin-right: 0;

        }

        a,
        button {
            color: inherit;
            display: inline-flex;
            align-items: center;
            border: none;

            &:focus {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }

        @include svg-view-arrow-right-element {
            fill: currentColor;
        }

        &.first-last a,
        &.first-last button {
            padding-left: 8px;
            padding-right: 8px;
            vertical-align: middle;


            &.page-previous {
                i {
                    transform: rotate(180deg);
                }
            }
        }

        .arrow-right-icon {
            line-height: 1;
        }
    }

    a,
    button,
    .current-page {
        padding: 9px 12px 8px;
    }

    .current-page {
        background-color: $black;
        color: $white;
    }
}
