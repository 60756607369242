/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    display: none;
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

// Default Variables
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: $slider-dot-size !default;
$slick-opacity-default: $slider-opacity-default !default;
$slick-opacity-on-hover: $slider-opacity-on-hover !default;
$slick-opacity-not-active: $slider-opacity-not-active !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}


/* Icons */

@if $slick-font-family=="slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: $slider-arrow-box-size-medium;
    width: $slider-arrow-box-size-medium;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: $slider-arrow-box-bg;
    border-radius: 0;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover,
    &:focus {
        outline: none;
        color: transparent;
        background: $slider-arrow-box-bg;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    &:before {
        display: inline-block !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include breakpoint(large) {
        height: $slider-arrow-box-size-large;
        width: $slider-arrow-box-size-large;
    }
}

.slick-prev {
    left: $slider-arrow-box-position-left;

    [dir="rtl"] & {
        left: auto;
        right: $slider-arrow-box-position-left;
    }

    &:before {
        // @include svg-view-slider-prev;
        background: url(../images/interface/left-arrow.png) no-repeat;
        content: '';
        display: block;
        background-size: 40px 40px;
        height: 40px;
        width: 40px;
    }
}

.slick-next {
    right: $slider-arrow-box-position-right;

    [dir="rtl"] & {
        left: $slider-arrow-box-position-right;
        right: auto;
    }

    &:before {
        // @include svg-view-slider-next;
        background: url(../images/interface/right-arrow.png) no-repeat;
        content: '';
        display: block;
        background-size: 40px 40px;
        height: 40px;
        width: 40px;
    }
}


/* Dots */

.slick-dots {
    position: absolute;
    bottom: 16px;
    right: 25px;
    list-style: none;
    display: block;
    text-align: right;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: $slick-dot-size;
        width: $slick-dot-size;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: $slick-dot-size;
            width: $slick-dot-size;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: $slick-dot-size;
                height: $slick-dot-size;
                background: $slider-inactive-dot-bg;
                border-radius: 50%;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button:before {
            background: $c-black;
            opacity: $slick-opacity-default;
        }
    }
}
