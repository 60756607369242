.c-amp-cards {
    display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px 8px;
        justify-content: center;
        margin-bottom: 75px;
        margin-top: 100px;

        @include breakpoint(medium) {
            margin-bottom: 100px;
            row-gap: 20px
        }

        @include breakpoint(large) {
            flex-wrap: nowrap;
            margin-top: 130px;
        }

    * {
        box-sizing: border-box;
    }

    &__card {
        flex-basis: 100%;
        position: relative;

        @include breakpoint(medium) {
            flex-basis: 80%;
        }

        @include breakpoint(large) {
            flex-basis: 50%;
        }

        &--light {
            color: $white;

            .c-amp-cards__card__btn {
                @include hollow-button;

                font-size: 15px;
                line-height: 1;
            }
        }

        &:nth-child(2n) {
            @include breakpoint(large) {
                margin-top: 200px;
            }
        }

        &__img {
            min-height: 325px;
            object-fit: cover;

            @include breakpoint(medium) {
                min-height: 375px;
            }
        }

        &__content {
            padding-left: 55px;
            padding-right: 55px;
            position: absolute;
                left: 50%;
                top: 50%;
            text-align: center;
            text-transform: uppercase;
            transform: translate(-50%, -50%);
            width: 100%;

            @include breakpoint(large) {
                padding-left: 80px;
                padding-right: 80px;
            }

            @include breakpoint(xlarge) {
                padding-left: 98px;
                padding-right: 98px;
            }
        }

        &__text {
            @include h2;

            color: inherit;
            margin-bottom: 20px;

            @include breakpoint(medium) {
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 16px;
                margin-top: 16px;
            }
        } 

        &__btn {
            background-color: transparent;
        }
    }
}