.content-slot img {
    height: auto;
    max-width: 100%;
}

.slot-grid-header {
    p {
        margin: 0;
        padding: 0;
    }

    .promo-banner {
        margin-bottom: 9px;
        
        @include breakpoint(large) {
            margin-bottom: 27px;
        }    
    }

    .content-asset h1 {
        position: absolute;
            left: 50%;
            top: MAX(43%, 200px);
        text-align: center;
        transform: translate(-50%, -50%);
    }

    .html-slot-container,
    .content-asset {
        img {
            clip-path: polygon(0 0, 0 76%, clamp(60%, 368px, 90%) 83%, 10% 91%, 100% 100%, 100% 0); // The use of clamp makes the breakpoint feel more natural
    
            @include breakpoint(large) {
                clip-path: polygon(0 0, 0 73%, 60% 83%, 40% 87.5%, 100% 100%, 100% 0);
            }
        }
    }

    .html-slot-container {
        img {
            min-height: 300px;
            object-fit: cover;
        }
    }

    .content-asset {
        background-color: $c-black;
    }
    
    .grid {
        margin-bottom: 0;
    }
}
.product-listing-1x4 {
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: .83em 0;
    .search-result-items .grid-tile {
        margin: 0 1% 0 2%;
        width: 18%;
    }
}

.account-nav-asset {
    line-height: 1.5em;
    margin: 3.33em .83em;
    padding: .83em 0;
    display: none !important;

    h2 {
        font-style: italic;
        font-weight: 400;
        margin: 1.16em 0 1.66em;
    }
}

.content-asset {
    address {
        margin: 1em 0;
    }
}

.browser-compatibility-alert {
    background-color: $sangria;
    color: $white;
    font-size: 1.3em;
    line-height: 2em;
    padding: 0.8em;
    top: 112px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.menu-slot {
    display: none;
    border-top: 1px solid $tutu;
    margin-top: 0.5em;
    padding-top: 1.5em;
    .social-links {
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 0;
        }
    }
    @media screen and (min-width: 768px) {
        width: 13.5rem;
        display: block;
    }
}

#homepage-slides {
    margin: 0;
    position: relative;
    width: 20000em;
    .slide {
        float: left;
        overflow: hidden;
        position: relative;
        img {
            height: auto;
            max-width: 160%;
            @media screen and (min-width: 768px) {
                max-width: 100%;
            }
        }
        .banner-message {
            position: absolute;
            top: 10%;
            @media screen and (min-width: 768px) {
                top: 2%;
            }
            .banner-text1 {
                font-family: $sans-serif-alt;
                font-size: 2rem;
                font-weight: 300;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    font-size: 5rem;
                    font-weight: 200;
                }
            }
            .banner-text2 {
                font-family: $serif;
                font-size: 1.5rem;
                font-style: italic;
                text-transform: lowercase;
                @media screen and (min-width: 768px) {
                    font-size: 4rem;
                }
            }
            .banner-text3 {
                font-family: $sans-serif-alt;
                font-size: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    font-size: 1.3rem;
                    font-weight: 200;
                }
            }
        }
    }
    .slide1 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $cerulean;
                display: inline;
            }
            .banner-text2 {
                color: $white;
                display: inline;
            }
            .banner-text3 {
                color: $white;
                text-align: right;
            }
        }
    }
    .slide2 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $citrus;
                display: inline;
                letter-spacing: -0.1rem;
            }
            .banner-text2 {
                color: $dark-gray;
                display: inline;
            }
            .banner-text3 {
                color: $black;
            }
        }
    }
    .slide3 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $citrus;
                display: inline;
                margin-top: -2rem;
            }
            .banner-text2 {
                color: $white;
                display: inline;
            }
            .banner-text3 {
                color: $black;
            }
        }
    }
    .slide4 {
        .banner-message {
            right: 3%;
            .banner-text1 {
                color: $citrus;
                margin-top: 0rem;
                text-indent: 3rem;
                @media screen and (min-width: 768px) {
                    margin-top: -1rem;
                }
            }
            .banner-text2 {
                color: $white;
            }
            .banner-text3 {
                color: $white;
            }
        }
    }
    .slide5 {
        .banner-message {
            left: 15%;
            .banner-text1 {
                color: $cerulean;
                display: inline;
            }
            .banner-text2 {
                color: $sangria;
                display: inline;
            }
            .banner-text3 {
                color: $white;
            }
        }
    }
}

#home-bottom-left {
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    width: 100%;
    h2 {
        color: $black;
        font-family: $sans-serif-alt;
        font-size: 2rem;
        font-weight: 100;
        margin: 0;
        padding: 1rem 0;
        text-transform: uppercase;
        @media screen and (min-width: 768px) {
            font-size: 2.3rem;
        }
    }
    ul {
        margin: -.5em 0 .5em 0;
        li {
            border-right: 1px solid $dim-gray;
            color: $dim-gray;
            display: inline;
            font-family: $sans-serif-alt;
            font-size: .8rem;
            font-weight: 400;
            list-style: none outside none;
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            text-transform: uppercase;
            &:last-child {
                border: 0 none;
            }
            span {
                margin: 0 10px 0 0;
            }
        }
    }
    img {
        display: block;
    }
}

#home-bottom-center {
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    .home-bottom-center-text {
        position: absolute;
        text-align: center;
        top: 18%;
        width: 100%;
        h1 {
            color: $cerulean;
            font-family: $serif;
            font-size: 4rem;
            font-style: italic;
            font-weight: normal;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
        }
        h2 {
            color: $dim-gray;
            font-family: $sans-serif-alt;
            font-size: 1.3rem;
            font-weight: 100;
            letter-spacing: .2em;
            margin-top: -.05rem;
            text-transform: uppercase;
        }
        h3 {
            color: $dim-gray;
            font-family: $serif;
            font-size: .8rem;
            font-weight: normal;
            margin-top: -1rem;
            text-transform: uppercase;
        }
    }
    img {
        display: block;
    }
}

.home-bottom-right {
    font-family: $sans-serif-alt;
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    .home-bottom-right-header {
        h2 {
            color: $dim-gray;
            font-size: 0.7rem;
            font-weight: 400;
            padding: .5em 0 0 0;
            margin: 0;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
                font-weight: 200;
            }
        }
        h3 {
            color: $rain-forest;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 200;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
            }
        }
        img {
            display: block;
        }
    }
    .product-tile {
        border: 0 none;
        margin: 1em auto 0;
        min-height: 255px;
        width: 90%;
        img {
            box-shadow: 0px 0px 5px 3px $very-light-gray;
            max-width: 90%;
        }
        .product-name {
            height: auto;
        }
        .product-sales-price {
            color: $black;
        }
    }
}

.category-slot,
.product-slot {
    .tiles-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .grid-tile {
            box-shadow: none;
            margin: 1rem;
            padding: 1rem;
            width: 25%;
        }
    }

    .category-tile {
        float: left;
        overflow: hidden;
        padding: 0 .15%;
        position: relative;
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 33%;
        }
        img {
            height: auto;
            width: 100%;
        }
    }

    .cat-banner, .catlanding-banner {
        position: relative;
        width: 100%;
        @include content-width;

        .page-title {
            position: absolute;
                left: 50%;
                top: MAX(43%, 160px);
            transform: translate(-50%, -50%);

            &.without-image {
                position: relative;
                transform: translateY(0);
                padding: $l-small-padding 0;

                @include breakpoint(medium) {
                    padding: $l-medium-padding 0;
                }

                @include breakpoint(large) {
                    padding: $l-large-padding 0;
                }

                @include breakpoint(xlarge) {
                    padding: $l-xlarge-padding 0;
                }

                h1 {
                    @include h1;
                }
            }
        }

        h1 {
            color: $cat-banner-h1-color;
        }

        &.promo-banner {
            max-width: 100vw;
            
            a {
                overflow: visible;
                display: block;
                position: relative;
            }

            .content {
                @include position(absolute, null null 8px 50%);
                transform: translateX(-50%);
                text-align: center;
                color: $white;
                width: 100%;

                @include breakpoint(large) {
                    bottom: 17px;
                }

                .price {
                    font-weight: $f-weight-bold;
                    font-size: $f-size-xs;

                    @include breakpoint(large) {
                        font-size: $f-size;
                    }
                }

                h3 {
                    @include h5;
                    color: $white;
                    line-height: 30px;

                    @include breakpoint(large){
                        @include h3;
                        color: $white;
                    }
                }
            }
        }
    }
    .search-result-items {
        .grid-tile {
            box-shadow: none;
            margin: 0;
            padding: 0 .15%;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 33%;
            }
        }
    }
}

.category-slot {
    width: 100%;
}

.promo-banner {
    background-color: $c-red;
    box-sizing: border-box;
    color: $white;
    font-size: $f-size-xs;
    font-weight: $f-weight-semibold;
    letter-spacing: 1px;
    line-height: 1.43;
    padding: 15px 27px;
    text-align: center;
    text-transform: uppercase;

    .grid & {
        margin-left: calc((100vw - 100%) / -2);
        margin-right: calc((100vw - 100%) / -2);
        width: 100vw;
    }
}

.tiles-container.recommender {
    display: flex;
    justify-content: center;

    .grid-tile {
        width: 50%;
        margin: 0;

        @include breakpoint(medium){
            margin: 0 1% 0 2%;
            width: 18%;
        }
    }
}

.recommender {
    background-color: $c-mostly-white
}
.recommendations {
    .carousel {
        visibility: hidden;
        &.slick-initialized {
            visibility: visible;
        }
    }
}
