.product-listing {
    &__header {
        margin-top: $recomm-header-top-margin;

        &--suggestions {
            margin-bottom: 0;
            margin-top: 4px;

            @include breakpoint(large) {
                margin-top: 35px;
            }
        }
    }
}

.item-list {
    margin-bottom: 1em;

    thead {
        tr {
            border-bottom: $plist-table-thead-tr-border;
        }
    }

    tr {
        border-bottom: $plist-table-tr-border;

        &.last {
            border: 0 none;
        }
        &.headings {
            display: none;
        }
    }

    td {
        padding: 1em;
        vertical-align: top;
    }
}

#cart-table .rowbundle {
    .item-details {
        padding-top: 0;

        .bundle-image {
            float: left;
            max-width: 25%;
        }

        .name, .itemnumber {
            margin-left: calc(25% + 15px);
        }
    }
}

.item-details .product-list-item {
    .value {
        font-weight: $f-weight-bold;
    }

    .promo {
        color: $brand-primary;
    }

    .price-standard {
        color: $nobel;
        text-decoration: line-through;
    }
}
.item-availability ul,
.item-quantity-details ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}

.item-dashboard {
    width: 16rem;

    label {
        display: inline-block;
        font-weight: $f-weight-bold;
        width: auto;
        margin-right: 20px;
    }

    .field-wrapper {
        width: auto;
        display: inline-block;
    }

    .option-quantity-desired {
        input {
            width: 3rem;
        }
    }

    .value {
        margin-top: .5rem;
    }

    .form-row {
        margin: 0;
    }

    input,
    .value,
    select
    .option-toggle-public label {
        color: $night-rider;
    }

    .input-checkbox {
        margin: 0 5px;
    }
    .option-add-to-cart {
        margin-top: .5rem;
        width: 18rem; //for IE
        width: max-content;
        label {
            color: $night-rider;
            float: left;
            font-weight: 700;
            margin: .5rem .5rem 0 0;
            text-transform: uppercase;
            width: auto;
        }
        input {
            width: 3rem;
            margin-right: 1em;
        }
        button {
            padding: 0.5em 1.5em;
        }
    }
    .option-quantity-purchased span.exceeded {
        color: $sangria;
    }
}
