#secondary a {
    color: $black;
}

#category-level-1 {
    li {
        display: flex;
            flex-wrap: wrap;
        margin-bottom: 7px;
        padding: 4px 10px 4px 0;

        @include breakpoint(large) {
            padding-right: 0;
        }

        &.fa-angle-down:before,
        &.fa-angle-right:before {
            font-size: $refinement-icon-font-size;
            vertical-align: middle;
        }

        .refinement-link {
            background: none repeat scroll 0 0 transparent;
            padding: 0;
            display: block;
            flex-grow: 0;

            &.active {
                color: $c-red;

                @include breakpoint(large) {
                    color: $brand-primary;
                    font-weight: $f-weight-bold;
                }
            }
        }

        &.expandable {
            .active {
                .fa-angle-up {
                    transform: translateY(100%) rotate(0);
                }
            }

            .fa {
                display: none;

                @include breakpoint(large) {
                    display: inline-flex;
                }
            }

            &.is-expanded {
                .fa {
                    display: block;
                }

                #category-level-2 .expandable {
                    display: flex;

                    &.is-expanded {
                        #category-level-3 {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .refinement-link {
            .catRadiobtn {
                display: inline-block;
                border: 2px solid $c-gray;
                width: 10px;
                height: 10px;
                border-radius: 10px;
                position: relative;
                vertical-align: middle;
                margin-top: -3px;

                @include breakpoint(large) {
                    display: none;
                }
            }

            &.active {
                .catRadiobtn {
                    border: 2px solid $c-dark-red;

                    .catRadiobtnSelect {
                        position: absolute;
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: $c-red;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

#category-level-2 {
    a {
        &.active {
            color: $night-rider;

            @include breakpoint(large) {
                font-weight: 700;
            }
        }

        &.refinement-link {
            padding: 0;
        }
    }
}

#category-level-2 {
    padding: 22px 0 3px 0;
    width: 100%;

    @include breakpoint(large) {
        padding: 11px 0 0 9px;
    }

    li {
        margin-bottom: 12px;
        padding: 4px 24px;

        @include breakpoint(large) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &:last-child {
            @include breakpoint(large) {
                margin-bottom: 16px;
            }
        }
    }
}

#category-level-3 {
    padding: 0.3em 0 0;

    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 0.9em;
            padding: 0;
        }
    }
}

.scrollable {
    height: 10em;
    overflow: auto;
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: 9px 0;
        padding: 0;

        li {
            list-style: none outside none !important;
            line-height: 32px;
        }
    }

    .toggle {
        border: 0 none;
        display: block;
        margin: 0.83em 0 0.58em;
        text-transform: uppercase;
        font-weight: $f-weight-bold;
        color: $c-gray;

        span {
            background: none repeat scroll 0 0 transparent;
        }
    }

    .expanded {
        background-position: left -96px;
    }
}

#category-level-2,
#category-level-3,
#category-level-4 {
    display: none;

    .is-expanded > & {
        display: block;
        width: 100%;
    }
}