.c-store {
    &__card-tiles {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            gap: 30px;
        }

        &__body {
            color: $c-black;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
        }

        &__heading {
            margin-bottom: 24px;

            @include breakpoint(medium) {
                margin-bottom: 16px;
            }
        }

        &__image {
            aspect-ratio: 1 / 1;
            display: block;
            height: 100%;
            margin-bottom: 24px;
            object-fit: cover;
            width: 100%;
        }

        &__tile {
            box-sizing: border-box;
            margin-bottom: 24px;
            width: 50%;

            @include breakpoint(medium) {
                margin-bottom: 0;
                width: 25%;
            }

            &:hover {
                text-decoration: none;
            }

            &--even {
                padding-left: 10px;

                @include breakpoint(medium) {
                    padding-left: 0;
                }
            }

            &--odd {
                padding-right: 10px;

                @include breakpoint(medium) {
                    padding-right: 0;
                }
            }
        }
    }

    &__dark {
        background-color: $c-black;
        clip-path: polygon(#{$polygon-clip-path-bottom}, 100% 0);
        position: relative;

        @include breakpoint(medium) {
            clip-path: polygon(#{$polygon-clip-path-bottom-md}, 100% 0);
        }

        @include breakpoint(large) {
            clip-path: polygon(#{$polygon-clip-path-bottom-lg}, 100% 0);
        }

        &::before {
            background-image: url('../images/bg-textured-mobile-dark.jpg');
            background-size: 100% auto;
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            
            @include breakpoint(medium) {
                background-image: url('../images/bg-textured-dark.jpg');
            }
        }

        .c-tabbed-carousels {
            &--dark {
                background-color: transparent;
            }
        }
    }
}

.s-store {
    &__carousel {   
        .accent {
            opacity: 0.15;
        }

        .product-tile {
            color: $white;

            .product-name a {
                color: inherit;
            }

            .product-sales-price {
                color: $white;
            }

            .badge {
                background-color: $white;
                color: $c-black;
            }
        }

        .c-tile-slider {
            @include bp(max-width, $bp-medium) {
                padding-bottom: 36px;
            }

            &__header {
                * {
                    color: $white;
                }
            }
        }

        .slick-arrow {
            color: $white;

            @include bp(max-width, $bp-medium) {
                bottom: -62px;
            }

            &:hover:not(:disabled):not(.disabled) {
                @include bp(max-width, $bp-medium) {
                    bottom: -62px;
                }
            }

            &::before {
                background: transparent;
                height: 14px;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        .slick-prev {
            &::before {
                border-bottom: 3px solid currentColor;
                border-left: 3px solid currentColor;
                left: 24px;
            }
        }

        .slick-next {
            &::before {
                border-right: 3px solid currentColor;
                border-top: 3px solid currentColor;
                left: 16px;
            }
        }

        .slick-dots {
            li {    
                &.slick-active {
                    button {
                        &::before {
                            background-color: $c-bright-red;
                        }
                    }
                }
            }
        }
    }

    &__recommender-group {
        .c-tile-slider {
            padding-top: $tile-slider-padded-padding-top + 80px;

            @include breakpoint(medium) {
                padding-top: $tile-slider-padded-padding-top-md + 65px;
            }

            @include breakpoint(large) {
                padding-top: $tile-slider-padded-padding-top-lg + 65px;
            }
        }

        .c-tabbed-carousels {
            &__tabs__wrap {
                margin-bottom: -$tile-slider-padded-padding-top - 90px;
                padding-top: $tile-slider-padded-padding-top + 50px;
    
                @include breakpoint(medium) {
                    margin-bottom: -$tile-slider-padded-padding-top-md - 85px;
                    padding-top: $tile-slider-padded-padding-top-md + 10px;
                }
    
                @include breakpoint(large) {
                    margin-bottom: -$tile-slider-padded-padding-top-lg - 118px;
                    padding-top: $tile-slider-padded-padding-top-lg + 10px;
                }
            }

            &__tabs-content {
                min-height: $tile-slider-padded-padding-top + 90px;
    
                @include breakpoint(medium) {
                    min-height: $tile-slider-padded-padding-top-md + 85px;
                }
    
                @include breakpoint(large) {
                    min-height: $tile-slider-padded-padding-top-lg + 118px;
                }
            }
        }
    }

    &__featured-collections {
        .c-tile-slider {
            padding-top: $tile-slider-padded-padding-top - 2px;

            @include breakpoint(medium) {
                padding-top: $tile-slider-padded-padding-top-md + 10px;
            }

            @include breakpoint(large) {
                padding-top: $tile-slider-padded-padding-top-lg + 10px;
            }
        }
    }
}


.pt_storelanding {
    .js-banner-carousel {
        .js-banner {
            .c-banner-image {
                @include bp(max-width, $bp-medium + 1) {
                    min-height: 320px;
                }
            }

            .slick-arrow {
                z-index: 1;
            }

            .slick-dots {
                @include bp(max-width, $bp-medium + 1) {
                    bottom: 20%;
                }
            }
        }
    }

    .grid {
        .c-amp-cards {
            margin-bottom: 100px;

            @include breakpoint(medium) {
                margin-bottom: 275px;
            }
        }
    }

    .s-store .c-tile-slider {
        padding-bottom: 135px;

        @include breakpoint(large) {
            padding-bottom: 145px;
        }

        &.s-featured-article {
            padding-top: 0;

            @include bp(max-width, $bp-medium + 1) {
                padding-bottom: 25px;
            }
            
            .js-slidetext {
                display: none;
            }
        }

        .c-tile-slider__slider {
            .c-featured-article {
                height: 360px;
                max-width: 360px;

                @include breakpoint(medium) {
                    height: 384px;
                    max-width: 384px;
                }

                &__img-wrap {
                    &::after {
                        content: "";
                        background: rgba(0, 0, 0, 0.7);
                        display: block;
                        position: absolute;
                            left: 0;
                            top: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                &__details {
                    color: $white;
                }

                &__read-more {
                    color: $white;
                    border-color: $white;
                }
            }
        }
    }

    .s-tile-slider--more-articles {
        display: none;
    }
}
