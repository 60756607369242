// ================================================
// Account Pages
// ================================================

// Account Pages specific overrides/resets
.account-nav-asset {
    padding: 0;
    margin: 16px 0;
    display: none;

    @include breakpoint(medium) {
        display: block;
    }

    h2 {
        margin: 0 0 16px;
    }
}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.registration-selection {
    input,
    label {
        display: inline;
        float: none;
    }
}

.account-nav-flyout-menu {
    @extend .clearfix;
    background: $nav-mobile-bg-color;
    box-sizing: border-box;
    color: $brand-primary;
    display: flex;
        flex-direction: column;
    height: 100vh;
    overflow: hidden;
    padding: 50px 40px;
    position: fixed;
        bottom: 0;
        right: 0;
        top: 0;
    transition: transform $default-transition-duration ease-in-out;
    transform: translateX(100%);
    width: 100%;
    z-index: 104;

    @include breakpoint(medium) {
        max-width: 375px;
    }

    @include breakpoint(large) {
        max-width: 600px;
        padding-top: 70px;
    }

    .account-nav-flyout-menu-close {
        @include position(absolute, 20px 20px null null);
        color: $black;
        display: flex;
        font-weight: $f-weight-bold;

        svg {
            height: 18px;
            width: 18px;
        }

        @include svg-view-close-gray-element {
            fill: $black !important;
        }
    }

    .account-nav-flyout-content {
        height: 100%;
        overflow-y: auto;

        h3 {
            color: $c-black;
            font-family: $f-header;
            font-weight: $f-weight-bold;
            font-size: 48px;
            line-height: 54px;
            letter-spacing: 1px;
            margin-bottom: 32px;
            padding: 0;
        }

        h5 {
            color: $c-black;
            font-family: $f-header;
            font-weight: $f-weight-bold;
            font-size: 21px;
            letter-spacing: 0.5px;
            line-height: 27px;
            margin-bottom: 24px;
            padding: 0;
        }
    
        ul {
            border-bottom: 1px solid $c-stroke-gray;
            list-style: none;
            margin-top: 32px;
            margin-bottom: 32px;
            padding: 0;
            padding-bottom: 31px;

            &:first-of-type {
                li {
                    margin-bottom: 32px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
    
            li {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
    
                a {
                    color: $black;
                    font-size: 16px;
                    font-weight: $f-weight-semibold;
                    letter-spacing: 1px;
                    line-height: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

#wrapper {
    &.pt_account,
    &.pt_order {
        #main {
            position: relative;
                z-index: 0;
    
            @media screen and (min-width: $footer-break-mobile) {
                margin-bottom: 0;
            }
        }
        
        #primary {
            @media screen and (min-width: $footer-break-mobile) {
                padding-bottom: calc(#{$footer-vertical-spacer-mobile} + 26px);
            }

            @include breakpoint(medium) {
                padding-top: 25px;
            }

            &.vinyl-primary {
                padding: 0;
                margin: 0;

                .vinyl-banner {
                    margin-top: -22px;
                    margin-bottom: 10px;

                    @include bp(max-width, $bp-large) {
                        margin-left: -21px;
                        width: calc(100% + 42px);
                    }

                    @include breakpoint(large) {
                        margin-top: -45px;
                        margin-bottom: 30px;
                    }
                }
            }

            #my_subscriptions {
                @include breakpoint(large) {
                    margin-bottom: 160px;
                }
                .subscriptionSection {
                    display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    border-bottom: 1px solid $c-stroke-gray;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    text-align: left;

                    @include breakpoint(large) {
                        flex-wrap: nowrap;
                        margin-bottom: 30px;
                        padding-bottom: 30px;
                    }
        
                    &:last-child {
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
        
                    .subscriptionSection-img {
                        display: block;
                        text-align: left;

                        @include bp(max-width, $bp-medium) {
                            flex: 0 0 100%;
                            margin-bottom: 22px;
                            width: 100%;
                        }
                        
                        h5 {
                            margin: 0 0 4px 0;
                            text-align: left;
                        }
        
                        .quantity-shipments {
                            display: block;
                            font-family: $f-subheader;
                            font-size: 14px;
                            line-height: 1.29;
                            letter-spacing: 1px;
                            text-align: left;
                            text-transform: uppercase;
                        }

                        .subscriptionSection-img__description {
                            margin-bottom: 20px;
                        }
                    }

                    .description-item {
                        display: block;
                        margin-bottom: 4px;

                        .description-label {
                            color: $c-charcoal;
                            font-size: 14px;
                            line-height: 1.43;
                            margin-left: 0;
                        }

                        .description-value {
                            font-size: 14px;
                            line-height: 1.43;
                            letter-spacing: 1px;
                            padding-left: 6px;
                            text-transform: uppercase;
                        }
                    }

                    .subscription-info {
                        @include bp(max-width, $bp-medium) {
                            flex: 0 0 100%;
                            width: 100%;
                        }

                        @include breakpoint(large) {
                            max-width: 286px;
                            width: 100%;
                        }

                        .subscription-buttons {
                            padding: 0;

                            .button {
                                box-sizing: border-box;
                                margin-top: 8px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        #secondary {
            @include breakpoint(medium) {
                padding-top: 15px;
            }

            .secondary-navigation {
                padding-left: 0;
                padding-right: 0;
        
                .toggle {
                    border-top: 1px solid $c-stroke-gray;
                    color: $c-black;
                    font-family: $f-header;
                    font-weight: $f-weight-bold;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                    line-height: 1.1;
                    margin-top: 11px;
        
                    @include breakpoint(medium) {
                        border: none;
                        font-size: 21px;
                        line-height: 1.29;
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
        
                ul {
                    margin-top: 0;
        
                    @include breakpoint(medium) {
                        margin-bottom: 4px;
                        margin-top: 10px;
                    }
        
                    li {
                        margin-bottom: 4px;
        
                        a {
                            color: $black;
                            font-size: 14px;
                            font-weight: $f-weight-semibold;
                            letter-spacing: 1px;
                            line-height: 1.14;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        #primary,
        #secondary {
            @include bp(max-width, $bp-medium) {
                padding-top: 9px;
            }

            @include breakpoint(medium) {
                border-bottom: none;
            }

            @media screen and (min-width: $footer-break-desktop) {
                padding-bottom: calc(#{$footer-vertical-spacer-desktop} + 49px);
            }
        }

        .breadcrumb {
            margin: 17px 0 10px;
    
            @include breakpoint(medium) {
                margin: 36px 0 11px;
            }
    
            @include breakpoint(large) {
                margin-bottom: 3px;
            }
    
            @include breakpoint(xlarge) {
                margin-bottom: -5px;
            }
    
            .breadcrumb-element:nth-child(n+2) {
                color: $c-gray;
                font-weight: $f-weight-bold;
            }
        }
    
        .accent {
            display: none;
            position: absolute;
                left: calc(50% - 10px);
                top: 10px;
                z-index: -1;
            max-width: 100vw;
            overflow: hidden;
            transform: translateX(-50%);
    
            @include breakpoint(large) {
                display: block;
            }
        }
    }
}

#wrapper.pt_account {
    #primary .header-row {
        display: none;
    }

    .paymentslist {
        .header-button-wrapper {
            align-items: flex-end;
            .section-header-note {
                box-sizing: border-box;
                margin-top: 10px;
                @include breakpoint(large) {
                    margin-top: 0;
                }
                @include bp(max-width, $bp-large) {
                     width: 100%;
                }
            }

            h1 {
                font-size: 32px;
                line-height: 1;
            }
        }

        .payment-list {
            display: flex;
            margin-top: 20px;

            @include breakpoint(large) {
                gap: 1.25%; // Half the remaining space per gap
            }

            li {
                border: 1px solid $c-stroke-gray;
                flex: 0 0 100%;
                margin-bottom: 10px;
                padding: 20px;

                @include breakpoint(large) {
                    flex: 0 0 32.5%;
                }

                & > div {
                    color: $c-charcoal;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.43;
                    text-transform: capitalize;

                    @include breakpoint(large) {
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }

                button {
                    color: $brand-primary;
                    font-size: 14px;
                    font-weight: $f-weight-medium;
                    line-height: 1.43;
                    margin-top: 10px;
                    text-decoration: underline;

                    &:hover {
                        background: none;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .login-create-account {
        .form-row-button {
            margin-top: 12px;
        }
    }

    .login-order-track {
        margin-top: 50px;

        @include breakpoint(large) {
            margin-top: 40px;
        }

        .login-box-content p {
            margin-bottom: 16px;
        }

        .form-row-button {
            margin-top: 14px;
        }
    }

    #ChangeEmaillForm {
        .form-row {
            &.error {
                display: block;

                .error-message {
                    width: auto;
                }
            }

            &.subcopy-full:not(.error) {
                .form-field-subcopy-wrap {
                    display: none;
                }
            }
        }
    }

    #RegistrationForm {
        .form-row {
            &.error {
                display: block;

                .form-field-subcopy-wrap {
                    display: block;
                    width: 100%;

                    .form-caption  {
                        background: none;
                        color: $brand-error;
                        border: 0;
                        display: block;
                        padding-left: 0;
                        padding-right: 0;
                        text-transform: uppercase;
                        width: 100%;
                    }
                }
            }
            &.form-indent {
                margin: 0 0 22px;
            }
        }

        .form-row-button {
            display: block;
            margin-right: 0;
        }

        .label-inline {

            span {
                color: $black;
            }

            .form-caption {
                margin-left: 36px;
                line-height: 16px;

                @include breakpoint(medium) {
                    margin-left: 28px;
                }
            }
        }

        .dialog-required {
            @include breakpoint(medium) {
                display: none;
            }
        }
    }

    .dashboard-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-row {
            margin-bottom: 0;
        }

        .legacy-logo {

            img {
                width: 60px;
                margin: 10px 10px 0 2px;

                @include breakpoint(small) {
                    margin-top: 0;
                }

                @include breakpoint(large) {
                    margin-left: 11px;
                    margin-right: 6px;
                }
            }
        }
    }

    .overview-header {
        display: inline-block;

        @include breakpoint(medium) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 5px;
        }
    }

    .join-date {
        color: $c-black;
        font-family: $f-subheader;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1.29;
        text-transform: uppercase;

        &:not(.mobile) {
            @include bp(max-width, $bp-medium) {
                display: none;
            }
        }

        p {
            display: inline-block;
            margin-top: 1px;

            @include breakpoint(medium) {
                margin-bottom: 5px;
                margin-top: 14px;
            }

            &:first-of-type {
                margin-right: 4px;
            }
        }

        &.mobile {
            display: inline-block;

            @include breakpoint(small) {
                display: none;
            }
        }
    }

    .change-email-header {
        padding-top: 50px;

        @include breakpoint(large) {
            padding-top: 60px;
        }
    }

    // Contest
    .contests-list-table {
        display: block;
        width: 100%;

        .contests {
            @include breakpoint(large) {
                display: flex;
                    align-items: center;
                    justify-content: space-between;
            }
            .contest-name {
                font-size: 16px;
            }

            .winner-status {
                font-size: 16px;
            }

            .entries-status {
                @include breakpoint(large) {
                    text-align: right;
                }
            }
        }
    }
}

// Addresses
#addresses {
    .address-heading {
        @include breakpoint(medium) {
            font-size: 32px;
            line-height: 40px;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .address-create {
        text-transform: uppercase;
        float: none;

        @include bp(max-width, $bp-medium) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @include breakpoint(medium) {
            margin-bottom: 6px;
        }

        .message-mobile {
            @include breakpoint(medium) {
                display: none;
            }
        }

        .message-desktop {
            @include bp(max-width, $bp-medium) {
                display: none;
            }
        }
    }

    .header-button-wrapper {
        display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        margin-bottom: 16px;

        @include breakpoint(large) {
            margin-bottom: 23px;
        }
    }
}

// Payment Settings
.paymentslist {

    h1 {
        @include breakpoint(medium) {
            font-size: 32px;
            line-height: 40px;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .add-card {
        text-transform: uppercase;
        float: none;
        margin-top: 29px;

        @include breakpoint(large) {
            margin-top: 19px;
        }
    }

    .header-button-wrapper {
        @include breakpoint(large) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.payment-list {
    overflow: hidden;
    padding: 0;

    li {
        box-sizing: border-box;
        float: left;
        list-style: none outside none;
        margin: 0;
        min-height: 110px;
        width: 33%;
        padding-left: 0;
        padding-top: 19px;

        @include bp(max-width, $bp-small) {
            width: 100%;
        }
    }
}

// Order History
.pt_order {
    .order-history-h1 {        
        margin-bottom: 21px;

        @include breakpoint(large) {
            margin-bottom: 12px;
        }

        &-subtext {
            font-family: $f-subheader;
            font-size: 28px;
            font-weight: $f-weight-regular;
            letter-spacing: 0.5px;
            margin-left: 2px;
        }
    }
    
    &#wrapper {
        background-color: $c-mostly-white;
    
        #main {
            padding-bottom: 19px;
    
            @include breakpoint(medium) {
                padding-bottom: 26px;
            }
    
            @include breakpoint(large) {
                padding-bottom: 49px;
            }
        }
    
        .order-history {
            &-card {
                border: 1px solid $c-stroke-gray;
                margin-bottom: 8px;
                padding: 7px 0 10px;
                position: relative;
                width: 100%;
    
                @include breakpoint(large) {
                    padding-top: 20px;
                }
            }
    
            &-header,
            &-items {
                padding-left: 15px;
    
                @include breakpoint(large) {
                    padding-left: 20px;
                }
            }
    
            &-header {
                background: transparent;
                border-bottom: 1px solid $c-stroke-gray;
                padding-right: 14px;
        
                @include breakpoint(large) {
                    display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                    padding-bottom: 14px;
                    padding-top: 0;
                }
        
                .label {
                    color: $c-charcoal;
                    font-weight: $f-weight-regular;
                    margin-right: 4px;
    
                    @include breakpoint(medium) {
                        margin-right: 6px;
                    }
        
                    &.custserv {
                        color: $black;
                    }
                }
    
                .value {
                    font-weight: $f-weight-semibold;
                    text-transform: uppercase;
                }
        
                button {
                    float: none;
                    letter-spacing: 3px;
                    margin-top: 17px;
        
                    @include breakpoint(large) {
                        margin-top: 0;
                    }
                }
    
                .order-info-wrapper {
                    font-size: 14px;
                }
        
                .order-date,
                .order-status,
                .order-number {
                    float: none;
                    width: 100%;
                    display: block;
                    margin: 0 0 7px;
    
                    @include breakpoint(medium) {
                        margin-bottom: 6px;
                    }
                }
            }
    
            &-items {
                margin: 12px 0 10px;
                padding-right: 15px;
                padding-top: 4px;
    
                @include breakpoint(large) {
                    margin: 16px 0 0 0;
                    padding-right: 20px;
                }
    
                li {
                    padding-left: 0;
                    list-style: none;
                }
    
                .order-history-table {
                    @include responsive-table;
    
                    tbody, tr, th, td {
                        text-align: left;
                    }
    
                    tr > td {
                        margin-bottom: 10px;
                        display: block;
    
                        @include bp(max-width, $bp-large) {
                            width: 100%;
                        }
    
                        @include breakpoint(large) {
                            box-sizing: border-box;
                            display: inline-block;
                            margin-bottom: 9px;
                            padding-right: 20px;
                        }
    
                        &[data-title="Order Total:"] span {
                            font-weight: $f-weight-bold;
                        }
    
                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
    
                    tr > th {
                        @include breakpoint(small) {
                            display: none;
                        }
                    }
    
                    td {
                        font-size: 14px;
                        
                        @include breakpoint(medium) {
                            font-size: 16px;
                            line-height: 1.5;
                        }
    
                        &::before {
                            color: $c-charcoal;
                            font-size: 10px;
                            font-weight: $f-weight-semibold;
                            letter-spacing: 1px;
                            line-height: 1.4;
                            display: block;
                            text-transform: uppercase;
            
                            @include breakpoint(small) {
                                content: attr(data-title);
                            }
                        }
            
                    }
                    th,
                    td {
                        padding: 0;
                    }
    
                    ul {
                        margin: 0;
                        padding: 0;
                    }
    
                    li {
                        list-style: none outside none;
                    }
    
                    .toggle {
                        cursor: pointer;
                        display: block;
                    }
                }
            }
    
            &-items-list {
                position: relative;
                width: 47%;
    
                &:not(.order-history-items-list--last) {
                    @include breakpoint(medium) {
                        margin-bottom: 20px !important;
                    }
                }
    
                &--last {
                    @include bp(max-width, $bp-medium) {
                        margin-bottom: 14px !important;
                        padding-bottom: 16px !important;
                    }
        
                    &::after {
                        @include bp(max-width, $bp-medium) {
                            background-color: $c-stroke-gray;
                            content: '';
                            height: 1px;
                            position: absolute;
                                bottom: 0;
                                left: -15px;
                                right: -15px;
                        }
                    }
                }
            }
    
            &-customer{
                width: 28.5%;
            }
    
            &-total {
                margin-bottom: -4px !important;
                padding-top: 9px !important;
                width: 24.5%;
    
                @include breakpoint(medium) {
                    margin-bottom: 0;
                    padding-top: 0 !important;
                }
            }
    
            &-total {
                font-size: 14px !important; // Override overly specific selector
                font-weight: $f-weight-semibold;
                line-height: 1.8;
                text-align: right !important; // Override overly specific selector
            }
            
            &-submit {
                @include bp(max-width, $bp-medium) {
                    position: absolute;
                        bottom: 19px;
                        left: 15px;
                }
            }
        }
    
        .order-history-orders {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    
        .js .order-history-table .hidden {
            display: none;
        }
    }
}

// ================================================
// Gift Registries
// ================================================

.pt_gift-registry {
    .create-new-registry {
        margin: 1em 0;

        button {
            float: left;
            margin-right: 2em;
        }

        p {
            font-size: 1.3em;
        }
    }
    .add-gift-cert {
        border: 1px solid $white-smoke;
        margin-bottom: -1px;
        overflow: auto;
        padding: .83em;

        p {
            color: $nobel;
            font-style: italic;
            line-height: 1.5em;
        }
    }
    .hdr-options {
        width: 9%;
    }
}

.page-content-tab-navigaton button {
    background-color: $gainsboro;
    border-color: $white-smoke;
    border-width: 1px;
    color: $nobel;
    font-size: 1.1em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 -1px;

    &.selected {
        background-color: $white;
        border-bottom: 1px solid $white;
    }
}

.gift-reg-purchases {
    .quantity-purchased {
        color: $sangria;
    }
}

.gift-registry-advanced {
    @extend %clearfix;
    .toggle-content {
        display: none;
    }
}

.registry-results-list {
    @include responsive-table;
}

.registry-results-table {
    td {
        padding: 0;
    }
}

// ================================================
// Wish List
// ================================================

.pt_wish-list {
    .list-table-header {
        overflow: hidden;
        padding: 2em;

        p {
            color: $nobel;
            float: right;
            margin: 0;
            overflow: hidden;
            width: 40%;
        }
    }

    .primary-content .list-table-header h2 {
        float: left;
    }
}

.list-share {
    @extend %clearfix;
    border-top: 1px solid darken($white-smoke, 10%);
    padding: 16px 0;

    .share-option {
        box-sizing: border-box;
        display: block;
        margin-bottom: .5rem;
        float: left;
    }

    .share-options {
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1.7em;
        float: left;
        margin-left: 1rem;
        margin-top: 0.3rem;

        .share-title {
            display: inline;
        }
    }

    .list-title {
        float: left;
    }

    .print-page {
        float: right;
    }

    .share-link {
        cursor: pointer;
    }

    .share-link-content {
        display: none;
        &.active {
            display: block;
            float: left;
        }
    }
}

.event-info {
    margin: 1em;
}

.wishlist-search,
.registry-search {
    .form-row {
        @include bp(max-width, $bp-small) {
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    .form-row-button {
        margin-left: 0;
    }

    @include bp(max-width, $bp-medium) {
        .form-row {
            width: 100%;
            float: none;
            clear: both;
            margin: 0;
        }
    }
}

.pt_gift-registry,
.pt_wishlist,
.pt_account {
    .item-list {
        margin-top: 30px;
    }
}

.wishlist-item,
.registry-item {
    @extend %clearfix;
    border-bottom: 1px solid $white-smoke;
    padding-bottom: 16px;
    margin-bottom: 16px;

    @include breakpoint(medium) {
        .item-left,
        .item-right {
            width: calc(50% - 8px);
            float: left;
        }

        .item-left {
            margin-right: 16px;

            .item-image {
                float: left;
                width: 25%;
            }

            .item-details,
            .item-availability {
                float: right;
                width: 70%;
            }
        }
    }

    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
    }

    .form-indent {
        margin-left: 0;
    }
}

.product-availability-list {
    list-style: none;
    padding-left: 0;
}

.option-update,
.option-add-to-cart input {
    margin-bottom: 16px;
}

.wishlist-results-list {
    @include responsive-table;
}

.account-section-header {
    @include h3;

    @include breakpoint(medium) {
        @include h5;

        letter-spacing: 0.5px; 
    }
}

.account-primary-heading {
    @include h2;

    @include breakpoint(medium) {
        @include h3;
    }
}