.primary-content {
    dl {
        clear: both;
        overflow: hidden;
        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }
        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }
}

.col-1 {
    float: left;
    margin: 4px 0;
    width: 48%;
}

.col-2 {
    float: right;
    margin: 4px 0;
    width: 48%;
}

.content-header {
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 14px !important;
    span {
        font-size: 0.75em;
        font-style: normal;
        font-weight: 400;
    }
}

.section-header {
    margin: 0 0 20px;
    position: relative;
}

.section-header-note {
    text-transform: none;
    float: right;
}

.back {
    display: block;
    margin: 0 0 10px;
}
