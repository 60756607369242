.breadcrumb {
    font-size: $f-size-xs;
    line-height: 1em;
    margin: $breadcrumb-margin-top 0;
    @include breakpoint(medium) {
        margin: $breadcrumb-margin-top 0 0;
    }

    li {
        display: inline;
        list-style: none outside none;
    }

    .breadcrumb-result-text {
        text-transform: uppercase;
        color: $c-gray;
        font-family: $f-header;
        font-size: 27px;
        font-weight: 500;
        @include breakpoint(large) {
            font-size: 41px;
        }

        a {
            color: $black;
        }
    }
}

.paging-current-page {
    left: -999em;
    position: absolute;
}

.breadcrumb-element {
    color: $f-body-color;
    font-size: 10px;
    font-weight: $f-weight-bold;
    letter-spacing: 1px;
    line-height: 1;
    margin-left: 5px;
    text-transform: uppercase;

    &::after {
        margin-left: 11px;
        content: '>';
        display: inline-block;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    &:first-child {
        margin-left: 0;
    }
}

.breadcrumb-refinement {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1;

    &:after {
        content: attr(data-divider);
        font-style: normal;
    }

    &:last-of-type {
        &:after {
            content: '';
        }
    }

    .svg-close-dims {
        height: 10px;
        vertical-align: top;
        width: 10px;
    }
}

.breadcrumb-refinement-value {
    border: 1px solid $f-body-color;
    display: inline-flex;
        align-items: center;
    color: $f-body-color;
    font-size: 10px;
    font-weight: $f-weight-bold;
    letter-spacing: 1px;
    line-height: 1;
    margin: 4px 2px;
    padding: 6px 10px;
    text-transform: uppercase;

    &--clear {
        color: $white;
    }
}

.breadcrumb-relax {
    background: none;
    display: inline-block;
    font-style: normal;
    height: 10px;
    padding-left: 5px;

    svg {
        height: 10px;
        vertical-align: top;
        width: 10px;
    }
}

.breadcrumb .searchphraselink,
.breadcrumb-result-text a {
    background: none;
    color: inherit;
    padding: 0;
}