@mixin make-default() {
  &,
  &-auto {
    max-width: 100%;
    padding: $grid-small-padding #{$grid-small-padding/2} 0 #{$grid-small-padding/2};

    @include breakpoint(medium) {
        padding: $grid-medium-padding #{$grid-medium-padding/2} 0 #{$grid-medium-padding/2};
    }

    @include breakpoint(large) {
        padding: $grid-large-padding #{$grid-large-padding/2} 0 #{$grid-large-padding/2};
    }

    @include breakpoint(xlarge) {
        padding: $grid-xlarge-padding #{$grid-xlarge-padding/2} 0 #{$grid-xlarge-padding/2};
    }
  }

  & {
    flex-grow: 1;
    flex-basis: 0;
  }

  &-auto {
    flex-basis: auto;
    width: auto;
  }
}

@mixin make-offset($columns) {
  @for $number from 1 through $columns {
    &-offset-#{$number} {
      margin-left: (100 / $columns * $number)#{'%'};
    }
  }
}

@mixin make-column($columns ) {
  @for $number from 1 through $columns {
    &-#{$number} {
      width: (100 / $columns * $number)#{'%'};
      flex: none;
    }
  }
}

@mixin make-grid-column($breakpoint, $columns) {
  @include make-helpers($breakpoint);
  .col--#{$breakpoint} {
    @include make-default();
    @include make-column($columns);
    @include make-offset($columns)
  }
}

@mixin init($breakpoints, $columns) {
  @each $breakpoint, $width in $breakpoints {
    @if ($width == 0) {
      @include make-grid-column($breakpoint, $columns);
    } @else {
      @media (min-width: $width) {
        @include make-grid-column($breakpoint, $columns);
      }
    }
  }
}