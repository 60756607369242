
.pt_news, .pt_article {
    .page-title {
        line-height: 22px;
        letter-spacing: 3px;
        opacity: .15;

        @include breakpoint(medium) {
            line-height: 37px;
            font-size: 56px;
        }

        @include breakpoint(large) {
            line-height: 32px;
            font-size: 48px;
        }
    }

    .accent {
        &.accent-nowrap {
            white-space: nowrap;
        }
    }
}

.pt_news,
.amp-news {

    &.amp-news--fifthMember {
        position: relative;
        margin-bottom: 100px;

        h2 {
            margin-bottom: 20px;
            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        .news-articles {
            position: relative;
            margin-bottom: 40px;
        }

        .more-article-date {
            color: $brand-primary;
        }

        .more-article-item {
            @include bp(max-width, $bp-large) {
                margin-bottom: 0;
            }
        }
    }

    .primary-content {
        position: relative;
    }

    .news-top-bar {
        align-items: flex-end;
        border-bottom: 1px solid $c-black;
        padding-bottom: 15px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        @include breakpoint(large) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 12px;
            flex-grow: 1;
        }

        .top-bar-filters {
            margin-top: 20px;

            @include breakpoint(large) {
                margin-top: 0;
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }

            .select-wrapper {
                background: $white;
                margin: 0 0 5px 0;

                @include breakpoint(large) {
                    margin: 0 5px 0 0;
                }

                &::after {
                    right: 15px;
                }
            }

            select {
                min-width: 155px;

                @include breakpoint(large) {
                    height: 36px;
                }

                &::after {
                    z-index: 0;
                }
            }

            .pagination {
                margin: 0;
            }
        }
    }

    .news-title {
        &:not(.news-title--no-border) {
            border-bottom: 1px solid $c-black;
            margin-bottom: 20px;
            padding-bottom: 15px;
        }

        &--no-border {
            margin-bottom: 16px;
        }
    }

    .more-articles {
        margin-bottom: 10px;
        @include breakpoint(large) {
            margin: -40px -4px 40px -4px;
            .col {
                padding: 40px 4px 0 4px
            }
        }
    }
    .more-article-item {
        @include bp(max-width, $bp-large) {
            margin-bottom: 30px;
        }
    }
    .more-articles-titles {
        display: block;
        border-bottom: 1px solid $brand-primary;
        margin-bottom: 20px;
        padding-bottom: 15px;
        width: 100%;
    }
    .more-article-img {
        overflow: hidden;
        margin-bottom: 10px;
    }
    .more-article-date {
        color: $c-charcoal;
        font-size: 10px;
        font-weight: $f-weight-bold;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 4px;
        text-transform: uppercase;
    }
    .more-article-name {
        display: inline-block;
        font-family: $f-header;
        font-size: 18px;
        font-weight: $f-weight-bold;
        line-height: 1.22;
        letter-spacing: 0.5px;
        margin-right: 25px;
        text-transform: uppercase;
        width: auto;
    }
    .articles-load-more {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        @include breakpoint(large) {
            margin-bottom: 100px;
        }
    }

    .news {
        .news-item {
            margin-bottom: 60px;
            position: relative;

            @include breakpoint(large) {
                &:nth-of-type(even) {
                    .news-item__img {
                        order: 2;
                        padding-left: 20px;
                        padding-right: 0;
                    }

                    .news-item__data {
                        order: 1;

                        & > div {
                            text-align: right;
                        }
                    }
                }
            }

            @include breakpoint(large) {
                margin-bottom: 100px;
                display: flex;
            }

            &:last-child {
                margin-bottom: 48px;

                @include breakpoint(large) {
                    margin-bottom: 70px;
                }
            }

            &__img {
                margin-bottom: 20px;
                overflow: hidden;

                @include breakpoint(large) {
                    margin-bottom: 0;
                    padding-right: 20px;
                    width: 66.5%;

                    img {
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }

            &__data {
                @include breakpoint(large) {
                    width: 33.5%;
                }
                &__date {
                    font-family: $f-subheader;
                    font-size: 14px;
                    line-height: 1.29;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: 5px;

                    @include breakpoint(large) {
                        font-size: 21px;
                    }
                }

                &__title {
                    font-family: $f-header;
                    font-size: 20px;
                    line-height: 1.1;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    margin-bottom: 10px;

                    @include breakpoint(large) {
                        font-size: 28px;
                        line-height: 1.14;
                    }
                }

                &__description {
                    font-size: 14px;
                    line-height: 1.43;
                    margin-bottom: 20px;
                }

                &__link {
                    position: static;

                    &::before {
                        // Cover full tile
                        content: '';
                        position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            top: 0;
                    }
                }
            }
        }
    }

    .content-hero {
        position: relative;

        .hero-content-desktop {
            @include position(absolute, null null 18px 50%);
            transform: translateX(-50%);
            color: $white;
            text-align: center;
            width: 100%;

            h2 {
                @include h4;
                color: $white !important; // yes this is needed :)

                @include breakpoint(medium) {
                    @include h2;
                }
            }

            p {
                display: none;
                margin: 4px 0 25px 0;

                @include breakpoint(large) {
                    display: block;
                }
            }

            .button {
                display: none;
                @include hollow-button;

                @include breakpoint(large) {
                    display: inline-block;
                }
            }

            @include breakpoint(medium) {
                max-width: 460px; // magic number
                width: auto;
            }

            @include breakpoint(large) {
                bottom: 27px;
            }

            @include breakpoint(xlarge) {
                bottom: 35px;
            }
        }
    }

    .hero-content-mobile {
        text-align: center;

        p {
            margin: 5px 0 0 0;
            font-size: $f-size-xs;
        }

        h2 {
            display: none;
        }

        .button {
            margin-top: 17px;
        }

        @include breakpoint(large) {
            display: none;
        }

        & + .pagination-wrapper {
            margin-top: 35px;

            @include breakpoint(large) {
                margin-top: 0;
            }
        }
    }

    .tiles-container {
        @include breakpoint(large) {
            padding-top: 35px;
        }
    }

    .pagination-wrapper {
        margin: 0 0 32px 0;

        @include breakpoint(medium) {
            margin-bottom: 40px;
        }

        @include breakpoint(large) {
            margin: 0;
            @include position(absolute, 3px 16px null null);
        }

        @include variant(bottom) {
            position: relative;
            top: inherit;
            bottom: inherit;
            right: inherit;

            @include breakpoint(medium) {
                margin-bottom: 24px;
            }

            @include breakpoint(large) {
                margin-bottom: 32px;
            }
        }
    }

    .pagination {
        margin: 0;
    }

    .page-title {
        opacity: 1;
        @include breakpoint(large) {
            line-height: 37px;
            font-size: 56px;
        }

        @include breakpoint(xlarge) {
            line-height: 64px;
            font-size: 96px;
        }
    }

    .content-tile {
        margin-bottom: 24px;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            flex-direction: row;
            flex-wrap: nowrap;
        }

        .content-body {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }

            @include breakpoint(large) {
                margin: 0;
            }
        }

        .thumb-link {
            padding-bottom: 52.55%;
            height: 0;

            &.js-blazy-wrapper--finished {
                padding-bottom: 0;
                height: auto;
            }
        }

        .content-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            @include breakpoint(large) {
                flex-basis: 87%;
                margin-left: 16px;
                display: block;
                max-width: 50%;
            }

            @include breakpoint(xlarge) {
                margin-left: 24px;
                max-width: 49%;
            }
        }

        .content-title {
            margin-left: 0;

            @include breakpoint(medium) {
                margin-left: 8px;
                margin-right: 8px;
            }

            @include breakpoint(large) {
                margin: 0;

                @include has(date) {
                    line-height: 21px;
                }
            }
        }

        .content-title__name {
            line-height: 24px;
            margin-top: 1px;

            @include breakpoint(medium) {
                margin-bottom: 5px;
            }

            @include breakpoint(large) {
                font-size: 20px;
                margin-top: 3px;
                line-height: 24px;
            }
        }
    }
}

.amp-news {
    &--store {
        margin-bottom: 105px;
        padding-top: 65px;

        @include breakpoint(medium) {
            padding-top: 130px;
        }

        @include breakpoint(large) {
            margin-bottom: 60px;
        }

        .c-tile-slider__header {
            position: relative;
        }

        .c-tile-slider__header * {
            margin-bottom: 0;
        }

        .js-data-carousel {
            position: static;
        }

        .more-article-date {
            color: $c-black;
        }

        .more-article-item {
            & >  .more-article-name {
                box-sizing: border-box;
                display: block;
                padding-right: 25px;
            }
        }

        .more-article-name {
            margin: 0;
        }
    }
}

.pt_article {
    .primary-content, p {
        font-size: $f-size-xs;

        @include breakpoint(large) {
            font-size: $f-size;
        }
    }

    .content-asset {
        .article-title {
            text-align: center;
            @include h4;
            margin-top: 1px;

            @include breakpoint(medium) {
                @include h2;
            }

            @include breakpoint(large) {
                margin-top: 71px;
            }

            @include breakpoint(large) {
                margin-top: 18px;
            }
        }

        .h-caption {
            margin: 3px 0 0;
            text-align: center;

            @include breakpoint(medium) {
                font-size: 12px;
                margin-top: 1px;
            }
        }
    }

    .tertiary-content {
        margin-top: 9px;
        padding-bottom: 23px;

        @include breakpoint(large) {
            padding-bottom: 46px;
        }
    }
}

.store-news-load-more {
    border-bottom: 1px solid $c-black;

    &-wrap {
        margin-bottom: 65px;
        text-align: center;

        @include breakpoint(large) {
            margin-bottom: 70px;
        }
    }
}