.js .simple-submit {
    display: none;
}

legend {
    font-size: $legend-font-size;
    margin: 0;
    padding: $legend-padding-top 0 $legend-padding-bottom;
    font-weight: $legend-font-weight;
    position: relative;
    line-height: 1.3;

    span {
        font-size: 12px;
        font-weight: 400;
        position: relative;
        text-transform: none;
        padding: 0 .5em;

        em {
            color: $night-rider;
        }
    }
}

label {
    display: block;
    font-size: $label-f-size;
    font-weight: $label-f-weight;
    letter-spacing: 1px;
    line-height: $input-f-lh;
    margin-bottom: $label-margin-bottom;
    color: $c-black;
    text-transform: uppercase;

    .label-inline & {
        display: inline;
        float: none;
        font-weight: $f-weight-medium;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        text-align: left;
        text-transform: none;
        width: auto;
    }
}

.form-label-text {
    float: left;
    font-size: 1.1em;
    font-weight: 700;
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.form-row {
    @extend %clearfix;
    margin: $form-row-margin-top 0 $form-row-margin-bottom;
    
    &-inline {
        display: inline-block;
        margin-top: 7px;
        margin-right: 15px;
    }
}

.form-row-button {
    clear: left;
}

.field-wrapper {
    box-sizing: border-box;
    line-height: 1;
    margin-bottom: 0;
    width: 100%;

    .label-inline & {
        padding-left: 0;
        width: auto;
    }
    .label-above & {
        float: none;
        padding-left: 0;
        width: auto;
    }
}

.form-columns {
    fieldset {
        @include clearfix;
    }

    @include breakpoint(medium) {
        fieldset .form-row {
            float: left;
            width: calc(50% - 12px);

            &:nth-child(odd) {
                clear: both;
            }

            &:nth-child(even) {
                float: right;
            }

            &.form-row--no-float {
                float: none;
                clear: both;
            }

            &.form-row-button {
                float: left;
                clear: both;
                text-align: left;
            }
        }
    }

    .form-row.full-width {
        width: 100%;
    }
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
textarea {
    border: 1px solid $c-input-border;
    border-radius: $input-border-radius;
    box-sizing: border-box;
    font-size: $input-f-size;
    height: $input-text-height;
    line-height: 1.5;
    padding: $input-padding;
    text-indent: 0;
    font-weight: $f-weight-regular;
    width: 100%;
    -webkit-appearance: none;

    @include placeholder {
        color: $c-gray;
    }

    &:focus {
        outline: none;
        border-color: $c-dark-gray;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $white inset;
    }

    &.error {
        background: url("../images/svg/intermediate-svg/input-error.svg") no-repeat;
        background-color: $white;
        background-position: calc(100% - 19px) center;
        content: '';
        color: $c-red;
        display: block;
    }

    &.disabled,
    &:disabled {
        background-color: $c-light-gray;
        border-color: $c-stroke-gray;
        color: $c-charcoal;
    }
}

textarea {
    height: auto;

    &.valid, &.error {
        background: none
    }
}

select {
    background-color: #fff; // set background color to make height work
    border: 1px solid $c-input-border;
    font-size: $input-f-size;
    border-radius: $input-border-radius;
    font-weight: $f-weight-regular;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 25px 0 16px;
    width: 100%;
    height: $input-text-height;
    line-height: $select-height;
    position: relative;

    &:focus {
        outline: none;
    }

    &.algolia-search-filter {
        font-size: 15px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}

.select-wrapper {
    position: relative;

    &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid $c-black;
        content: '';
        pointer-events: none;
        position: absolute;
            right: 21px;
            top: 50%;
        transform: translateY(-50%);
    }
}

input[type="checkbox"],
input[type="radio"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: none;

    ~ label {
        font-weight: $f-weight-medium;
    }
}

input[type="checkbox"] {
    &:before {
        content: '';
        display: block;
        width: $input-checkbox-size;
        height: $input-checkbox-size;
        background-color: $white;
        border: $input-checkbox-border;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        cursor: pointer;

        @media screen and (min-width: 768px) {
            width: $input-checkbox-size-md;
            height: $input-checkbox-size-md;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
        width: $input-checkbox-size-inner;
        height: $input-checkbox-size-inner;
        transform: translate(-50%, -50%);
        transition: background-image 0.2s ease, background-color 0.2s ease;

        @media screen and (min-width: 768px) {
            width: $input-checkbox-size-inner-md;
            height: $input-checkbox-size-inner-md;
        } 
    }

    &:checked:after {
        background-color: $c-black;
    }

    &:focus {
        outline: none;
    }

    &:hover::after {
        @include breakpoint(large) {
            @include svg-view-checkbox-rollover-graphic();
        }
    }
}

input[type="radio"] {
    &:before {
        content: '';
        display: block;
        width: $input-radio-size;
        height: $input-radio-size;
        border-radius: 50%;
        background-color: $white;
        border: $input-radio-border;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        cursor: pointer;
    }

    &:checked:before {
        border: $input-radio-border-active;
    }

    &:checked:after {
        content: '';
        display: block;
        width: $input-radio-size-inner;
        height: $input-radio-size-inner;
        border-radius: 50%;
        border: 2px solid $white;
        background-color: $c-black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }


    &:focus {
        outline: none;
    }

    ~ label {
        margin-left: 12px;
    }
}

.label-inline {
    display: flex;
        align-items: center;
        flex-wrap: wrap;
    line-height: 20px;

    .field-wrapper {
        line-height: 1;
        margin-bottom: 0;
    }

    label {
        letter-spacing: normal;
        margin-left: 12px;
        vertical-align: top;
    }

    .input-checkbox {
        @include breakpoint(medium) {
            margin-right: -2px;
        }
    }

    .form-field-subcopy {
        font-size: 12px;
        font-weight: $f-weight-regular;
        letter-spacing: normal;
        text-transform: none;
    }
}

.input-radio {
    display:inline-block;
    height: $input-radio-size;
    width: $input-radio-size;
}

.input-checkbox {
    display:inline-block;
    height: $input-checkbox-size;
    width: $input-checkbox-size;

    @include breakpoint(medium) {
        height: $input-checkbox-size-md;
        width: $input-checkbox-size-md;
    }
}

.form-caption {
    clear: left;
    width: auto;
    color: $brand-primary;
    font-size: $input-message-f-size;
    line-height: $f-size-xs;

    .label-above & {
        margin-left: 0;
    }

    .form-indent & {
        margin-left: 0;
    }
}

.error-message {
    border: 2px solid $sangria;
    background-color: $tutu;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;

    &.captcha-error{
        display: inline-block;
        min-width: 80%;
        margin-top: 5px;
    }
}

.form-field-subcopy-wrap {
    display: grid;
        gap: 24px;
        grid-auto-rows: auto;
        grid-template-columns: auto auto;
        justify-content: space-between;

    .form-field-tooltip {
        grid-column-start: 2;
        text-align: right;
    }
}

span.error,
div.error,
.form-vinylclub-editcard label.error,
.form-field-subcopy {
    color: $c-charcoal;
    font-size: $input-message-f-size;
    font-weight: $f-weight-semibold;
    letter-spacing: $input-message-f-spacing;
    line-height: $input-message-f-lh;
    text-transform: uppercase;
    vertical-align: top;

    &:not(:empty) {
        padding-top: $input-message-padding-top;
    }
}
span.error,
div.error,
.form-field-subcopy {
    display: inline-block;
}
span.error,
div.error,
.form-vinylclub-editcard label.error {
    background: none;
    color: $c-red;
    
    &:not(.form-row) {
        margin-bottom: 4px;
    }
}

span.error {
    display: inline-block;
}

.form-field-subcopy {
    .subcopy-full & {
        grid-column-end: span 2;
    }
}

div.gc_error {
    background: none;
    color: $c-red;
    font-size: 14px;
}

.error-form {
    background: rgba($c-red, 0.1);
    border: 1px solid $c-red;
    color: $c-red;
    margin-top: 0.5em;
    margin-bottom: 2em;
    margin-left: 0;
    margin-right: 0;
    padding: 7px 12px;
    font-size: $f-size-xs;
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }
    label {
        padding: 0 0 0.75em;
        text-transform: uppercase;
        width: auto;
    }
    input[type="text"], select {
        margin-left: 0;
        width: 95%;
    }
    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;
    label {
        width: auto;
    }
}

// Required Indicator and Text
.dialog-required {
    position: relative;
    line-height: 16px;
    display: block;
    font-size: 10px;
    text-align: right;
    margin-bottom: 11px;

    em {
        color: $c-black;
        font-style: normal;
        text-transform: uppercase;
        vertical-align: sub;
        letter-spacing: 1px;
        margin-left: 1px;
    }
}

.form-field-tooltip {
    float: left;
    line-height: 14px;
    font-size: $input-message-f-size;
    font-weight: $f-weight-semibold;
    letter-spacing: 1px;
}

.form-double {
    .form-row,
    .form-expiration {
        @include breakpoint(medium) {

            float: left;
            width: calc(50% - 4px);

            &:nth-of-type(odd) {
                clear: both;
            }

            &:nth-of-type(even) {
                float: right;
            }

            &.form-row--no-float {
                float: none;
                clear: both;
            }

            &.form-row-button {
                float: right;
                clear: both;
                text-align: right;
                margin-bottom: 0;
                width: 100%;

                button, .button {
                    margin-left: 15px;
                }
            }
        }
    }
}

.c-product-quantity {
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    display: block;
    height: 42px;
    margin-right: 20px;
    width: 120px;
    #{$dark-theme-selector} & {
        border-color: $white;
    }

    &__nav {
        display: flex;
            align-items: center;
            justify-content: space-between;

        #{$dark-theme-selector} & {
            background-color: $brand-primary;
        }
    }

    &__input {
        background: $white;
        font-family: $f-body-bold;
        font-size: 15px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: 25px;

        @include breakpoint(large) {
            width: 50px;
        }

        @at-root input#{&} { // Stronger selected needed for base selector
            border: 0;
            height: 36px;
            padding-left: 5px;
            padding-right: 5px;
            min-width: 30px;
            -moz-appearance: textfield; //remove firefox arrows
        }

        #{$dark-theme-selector} & {
            background-color: $brand-primary;
            color: $white;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            margin: 0;
        }

        &.quantity {
            &:disabled {
                background: $white;
                color: $black;
                opacity: 1;
            }
        }
    }

    &__button {
        cursor: pointer;
        font-family: $f-body-bold;
        font-size: 16px;
        line-height: 40px;
        user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        padding: 0;

        &--down {
            padding-left: 20px;
            padding-right: 8px;
        }

        &--up {
            padding-right: 20px;
            padding-left: 8px;
        }
    }
}

.form {
    &-header {
        display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 16px;
        margin-bottom: 16px;

        @include breakpoint(large) {
            margin-bottom: 18px;
        }

        &--block {
            display: block;

            @include breakpoint(large) {
                margin-bottom: 15px;
            }

            .form-heading {
                margin-bottom: 0;
            }
        }
    }

    &-heading {
        flex: 1 0 0;
        font-size: 32px;
        letter-spacing: 0.5px;

        @include breakpoint(large) {
            font-size: 34px;
            letter-spacing: normal;
            line-height: 1.13;
        }
    }

    &-required {
        margin-bottom: 4px;
        margin-top: -4px;
        text-align: left;
        white-space: nowrap;

        @include breakpoint(large) {
            margin-top: 2px;
        }
    }
}