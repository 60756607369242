.store-locator-header {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
	font-size: 1.75em;
	font-style: italic;
	font-weight: 400;
	margin: 20px 0 0;
	padding: 20px 0;
}

.store-information {
	background: none repeat scroll 0 0 $gainsboro;
	border-bottom: 1px solid $white;
}

.store-name {
	font-weight: bold;
	margin: 0 0 10px;
}

.store-map {
	border-bottom: 1px solid $gainsboro;
}

.store-locator-details {
	float: left;
	width: 44%;
	img {
		max-width: 100%;
	}
	h1, p {
		margin: 0.25em 0;
	}
}

.store-locator-details-map {
	float: right;
	width: 50%;
	img {
		max-width: 100%;
	}
}
