.video-container {
    .video-image {
        position: relative;
    }

    .video-image:before {
        @include svg-view-btn_youtube_mobile;
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
}
