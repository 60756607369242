// Account Login
#PasswordResetForm {
    fieldset {
        position: relative;
        padding-bottom: 30px;
    }

    button {
        display: inline-block;
    }
}

div.modalheader {
    padding-top: 20px;
}

.new-site-dialog {
    background: $black;

    * {
        color: $white;
    }

    a {
        color: $c-red;
    }

    .ui-dialog-buttonset {
        .ui-button {
            @include hollow-button;
            margin-left: 15px;

            span {
                padding: 5px 30px;
            }
        }
    }

    .ui-dialog-titlebar-close {
        background: none;
        top: -34px;
        right: 5px;
        width: 40px;

        .ui-button-text {
            text-indent: 0;
            color: $white;
            text-transform: none;
            font-size: 16px;
        }

        .ui-button-icon-primary {
            @include svg-view-close-white;
            position: absolute;
            left: -12px;
        }

        &:before, &:after {
            display: none;
        }
    }

    &.gdpr .ui-dialog-titlebar-close {
        display: none;
    }
}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.header-date {
    display: flex;
        justify-content: space-between;
    width: 100%;
}

.profile-data-download {
    box-sizing: border-box;
    display: block;
    letter-spacing: 3px;
    margin-left: auto;
    width: max-content;

    @include bp(max-width, $bp-medium) {
        width: 100%;
    }
}

// My Account
// (powered by the 'account-landing' content asset)
// forgive the specificity, this is necessary to override the _slots.scss
.content-asset {
    .row {
        margin-top: 0;
    }

    .col {
        padding-top: 0;
    }

    .account-options {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        margin-top: 0;
        padding-left: 0;
    
        @include breakpoint(large) {
            margin-bottom: 25px;
            margin-left: -4px;
            margin-right: -4px;
        }
    
        h2 {
            font-size: 16px;
            display: inline-block;
            line-height: 1;
        }
    
        p {
            margin-top: 0;
        }
    
        li {
            background-color: $c-black;
            background-size: cover;
            border: 0;
            color: $white;
            width: 100%;
            border-radius: 0;
            list-style: none;
            margin: 4px 0;
            box-sizing: border-box;
            text-align: left;
    
            @include breakpoint(large) {
                margin-left: 4px;
                margin-right: 4px;
                width: calc(50% - 8px);
            }
    
            h3,
            h4,
            h5 {
                color: inherit;
                font-size: 20px;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                margin-top: 0;

                @include breakpoint(medium) {
                    font-size: 21px;
                    margin-bottom: 5px;
                }
            }
    
            a { 
                box-sizing: border-box;
                color: inherit;
                display: inline-block;
                padding: 16px;
                vertical-align: top;
                width: 100%;

                @include breakpoint(medium) {
                    padding: 20px;
                }
    
                p {
                    font-size: 14px;
                    margin-bottom: 20px;
                    line-height: 20px;
    
                    @include breakpoint(large) {
                        max-width: 275px;
                    }
    
                    @include breakpoint(xlarge) {
                        max-width: none;
                    }

                    &:last-child {
                        @include bp(max-width, $bp-medium) {
                            margin-bottom: 8px;
                        }
                    }
                }
    
                &::after {
                    background-image: url('../images/svg/intermediate-svg/arrow-right-white.svg'); // Due to needs for white space, sprite functionality not appropriate
                    background-position: center center;
                    background-repeat: no-repeat;
                    border: 1px solid $white;
                    content: '';
                    display: block;
                    height: 34px;
                    margin-bottom: -1px;
                    margin-left: auto;
                    margin-right: -1px;
                    margin-top: 8px;
                    width: 34px;

                    @include breakpoint(medium) {
                        margin-bottom: -10px;
                        margin-right: -10px;
                        margin-top: 34px;
                    }
                }
            }
        }
    }
}

.registration-selection {
    input, label {
        display: inline;
        float: none;
    }
}

.js-payment-instrument-form {
    .form-row {
        &.disabled {
            display: none;
        }

        &.enabled {
            display: block;
        }

        &.account-dialog-buttons {
            display: flex;
                flex-wrap: wrap;
                gap: 8px;
            float: none;
            padding-top: 2px;
            width: 100%;

            @include breakpoint(medium) {
                flex-wrap: nowrap;
                padding-top: 12px;
            }

            .account-dialog-btn {
                flex-grow: 0;
                flex-shrink: 0;
                margin: 0;
                width: 100%;
        
                @include breakpoint(medium) {
                    width: calc(50% - 4px);
                }
            }
        }
    }
}

#edit-address-form {
    .form-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .form-row {
            width: 100%;
            float: none;

            @include breakpoint(large) {
                width: calc(50% - 4px);
            }

            &:first-child { // Lacking a good way to apply classes to this element, target the name element as the first one in the form
                @include breakpoint(large) {
                    width: 100%;
                }
            }

            &.disabled {
                display: none;
            }

            &.enabled {
                display: block;
            }
        }
    }
}

.address-list,
.payment-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
        padding: 1em;
        
        &.default {
            background-color: $white-smoke;
            border: 1px solid $very-light-gray;
            border-radius: 5px;
            margin-top: 1em;
        }
        address {
            color: $dim-gray;
            font-style: normal;
        }
        a,
        .button-text {
            line-height: 2rem;
        }
    }
    .mini-address-title {
        font-weight: $f-weight-bold;
        text-transform: uppercase;
    }
    .cc-owner {
        font-weight: 700;
    }

    .button-text.delete {
        color: $c-red;
        font-weight: $f-weight-regular;
        text-transform: none;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;

        &:before, &:after {
            display: none;
        }
    }
}

.payment-list {
    li {
        width: 90%;
        @media screen and (min-width: 768px) {
            width: 25%;
        }

        &.default {
            width: 85%;
        }

        a,
        .button-text {
            padding-right: 21px;
        }
    }

    .mini-address-title {
        font-style: normal;
        color: $c-gray;
        line-height: 20px;
        margin-bottom: 2px;
    }
}

.address-list {
    padding: 0;
}

.address-group {
    display: flex;
        flex-wrap: wrap;
        gap: 8px;
    padding-left: 0;
    width: 100%;

   @at-root li#{&}-wrap { // Stronger selector needed
        padding-left: 0;
        padding-right: 0;
    }
    
    &-wrap {
        margin-bottom: 15px;
        width: 100%;

        @include breakpoint(medium) {
            margin-bottom: 25px;
        }
    }

    &-title {
        font-size: 15px;
        letter-spacing: 3px;
        line-height: 1;
        margin-bottom: 18px;

        @include breakpoint(medium) {
            margin-bottom: 9px;
        }
    }
    
    li {
        box-sizing: border-box;
        list-style: none outside none;
        margin: 0;
        padding: 20px;
        width: calc(50% - 4px); // 6px accounts for the grid gap to the closest whole pixel

        @include breakpoint(large) {
            width: calc(33.3% - 6px); // 6px accounts for the grid gap to the closest whole pixel
        }

        &.default {
            background-color: transparent;
            border-radius: 0;
            margin-top: 0;
        }

        @include bp(max-width, $bp-small) {
            width: 100%;
        }

        &.address-tile {
            border: 1px solid $c-stroke-gray;
        }

        .mini-address-name,
        .mini-address-location {
            color: $c-charcoal;
            font-size: 14px;
            line-height: 1.43;

            @include breakpoint(medium) {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        .mini-address-location {
            margin-bottom: 10px;
        }

        a {
            font-size: 14px;
            font-weight: $f-weight-medium;
            line-height: 1.43;
            text-decoration: underline;
        }

        a,
        .button-text {
            padding-right: 16px;
        }
    }

    .mini-address-title {
        font-family: $f-header;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 1.25;
        margin-bottom: 10px;

        @include breakpoint(medium) {
            font-size: 18px;
            line-height: 1.22;
        }
    }
}

//Presale Codes page
#primary {
    &.account-primary {
        .presale-above-form {
            padding-top: 0;

            .presale-above-item {
                display: block;
                margin-bottom: 20px;
                text-align: center;
            }
        }

        .presale-below-code-message {
            @include breakpoint(medium) {
                padding-top: 0;
            }
        }

        .presale-form-divider {
            padding-bottom: 30px;

            @include breakpoint(medium) {
                padding-bottom: 40px;
            }

            @include breakpoint(large) {
                padding-bottom: 54px;
            }
        }
        .fifth-member-section {
            padding: 0;
            position: relative;
            &.fifth-member-section-mobile {
                display: block;
                margin-bottom: 14px;
                .c-icon-grid__tile__title:not(.and-more) {
                    &::after {
                        content: ".";
                        display: inline;
                        position: relative;
                        left: -4px;
                    }
                }
                @include breakpoint(large) {
                    display: none;
                }
            }
            &.fifth-member-section-desktop {
                display: none;
                @include breakpoint(large) {
                    display: block;
                    right: -30px;
                }
                @include breakpoint(xlarge) {
                    right: 0;
                }
            }
            .section-content {
                padding: 0;
                margin-top: 0;
                background-color: $c-medium-gray;
                @include breakpoint(large) {
                    margin-top: 24px;
                }
                .c-icon-grid {
                    max-width: 100%;
                    padding: 8px 16px 12px 16px;
                    @include breakpoint(large) {
                        padding: 25px 25px 1px 25px;
                    }
                }
                .c-icon-grid__heading {
                    font-size: 26px;
                    display: none;
                    @include breakpoint(large) {
                        display: block;
                    }
                }
                .c-icon-grid__desc {
                    font-size: 14px;
                    margin: 10px auto 20px;
                    @include breakpoint(large) {
                        font-size: 16px;
                        margin: 10px auto 10px;
                    }
                }
                .c-icon-grid__tile {
                    padding: 20px 20px 0 20px;
                }
                .c-icon-grid__tile__text {
                    display: none;
                }
                .c-icon-grid__tile__content {
                    display: none;
                    @include breakpoint(large) {
                        display: block;
                    }
                }
                .c-icon-grid__grid {
                    display: none;
                    @include breakpoint(large) {
                        display: flex;
                        justify-content: center;
                        margin-top: 0;
                    }
                }
                img.c-icon-grid__tile__img {
                    max-width: 70px;
                    font-size: 16px;
                    display: none;
                    margin: 0 auto 20px auto;
                    @include breakpoint(large) {
                        display: flex;
                    }
                }
                .c-icon-grid__tile__title {
                    display: inline;
                    @include breakpoint(large) {
                        display: block;
                    }
                    &.mobile-only {
                        display: inline;
                        @include breakpoint(large) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.presale-header, .freedownloads-header {

    @include breakpoint(medium) {
        font-size: 32px;
        line-height: 40px;
        margin-top: 10px;
    }

    @include breakpoint(large) {
        margin-bottom: 11px;
    }
}

.presale-header {
    &.required-header {
        display: flex;
            align-items: baseline;
            justify-content: space-between;
    }

    .required-indicator {
        color: $c-charcoal;
        font-family: $f-body;
        font-size: 10px;
        font-weight: $f-weight-semibold;
        line-height: 1.4;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.presale-codes {
    padding-top: 0;

    .presale-form {
        legend {
            padding-bottom: 10px;
        }

        .select-form {
            select {
                font-size: 15px;
                font-weight: $f-weight-bold;
                line-height: 1;
                letter-spacing: 3px;
                text-transform: uppercase;
            }
        }

        .form-row-agreetoterms {
            padding-top: 20px;
            label {
                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .form-row-button {
            button {
                width: 100%;
            }
        }
    }

    .presale-codes-confirm-container,
    .no-presale-codes {
        box-sizing: border-box;
        display: block;
        background: $brand-primary;
        padding: 35px 20px;
        margin-bottom: 80px;
        text-align: center;
        width: 100%;

        @include bp(max-width, $bp-large) {
            margin-top: 20px;
        }

        @include breakpoint(large) {
            padding: 50px;
        }

        h5,
        .no-presale-title {
            color: $white;
            font-size: 21px;
            line-height: 1.29;
            letter-spacing: 1px;
            margin-bottom: 8px;
            margin-top: 0;
            @include breakpoint(large) {
                margin-bottom: 3px;
            }
        }

        p {
            color: $white;
            font-size: 12px;
            line-height: 1.5;
            margin: 0;
            padding: 0;

            @include breakpoint(large) {
                font-size: 14px;
            }
        }
    }

    .presale-codes-confirm-container {
        background-color: $very-light-gray;
        border: 1px solid $black;
        margin-bottom: 6px;

        @include breakpoint(large) {
            padding-bottom: 35px;
        }

        & *,
        h5, p {
            color: $black;
        }

        .presale-codes-confirm-container-bottom {
            border: 1px solid $c-medium-gray;
            color: $white;
            display: inline-block;
            font-size: 14px;
            font-weight: $f-weight-semibold;
            line-height: 1.43;
            letter-spacing: 1px;
            margin-top: 16px;
            padding: 10px 50px;
            text-transform: uppercase;
            width: auto;

            @include breakpoint(large) {
                margin-top: 24px;
            }
        }
    }

    .presale-codes-confirm-container-list {
        margin: 0 0 40px;

        li {
            border: 1px solid $c-medium-gray;
            display: flex;
                    align-items: center;
                    justify-content: space-between;
            gap: 15px;
            margin-bottom: 6px;
            padding: 20px 16px;

            @include breakpoint(large) {
                padding: 16px 20px;
            }

            h5 {
                margin: 0;
                padding: 0;
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                flex: 0 0 40%
            }
        }
    }
    ul {
        padding-left: 0;
        margin-top: 20px;

        li {
            list-style: none;
            margin-bottom: 28px;

            p {
                margin-top: 0;
                line-height: 20px;
            }
        }
    }

    & > .content-asset {
        margin-bottom: 20px;
    }
}

.freedownloads-header {
    font-size: 32px;
    margin-bottom: 31px;
}

#primary {
    .free-downloads-wrapper {
        margin: 0;

        .col {
            display: flex;
            margin: 0;
            padding: 0;

            img {
                object-fit: cover;
                width: 130px;
                height: 130px;
                display: block;

                @include breakpoint(xsmall) {
                    height: 100%;
                }
            }

            .content {
                border: 1px solid $c-stroke-gray;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0 15px 15px 15px;
                width: calc(100% - 130px);

                @include breakpoint(xsmall) {
                    align-items: stretch;
                    justify-content: center;
                }

                @include breakpoint(large) {
                    border-left: 0;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px 0 10px;
                }

                .exclusive-badge {
                    background-color: $c-red;
                    background-image: url("../images/svg/intermediate-svg/fifth-member-all-white.svg");
                    background-position: left center;
                    background-repeat: no-repeat;
                    box-sizing: border-box;
                    color: $white;
                    font-size: 10px;
                    font-weight: $f-weight-bold;
                    display: inline-flex;
                        align-items: center;
                    letter-spacing: 1px;
                    line-height: 1;
                    min-height: 20px;
                    margin: 10px 0 15px;
                    padding: 5px 7px 5px 20px;
                    text-transform: uppercase;
                    vertical-align: top;
                }

                p {
                    font-family: $f-header;
                    font-size: 16px;
                    font-weight: $f-weight-bold;
                    line-height: 1.25;
                    letter-spacing: 0.5px;
                    margin: 0 0 14px;
                    padding: 0;
                    text-transform: uppercase;
                }

                a {
                    font-size: 14px;
                    font-weight: $f-weight-bold;
                    line-height: 1;
                    letter-spacing: 3px;
                    overflow: visible;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.presale-form {
    .form-row-agreetoterms {
        .field-wrapper {
            display: flex;
            flex-flow: row wrap;
        }
        .input {
            width: 24px;
        }
        .label {
            width: 80%;
            flex-grow: 2;
        }
        span.error {
            margin-left: 24px;
        }
    }
}
.sor-form-row {
    .field-wrapper {
        label.error {
            background: none;
            color: #B52532;
            font-size: 12px;
            font-weight: normal;
            line-height: normal;
        }
    }
}

.account {
    &-primary {
        padding-left: $grid-small-padding / 2 !important; // Override overly specific base selector
        
        @include breakpoint(medium) {
            padding-left: 36px !important;
        }

        @include breakpoint(large) {
            padding-left: 49px !important;
        }

        .form-row-button {
            text-align: left !important; // Overide overly specific selector
        }

        .form-col {
            @include bp(max-width, $bp-medium) {
                margin-bottom: 40px;
            }
        }

        .col:not(.col--std-padding) {
            &:nth-child(odd) {
                @include breakpoint(large) {
                    padding-right: 24px;
                }

                @include breakpoint(xlarge) {
                    padding-right: 28px;
                }
            }

            &:nth-child(even) {
                @include breakpoint(large) {
                    padding-left: 24px;
                }

                @include breakpoint(xlarge) {
                    padding-left: 28px;
                }
            }
        }
    }

    &-secondary#secondary,
    &-breadcrumbs-col { // Stronger selector needed
        @include breakpoint(medium) {
            border-right: 1px solid $c-medium-gray;
        }
    }

    &-breadcrumbs-col {
        padding-top: 0;
    }

    &-breadcrumbs-row {
        margin-top: 0;
    }

    &-main-divider {
        border-bottom: none;
        border-color: $c-medium-gray;
        margin-bottom: 22px;
        margin-left: ($grid-small-padding / 2) - $l-small-padding;
        margin-right: ($grid-small-padding / 2) - $l-small-padding;
        width: 100vw;

        @include breakpoint(medium) {
            display: none;
        }
    }
}

.ui-dialog.smart-order-refill-modal {
    width: 100% !important;
    max-width: 776px;
    
    .ui-dialog-buttonpane {
        margin: 0 !important;
        padding: 0 27px 20px;
        text-align: left;

        .ui-dialog-buttonset {
            float: none;
            margin: 0 !important;
        }

        @include bp(max-width, $bp-large) {
            button {
                width: 100%;
                margin: 0 !important;
            }
        }

        @include breakpoint(large) {
            padding: 0 98px 13px;
        }
    }

    #subscriptionview {
        overflow: auto;
        height: auto !important;
        margin: 0;
        padding-top: 50px;
        padding-bottom: 0;
        width: 100% !important;

        @include breakpoint(large) {
            padding-top: 70px;
        }

        .header {
            padding: 0 27px 20px;
            border-bottom: 0;

            @include breakpoint(large) {
                padding: 0 98px 13px;
            }

            .order-date {
                font-family: $f-body-bold;
                font-size: 32px;
                font-weight: $f-weight-bold;
                line-height: 1.12;

                @include breakpoint(large) {
                    font-size: 34px;
                }
            }
        }

        .product-vinyl {
            border: 1px solid $c-stroke-gray;
            display: flex;
                justify-content: space-between;
            margin-bottom: 20px;

            .product-vinyl-img {
                .plitemimage {
                    img {
                        display: block;
                        height: 120px;
                        width: 120px;
                        object-fit: cover;

                        @include breakpoint(large) {
                            height: 160px;
                            width: 160px;
                        }
                    }
                }
            }

            .product-vinyl-data {
                padding: 20px;

                .productName {
                    font-size: 12px;
                    font-weight: $f-weight-semibold;
                    line-height: 1.17;
                    letter-spacing: 1px;
                    margin-bottom: 5px;

                    @include breakpoint(large) {
                        font-size: 14px;
                        line-height: 1.14;
                    }
                }
                .product-vinyl-data-variation,
                .product-vinyl-data-quantity {
                    display: inline;
                    margin-right: 10px;

                    .attribute {
                        display: inline;
                    }
                }
            }

            .product-vinyl-price {
                font-size: 12px;
                font-weight: $f-weight-semibold;
                line-height: 1.17;
                letter-spacing: 1px;
                padding: 20px 27px 20px 0;

                @include breakpoint(large) {
                    font-size: 14px;
                    line-height: 1.14;
                    padding: 20px;
                }
            }
        }

        .payment-section__order {
            border: 0;
            padding: 0;
            margin: 0 0 10px;
            @include bp(max-width, $bp-large) {
                padding: 0 27px;
            }
            & > div {
                text-transform: uppercase;
                &:last-child {
                    text-align: right;
                }
            }

            &.payment-section {
                color: $c-charcoal;
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                letter-spacing: 1px;
            }

            &:not(.payment-section) {
                font-family: $f-subheader;
                font-size: 21px;
                line-height: 1.29;
                letter-spacing: 1px;
            }
        }

        .oreplen-product-list {
            border-bottom: 1px solid $brand-primary;
            margin-bottom: 30px;
            padding: 0 0 20px;

            @include breakpoint(large) {
                margin-bottom: 60px;
                padding: 0 98px 13px;
            }
        }
        .order-section {
            &:not(.oreplen-product-list) {
                border: 0;
                padding: 0 27px 30px;

                @include breakpoint(large) {
                    padding: 0 98px 30px;
                }
            }

            .content-section {
                address {
                    font-style: normal;
                }
            }

            .payment-section__card {
                border: 0;
                margin: 0;
                padding: 0;

                .payment-section__card--item {
                    margin-bottom: 6px;
                    width: 100%;

                    p {
                        font-size: 14px;
                        font-weight: $f-weight-regular;
                        line-height: 1.43;
                    }

                    span {
                        font-size: 14px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.43;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }

            .title {
                border-bottom: 1px solid $c-stroke-gray;
                font-size: 18px;
                font-weight: $f-weight-bold;
                line-height: 1.22;
                letter-spacing: 0.5px;
                padding-bottom: 7px;
                margin-bottom: 10px;

                span,
                button {
                    border: 0;
                    color: $brand-primary;
                    font-family: $f-body;
                    font-size: 11px;
                    font-weight: $f-weight-bold;
                    line-height: 1;
                    letter-spacing: 2.5px;
                    margin: 0;
                    padding: 0;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: none;
                    }

                    @include breakpoint(large) {
                        font-size: 14px;
                        font-weight: $f-weight-bold;
                        letter-spacing: 3px;
                    }
                }
            }
        }
    }
}

.add-address-dialog,
.account-form-dialog {
    box-sizing: border-box;
    max-height: 100vh;
    max-width: 100%;
    overflow: auto;
    padding: 60px 27px 30px;

    @include breakpoint(medium) {
        padding-bottom: 52px;
        padding-left: 98px;
        padding-right: 98px;
    }

    &.ui-dialog .ui-dialog-content {
        width: 100% !important; // Override importanted style
    }

    .ui-resizable-handle {
        display: none !important;
    }

    .ui-dialog-titlebar {
        position: static;
    }

    .ui-dialog-buttonpane {
        margin-top: 0;
        padding: 0;
    }

    .account-dialog-buttons,
    .ui-dialog-buttonset {
        display: flex;
            flex-wrap: wrap;
            gap: 8px;
        float: none;
        padding-top: 2px;
        width: 100%;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            padding-top: 12px;
        }

        &::after {
            content: none;
        }
    }

    .ui-dialog-buttonset {
        button {
            &:first-child {
                @include secondary-button;

                flex-grow: 0;
                flex-shrink: 0;
                width: 100%;
            
                @include breakpoint(medium) {
                    width: calc(50% - 4px);
                }
            }
            
            &:not(:first-child) {
                @include primary-button;
            }

            .ui-button-text {
                line-height: 1;
                padding: 0;
            }
        }
    }

    .account-dialog-btn,
    .ui-dialog-buttonset .ui-button {
        flex-grow: 1;
        margin: 0 !important; // Override overly specific base selector
        width: calc(50% - 4px);
        
        @include breakpoint(medium) {
            width: calc(25% - 4px);
        }

    }
    
    .form-wrapper {
        padding-top: 4px;

        @include breakpoint(medium) {
            padding-top: 0;
        }
    }
}

.account-dialog {
    &-heading {
        @include bp(max-width, $bp-medium) {
            @include h2;
        }

        @include breakpoint(medium) {
            @include h3;
        }
    }

    &-btn {
        &#{&}--apply {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            
            @include breakpoint(medium) {
                width: calc(50% - 4px);
            }
        }
    }
}

.expiration-fields {
    width: 100%;

    .month,
    .year {
        float: none;
        width: calc(50% - 4px);

        @include breakpoint(medium) {
            width: calc(50% - 6px);
        }

        label {
            @include visually-hidden;

            display: block;
        }
    }

    .year {
        margin-right: 0;
    }
}

.payment-instrument {
    &-billing-address {
        width: 100%;

        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        &-legend {
            @include h3;

            padding-bottom: 30px;
            padding-top: 40px;
            text-transform: uppercase;

            @include breakpoint(medium) {
                @include h5;

                letter-spacing: 0.5px;
                padding-bottom: 20px;
                padding-top: 22px;
            }
        }
    }
}

.sor-subscription-shipping-alert {
    p {
        color: $brand-error;
        font-size: 12px;
        font-weight: $f-weight-semibold;
        line-height: 1.17;
        letter-spacing: 1px;
        text-transform: uppercase;

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.ui-dialog {
    &.ui-changeaddress {
        max-width: 100%;
        .ui-dialog-buttonpane {
            margin: 4px 0 0;
            @include breakpoint(large) {
                margin: 14px 0 0;
            }
            .ui-dialog-buttonset {
                float: none;
                padding: 0;
                button {
                    width: 100%;
                    @include breakpoint(large) {
                        width: calc(50% - 4px);
                    }

                    &:first-child {
                        @include secondary-button;
                    }
                }
            }
        }
    }

    #updatecreditcard,
    #addresschange {
        box-sizing: border-box;
        width: 100% !important;

        .form-row {
            margin-bottom: 16px;
        }

        .expiration-date-wrapper {
            display: flex;
            .form-row {
                margin: 0;
                width: calc(50% - 4px);

                &:first-child {
                    margin-right: 8px;
                }
            }
        }

        #editCreditCard,
        #editprofile {
            .tooltip,
            .form-caption {
                color: $c-charcoal;
                font-size: 10px;
                font-weight: $f-weight-semibold;
                line-height: 1.4;
                letter-spacing: 1px;
                margin-top: 4px;
                text-transform: uppercase;
            }

            .form-field-tooltip {
                display: block;
                float: none;
                margin-top: 4px;
                text-align: right;
                .tooltip {
                    color: $brand-primary;
                }
            }
        }
    }
}

.form-vinylclub-editcard {
    .month, .year {
        label.error:not(.hide) {
            display: block;
        }
    }
    .select-wrapper:after {
        top: 25px;
    }
}
