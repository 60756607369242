#contactus-form {
    #return-fields, #exchange-fields {
        display: none;
    }
    .firstname,
    .lastname {
        display: inline-block;
        width: 48%;
    }
    .lastname {
        float: right;
    }
    textarea[name="description"] {
        height: 160px;
    }
    .qty,
    .item,
    .size,
    .remove-rline-js,
    .remove-xline-js {
        display: inline-block;
        margin-bottom: 0px;
    }
    .qty,
    .size {
        width: 15%;
        vertical-align: top;
    }
    .remove-rline-js,
    .remove-xline-js {
        width: 15%;
    }
    .item {
        width: 48%;
    }
    .exchangeRow1,
    .exchangeRow2,
    .hide-exchange-js,
    .hide-return-js,
    .returnRow1,
    .returnRow2 {
        display: none;
    }
    .return-reason,
    .section-header {
        margin-bottom: 0px;
    }
    textarea {
        &.error,
        &.valid {
            background-color: $white;
        }
    }
    .field-wrapper {
        .input-text {
            font-size: 16px;
        }
    }
}