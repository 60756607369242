.c-so-what {
    &__h3 {
        @include h3;

        @include breakpoint(medium) {
            @include h4;
        }
    }

    &__explore-mag-heading {
        border-bottom: 1px solid $c-black;
        margin-bottom: 20px;
        padding-bottom: 11px;

        @include breakpoint(large) {
            padding-bottom: 8px;
        }
    }

    &__category-links {
        display: flex;
            align-items: flex-start;
            gap: 8px;
        margin-bottom: 51px;
        margin-top: 0;
        padding-left: 0;
        width: calc(100% + ((100vw - 100%) / 2));

        @include breakpoint(large) {
            margin-bottom: 20px;
            width: 100%;
        }

        &:not(.slick-initialized) {
            .c-so-what__category {
                width: 25%;

                @include breakpoint(medium) {
                    width: 20%;
                }

                @include breakpoint(large) {
                    width: 16.66%;
                }
            }
        }

        .slick-list {
            padding-left: 0 !important;
            padding-right: 68px !important;
        }
    }

    &__category {
        aspect-ratio: 1/1;
        box-sizing: border-box;
        overflow: hidden;
        padding-left: 0 !important;
        padding-right: 0 !important;

        @include bp(max-width, $bp-large) {
            margin-left: 3.5px;
            margin-right: 3.5px;
        }

        &__link {
            display: inline-block;
            height: 100%;
            position: relative;
            width: 100%;

            &.selected,
            &:hover {
                &::before {
                    border: 1px solid $white;
                    content: '';
                    position: absolute;
                        bottom: 5px;
                        left: 5px;
                        right: 5px;
                        top: 5px;
                        z-index: 1;

                    @include breakpoint(large) {
                        bottom: 10px;
                        border-width: 2px;
                        left: 10px;
                        right: 10px;
                        top: 10px;
                    }
                }
            }

            &.selected {
                &::after {
                    border-bottom: 8px solid $white;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    content: '';
                    position: absolute;
                        bottom: 5px;
                        left: 50%;
                    transform: translateX(-50%);

                    @include breakpoint(large) {
                        border-bottom: 16px solid $white;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        bottom: 10px;
                    }
                }
            }
        }

        & &__img {
            height: 100%;
        }

        &__img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }

        &__name {
            box-sizing: border-box;
            font-family: $f-header;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1.29;
            padding-left: 12px;
            padding-right: 12px;
            position: absolute;
                left: 50%;
                top: 50%;
                z-index: 1;
            text-align: center;
            text-transform: uppercase;
            transform: translate(-50%, -50%);
            width: 100%;

            @include breakpoint(large) {
                font-size: 21px;
                padding-left: 16px;
                padding-right: 16px;
            }

            img + & {
                color: $white;
            }
        }
    }

    &__sort-bar {
        display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            gap: 16px;
        margin-bottom: 7px;
        padding-bottom: 13px;

        @include breakpoint(medium) {
            margin-bottom: 20px;
        }
    
        @include breakpoint(large) {
            border-bottom: 1px solid $c-black;
            flex-wrap: nowrap;
            gap: 20px;
        }
    
        @include breakpoint(xlarge) {
        }

        &__col {
            flex: 1 1 100%;

            @include breakpoint(large) {
                flex-basis: 3;
            }

            &--sort {
                margin-top: 20px;
                margin-bottom: 6px;

                @include breakpoint(large) {
                    flex-grow: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }

                .select-wrapper {
                    background: $white;
                    margin: 0 0 5px 0;

                    @include breakpoint(large) {
                        margin: 0 5px 0 0;
                    }

                    &::after {
                        right: 15px;
                    }
                }

                select {
                    min-width: 245px;

                    @include breakpoint(large) {
                        height: 36px;
                    }

                    &::after {
                        z-index: 0;
                    }

                    &.year-select {
                        min-width: 155px;
                    }
                }

                .pagination {
                    margin: 0;
                }
            }
        }
    }

    &__sort-select {
        background: initial;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 3px;
        text-transform: uppercase;
        z-index: 1;
    }

    &__article-cards {
        margin-bottom: 40px;
    }

    &__article-card-list {
        list-style: none;
        margin-top: 0;
        padding-left: 0;

        &--top {
            margin-bottom: ($grid-small-padding + 30px);
            @include breakpoint(medium) {
                margin-bottom: $grid-medium-padding + 48px;
            }

            @include breakpoint(large) {
                margin-bottom: $grid-large-padding + 58px;;
            }

            @include breakpoint(xlarge) {
                margin-bottom: $grid-xlarge-padding + 58px;
            }
        }

        &--bottom {
            margin-top: 40px;

            @include breakpoint(medium) {
                margin-top: 100px;
            }
        }

        .no-results {
            text-align: center;
            font-size: 1.4em;
        }
    }

    &__load-more-articles {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.s-so-what {
    .slick-list {
        margin-left: -3.5px;
    }

    .slick-arrow {
        border-radius: 50%;
        height: 32px;
        overflow: hidden;
        width: 32px;
    }

    .slick-prev {
        left: -6px;
    }

    .slick-next {
        right: $l-small-padding - 6px;
    }
}
