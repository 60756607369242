// =============================================
// SiteGenesis Defaults
// =============================================
// colors
// names taken from http://www.color-blindness.com/color-name-hue/
$cerulean: #00629b;
$charcoal: #424242;
$citrus: #84bd00;
$dark-gray: #aaa;
$dim-gray: #666;
$gainsboro: #e0e0e0;
$islamic-green: #019328;
$night-rider: #333;
$nobel: #999;
$rain-forest: #64772d;
$sangria: #990000;
$stark-white: #cebdb3;
$teal: #008578;
$tutu: #faeded;
$very-light-gray: #ccc;
$white-smoke: #efefef;

$swatch-types: black #000000, beige #f5f5dc, blue blue, purple purple, red red, brown #783201, green green, grey #8f979d, pink #fe249a, orange orange, white #ffffff, yellow #ffff00, navy navy;

// =============================================
// Blue Acorn Refapp Defaults
// =============================================

// Border Radius

$default-border-radius: 5px;

// Transition

$default-transition-property: all;
$default-transition-duration: 200ms;
$default-transition-function: linear;
$default-transition-delay: 0;

$menu-reveal-transition-duration: 1000ms;
$menu-reveal-transition-delay: 150ms;
$menu-reveal-transition: clip-path $menu-reveal-transition-duration $menu-reveal-transition-delay;

// =============================================
// Primary Break Points
// =============================================

// These should be used with the bp (max-width, xx) mixin
// where a min-width is used, remember to +1 to break correctly.
// If these are changed, they must also be updated in app.js

$bp-xsmall: 479px;
$bp-small: 599px;
$bp-medium: 767px;
$bp-large: 1023px;
$bp-xlarge: 1279px;
$bp-max: $bp-xlarge + 1;
$bp-max-content-width: clamp(760px, calc(100% - 80px), 1276px);

// =============================================
// Colors
// =============================================

// Grayscale
$white: #fff;
$black-5: darken($white, 5%);
$black-10: darken($white, 10%);
$black-20: darken($white, 20%);
$black-30: darken($white, 30%);
$black-40: darken($white, 40%);
$black-50: darken($white, 50%);
$black-60: darken($white, 60%);
$black-70: darken($white, 70%);
$black-80: darken($white, 80%);
$black-90: darken($white, 90%);
$black: #000;
$c-black70:rgba(0, 0, 0, 0.7);

// =============================================
// Brand Colors
// =============================================

// Usually not used directly in Sass partials.
// Only used to define context-based color vars in this file.

$c-blue: #39c;
$c-green: #67B930;
$c-pink: #d85378;
$c-orange: #f3793b;
$c-red: #A71E2D;
$c-bright-red: #D32930;
$c-light-red: #FF353D;
$c-action-blue: $c-red;
$c-dark-red: #741820;
$c-yellow: #ffdd15;
$c-black: #000;
$c-gray: #9B9B9B;
$c-gray70:rgba(155, 155, 155, 0.7);
$c-gray30:rgba(155, 155, 155, 0.3);
$c-dark-gray: #595959;
$c-charcoal: #555;
$c-medium-gray: #ACACAC;
$c-stroke-gray: #DCDCDC;
$c-light-gray: #F6F5F4;
$c-mostly-white: #F7F6F6;
$c-dark-blue: #354052;
$c-dark-teal: #157A6E;
$c-light-blue: #d4d4d4;

$brand-primary: $c-black;
$brand-secondary: $c-blue;
$brand-success: $c-green;
$brand-tertiary: $c-pink;
$brand-warning: $c-orange;
$brand-error: $c-red;
$brand-info: lighten($brand-warning, 10%);
$brand-dark: darken($brand-primary, 15%);
$brand-light: lighten($brand-primary, 15%);

// =============================================
// Base Colors
// =============================================

// Text

$c-text: $black-80;
$c-heading: $c-black;
$brand-text: $c-text;
$c-text-gray: $black-50;
$c-text-white: $black-10;
$c-anchor: $c-black;

// Primary font color for headings and other non-link text
$c-text-primary: $brand-primary;

// =============================================
// Theme variables
// =============================================
$dark-theme-selector: '.dark-theme';
$animated: '.animated';
$dark-theme-color: $white;

// Interaction
$c-action: $c-action-blue;
$c-stimulus: darken($brand-primary, 15%);
$c-subtle: $black-10;

// Notifications
$c-error: $brand-error;
$c-danger-background: $white;

$c-success: $c-green;
$c-success-background: $white;

$c-warn: $brand-warning;
$c-warn-background: $white;

$c-dark: $brand-dark;

// Global Elements
$c-divider-border: $black-10;
$c-body-color: $white;
$c-headerbanner-color: $c-dark-blue;
$c-headerbanner-text: $white;
$c-nav-links-color-small: $black;
$c-nav-links-color-large: $c-green;

// Buttons
$c-button-padding: 12px 20px 11px;
$c-primary-button: $white;
$c-primary-button-hover: $c-black;
$c-primary-button-f-color: $c-black;
$c-primary-button-f-color-hover: $white;
$c-secondary-button: $c-black;
$c-secondary-button-hover: $white;
$c-secondary-button-f-color: $white;
$c-secondary-button-f-color-hover: $c-black;
$c-primary-button-border: $c-black;
$c-primary-button-border-disabled: $c-black;
$c-secondary-button-border: $c-black;
$c-secondary-button-border-disabled: $c-black;
$c-tertiary-button-border: $c-action-blue;
$c-tertiary-button: $c-action-blue;


// Headings
$c-h1: $c-blue;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// Link
$c-link: $c-action;
$c-link-hover: darken($c-action, 5%);
$c-link-focus: $c-action;
$c-link-active: darken($c-action, (5% / 2));

// Forms

$c-input-border: $c-medium-gray;
$c-checkbox-input-border: $c-black;
$c-input-border-focus: #14ABF4;

$c-input-placeholder: $c-text-gray;
$c-input-text: $c-text;

$c-fieldset-border: #CCCCCC;
$c-fieldset-border-light: #EDEDED;

// =============================================
// Typography
// =============================================

// Font Stacks

$f-stack-icon: "SSStandard";

$sans-serif: 'proxima-nova', sans-serif;
$sans-serif-alt: 'proxima-nova', sans-serif;
$serif: Crete Round, serif;

// flag-icon-css
$flag-icon-css-path: '../lib/flag-icon-css/flags';

$f-accent: 'din-condensed', sans-serif;
$f-body: $sans-serif;
$f-subheader: 'futura-pt', sans-serif;
$f-header: 'futura-pt-bold', sans-serif;
$f-body-bold: $f-header;
$f-body-color: $c-black;

$f-weight-light: 300;
$f-weight-regular: 400;
$f-weight-medium: 500;
$f-weight-semibold: 600;
$f-weight-bold: 700;

// =============================================
// Sizes
// =============================================

// Product
$product-column-spacing: 20px;

// Layout sizes
$l-small-padding: 27px;
$l-medium-padding: 24px;
$l-large-padding: 54px;
$l-xlarge-padding: 54px;

$grid-small-padding: 12px;
$grid-medium-padding: $l-medium-padding;
$grid-large-padding: 32px;
$grid-xlarge-padding: 40px;

// Font Sizes
$f-size-xxs: 12px;
$f-size-xs: 14px;
$f-size-s: 15px;
$f-size: 16px;
$f-size-l: 17px;
$f-size-xl: 18px;
$f-size-xxl: 19px;

// Headers Sizes
$f-header-h1-size: 40px;
$f-header-h1-size-md: 60px;
$f-header-h1-lh: 1.05;
$f-header-h1-lh-md: 1;
$f-header-h2-size: 32px;
$f-header-h2-size-md: 48px;
$f-header-h2-lh: 1.13;
$f-header-h3-size: 20px;
$f-header-h3-size-md: 34px;
$f-header-h3-lh: 1.1;
$f-header-h3-lh-md: 1.12;
$f-header-h4-size: 16px;
$f-header-h4-size-md: 28px;
$f-header-h4-lh: 1.25;
$f-header-h4-lh-md: 1.14;
$f-header-h5-size: 14px;
$f-header-h5-size-md: 21px;
$f-header-h5-lh: 1.29;
$f-header-h6-size: 18px;
$f-header-h6-lh: 1.22;

// Base Measures
$b-line-height: 1.33;
$b-line-height-md: 1.43;
$b-margin-bottom: 1.5em;

// Input Settings
$select-height: 32px;

// =============================================
// Icons
// =============================================
$icon-base-color: $black;
$icon-menu-color: $black;
$icon-cart-width: 13px;
$icon-cart-height: 13px;
$icon-cart-color: $black;
$icon-icon-expand-color: $black;

// =============================================
// Components
// =============================================

// Base Animation Settings
$animation-base-timing: 0.3s;

// Main Button Styling
$button-border-radius: 0;
$button-border: 1px solid $c-primary-button-border;
$button-f-size: 15px;
$button-f-large-size: $f-size-s;
$button-text-transform: uppercase;
$button-line-height: 1;
$button-f-letter-spacing: 2.3px;

// Form Settings
$form-row-margin-top: 0;
$form-row-margin-bottom: 18px;
$legend-font-size: 27px;
$legend-font-weight: $f-weight-light;
$legend-padding-top: 10px;
$legend-padding-bottom: 39px;

// Input Settings
$input-border-radius: 0;
$input-f-size: $f-size;
$input-radio-size: 22px;
$input-checkbox-size: 24px;
$input-checkbox-size-md: 18px;
$input-checkbox-size-inner: $input-checkbox-size - 8px;
$input-checkbox-size-inner-md: $input-checkbox-size-md - 8px;
$input-checkbox-border: 2px solid $c-checkbox-input-border;
$input-radio-border: 2px solid $c-black;
$input-radio-border-active: 2px solid $c-black;
$input-radio-size-inner: $input-radio-size - 8px;
$input-text-height: 50px;
$input-padding: 13px 16px;
$label-f-weight: $f-weight-semibold;
$label-f-size: $f-size-xs;
$input-f-lh: 1.43;
$label-margin-bottom: 4px;
$input-message-f-lh: 1.4;
$input-message-f-spacing: 1px;
$input-message-f-size: 10px;
$input-message-padding-top: 4px;

// Header Banner Settings
$headerbanner-height: 32px;

// Header Settings
$header-logo-size-small: 68px;
$header-logo-size-medium: 83%;
$header-logo-size-large: 120px;
$header-search-width: 322px;
$header-checkout-height-large: 55px;
$header-nav-top--desktop: 32px;

// Search Suggestions Settings


// Footer Settings
$footer-sub-bg: $c-dark-blue;
$footer-sub-margin-top: 90px;
$footer-padding-bottom-small: 25px;
$footer-padding-bottom-large: 30px;
$footer-padding-top-small: 90px;
$footer-bg: $c-mostly-white;
$footer-logo-width: 180px;
$footer-logo-margin-bottom: 13px;
$footer-signup-button-margin: 0 0 0 7px;
$footer-vertical-spacer: 50vw;
$footer-vertical-spacer-mobile: 250px;
$footer-vertical-spacer-desktop: 15.625vw;

// Menu utility settings
$menuutility-icon-medium-f-size: 17px;
$menuutility-text-color: $c-gray;
$menuutility-box-shadow: none;
$menuutility-border: 1px solid $c-action-blue;
$menuutility-arrow-box-shadow: none;

// Navigation Settings
$nav-links-font-weight-small: $f-weight-bold;
$nav-links-font-weight-large: $f-weight-medium;
$nav-links-font-size: $f-size-xs;
$nav-links-line-height-small: 24px;
$nav-links-line-height-large: 40px;
$nav-mobile-bg-color: $white;
$nav-mobile-width: 270px;
$nav-mobile-padding: 8px 10px $l-small-padding;
$nav-padding-x: 10px;
$nav-padding-x-desktop: 15px;
$nav-padding: 8px $nav-padding-x;
$nav-links-padding-small: 0 26px 0 $l-small-padding;
$nav-links-padding-large: 0 16px 0 $l-small-padding;
$nav-links-dropdown-hover-bg: #E7F6FD;

$megamenu-padding-x: 26px;

// content collapse sidebar settings
$content-sidebar-bg: $white;
$content-sidebar-border: 2px solid $c-black;
$content-sidebar-margin: 0 0 13px 0;

// Minicart Settings
$minicart-icon-small-f-size: 13px;
$minicart-icon-medium-f-size: 17px;
$minicart-number-bg-color-empty: $c-light-gray;
$minicart-number-bg-color: $c-red;
$minicart-number-color-empty: $c-headerbanner-color;
$minicart-number-color: $white;
$minicart-pli-price-margin: 0 0 0 15px;
$minicart-slot-bg-color: #E7F6FD;
$minicart-bg-color: $white;

// Slider Settings
$slider-inactive-dot-bg: $c-light-gray;
$slider-active-dot-bg: $white;
$slider-dot-size: 8px;

$slider-arrow-box-size-medium: 32px;
$slider-arrow-box-size-large: 40px;

$slider-arrow-box-bg: rgba($white, .35);
$slider-opacity-default: 1;
$slider-opacity-on-hover: 1;
$slider-opacity-not-active: 1;
$slider-arrow-box-position-left: 0;
$slider-arrow-box-position-right: 0;

// Recommendation Settings
$recomm-header-top-margin: 39px;

// Product Tile Settings
$ptile-font-color: $c-dark-blue;
$ptile-pname-margin-top: 20px;
$ptile-price-f-size: 12px;
$ptile-price-f-size-lg: 14px;
$ptile-standard-price-color: $c-gray;
$ptile-sales-price-color: $c-red;

// CLP Settings
$ctile-header-padding-left: 17px;
$ctile-header-padding-bottom: 17px;

// PLP settings
$breadcrumb-margin-top: 20px;
$refinement-header-margin-bottom: 6px;
$refinement-link-f-size: 14px;
$refinement-link-f-lineheight: 18px;
$refinement-margin-bottom: 18px;
$refinement-icon-font-size: 18px;
$refinement-swatch-spacing: 6px;
$refinement-swatch-border: 1px solid $black;
$refinement-swatch-border-radius: 0;
$refinement-swatch-color-radius: 50%;
$refinement-swatch-color-size: 30px;
$refinement-swatch-size: 22px;
$toggle-grid-color: $c-action-blue;
$toggle-grid-column-color: $c-dark-blue;
$cat-banner-h1-color: $white;

// PDP settings
$product-number-margin: 10px 0 4px 0;
$product-price-f-size: 18px;
$product-price-f-lh: 24px;
$product-price-margin-top: 8px;
$product-price-margin-bottom: 17px;

$product-thumb-ul-padding: 0;
$product-thumb-li-margin: 16px 0 0 0;
$product-thumb-size: 64px;

$tabs-boder: 1px solid $c-light-gray;
$tabs-heading-margin: 0 0 3px;
$tabs-heading-active-border-position: null 0 -6px 50%;
$tabs-heading-active-bg: $c-action-blue;
$tabs-heading-active-height: 3px;
$tabs-heading-active-width: 130%;

$variation-label-f-weight: $f-weight-bold;
$variation-label-f-size: 16px;
$variation-label-f-lh: 1.25;
$variation-label-f-family: $f-header;
$variation-label-margin-bottom: 16px;
$variation-swatch-spacing--mobile: 6px;
$variation-swatch-spacing: 8px;
$variation-swatch-border: $refinement-swatch-border;
$variation-swatch-border-radius: $refinement-swatch-border-radius;
$variation-swatch-color-radius: $refinement-swatch-color-radius;
$variation-swatch-color-size: $refinement-swatch-color-size;
$variation-swatch-size: $refinement-swatch-size;
$variation-swatch-padding: 5px 12px;
$variation-swatch-c-selected: $white;
$variation-swatch-bg-selected: $c-black;
$variation-label-value-margin-left: 12px;
$pdp-in-stock-margin-left: 10px;

// Quickview settings
$quickview-dialog-padding: 40px 0 0;

// Cart Settings
$cart-info-col-padding-y: 12px;
$cart-info-col-padding-y-lg: 10px;
$cart-order-totals-td-padding: 1px 0;
$cart-page-padding-top: 28px;
$cart-table-tbody-td-padding: 0 15px 15px;
$cart-table-image-size: 110px;
$cart-table-border: 1px solid $c-light-gray;
$cart-content-width-lg: 62.5%;
$cart-coupon-code-input-width-small: 58%;
$cart-coupon-code-input-width-medium: 57%;
$cart-coupon-code-input-width-large: 250px;
$cart-recommendations-padding: 80px 0 0;

// Checkout Settings
$in-stock-f-color: $f-body-color;
$checkout-primary-content-margin-top: 33px;
$checkout-progress-bar-width-small: 10%;
$checkout-progress-bar-width-medium: 9%;
$checkout-progress-bar-maxwidth: 72px;
$checkout-progress-bar-height: 1px;
$checkout-progress-bar-bg-color: $c-gray;
$checkout-progress-f-weight: $f-weight-bold;
$checkout-progress-active-f-color: $black;
$checkout-progress-margin-top-small: 25px;
$checkout-progress-margin-top-large: 36px;
$checkout-nav-summary-padding: 0 0 0 10px;
$checkout-minisummary-header-padding: 3px 0 10px;
$checkout-minisummary-details-padding: 15px 6px 10px;
$checkout-minisummary-header-edit-f-size: 14px;
$checkout-minisummary-header-border: 1px solid $c-light-gray;
$checkout-minisummary-padding: 23px 8px 15px 8px;
$checkout-minisummary-image-size: 100px;
$checkout-minisummary-data-margin: 0 0 0 16px;
$checkout-minisummary-pli-margin: 24px 0 0;
$checkout-totals-table-padding: 16px 13px 24px 6px;
$checkout-totals-table-margin: 10px 0;
$checkout-shipto-margin: 0 0 30px 0;
$checkout-payment-options-width: 21%;

// Product list Table Settings
$plist-table-tr-border: none;
$plist-table-thead-tr-border: 1px solid $c-gray;
$plist-table-thead-td-padding: 0 15px 10px;
$plist-table-thead-td-text-align: right;
$plist-table-tbody-td-image-padding-small: 13px;
$plist-table-tbody-td-image-padding-medium: 15px;
$plist-table-tbody-td-padding: 23px 15px 15px 0;

// Dialog Settings
$dialog-close-button-position: -20px -20px null null;
$dialog-close-button-bg-color: $c-light-gray;
$dialog-close-button-size: 40px;

$banner-clip-path: polygon(0 0, 0 calc(100% - 78px), clamp(60%, 368px, 90%) calc(100% - 54px), 10% calc(100% - 29px), 100% 100%, 100% 0);
$banner-clip-path-md: polygon(0 0, 0 calc(100% - 162px), 60% calc(100% - 102px), 40% calc(100% - 75px), 100% 100%, 100% 0);
$polygon-clip-path-bottom: 0 0, 0 calc(100% - 65px), 88% 100%, 53% calc(100% - 65px), 100% calc(100% - 39px);
$polygon-clip-path: polygon(#{$polygon-clip-path-bottom}, 100% 10%, 12% 0, 47% 10%, 0 6%);
$polygon-clip-path-bottom-md: 0 0, 0 calc(100% - 95px), 86% 100%, 56% calc(100% - 96px), 100% calc(100% - 45px);
$polygon-clip-path-md: polygon(#{$polygon-clip-path-bottom-md}, 100% 13%, 15% 0, 44% 12%);
$polygon-clip-path-bottom-lg: 0 0, 0 calc(100% - 152px), 69% 100%, 53% calc(100% - 96px), 100% 100%;
$polygon-clip-path-lg: polygon(#{$polygon-clip-path-bottom-lg}, 100% 19%, 31% 0, 47% 12%);

$img-overlay-bg: rgba(0, 0, 0, 0.7);

$tile-slider-padded-padding-top: 30px;
$tile-slider-padded-padding-top-md: 100px;
$tile-slider-padded-padding-top-lg: 170px;

$carousel-tab-padding-h: 5px;
$carousel-tab-padding-h-md: 17px;
