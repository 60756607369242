.refinement-header {
    display: none;
    @include refinement-header;
    clear: left;
    margin-bottom: $refinement-header-margin-bottom;
    @include breakpoint(medium) {
        display: block;
    }
}

.folder-refinement #category-level-1 {
    background: none;

    li {
        padding: 0 8px;

        @include breakpoint(xlarge) {
            padding: 0 16px;
        }
    }
}

.refinements-wrapper {
    display: block;
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
    flex-direction: row;
    max-width: none;
    background: #fff;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    padding-left: $l-small-padding;
    padding-right: $l-small-padding;
    position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 104;
    visibility: hidden;

    @include breakpoint(medium) {
        width: 360px;
    }

    @include breakpoint(large) {
        visibility: visible;
        transform: none;
        transition: inherit;
        flex-direction: inherit;
        background: transparent;
        height: auto;
        width: 25%;
        position: relative;
        overflow-y: inherit;
        overflow-x: inherit;
        left: inherit;
        bottom: inherit;
        top: inherit;
        display: block;
        z-index: 0;
        padding: 20px 24px 0 17px;
        visibility: visible !important
    }

    @include breakpoint(xlarge) {
        padding-bottom: 20px;
    }

    &.active {
        transform: translateX(0);
        visibility: visible !important;
    }

    &-content {
        border-bottom: none;
        clear: left;
        @include clearfix;
        display: block;
        z-index: 99;
        background: $white;
        @include position(relative, 22px null null null);

        @include breakpoint(medium) {
            display: block;
            @include position(relative, null null null);
        }
    }

    .buttonsWrap {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        display: table;
        margin-bottom: 22px;
        padding-top: 4px;

        @include breakpoint(large) {
            display: none;
        }

        .backButton,
        .backButtonSub {
            display: inline-block;
            float: left;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
            @include breakpoint(large) {
                display: none;
            }

            &:before {
                @include svg-view-menu-back;
                display: inline-block;
                content: '';
                margin: 0 13px -2px 0;
            }
        }

        .closeButton {
            color: $c-gray;
            font-weight: 700;
            margin-left: 22px;
            font-size: 16px;
            display: inline-block;
            float: right;
            cursor: pointer;

            @include breakpoint(large) {
                display: none;
            }

            .svg-close-dims {
                height: 18px;
                width: 18px;
            }
        }
    }

    .category-header,
    .filtersHeader {
        font-weight: 700;
        color: $f-body-color;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 1.1;
        margin-top: 33px;
        padding-bottom: 1px;
        padding-top: 1px;
        text-transform: uppercase;

        @include breakpoint(large) {
            font-size: 21px;
            line-height: 1.29;
        }
    }

    .category-header {
        margin-top: 16px;
        padding-bottom: 12px;

        @include breakpoint(large) {
            margin-top: 18px;
            padding-bottom: 0;
        }
    }

    .filtersHeader {
        margin-top: 35px;

        @include breakpoint(large) {
            margin-top: $refinement-margin-bottom;
        }
    }
}

.refinement {
    padding-bottom: 24px;
    padding-top: 3px;
    position: relative;

    @include breakpoint(large) {
        padding-bottom: 12px;
    }
    
    &:not(.is-expanded) {
        > ul,
        > .refinement-toggle-full-btn {
            display: none;
        }
    }

    &.is-expanded {
        margin-bottom: 10px;

        @include breakpoint(large) {
            padding-bottom: 16px;
        }

        &:not(:nth-last-child(2)) { // Since we have the footer, second to last is the last refinement
            border-bottom: 2px solid $c-stroke-gray;
        }
    }

    &-value {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 2px;
        }
    }

    &-toggle-full-btn {
        border: none;
        display: block;
        font-size: 14px;
        letter-spacing: normal;
        line-height: 1.43;
        margin-bottom: 4px;
        padding: 3px 0 0;
        text-transform: none;

        @include breakpoint(large) {
            margin-bottom: 23px;
            margin-top: -12px;
            padding-top: 0;
        }

        &:hover:not(:disabled):not(.disabled) {
            background-color: transparent;
            border: none;
            color: $f-body-color;
            text-decoration: underline;
        }
    }

    &-category-expand {
        background-color: transparent;
        border: none;
        flex-grow: 1;
        margin-bottom: 0;

        &:hover:not(:disabled):not(.disabled) {
            background-color: transparent;
            border: none;
        }
    }

    &-footer {
        margin-left: -$l-small-padding;
        margin-right: -$l-small-padding;
        padding: 30px $l-small-padding 25px;
        text-align: center;

        @include breakpoint(large) {
            display: none;
        }

        &--sticky {
            background-color: $c-light-gray;
            position: sticky;
                bottom: 0;
                z-index: 1000;
        }
    }

    &-apply-filters,
    &-clear-filters {
        letter-spacing: 3px;
    }

    &-apply-filters {
        margin-bottom: 30px;
        padding-bottom: 16px;
        padding-top: 17px;
        width: 100%;
    }

    &-clear-filters {
        font-family: $f-header;
        font-size: 11px;
        line-height: 1;
        text-transform: uppercase;

        &.disabled {
            opacity: 0.4;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &:not(.show-all) {
        .refinement {
            &-value:nth-of-type(n + 5) {
                display: none;
            }

            &-toggle-full-btn {
                &--less {
                    display: none;
                }
            }
        }
    }

    &.show-all {
        .refinement-toggle-full-btn {
            &--more {
                display: none;
            }
        }
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;

        &.category-refinement {
            display: block;
        }
    }

    h4 {
        color: $f-body-color;
        font-family: $f-body;
        font-size: $f-size-xs;
        font-weight: $f-weight-bold;
        letter-spacing: 0.5px;
        line-height: 1.86;
        text-transform: uppercase;
        padding: 0;

        &:hover {
            cursor: pointer;
        }

        i {
            margin-right: 7px;
        }
    }

    ul {
        list-style: none outside none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: 4px 0;
    }

    .category-header {
        border-bottom: 2px solid $c-stroke-gray;
    }

    &.category-refinement {
        border-top: 2px solid $c-stroke-gray;
        padding-bottom: 12px;

        @include breakpoint(large) {
            margin-top: 12px;
            padding-bottom: 11px;
        }

        > ul {
            padding: 8px 0 0;

            @include breakpoint(large) {
                padding-right: 17px;
            }

            &.category-probity {
                padding-top: 0;

                @include breakpoint(large) {
                    padding: 0 17px 17px 0;
                }

                .refinement-link {
                    padding-top: 0;
                }
            }
        }

        .active-category {
            color: $c-red;
            padding: 0 26px 0 18px;
            line-height: 40px;
            text-transform: capitalize;

            @include breakpoint(large) {
                display: none;
            }
        }

        .refinement-link {
            color: $f-body-color;
        }
    }

    > ul {
        flex-direction: row;
        max-width: none;
        background: #fff;
        height: 100%;
        width: 100%;
        position: relative;
            left: inherit;
            bottom: inherit;
            top: inherit;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 104;
        padding: 0;

        .active-category {
            display: none;
        }

        #category-level-2 {
            transform: translateX(0);
            position: relative;

            .buttonsWrap {
                display: none;
            }
        }

        @include breakpoint(large) {
            flex-direction: inherit;
            background: #fff;
            height: auto;
            overflow-y: inherit;
            overflow-x: inherit;
            z-index: 0;
            padding: 10px 17px 17px 0;
        }

        &.swatches {
            padding: 10px 17px 17px;
            @include breakpoint(large) {
                padding: 10px 17px 17px 0;
            }
        }

        li {
            a {
                color: $f-body-color;
            }

            i {
                color: $f-body-color;
                margin-right: 8px;

                @include breakpoint(large) {
                    margin-right: 7px;
                }
            }

            i.fa-check-square-o,
            i.fa-square-o {
                box-sizing: border-box;
                height: 24px;
                width: 24px;
                content: '';
                display: inline-block;
                border: 2px solid $f-body-color;
                vertical-align: middle;
                margin-top: -2px;

                @include breakpoint(large) {
                    height: 18px;
                    margin-top: -3px;
                    width: 18px
                }
            }

            &.selected {
                i.fa-check-square-o,
                i.fa-square-o {
                    position: relative;

                    &:before {
                        height: 16px;
                        width: 16px;
                        content: '';
                        background: $f-body-color;
                        display: block;
                        @include position(absolute, 50% null null 50%);
                        transform: translate(-50%, -50%);

                        @include breakpoint(large) {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }

        &.swatches li {
            float: left;
            padding: $refinement-swatch-spacing;

            a {
                border: $refinement-swatch-border;
                border-radius: $refinement-swatch-border-radius;
                display: block;
                color: $f-body-color;
                padding: 3px;
                min-width: $refinement-swatch-size - 6;
                height: $refinement-swatch-size - 6;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.selected a {
                border-color: $variation-swatch-c-selected;
                color: $variation-swatch-c-selected;
            }

            &.unselectable a {
                background-image: url("../images/interface/icon-color-swatch-unselectable.png");
                background-position: right top;
            }
        }

        &.refinementColor li a {
            padding: 0;
            text-indent: -999em;
            border-radius: $refinement-swatch-color-radius;
            width: $refinement-swatch-color-size;
            height: $refinement-swatch-color-size;
        }

        .buttonsWrap {
            width: 90%;
            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &.folder-refinement {
        > ul#category-level-1 {
            padding-left: 0;

            li a.refinement-link {
                padding-left: 0;
            }
        }
    }

    .clear-refinement {
        font-weight: $f-weight-bold;
        padding: 15px 0 0 23px;
    }

    .refinement-link {
        color: $c-charcoal;
        border: 0 none;
        display: inline-block;
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0;
        padding-top: 2px;
        vertical-align: top;

        @include breakpoint(large) {
            padding-top: 0;
        }

        &--l1 {
            font-weight: $f-weight-bold;
            letter-spacing: 0.5px;
            line-height: 1.86;
            text-transform: uppercase;
        }

        &--l2 {
            font-weight: $f-weight-medium;
        }
    }

    .selected .refinement-link {
        color: $brand-primary;
        background-position: left bottom;
        letter-spacing: 0;
    }

    .refinement-top-level {
        padding: 1em;
        display: none;
    }

    .toggle {
        position: relative;

        &::after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 8px solid $c-black;
            content: '';
            position: absolute;
                right: 0;
                top: 50%;
            transform: translateY(-50%);
        }

        &.expanded {
            margin-bottom: 12px;

            @include breakpoint(large) {
                margin-bottom: 2px;
            }

            &::after {
                border-bottom: 8px solid $c-black;
                border-top: none;
            }
        }
    }
}

.refinements {
    &-overlay {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        z-index: 102;
        transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
        cursor: pointer;
        @include breakpoint(large) {
            display: none;
        }
    }

    &-heading {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 1.05;

        &-wrap {
            margin-bottom: 28px;

            @include breakpoint(large) {
                display: none;
            }
        }
    }
}

.search-refinements-toggle {
    display: none;

    @include breakpoint(large) {
        display: block;
        letter-spacing: 3px;
        min-width: 180px;
    }

    &--show {
        display: none;
    }

    &--hide {
        @include breakpoint(large) {
            margin-bottom: 39px;
        }
    }
}

.has-hidden-refinements {
    @keyframes gridExpansionMargin {
        from {
            margin-left: 25%;
        }

        to {
            margin-left: 0;
        }
    }

    .refinements-wrapper {
        @include breakpoint(large) {
            display: none;
        }
    }

    .search-results {
        @include breakpoint(large) {
            width: 100%;
        }
    }

    .search-result-items {
        @include breakpoint(large) {
            animation-duration: 350ms;
            animation-name: gridExpansionMargin;
        }
    }

    .search-refinements-toggle--hide {
        display: none;
    }

    .search-refinements-toggle--show {
        @include breakpoint(large) {
            display: block;
        }
    }

    .primarySortby {
        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }
}