.pt_ampcontent,
.pt_storefront {
    ~ .fancybox-overlay {
        background-color: rgba(0, 0, 0, .6);
    }

    ~ .fancybox-overlay,
    ~ .fancybox-mobile {
        .fancybox-title {
            font-family: $f-body;
        }

        .fancybox-wrap {
            margin-top: 0;
            min-width: 300px;
        }

        .fancybox-inner {
            margin-left: auto;
            margin-right: auto;
        }

        .fancybox-close {
            top: -39px;
            width: 18px;

            &::before {
                margin-right: 0;
                transform: scale(1.5); // Use scale rather than height/width to handle how the sprites are used for BG
                transform-origin: left top;
            }

            &::after {
                @include visually-hidden;
            }
        }

        .fancybox-skin,
        .fancybox-outer {
            position: static;
        }

        .fancybox-nav {
            bottom: -12px;
            color: $white;
            font-size: 13px;
            font-weight: $f-weight-bold;
            height: auto;
            letter-spacing: 2.5px;
            line-height: 1.08;
            overflow: visible;
            padding-bottom: 12px;
            padding-top: 12px;
            text-transform: uppercase;
            top: initial;
            width: auto;

            span {
                display: inline-block;
                height: auto;
                margin-top: 0;
                position: static;
                width: auto;

                &::before {
                    display: none;
                }
            }

            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }

        .fancybox-prev::before,
        .fancybox-next::after {
            content: '';
            display: inline-block;
            height: 12px;
            transform: rotate(45deg);
            vertical-align: top;
            width: 12px;
        }

        .fancybox-prev {
            left: 3px;
            padding-right: 4px;

            @include breakpoint(medium) {
                left: clamp(3px, calc((100% - 325px) * 100), 16px); // With a parent width of 325px or lower, we get 3px to prevent bunching.  Otherwise 16
            }

            &::before {
                border-bottom: 3px solid currentColor;
                border-left: 3px solid currentColor;
                margin-right: 16px;
            }
        }

        .fancybox-next {
            right: 3px;
            padding-left: 4px;

            @include breakpoint(medium) {
                right: clamp(3px, calc((100% - 325px) * 100), 16px); // With a parent width of 325px or lower, we get 3px to prevent bunching.  Otherwise 16
            }

            &::after {
                border-right: 3px solid currentColor;
                border-top: 3px solid currentColor;
                margin-left: 16px;
            }
        }
    }

    ~ .fancybox-mobile {
        position: fixed !important;
            top: 50% !important;
        transform: translateY(-50%);
    }
}