.tl-media .tl-media-content-container .tl-media-content img,
.tl-media .tl-media-content-container .tl-media-content embed,
.tl-media .tl-media-content-container .tl-media-content object,
.tl-media .tl-media-content-container .tl-media-content video {
    max-height: 100% !important;
}

.tl-slidenav-next {

    &:hover {
        .tl-slidenav-icon {
            margin-left: 8px !important;

            @include breakpoint(medium) {
                margin-left: 76px !important;
            }
        }
    }

    .tl-slidenav-content-container {

        .tl-slidenav-icon {
            opacity: 1 !important;

            &:before {
                @include svg-view-slider-next-timeline;
                transform: scale(3);

                @include breakpoint(medium) {
                    transform: scale(5);
                }
            }
        }
    }

    .tl-slidenav-content-container.tl-slidenav-inverted {

        .tl-slidenav-icon {
            opacity: 1 !important;

            &:before {
                @include svg-view-slider-next-white;
                transform: scale(3);

                @include breakpoint(medium) {
                    transform: scale(5);
                }
            }
        }
    }
}

.tl-slidenav-previous {

    &:hover {

        .tl-slidenav-icon {
            margin-left: 20px !important;
        }
    }

    .tl-slidenav-content-container {

        .tl-slidenav-icon {
            margin-left: 20px !important;
            opacity: 1 !important;

            &:before {
                @include svg-view-slider-prev-timeline;
                transform: scale(3);

                @include breakpoint(medium) {
                    transform: scale(5);
                }
            }
        }
    }

    .tl-slidenav-content-container.tl-slidenav-inverted {

        .tl-slidenav-icon {
            opacity: 1;

            &:before {
                @include svg-view-slider-prev-white;
                transform: scale(3);

                @include breakpoint(medium) {
                    transform: scale(5);
                }
            }
        }
    }
}

.tl-menubar {
    .tl-icon-zoom-in {

        &:after {
            @include svg-view-mag-plus-gray;
        }
    }

    .tl-icon-zoom-out {

        &:after {
            @include svg-view-mag-minus-gray;
        }
    }

    .tl-icon-goback {
        &:after {
            @include svg-view-rewind-gray;
        }
    }

    .tl-menubar-button:hover {
        .tl-icon-zoom-in {

            &:after {
                @include svg-view-mag-plus-white;
            }
        }

        .tl-icon-zoom-out {

            &:after {
                @include svg-view-mag-minus-white;
            }
        }

        .tl-icon-goback {
            &:after {
                @include svg-view-rewind-white;
            }
        }
    }
}

.tl-icon-image, .tl-icon-youtube, .tl-icon-vimeo, .tl-icon-flickr {
    display: none !important;
}
