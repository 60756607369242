body {
    background: none repeat scroll 0 0 $c-body-color;
    color: $f-body-color;
    font-family: $f-body;
    font-size: $f-size-xxs;
    line-height: $b-line-height;
    font-weight: $f-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: clip; // Using clip rather than hidden doesn't break position: sticky if we want to use it

    @include breakpoint(medium) {
        font-size: $f-size-xs;
        line-height: $b-line-height-md;
    }

    &.dialog-open {
        overflow: hidden;

        .ui-dialog:not(.s-sizechart-dialog) {
            @include bp(max-width, $bp-large) {
                margin: 0 auto;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
                position: fixed !important;
            }

            @include bpmm($bp-small, 896px) {
                overflow-y: auto;
                overflow-x: hidden;
            }

            &.js-multiple-suggested-address {
                max-height: 100% !important;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}

html:has(body.dialog-open):has(.ui-dialog:not(.s-sizechart-dialog)) {
    overflow: hidden;

    @include bp(max-width, $bp-medium) {
        position:fixed;
        width: 100%;
    }
}

a {
    color: $c-anchor;
    outline: medium none;
    overflow: hidden;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.primary-link {
    border: none;
    border-bottom: 1px solid transparent;
    color: $c-black;
    font-size: 11px;
    font-weight: $f-weight-bold;
    letter-spacing: 2.5px;
    line-height: 1;
    padding-bottom: 3px;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(medium) {
        font-size: $f-size-xs;
        letter-spacing: 3px;
        padding-bottom: 4px;
    }

    &:active,
    &:focus,
    &:hover {
        &:not(.disabled):not(:disabled) {
            background-color: transparent;
            border-bottom-color: $c-black;
            color: $c-black;
            text-decoration: none;
        }
    }
    
    &.disabled,
    &:disabled {
        opacity: .5;
    }
}

ol {
    padding-left: 19px;

    li {
        padding-left: 8px;
    }
}

ul {
    padding-left: 21px;
}

i {
    line-height: normal;
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}

button.js-awmh-donation.donation {
    @include donation-button;
    background-color: #fff;
    color: #000;
    line-height: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 6px 6px;
    text-decoration: none;
}

button,
.button,
.buttonstyle,
input[type="button"] {
    @include primary-button;
    display: inline-block;
    overflow: visible;

    &.large {
        font-size: $button-f-large-size;
    }

    &.xlarge {
        font-size: $button-f-large-size;
        letter-spacing: 4px;
        width: 200px;
        padding: 12px 32px;
        @include breakpoint(medium) {
            width: 274px;
        }
    }

    &:hover {
        // leave blank for now
    }

    &:focus {
        outline: none;
    }

    &.raised {
        border-width: 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

        &:hover {
            background-color: darken($citrus, 10%);
            color: $white;
        }
    }

    a {
        color: $black;

        &:hover {
            color: $black;
        }
    }

    i {
        &.left {
            margin-right: 0.6em;
        }
        &.right {
            margin-left: 0.6em;
        }
    }

    &.disabled,
    &:disabled {
        opacity: .5;
    }
}

.button--simple {
    @include simple-button;
}

.button--secondary {
    @include secondary-button;
}

.button--tertiary {
    @include secondary-button;
}

.button--cta, .add-to-cart {
    @include secondary-button;
}

.button--hollow {
    @include hollow-button;
}

.button--small {
    line-height: 10px;
    padding: 6px 15px
}

.button--see-more {
    @include see-more-button;
}

.button-fancy-medium {}

.button-text {
    background-color: transparent;
    border-width: 0;
    color: $citrus;
    padding: 0;
    &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}

// =============================================
// Typography Base Elements
// =============================================

h1, .h1 {
    @include h1;
    text-transform: uppercase;
}

h2, .h2 {
    @include h2;
    text-transform: uppercase;
}

h3, .h3 {
    @include h3;
    text-transform: uppercase;
}

h4, .h4 {
    @include h4;
    text-transform: uppercase;
}

h5, .h5 {
    @include h5;
    text-transform: uppercase;
}

h6, .h6 {
    @include h6;
    text-transform: uppercase;
}

.s1 {
    @include s1;
}

.s2 {
    @include s2;
}

.s3 {
    @include s3;
}

.cta1 {
    @include link1;
}

.cta2 {
    @include link2;
}

.link1 {
    @include link1;
}

.link2 {
    @include link2;
}

p a,
.link-inline {
    text-decoration: underline;
}

.accent {
    @include accent;
}

h6.subtext {
    @include font-source($sans-serif, 12px, $black, 16px);
    text-transform: none;
    margin-bottom: 5px;

    @include breakpoint(large) {
        margin-bottom: 50px;
    }
}

.rollover-tab {
    overflow: visible;
    position: relative;

    &:nth-child(3n + 2) {
        .rollover {
            top: 0;
        }
    }

    .rollover {
        display: none;
        position: absolute;
            left: 50%;
            top: 50%;
        transform: translateX(-50%);
    }
    
    @include svg-view-tab-rollover-1-element {
        fill: currentColor;
    }

    @include svg-view-tab-rollover-2-element {
        fill: currentColor;
    }

    @include svg-view-tab-rollover-3-element {
        fill: currentColor;
    }
}

.textbutton {
    background: transparent;
    border: none;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1.43;
    padding: 0;
    text-decoration: underline;
    text-transform: none;

    &:hover:not(:disabled):not(.disabled) {
        background: transparent;
        border: none;
    }
}

.markdown {
    strong {
        font-family: $f-header;
    }
}