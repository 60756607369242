.js-collapse-trigger {
    &:hover {
        cursor: pointer;
    }
}

.mobile-navigation {
    background: $content-sidebar-bg;
    padding: 16px 0;
    border: $content-sidebar-border;
    margin: $content-sidebar-margin;

    .collapse-trigger,
    .toggle,
    ul {
        padding-left: 18px;
        padding-right: 18px;

        @include breakpoint(medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .collapse-trigger {
        position: relative;
        font-weight: $f-weight-bold;
        font-family: $sans-serif;
        font-size: 15px;
        line-height: 1;
        letter-spacing: 3px;

        &:after {
            @include position(absolute, 50% 18px null null);
            
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 8px solid $c-black;
            content: '';
            transform: translateY(-50%);
            transition: transform .2s linear;
        }
    }

    .toggle {
        margin-bottom: 16px;
        padding-top: 20px;
    }

    .js-collapse-content {
        display: none;
    }

    &.collapse-open {
        padding-bottom: 10px;
        
        .collapse-trigger {
            padding-bottom: 4px;
    
            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    @include breakpoint(medium) {
        background: none;
        border: none;
        margin: 0;
        padding: 0;

        .js-collapse-trigger {
            display: none;
        }

        .js-collapse-content {
            display: block !important;
        }
    }
}
