.c-2up-article {
    @include breakpoint(medium) {
        padding-left: 4px;
        padding-right: 4px;
    }

    &:not(:first-child) {
        @include bp(max-width, $bp-medium) {
            padding-top: 8px;
        }
    }

    &__wrap {
        @include breakpoint(medium) {
            margin-left: -4px;
            margin-right: -4px;
        }

        &:last-child {
            margin-bottom: 41px;

            @include breakpoint(medium) {
                margin-bottom: 93px;
            }
        }
    }

    &__link {
        box-sizing: border-box;
        display: inline-block;
        min-height: 100%;
        position: relative;
        vertical-align: top;
        width: 100%;
    }

    &__img-wrap {
        position: relative;

        &::before {
            background-color: $img-overlay-bg;
            content: '';
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
        }
    }

    & &__img {
        height: auto;
        width: 100%;
    }

    &__img {
        min-height: 250px;
        object-fit: cover;
        object-position: center center;
    }

    &__details {
        box-sizing: border-box;
        color: $white;
        padding: 80px #{$grid-small-padding/2};
        position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;

        @include breakpoint(medium) {
            padding-left: #{$grid-medium-padding/2};
            padding-right: #{$grid-medium-padding/2};
        }

        @include breakpoint(large) {
            padding-left: #{$grid-large-padding/2};
            padding-right: #{$grid-large-padding/2};
        }

        @include breakpoint(xlarge) {
            padding-left: #{$grid-xlarge-padding/2};
            padding-right: #{$grid-xlarge-padding/2};
        }
    }

    &__name {
        @include h3;

        color: inherit;
        margin-bottom: 8px;

        @include breakpoint(medium) {
            @include h4;

            color: inherit;
        }
    }

    &__author {
        font-size: 14px;
        line-height: 1;
        margin-top: 8px;
    }
}