.c-banner-ad {
    display: flex;
        align-items: flex-end;
        justify-content: center;
    min-height: 300px;
    overflow: hidden;
    position: relative;
        z-index: 0;

    @include breakpoint(medium) {
        align-items: center;
        justify-content: flex-end;
        min-height: 150px;
    }

    &__link {
        display: flex;
            align-items: flex-end;
            justify-content: center;
        min-height: 300px;
        width: 100%;

        @include breakpoint(medium) {
            align-items: center;
            justify-content: flex-end;
            min-height: 150px;
        }
    }

    &__bg {
        height: 100% !important;
        object-fit: cover;
        object-position: center center;
        position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        width: 100%;
    }

    &__text {
        box-sizing: border-box;
        color: $white;
        font-size: $f-size;
        line-height: 1.6;
        margin-bottom: 42px;
        text-align: center;

        @include breakpoint(medium) {
            margin-bottom: 0;
            max-width: MIN(416px, 40%);
            padding-left: 32px;
            padding-right: 32px;
            text-align: left;
        }

        @include breakpoint(large) {
            padding-right: 64px;
        }

        h2 {
            color: inherit;
            font-size: 21px;
            letter-spacing: 0.5px;
            line-height: 1.29;
        }
    }
}