.c-note-cards {
    margin: 16px 0 100px;

    @include breakpoint(large) {
        margin-top: 14px;
    }

    &:not(.slick-initialized) {
        margin-left: -4px;
        margin-right: -4px;
        width: calc(100% + 8px);
    }

    * {
        box-sizing: border-box !important;
    }

    &__slider {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(large) {
            width: auto !important;
        }
    }

    &__heading {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.5px;
        margin-bottom: 20px;

        @include breakpoint(medium) {
            font-size: 34px;
        }
    }

    &__item {
        background-image: url("../images/note-cards-bg.png");
        background-position: center;
        background-size: cover;
        color: $white;
        font-family: 'futura-pt-bold', sans-serif;
        font-size: 20px !important;
        font-weight: bold;
        height: inherit !important;
        line-height: 1.1;
        letter-spacing: 0.5px;
        margin: 0 3.5px 8px;
        padding: 30px;
        text-align: left;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: 25px !important;
            letter-spacing: normal;
            line-height: 1.15;
            margin-right: 4px;
            margin-left: 4px;
        }

        @include breakpoint(xlarge) {
            font-size: 28px;
        }

        &:not(#{&}--tile) {
            min-height: 106.2801vw !important;
            width: calc(100% - 8px);
            word-wrap: break-word;
            
            @include breakpoint(small) {
                min-height: 440px !important;
                width: calc(50% - 8px);
            }

            @include breakpoint(medium) {
                min-height: 468px !important;
                width: calc(33.3333% - 8px);
            }
        }
    }

    &.slick-initialized {
        .c-note-cards {
            &__item {
                margin-bottom: 0;

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
}

.s-note-cards {
    // force equal height slides
    .slick-track {
        display: flex !important;
    }

    .slick-list {
        margin-left: -4px;

        @include bp(max-width, $bp-medium) {
            padding-left: 24px !important;
            padding-right: 24px !important;
        }
    }

    &.s-tile-slider {
        .slick-dots {
            left: 31px;
        }
    }

    .slick-prev {
        right: 50px;
    }

    .slick-next {
        right: 10px;
    }

}
