.tabs-container {
    margin-top: 50px;

    @include breakpoint(medium) {
        margin-top: 0;
    }
}

.tabs {
    @include breakpoint(medium) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        border-bottom: $tabs-boder;
    }
}

.tab {
    flex-basis: 25%;

    @include breakpoint(medium) {
        text-align: center;
    }

    @include has(label) {
        position: relative;
        padding: 5px 0;

        &:after {
            @include svg-view-icon-collapse;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: transform .2s linear;

            @include breakpoint(medium) {
                display: none;
            }
        }

        @include breakpoint(medium) {
            display: inline-block;
            position: relative;
            padding: 0;

            &:hover {
                cursor: pointer;
                font-weight: $f-weight-bold;
            }
        }

    }

    @include has(content) {
        display: none;
        text-align: left;
        z-index: 1;

        .js-collapse-content & {
            display: block;
            padding: 20px 0;
        }

        @include breakpoint(medium) {
            display: none;
        }
    }

    @include breakpoint(medium) {
        flex-basis: 25%;

        &.active {
            .tab__content {
                display: block;
            }

            .tab__label {
                font-weight: $f-weight-bold;

                &:after {
                    content: '';
                    height: $tabs-heading-active-height;
                    background: $tabs-heading-active-bg;
                    transform: translateX(-50%);
                    width: $tabs-heading-active-width;
                    @include position(absolute, $tabs-heading-active-border-position);
                }
            }
        }
    }

    &.js-collapse-parent.collapse-open .js-collapse-trigger:after {
        @include rotate(180deg);
    }
}

.tabs__heading {
    .tab {
        margin: $tabs-heading-margin;
    }
}

.tabs--fifthMember {
    &.ui-tabs {
        .metclub-chapter-wrapper {
            background: initial;
        }
    }

    .ui-tabs-nav {
        height: auto;
    }

    .ui-state-default {
        border: 0;

        .ui-tabs-anchor {
            padding-left: 0;
        }
    }

    .ui-tabs-panel {
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(large) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .c-whats-included__tab-btns {
        display: inline-block;

        li {
            &.ui-state-active {
                border-color: $white;
                margin-bottom: 0;

                &:hover {
                    background: none;
                }
            }

            &.ui-state-default {
                a {
                    font-size: 20px;
                    font-weight: $f-weight-bold;

                    @include breakpoint(large) {
                        font-size: 34px;
                    }
                }
                &:hover {
                    background-color: initial;

                    a {
                        color: $black;
                    }
                }
            }

            a {
                font-family: $f-header;
            }
        }
    }

    .c-whats-included__header {
        margin-bottom: 30px;

        @include bp(max-width, $bp-large) {
            border: 0;
            margin-bottom: 10px;
            padding-bottom: 0;
        }
    }

    .metclub-local-chapters-filter {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        margin-top: -30px;
        padding: 0px 4px 15px;
        width: auto;

        @include breakpoint(large) {
            position: absolute;
            right: 68px;
            top: 20px;
            width: 45%;
            margin-top: 0;
        }

        .sort-by {
            flex-grow: 1;

            @include breakpoint(large) {
                flex-grow: 0;
            }
        }

        .metclub-chapter-sort-select {
            height: 36px;
            border-color: $black;
            font-size: 12px;
            font-weight: $f-weight-bold;
            line-height: 1;
            letter-spacing: 0;
            text-transform: uppercase;
            padding: 0 10px 0 5px;
            font-size: 1.1em;

            @include breakpoint(large) {
                letter-spacing: 3px;
                padding: 0 30px 0 15px;
                font-size: 15px;
            }
        }

        .pagination-wrapper {
            margin-left: 3px;
        }

        .pagination {
            margin: 0;
        }

        .select-wrapper {
            &:after {
                right: 5px;
                @include breakpoint(large) {
                    right: 15px;
                }
            }
        }
    }

    .bottom-pagination-wrapper {
        margin: 0px -5px;
        width: auto;

        @include breakpoint(large) {
            margin: 0px -15px;
        }
    }
}

.chapter-locations {
    #tabs-2 .pagination.total-gt-4 {
        @include bp(max-width, $bp-medium) {
            &.cur-pg-1 li.last-item {
                display: none;
            }
            &.cur-pg-2 li.stlast-item,
            &.cur-pg-2 li.last-item {
                display: none;
            }
            &.cur-pg-gt-2 li.first-item,
            &.cur-pg-gt-2 li.last-item {
                display: none;
            }
            &.cur-pg-stlast li.first-item,
            &.cur-pg-stlast li.second-item {
                display: none;
            }
        }
    } 
}
