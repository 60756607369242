.pt_contest, .pt_contest_detail {

    .content-hero img {
        object-fit: cover;
    }

    .title {
        .col {
            padding-top: 0;
        }

        .page-title {
            line-height: 22px;
            letter-spacing: 1px;
            opacity: .15;

            @include breakpoint(medium) {
                line-height: 36px;
                font-size: 56px;
            }

            @include breakpoint(large) {
                line-height: 30px;
                font-size: 48px;
                letter-spacing: 3px;
            }
        }
    }
}

.pt_contest {
    .title .page-title {
        @include breakpoint(large) {
            font-size: 56px;
            line-height: 36px;
        }

        @include breakpoint(xlarge) {
            font-size: 96px;
            line-height: 64px;
        }
    }

    .refinements {
        margin-top: 10px;
        margin-bottom: 20px;

        @include breakpoint(medium) {
            margin-top: 13px;
            margin-bottom: 12px;
        }

        @include breakpoint(large) {
            margin-bottom: 18px;
        }

        @include breakpoint(xlarge) {
            margin-top: 18px;
        }

        .top-bar {
            @include breakpoint(medium) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .year-refinements-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            label {
                margin: 0 16px 0 0;
            }

            .select-wrapper {
                width: 100%;
                max-width: 200px;
            }

            @include breakpoint(medium) {
                justify-content: flex-start;
                width: 100%;
            }
        }
    }

    .contests-list {
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding-top: 0;

        .contest {
            padding: 15px 15px 15px 0;
            position: relative;

            @include breakpoint(small) {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            }

            &:first-child .ghost-year {
                top: -5px;
                cursor: default;
            }

            .contest-date-venue {
                .contest-name {
                    margin-top: 0;
                    margin-bottom: 0;
                    color: $black;
                }

                .contest-location {
                    text-transform: uppercase;
                }

                .date-numbers {
                    line-height: 20px;
                    min-width: 90px;
                }
            }

            .ctas {
                flex-direction: row;
                margin-top: 11px;
                width: auto;

                @include breakpoint(medium) {
                    margin-top: 0;
                }

                .opt-out {
                    margin-left: 10px;
                }
            }
        }

        .ghost-year {
            font-family: $f-header;
            opacity: 0.05;
            font-size: 72px;
            color: $black;
            line-height: 80px;
            font-weight: $f-weight-light;
            cursor: default;
            @include position(absolute, 1px null null -18px);
            z-index: -1;

            @include breakpoint(large) {
                left: -175px;
            }
        }
    }

    .top-title-bar {
        margin-bottom: 0;
        margin-left: 0;

        .contest-divider {
            border-bottom: 1px solid $black;
            margin: 20px 0;
            width: 100%;
        }

        .page-title {
            display: flex;
            align-items: center;
            padding-left: 0;
        }

        .top-bar {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .year-refinements-wrapper {
        @include breakpoint(small) {
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        label {
            padding-left: 0;
        }

        .select-wrapper {
            padding-right: 0;
            padding-left: 0;

            &:after {
                top: 60%;

                @include breakpoint(large) {
                    top: 70%;
                }
            }
        }
    }
}

.pt_contest_detail {

    .contest-header {

        @include breakpoint(medium) {
            margin-bottom: 21px;
        }

        h1 {
            @include h4;
            margin-top: 2px;
            text-align: left;

            @include breakpoint(medium) {
                font-size: 32px;
                line-height: 40px;
                margin-top: 0;
                margin-bottom: -1px;
            }

            @include breakpoint(large) {
                @include h2;
                margin-top: 22px;
                margin-bottom: -3px;
            }
        }

        h4 {
            line-height: 25px;
            font-family: $f-body;
            font-size: 14px;
            font-weight: $f-weight-regular;
            margin-bottom: 2px;
            text-align: left;

            @include breakpoint(medium) {
                font-size: 20px;
                line-height: 32px;
                font-family: $f-header;
                font-weight: $f-weight-medium;
            }

            @include breakpoint(large) {
                @include h4;
                margin-bottom: 2px;
            }
        }

        .contest-dates {
            display: flex;
            flex-direction: row;
            
            p {                    
                font-size: 14px;
                line-height: 20px;
                margin: 0;
            }

            @include breakpoint(large) {
                text-align: center;
            }
        }

        .login-req-msg {
            margin: 6px 0 3px 0;

            p {
                margin-top: 0;
                margin-bottom: 5px;
                font-weight: $f-weight-bold;
            }
        }

        .win-status-msg {
            color: $c-red;
            font-weight: $f-weight-bold;
            font-size: 24px;
            margin: 40px 0 10px 0;

            p {
                margin-bottom: 0;
            }
        }

        .ended-button {
            margin-bottom: 3px;
            margin-top: 7px;

            @include breakpoint(medium) {
                margin-bottom: 6px;
                margin-top: 6px;
            }

            #contest-ended {
                cursor: auto;
            }
        }
    }

    .contest-description {
        margin-top: -6px;

        @include breakpoint(medium) {
            margin-top: -30px;
        }

        @include breakpoint(large) {
            margin-top: -33px;
        }

        @include breakpoint(xlarge) {
            margin-top: -41px;
        }

        section {
            margin-bottom: 24px;

            .section-title {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 14px;

            @include breakpoint(large) {
                font-size: 16px;
            }
        }

        .contest-rules ol {
            padding-left: 15px;
            font-size: 14px;
            margin-top: 0;
        }

        .fine-print {

            p {
                font-size: 16px;
            }

            .details {
                margin-top: 0;
            }
        }

        .h-caption {
            font-size: 12px;
        }
    }


    .detail-image-layout {

        .contest-header {
            position: relative;

            h1 {
                margin-top: 17px;
                margin-bottom: 15px;
                max-width: 115px;

                @include breakpoint(medium) {
                    max-width: none;
                    margin-top: 0;
                    margin-bottom: -1px;
                }

                @include breakpoint(large) {
                    margin-top: 22px;
                    margin-bottom: -3px;
                }
            }

            .ended-button {

                @include breakpoint(medium) {
                    margin-top: 6px;
                }

                @include breakpoint(large) {
                    text-align: center;
                }
            }

            .win-status-msg, .login-req-msg {
                @include breakpoint(large) {
                    text-align: center;
                }
            }

            .contest-image {
                @include position(absolute, -70px 0 null null);
                max-width: 224px;

                @include breakpoint(medium) {
                    max-width: 247px;
                }

                @include breakpoint(large) {
                    max-width: 327px;
                    @include position(absolute, -110px 82px null null);
                }

                @include breakpoint(xlarge) {
                    max-width: 411px;
                    @include position(absolute, -110px 103px null null);
                }
            }
        }
    }

    .ctas-bottom {
        margin-top: 12px;
        margin-bottom: 43px;
    }

    .contest-location {
        margin-top: 0;
        text-transform: uppercase;
    }

    .win-status-msg {
        margin-top: 20px;

        p {
            font-weight: $f-weight-bold;
            font-size: 24px;

            &.winner {
                color: $c-red;
            }
        }
    }
}

////////////////////////////////////
// CONTEST LIST PAGE IN MY ACCOUNT//
////////////////////////////////////

.account-contests-header {
    @include breakpoint(medium) {
        font-size: 32px;
        line-height: 40px;
        margin-top: 10px;
        margin-bottom: 22px;
    }
}

.account-contests-list {
    padding-left: 0;
    margin-top: 8px;
    margin-bottom: 40px;

    li {
        border: 1px solid $c-stroke-gray;
        display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: column;
        list-style: none;
        margin-bottom: 10px;
        padding: 16px 16px 20px;

        @include breakpoint(small) {
            flex-wrap: nowrap;
            align-items: center;
            flex-direction: row;
        }

        @include breakpoint(large) {
            padding: 15px 20px;
        }

        .contest-status {
            font-size: 16px;
        }

        @include bp(max-width, $bp-large) {
            .contest-name a {
                font-size: 16px;
            }

            .opt-out {
                margin-top: 10px;
                .button {
                    box-sizing: border-box;
                    width: 100%;
                }
            }
        }
    }
}

/////////////////////////
// CONTEST ENTRY MODAL //
/////////////////////////

.ui-dialog.contest-entry-modal {
    background-color: $c-mostly-white;
    max-width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute !important; // override JS
    @include breakpoint(medium) {
        max-width: 80%;
        margin: 0 auto;
        position: fixed !important; // override JS
    }

    &:has(.entered-contest) {
        background-color: #fff;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%);
        width: auto !important;

        .ui-dialog-titlebar-close {
            right: 36px;
            width: auto;

            span {
                text-indent: -9999px;
                width: auto;
    
                &.ui-icon-closethick {
                    width: 20px;
                    height: 20px;
                    transform: translate(-50%, -50%) scale(0.9);
                    background-position: 71.69% 63.95%;
                }
            }
        }

        .ui-dialog-content {
            box-sizing: border-box;
            padding: 50px 30px 30px;
            width: 100% !important;

            @include breakpoint(medium) {
                padding: 30px 85px;
            }

            #contestEntry #entry-submit-wrapper {
                margin-bottom: 0;

                .entered-contest {
                    font-size: 28px;

                    @include breakpoint(medium) {
                        font-size: 38px;
                    }

                    @include breakpoint(large) {
                        font-size: 48px;
                    }
                }
            }
        }

        .contest-entry-header,
        .dialog-required,
        .form-row {
            display: none;
        }
    }

    .ui-dialog-titlebar-close {
        top: 5px;
        right: -10px;
        width: 100px;
        background-color: transparent;
        border-color: $c-gray;
        color: $c-gray;

        &:before, &:after {
           display: none;
        }

        .ui-icon-closethick {
            left: 11px;
            @include svg-view-close-gray;
        }

        .ui-button-text {
            text-indent: unset;
            color: $c-gray;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
        }
    }

    &.display-spinner-image {
        .dialog-content {
            .image-spinner {
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translate(50%, -50%);
            }
        }

        .dialog-content:has(#opt-out-wrapper) {
            .image-spinner {
                display: none;
            }
        }
    }
}

.contest-entry-header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 14px;
    margin-top: 40px;

    @include breakpoint(medium) {
        font-size: 32px;
        line-height: 40px;
    }
}

#contestEntry {
    > .required-indicator {
        display: none;
    }

    .form-row {
        .form-field-subcopy-wrap {
            display: block;
            width: 100%;
            .form-caption.error-message  {
                background: none;
                color: $brand-error;
                border: 0;
                display: block;
                padding-left: 0;
                padding-right: 0;
                text-transform: uppercase;
                width: 100%;
            }
        }

        &.disabled{
            display: none;
        }

        &.castate{
            clear: none;
            float: left;
        }

        &.label-inline {
            position: relative;
            span {
                color: $black;
                text-align: right;
                font-size: 12px;

                &.required-indicator {
                    color: $c-black;
                }
                &.error {
                    position: absolute;
                    bottom: 0;
                    left: 28px;
                    color: $c-red;
                }
            }

            @include breakpoint(medium) {
                width: 100%;
            }
        }
    }

    #entry-submit-wrapper {
        margin-bottom: 26px;

        .entered-entry {
            margin-bottom: 10px;
        }
    }
}

.ui-dialog {
    &.optout-entry-modal {
        left: 50% !important;
        transform: translate(-50%, 0);

        .ui-dialog-content {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 0 20px;

            @include breakpoint(medium) {
                padding: 0 40px;
            }
        }
    }
}