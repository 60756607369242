.product-tile {
    @extend %clearfix;
    color: $ptile-font-color;
    position: relative;
    display: flex;
        flex-direction: column;
    height: 100%;
    padding-left: 3px;
    padding-right: 3px;

    @include breakpoint(medium) {
        padding-left: 4px;
        padding-right: 4px;

        > div {
            padding: 0;
        }
    }

    img {
        max-width: 100%;
        display: inline-block;
    }

    .thumb-link {
        display: block;
    }

    .product-info {
        display: flex;
        flex-direction: column;
    }

    .gated-flag {
        margin-bottom: 7px;
        text-transform: uppercase;

        & + .product-name {
            margin-top: 0;
        }

        @include breakpoint(medium) {
            & + .product-name {
                margin-top: 9px;
            }
        }

        @include breakpoint(large) {
            margin-bottom: 6px;

            & + .product-name {
                margin-top: 4px;
            }
        }
    }

    .product-image {
        height: auto;
        margin-bottom: 8px;
        position: relative;

        @include breakpoint(large) {
            padding: 0;
        }

        .quickview {
            @include primary-button;

            box-sizing: border-box;
            display: none;
            font-size: 10px;
            letter-spacing: 1px;
            line-height: 1px;
            opacity: 90%;
            padding-bottom: 7px;
            padding-top: 6px;
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            width: 100%;

            &:hover {
                text-decoration: none;
            }

            i {
                margin-left: 0.5em;
            }
        }

        a {
            border: 1px solid $c-light-gray;
            display: block;
        }

        &:hover {
            .quickview {
                display: block;
            }
        }
    }

    .product-name {
        text-transform: uppercase;
        flex-grow: 1;

        a {
            color: $c-black;
            font-family: $sans-serif;
            font-size: 12px;
            font-weight: $f-weight-semibold;
            letter-spacing: 1px;
            line-height: 1.17;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 14px;
                line-height: 1.14;
            }
        }
    }

    .product-price {
        font-size: $ptile-price-f-size;
        color: $black;
        font-weight: $f-weight-bold;
        margin-top: 3px;

        .price-standard {
            color: $c-gray;
            text-decoration: line-through;
            font-weight: $f-weight-regular;
            margin-right: 14px;

            + .price-sales {
                color: $c-red;
            }
        }
    }

    .product-pricing {
        font-size: $ptile-price-f-size;

        @include breakpoint(large) {
            font-size: $ptile-price-f-size-lg;
        }

        > span {
            line-height: 21px;

            @include breakpoint(large) {
                line-height: 24px;
            }
        }

        .product-standard-price {
            text-decoration: line-through;
            color: $ptile-standard-price-color;

            + .product-sales-price {
                color: $ptile-sales-price-color;
                margin-left: 14px;
            }
        }

        .product-sales-price {
            color: $black;
            font-weight: $f-weight-bold;
            letter-spacing: 1px;
        }
    }

    .grid-tile & .product-swatches {
        float: right;
    }

    .product-swatches-all {
        cursor: pointer;
        font-size: 0.9em;
    }

    .product-swatches {
        ul {
            list-style: none outside none;
            margin: 0;
            overflow: hidden;
            padding: 0;

            &.swatch-toggle {
                background: none repeat scroll 0 0 $white;
                display: none;
                position: absolute;
            }
        }
        display: none;

        .pt_product-compare &,
        .pt_product-search-result & {
            display: block;
        }

        &:hover ul.swatch-toggle {
            display: block;
        }

        li {
            float: left;
        }

        .swatch {
            display: block;
            width: 16px;

            img {
                border: 1px solid $white;
                height: 12px;
                padding: 1px;
                width: 12px;
            }
        }

        .selected img,
        img:hover {
            border-color: $citrus;
        }
    }

    .product-promo {
        margin: 0.5em 0;
        font-size: 12px;
        line-height: 16px;

        @include breakpoint(medium) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .product-compare {
        line-height: 11px;

        .field-wrapper {
            margin-bottom: 0;
            float: none;
            display: inline-block;
        }

        label {
            font-weight: 400;
            line-height: 1;
            padding: 0 0 0 0.5em;
            width: auto;
        }

        input {
            display: inline-block;
            margin: 0;
        }
    }

    .product-review {
        margin-bottom: 1em;

        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }

        .rating {
            display: inline-block;
        }
    }

    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element

        .product-image {
            float: left;
            padding-right: 2em;
        }

        .product-name,
        .product-pricing,
        .product-promo,
        .product-review,
        .product-swatches {
            float: left;
            text-align: left;
            width: 65%;
        }

        .product-name {
            font-size: 1.5em;
            height: auto;
            margin-top: 0;
        }

        .product-pricing {
            font-size: 1.3em;
        }

        .product-swatches {
            margin-bottom: 1em;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

@media only screen and (max-width: 760px) {
    #quickviewbutton {
        display: none;
    }
}