.c-shop-by {
    margin-top: 80px;
    margin-bottom: 85px;
    position: relative;

    @include breakpoint(medium) {
        margin-top: 68px;
        margin-bottom: 100px;
    }

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 145px;

        @include breakpoint(medium) {
            padding-bottom: 200px;
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include bp(max-width, $bp-medium + 1) {
            justify-content: center;
        }

        @include breakpoint(medium) {
            align-items: stretch;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 40px;
        }
    }

    &__card {
        width: 46%;

        @include breakpoint(small) {
            width: 48%;
        }

        @include breakpoint(medium) {
            height: 100%;
            width: 100%;
        }

        &__link {
            display: block;
            height: 100%;
            position: relative;
            width: auto;

            img {
                display: block;
                height: 100%;
                margin: 0;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    &__heading {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 14px;

        @include breakpoint(medium) {
            margin-bottom: 18px;
        }

        &__link,
        &__link * {
            font-size: 11px;
            font-weight: $f-weight-bold;
            line-height: 1;
            letter-spacing: 2.5px;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 14px;
                letter-spacing: 3px;
            }
        }
    }
}