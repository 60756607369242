.more-articles {
    margin-bottom: 10px;
    @include breakpoint(large) {
        margin: -34px -4px 80px -4px;

        .col {
            padding: 40px 4px 0 4px;

            @include breakpoint(large) {
                padding-top: 34px;
            }
        }
    }
    
    &,
    &:last-child {
        @include breakpoint(large) {
            margin-bottom: 80px;
        }
    }

    &-titles {
        display: block;
        margin-bottom: 20px;
        width: 100%;
    
        &:not(#{&}--no-border) {
            border-bottom: 1px solid $brand-primary;
            padding-bottom: 15px;
        }
    
        &--no-border {
            margin-bottom: 0;
        }
    }
}

.more-article {
    &-item {
        @include bp(max-width, $bp-large) {
            margin-bottom: 30px;
        }

        .slick-initialized & {
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    &-img {
        overflow: hidden;
        margin-bottom: 10px;

        img {
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
    }    

    &-date {
        color: $c-charcoal;
        font-size: 10px;
        font-weight: $f-weight-bold;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 4px;
        text-transform: uppercase;
    }

    &-name {
        display: inline-block;
        font-family: $f-header;
        font-size: 16px;
        font-weight: $f-weight-bold;
        line-height: 1.25;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        width: 100%;

        @include breakpoint(medium) {
            font-size: 18px;
            line-height: 1.22;
        }
    }

    &-type {
        color: $c-charcoal;
        display: inline-block;
        font-size: 10px;
        font-weight: $f-weight-bold;
        letter-spacing: 1px;
        line-height: 1;
        margin-top: -2px;
        text-transform: uppercase;
        vertical-align: top;
    }
}

.more-articles-load-more {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    @include breakpoint(large) {
        margin-bottom: 100px;
    }
}
