.avs-candidate-list {
    z-index: 20;

    @include breakpoint(medium) {
        z-index: 101;
        position: relative;
    }
    
    h4 {
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 20px;
    }
}

#avsCandidateUser {
    border-bottom: 1px solid $c-gray;
    margin-bottom: 10px;
    padding-top: 0;
    padding-bottom: 10px;
}

.ui-dialog {
    .ui-dialog-titlebar {
        button {
            background-color: initial;
            right: 10px;
            top: 10px;

            &:hover {
                background-color: initial;
            }

            .ui-button-icon-primary {
                &:not(.ui-icon-closethick) {
                    height: 18px;
                    width: 18px;
                }

                &.ui-icon-closethick {
                    transform: translate(-50%, -50%) scale(0.9); // Resize in a way that accommodates the icon loaded from an svg sprite
                }
            }
        }
    }

    &.vinyl-club-subscription #avsCandidateList {
        display: flex !important;
        flex-wrap: wrap;

        h1 {
            max-width: 100%;
            width: 90%;
        }

        .avs-candidate {
            width: 100%;

            @include breakpoint(medium) {
                width: 50%;

                button {
                    width: auto;
                }
            }
        }

        .select-avs-candidate {
            button {
                @include breakpoint(medium) {
                    width: auto;
                }
            }
        }
    }

    #avsCandidateList {
        box-sizing: border-box;
        height: initial !important;
        padding: 20px;
        text-align: center;
        width: 100% !important;

        & + .ui-dialog-buttonpane {
            display: none;
        }

        .h1,
        h1 {
            font-size: 32px;
            line-height: 1.13;
            letter-spacing: 0.5px;
            margin: 40px auto 30px;
            max-width: 320px;
            text-align: center;

            @include breakpoint(large) {
                font-size: 34px;
                line-height: 1.12;
                max-width: 482px;
            }
        }

        .avs-candidate {
            display: block;
            border: 0;
            padding: 0;
            margin-bottom: 40px;

            @include breakpoint(large) {
                display: inline-block;
                width: 40%;

                button {
                    width: 200px;
                }
            }

            .avs-candidate-address {
                div {
                    font-size: 16px;
                    line-height: 1.5;
                    text-align: center;
                    text-transform: capitalize;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 20px;
                    }

                    @include breakpoint(large) {
                        font-size: 14px;
                    }
                }
            }
        }

        .select-avs-candidate {
            display: block;
            text-align: center;

            button {
                width: 100%;

                @include breakpoint(large) {
                    width: 200px;
                }
            }
        }
    }
}