.pt_giftcertpurchase {
    .top-content,
    .bottom-content {
        background: $c-mostly-white;
    }

    .breadcrumb {
        margin-bottom: 14px;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            margin-bottom: 7px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 0;
        }
    }

    .top-content {
        padding-top: 43px;

        @include breakpoint(large) {
            padding-top: 54px;
        }

        h1 {
            margin-top: -11px;
        }

        .mobile-header {

            h1 {
                margin-bottom: 1px;
            }

            @include breakpoint(medium) {
                display: none;
            }
        }
    }

    .primary-image img {
        border: 1px solid $c-light-gray;
        background: $white;
        padding: 8px;
        box-sizing: border-box;
        width: 100%;

        @include breakpoint(medium) {
            width: 93%;
        }

        @include breakpoint(large) {
            padding: 16px;
            width: 100%;
        }

        @include breakpoint(xlarge) {
            padding: 24px;
        }
    }

    .description {
        padding-bottom: 17px;

        @include breakpoint(large) {
            padding-bottom: 33px;
        }
    }

    h2 {
        text-align: center;
        margin-top: 21px;
        margin-bottom: 13px;

        @include breakpoint(medium) {
            margin-top: 8px;
        }
    }

    .bottom-content {
        padding-bottom: 24px;

        @include breakpoint(xlarge) {
            padding-bottom: 49px;
        }
    }
}

.gift-certificate-purchase {
    margin-top: 26px;

    @include breakpoint(medium) {
        margin-top: 0;
    }

    .char-count {
        font-size: 12px;
        line-height: 16px;
    }

    .order-description p {
        margin-top: 24px;
        margin-bottom: 37px;
        font-size: 12px;
        color: $c-gray;
        letter-spacing: 1px;
        line-height: 16px;
        font-weight: $f-weight-bold;

        @include breakpoint(large) {
            margin-top: 34px;
            margin-bottom: 59px;
        }
    }

    .no-margin {
        margin: 0;
    }

    .dialog-required {
        display: none;
    }

    h1 {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    form {
        box-sizing: border-box;
        width: 100%;

        .cert-amount {
            clear: none;
            margin: 0;
            width: 29.1%;
            display: inline-block;
            vertical-align: top;

            .field-wrapper {
                margin-bottom: 0;
            }

            @include breakpoint(large) {
                width: 43%;
            }

            @include breakpoint(xlarge) {
                width: 34%;
            }
        }

        button {
            width: max-content;
        }

        .form-row-button {
            margin-left: 20px;
            clear: none;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
            margin-top: 25px;
        }
    }

    .cert-amount {

        .form-caption {
            margin: 1.66em 0 0;
            width: 100%;
        }

        &.error {
            padding-bottom: 2.92em;
        }
    }

    span.error {
        margin-left: 2%;
    }
}

.check-balance {
    margin-top: 16px;
}

.gift-cert-balance {
    width: 47%;
    display: inline-block;
    max-width: 140px;
    vertical-align: top;

    &.error {
        margin-left: 0;
    }

    @include breakpoint(medium) {
        width: 49%;
        max-width: initial;
    }

    .field-wrapper {
        margin-bottom: 0;
    }

    label {
        margin-left: 0;
    }

    input {
        margin-top: 0;
        width: 93%;
    }

    .form-caption {
        margin-left: 0;
    }
}

.gift-cert-balance-submit {
    width: 51%;
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;

    @include breakpoint(medium) {
        width: 46%;
    }

    button {
        padding: 6px 15%;

        @include breakpoint(medium) {
            padding: 6px 32px;
        }
    }
}

.gift-cert-balance .label {
    display: none;
}
