.shows-list {
    &--past {
        list-style: none;
        margin-top: 0;
        padding-left: 0;

        .show {
            border-bottom: 1px solid $c-stroke-gray;
            padding: 6px 0 15px;
            cursor: pointer;
            
            &:first-child {
                padding-top: 6px;
            }
        
            @include breakpoint(medium) {
                padding-bottom: 29px;
                padding-top: 7px;
                display: flex;
                    align-items: flex-end;
                    gap: 50px;
                    justify-content: space-between;
            }

            p {
                margin-bottom: 0;
            }

            .date-venue-location {
                @include breakpoint(medium) {
                    flex-basis: MIN(50%, 538px); // All uppercase so SCSS doesn't try and compile
                }
            }
        
            .date-numbers {
                font-size: $f-size-xs;
                font-weight: $f-weight-bold;
                letter-spacing: 1px;
                line-height: 1.43;
                text-transform: uppercase;
            }
        
            &.hasRange {
                .date-numbers {
                    .delim {
                        hr {
                            width: 6px;
                            color: black;
                            border: 1px solid black;
                        }
                    }
                    @include breakpoint(medium) {
                        min-width: 95px;
                    }
        
                    @include breakpoint(xsmall) {
                        min-width: 80px;
                    }
                }
            }
        
            .venue {
                &-city {
                    @include h3();

                    text-transform: uppercase;
            
                    @include breakpoint(medium) {
                        @include h4();
                    }

                    p {
                        margin-top: 6px;
                    }
                }

                &-name {
                    font-size: $f-size-xs;
                    line-height: 1.43;
                    margin-top: 4px;

                    @include bp(max-width, $bp-medium) {
                        margin-bottom: 16px;
                    }

                    @include breakpoint(medium) {
                        font-size: $f-size;
                        line-height: 1.5;
                        margin-top: 14px;
                    }

                    &-wrap {
                        flex-grow: 1;
                        flex-basis: MAX(35%, 200px); // All uppercase so SCSS doesn't try and compile
                    }
                }
            }
        
            .ctas {
                @include breakpoint(medium) {
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    flex-direction: column;
                }
        
                a, button {
                    margin: 0 7px 10px 0;
                    line-height: 13px;
                    display: inline-flex;
                    align-items: center;
                    white-space: nowrap;
        
                    &:last-child {
                        margin-right: 0;
                    }
        
                    @include breakpoint(medium) {
                        margin: 0 0 10px 10px;
                    }
                }
        
                > a {
                    margin-right: 0;
                }
        
                .second-row-ctas {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    @include breakpoint(medium) {
                        justify-content: flex-end;
                    }
        
                    display: flex;
        
                    &:last-child {
                        margin-right: 0;
                    }
                }
        
                > a + .second-row-ctas {
                    @include breakpoint(medium) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
