.content-tile {
    @extend %clearfix;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100%;
    }

    .thumb-link {
        display: block;
    }

    .content-title {
        flex-grow: 1;
        margin: 12px 8px 0 8px;
        position: relative;

        @include breakpoint(large) {
            margin: 20px 16px 0 16px;
        }

        @include has(date) {
            @include font-source($f-body, 12px, null, 16px, $f-weight-regular);

            @include breakpoint(medium) {
                margin-bottom: 5px;
            }

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        @include has(category) {
            @include font-source($f-body, 12px, $c-gray, 16px, $f-weight-bold);
            letter-spacing: 1px;
            @include position(absolute, 0 0 null null);
        }

        @include has(name) {
            margin-top: -4px;

            @include breakpoint(medium) {
                margin-top: -4px;
                line-height: 24px;
            }

            @include breakpoint(large) {
                @include h4;
                margin-top: 3px;
            }
        }
    }

    .content-body {
        margin-left: 8px;
        margin-right: 8px;

        @include breakpoint(large) {
            margin-left: 16px;
            margin-right: 16px;
        }

        @include has(excerpt) {
            display: none;
            margin: 0;
            @include multiLineEllipsis($lineHeight: 24px, $lineCount: 3, $bgColor: $white);

            @include breakpoint(large) {
                display: block;
            }

            * {
                margin: 0;
                padding: 0;
            }
        }

        .button {
            display: none;

            @include breakpoint(medium) {
                display: inline-block;
            }
        }
    }
}
