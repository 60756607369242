.pt_product-search-result {
    .top-banner {
        background-color: transparent;
        border-bottom: none;
    }

    .header {
        position: absolute;
            left: 0;
            right: 0;
            top: 0;
    }

    .primary-content {
        h1.content-header {
            padding-left: 15px;
        }
    }

    .breadcrumb {
        margin-bottom: 6px;
        margin-top: 8px;

        @include breakpoint(large) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .black-bg {
        .slot-grid-header {
            background: $black;
        }
    }
}

.pt_product-search-noresult {
    .no-hits-search {
        background-color: initial;
        border: 0;
        margin-top: 30px;

        button {
            vertical-align: top;
            float: left;
            margin-left: 15px;
        }
    }

    #primary {
        
    }

    .no-hits-banner {
        img {
            width: 100%;
        }
    }
    .nohits-einstein-recomm {
        h2 {
            color: $black;
            display: block;
            font-size: 14px;
            letter-spacing: 0.6px;
            line-height: 24px;
            position: relative;
            text-align: left;
            text-transform: uppercase;
        }

        .search-result-items {
            margin-top:1em;
        }

        .row {
            margin-bottom: 0;
        }

        .product-tile {
            padding-top: 0;
        }
    }

    .nohits-einstein-recomm {
        max-width: 1236px;
        overflow: visible;
        @include breakpoint(medium) {
            overflow: hidden;
        }
        .slick-track {
            margin-left: 0;
            margin-right: 0;
        }

        .slick-prev, .slick-next {
            vertical-align: middle;
            top: 42%;
        }
    }
}

.search {
    &-header {
        &.optionsContainer { // Stronger selector needed due to base row styles
            @include breakpoint(large) {
                margin-bottom: 12px;
            }
        } 
    }
    
    &-cat-heading {
        max-width: 100vw;
        overflow: hidden;
        position: absolute;
            left: calc(((100% - 100vw) / 2) - 8px);
            top: -24px;

        @include breakpoint(large) {
            left: calc(((100% - 100vw) / 2) - 30px);
            top: 0;
            transform: translateY(-65%);
        }
    }

    &-filters-pagination {
        align-self: flex-end;
        display: flex;
            align-items: flex-end;
        z-index: 1;

        @include breakpoint(large) {
            align-self: flex-start;
            padding-right: 8px;
            // Shift content so bottom edge is flush with sort menu
            transform: translateY(calc(#{$grid-large-padding + $input-text-height + 3px} - 100%));
        }

        @include breakpoint(xlarge) {
            transform: translateY(calc(#{$grid-xlarge-padding + $input-text-height + 3px} - 100%));
        }
    }

    &-pagination-wrap {
        @include breakpoint(large) {
            padding-top: 36px;
        }
    }
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
    color: $sangria;
    font-style: italic;
}

.no-hits-footer {
    margin: 20px 0;
}

.search-result {
    &-container {
        position: relative;
    }

    &-bookmarks {
        margin: 15px;
    
        .first {
            border-right: 1px solid $nobel;
            padding-right: 5px;
        }
    }
}

.search-results {
    @include breakpoint(large) {
        padding-top: 10px;
    }
}

.search-result-options {
    overflow: hidden;
    @include breakpoint(medium) {
        display: block;
    }
    @include breakpoint(large) {
        display: block;
        margin-bottom: 0;
    }

    &--bottom {
        margin-bottom: 14px;
        
        @include breakpoint(large) {
            margin-bottom: 0;
        }   
        
        .optionsContainer {
            @include breakpoint(large) {
                margin-bottom: 17px;
            }
        }
    }

    .breadCrumb-wrapper {
        padding: 0;
        position: relative;
            z-index: 1;
        margin-bottom: 0;

        .breadcrumb {
            margin: 0;
        }

        .breadcrumb-element {
            display: none;
        }
    }
    
    .breadRefinements {
        display: none;
        padding-left: 0;
        z-index: 1;

        @include breakpoint(large) {
            display: block;
            padding-top: 22px + $grid-large-padding;
        }

        @include breakpoint(xlarge) {
            padding-top: 22px + $grid-xlarge-padding;
        }

        .results-hits {
            font-size: $f-size-xs;
            text-align: center;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }
    }
    
    .sort-by {
        display: block;
        width: 100%;

        @include breakpoint(medium) {
            display: flex;
            margin-right: 0;
            padding-bottom: 0;
            width: 320px;
        }

        @include breakpoint(large) {
            justify-content: flex-end;
        }

        @include breakpoint(xlarge) {
            width: 360px;
        }

        button {
            display: none;
        }

        form {
            @include breakpoint(large) {
                width: 100%;
            }

            .js-sort-by {
                display: block;

                @include breakpoint(large) {
                    display: none;
                }
            }

            select {
                font-size: 15px;
                font-weight: $f-weight-bold;
                letter-spacing: 3px;
                line-height: 1;
                padding-left: 19px;
                padding-right: 35px;
                text-transform: uppercase;
            }
        }
    }

    .secondaySortby {
        @include breakpoint(large) {
            display: none;
        }
    }

    .items-per-page {
        float: left;
        margin-right: 0.5em;
        clear: left;

        button {
            display: none;
        }
        @include breakpoint(medium) {
            clear: none;
        }
    }

    form {
        margin-bottom: 0;
    }

    label {
        display: none;
    }

    .optionsContainer {
        @include bp(max-width, $bp-large) {
            justify-content: space-between;
        }

        .has-hidden-refinements & {
            @include breakpoint(large) {
                margin-bottom: 20px;
            } 

            .search-filters-pagination {
                @include breakpoint(large) {
                    align-self: flex-start;
                    // Shift content so bottom edge is flush with sort menu
                    transform: translateY(calc(#{$grid-large-padding + $input-text-height} - 100%));
                }
        
                @include breakpoint(xlarge) {
                    transform: translateY(calc(#{$grid-xlarge-padding + $input-text-height} - 100%));
                }
            }
        }

        .pagination {
            text-align: center;
            margin: 0;
            vertical-align: middle;
            display: inline-block;

            ul {
                @media only screen and (max-width: 400px) { // Allow for design to hold up on small screens when the "previous" button is present
                    flex-wrap: wrap;
                }
            }

            li {
                &:last-child {
                    margin-right: 0;
                }

                &:first-child {
                    margin-left: 0;
                }
            
                &.view-all-btn {
                    border: none;
                    font-size: 13px;
                    height: auto;
                    letter-spacing: 2.5px;
                    line-height: 1.08;
                    text-transform: uppercase;
                    display: inline-block;
                    margin-right: 14px;
                    padding-top: 2px;
                    width: auto;

                    @media only screen and (max-width: 400px) { // Allow for design to hold up on small screens when the "previous" button is present
                        width: min-content;
                    }
                }
            }
        }

        .search-filters-pagination,
        .secondaySortby {
            @include bp(max-width, $bp-large) {
                display: none;
            }
        }

        .results-hits {
            padding-top: 5px;
            font-size: 14px;
            line-height: 1;
            text-align: center;

            @include breakpoint(large) {
                line-height: 24px;
                text-align: left;
            }

            &--desktop {
                display: none;
                
                @include breakpoint(large) {
                    display: inline-block;
                }
            }

            &-wrap {
                &--mobile {
                    @include breakpoint(large) {
                        display: none;
                    }
                }
            }

            .has-hidden-refinements & {
                @include breakpoint(large) {
                    margin-left: 20px;
                }

                .paginationCount {
                    @include breakpoint(large) {
                        line-height: 0.8;
                    }
                }
            }

            .toggle-grid {
                display: none;
            }

            .paginationCount {
                margin-top: 1px;
                display: block;
                line-height: 1;
                font-size: 14px;

                @include breakpoint(large) {
                    margin-top: 0;
                }
            }
        }
    }

    .filter-by {
        padding: 0 8px;
        font-weight: 700;
        font-size: 15px;
        border-radius: 0;
        box-sizing: border-box;
        width: 100%;
        letter-spacing: 3px;
        line-height: 1;
        padding-bottom: 13px;
        padding-top: 13px;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;

        @include breakpoint(large) {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }
}

.search-result-items {
    margin: 0;
    padding: 0;
    @include grid-row;

    margin-left: -3px;
    margin-right: -3px;
    margin-top: -26px;

    @include breakpoint(medium) {
        margin-left: -4px;
        margin-right: -4px;
        margin-top: -36px;
    }

    /* PLP banner styles */
    .product-banner-image {
        position: relative;

        .product-banner-container {
            position: absolute;
                top: 50%;
                left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            max-width: calc(100% - 20px);

            .product-banner-title {
                color: $white;
                line-height: 1.1;
                letter-spacing: 0.5px;

                @include bp(max-width, $bp-large) {
                    font-size: 20px;
                }
                &.text-overflow {
                    font-size: 30px;
                    @include bp(max-width, $bp-large) {
                        font-size: 20px;
                    }
                }
            }

            .product-banner-link {
                border-color: $white;
                background: none;
                color: $white;
                margin-top: 10px;

                &:hover {
                    background: $f-body-color;
                    border-color: $f-body-color;
                }
            }
        }
    }

    li {
        list-style: none outside none;
    }

    .new-row {
        clear: both;
    }

    .grid-tile {
        @include grid-col;
        flex: none;

        padding-left: 0;
        padding-right: 0;
        padding-top: 26px;

        @include breakpoint(medium) {
            // Including horizontal again here ensures it overrides base col
            padding-left: 0;
            padding-right: 0;
            padding-top: 36px;
        }
    }

    .invisible {
        display: none;
    }
}

.search-result-content {
    clear: both;

    & ~ .search-result-options {
        padding-bottom: 0;
        padding-top: 23px;

        @include breakpoint(medium) {
            padding-top: 56px;
        }
    }

    .product-price {
        .price-standard {
            color: #999;
            font-weight: 100;
            text-decoration: line-through;
        }
    }
}

#results-content {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    padding-top: 30px;
}

.folder-content-list {
    align-items: stretch;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -#{$f-size};

    li {
        background-color: $white-smoke;
        border: 1px solid $very-light-gray;
        flex-basis: 100%;
        flex-grow: 1;
        list-style-type: none;
        padding: $f-size;
        margin: $f-size;
        @include breakpoint(medium) {
            flex-basis: 40%;
        }
    }

    p {
        line-height: 1.1rem;
    }

    .content-title {
        display: block;
        font-size: 1.2em;
    }

    .readmore {
        font-style: italic;
        padding-left: 0.3rem;
    }
}

.no-results {
    padding: 0 20px;
    text-align: center;
}

.no-search-results {
    padding-top: 0;
}

.toggle-grid {
    display: block;
    float: left;
    margin-right: 0.5em;

    i {
        cursor: pointer;
        margin-right: 0.3em;
        color: $toggle-grid-column-color;
    }

    [data-option="column"] {
        color: $toggle-grid-color;
    }

    &.wide {
        [data-option="wide"] {
            color: $toggle-grid-color;
        }

        [data-option="column"] {
            color: $toggle-grid-column-color;
        }
    }
}

.wide-tiles {
    .search-result-items .grid-tile {
        width: 91%;
    }

    .product-tile {
        .product-swatches {
            bottom: auto;
            left: auto;
            position: relative;
            right: auto;
            margin-top: 5px;

            li {
                float: left;
            }
        }
    }
}

.activeOverlay {
    visibility: visible;
    opacity: 0.7;
}

.slot-grid-header {
    position: relative;
        z-index: 2;
}

.breadcrumb-result-text {
    color: $white;
    line-height: 1;
    position: absolute;
        left: 50%;
        top: 100px;
        z-index: 2;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;

    @include breakpoint(large) {
        top: 38%;
    }

    &-label {
        @include h5;

        color: inherit;
        font-family: $f-subheader;
        font-weight: $f-weight-regular;
        letter-spacing: 1px;
    }
}

.primarySortby {
    display: none;

    select {
        font-size: 15px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        line-height: 1;
        padding-left: 19px;
        padding-right: 35px;
        text-transform: uppercase;
    }

    @include breakpoint(large) {
        display: block;
        margin-bottom: 7px;
    }
}

.search-nav {
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.29;
    margin-bottom: 3em;
    padding-left: 17px;

    &.search-category-producthits {
        margin: 0 auto 3em;
        padding-left: 0;
    }

    .nav {
        display: flex;
            flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        .nav-item {
            flex: 1 1 auto;
            text-align: center;
            text-transform: uppercase;

            .nav-link {
                color: $c-medium-gray;

                &.active {
                    color: $black;
                }
                &.product-search {
                    padding-right: 30px;
                }
            }
        }
    }
}

.grid--container .hidden {
    display: none;
}

.grid + .breadCrumb-wrapper {
    position: relative;
    z-index: 1;
}

.content-result {
    .search-header {
        .contentSortby {
            width: 100%;

            @include breakpoint(medium) {
                float: right;
                width: 360px;
            }
        }

        .paging-result {
            padding-top: 15px;
            text-align: center;

            @include breakpoint(medium) {
                padding-top: 30px;
                text-align: left;
            }
        }
    }
    .content-lists {
        margin: 40px 16px 80px 16px;
        width: 100%;

        @include breakpoint(medium) {
            margin-top: 28px;
        }
        .show {
            border-top: 1px solid #dcdcdc;
            cursor: pointer;
            display: block;

            .name {
                font-size: 14px;
                font-stretch: normal;
                font-style: normal;
                font-weight: bold;
                line-height: 1.29;
                letter-spacing: 0.5px;
            }

            &:last-child {
                border-bottom: 1px solid #dcdcdc;
            }

            .read-more {
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                font-weight: $f-weight-medium;
                letter-spacing: normal;
                line-height: 1.67;
                margin-top: -10px;
                margin-bottom: 10px;
                text-decoration: underline;

                @include breakpoint(small) {
                    margin-top: 15px;
                }
            }

            @include breakpoint(small) {
                display: flex;
                    justify-content: space-between;
            }
        }
    }

    .search-result-options--bottom {
        @include breakpoint(large) {
            padding-top: 10px;
        }
    }

    .pagination {
        li {
            color: $black;
            border: 1px solid $black;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
            height: 36px;
            margin: 0 2px 4px;
            letter-spacing: 1px;
            line-height: 1.25;
            padding: 0;
            text-align: center;
            box-sizing: border-box;
            vertical-align: top;
            width: 34px;
            &.view-all-btn {
                border: none;
                font-size: 13px;
                height: auto;
                letter-spacing: 2.5px;
                line-height: 1.08;
                text-transform: uppercase;
                display: inline-block;
                margin-right: 14px;
                padding-top: 2px;
                width: auto;
            }
            &.current-page {
                padding: 9px 12px 8px;
                color: $white;
                width: 36px;
                padding: 0;
                button {
                    opacity: 1;
                }
            }
            button {
                background: none;
                letter-spacing: 0;
            }
        }
    }
}

.mobile-filter-sort-container {
    display: block;
    position: sticky;
	position: -webkit-sticky;
		top: 0;
    width: 100%;
	z-index: 1;

    @include breakpoint(large) {
        display: none;
    }

    &.hidden {
        display: none;
    }

    &.js-threshold-reached {
        background-color: $white;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    button {
        &.filter-by {
            line-height: normal;
            height: 100%;
        }
    }

    label[for="grid-sort-header"],
    label.lbl-sortby {
        display: none;
    }

    .search-filters-pagination {
        align-items: stretch;
    }

    .select-wrapper {
        @include bp(max-width, $bp-large) {
            display: flex;
            align-items: center;
        }

        .js-sort-by {
            background-color: $white;
            display: inline-block;
            font-size: 15px;
            font-weight: $f-weight-bold;
            letter-spacing: 3px;
            line-height: 1;
            margin-left: 20px;
            position: absolute;
                z-index: 1;
            text-transform: uppercase;
            width: auto;

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    select {
        @include bp(max-width, $bp-large) {
            text-indent: -9999px;
        }
    }

    .search-filters-pagination {
        > .col {
            padding-top: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .sort-Go-Button {
        display: none;
    }
}