@keyframes fadeInAnimation {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes slideDownLine {
    0% {block-size: 0; height: 0;}
    100% {block-size: 100%; height: 100%;}
}

@keyframes slideDownText {
    0% {max-height: 0;}
    100% {max-height: 1500px; transition: max-height 2s ease-in;}
}

@keyframes slideDown {
    0% {transform: translateY(0);}
    100% {transform: translateY(100px);opacity:1;}
}

@keyframes scaleImage {
    0% {width: 0;}
    100% {width: 100%;}
}

@keyframes scaleImageLeft {
    0% {width: 0; position: absolute;}
    25% {width: 100%;}
    100% {width: 100%;}
}

@keyframes slideDownImg {
    0% {height: 0;}
    100% {height: 100%;}
}

@keyframes slideUp {
    0% {transform: translateY(500px);}
    100% {transform: translateY(0);}
}

@keyframes textRightToLeft {
    0% {
        opacity: 0;
        block-size: 0;
        transform: translateX(20%); 		
    }
    100% {
        opacity: 1;
        block-size: 100%;
        transform: translateX(0%); 
    }
}

#wrapper {
    background-color: $white;
    border: 0;
    margin: auto;
    position: relative;
    width: 100%;

    &.pt_product-details {
        .primary-content {
            padding-top: 1rem;
        }
    }

    &.menu-active {
        #navigation {
            opacity: 1;
            transform: translateX(0);
        }

        .mega-menu-divider,
        .nav-external-links {
            animation-duration: $menu-reveal-transition-duration;
            animation-name: menuCategoryRevealLToR;
        }

        .menu-category {
            animation-duration: $menu-reveal-transition-duration;
            animation-name: menuCategoryRevealTToB;
        }

        // Use animations instead of transitions, so that the clip path is removed after it's done playing
        @keyframes menuCategoryRevealLToR {
            from {
                clip-path: polygon(0 0, 0 0, 0 100%, 0 100%); // Pre, hidden state, to be revealed
            }

            10% { // Create delay without starting at visible state
                clip-path: polygon(0 0, 0 0, 0 100%, 0 100%); // Pre, hidden state, to be revealed
            }

            to {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }

        @keyframes menuCategoryRevealTToB {
            from {
                clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            }

            10% { // Create delay without starting at visible state
                clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            }

            to {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }

        .nav-overlay {
            opacity: .7;
            visibility: visible;
        }
    }

    &.past-filter-active {
        .nav-overlay {
            opacity: .7;
            visibility: visible;
        }
    }

    &:not(.menu-active) {
        #navigation > * {
            display: none; // Prevent tabbing while not interfering with animation
        }
    }

    &.minicart-active {
        .nav-overlay {
            opacity: .7;
            visibility: visible;
            z-index: 99;
        }

        &.menu-active {
            .mini-cart-content {
                display: none;
            }
        }
    }

    &.search-active {
        #search-wrapper {
            transform: translateX(0);
            visibility: visible;
        }

        .nav-overlay {
            opacity: .7;
            visibility: visible;
        }
    }

    &.user-account-active {
        #user-account-wrapper {
            transform: translateX(0);
            transition: transform $default-transition-duration ease-in-out;
        }

        .nav-overlay {
            opacity: .7;
            visibility: visible;
        }
    }

    &.overlay-header {
        position: relative;
            z-index: 0;
    }

    &.accountflyoutnav-active {
        .account-nav-flyout-menu {
            transform: translateX(0);
        }
        
        .nav-overlay {
            opacity: .7;
            visibility: visible;
            z-index: 103;
        }
    }
}

.nav-overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    z-index: 102;
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }
}

#secondary {
    border: 0;
    float: none;

    .pt_content-search-result & {
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }
    }
}

.secondary-content {
    clear: both;
}

.pt_content {
    &#wrapper {
        background: $c-mostly-white;

        &.page-bg {
            background-image: url('../images/bg-textured-mobile-white.png');
            background-size: 100% auto;
            
            @include breakpoint(medium) {
                background-image: url('../images/bg-textured-white.png');
                background-size: 75% auto;
            }

            &:not(.pt_help) {
                .accent:not(.accent-animate) {
                    display: none;
                    position: absolute;
                        left: calc(50% - 10px);
                        top: 10px;
                        z-index: -1;
                    max-width: 100vw;
                    overflow: hidden;
                    transform: translateX(-50%);
            
                    @include breakpoint(large) {
                        display: block;
                    }
                }
            }
        }

        #main {
            position: relative;
        }

        .accent {
            &.accent-nowrap {
                white-space: nowrap;
            }
        }
    }
    

    &.pt_band-timeline,
    &.pt_museum {
        .c-banner-content {
            z-index: 1;
        }

        .c-banner {
            & + .accent {
                @include breakpoint(medium) {
                    margin-bottom: -270px;
                }
            }
        }
    }

    .upcoming-event-body {
        margin-bottom: 40px;

        @include breakpoint(large) {
            padding-top: 20px;
        }

        .upcoming-event-body__header {
            margin-top: 40px;
            text-align: center;
            @include breakpoint(large) {
                text-align: left;
            }

            span {
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .upcoming-event-body__header__title {
                border-bottom: 1px solid $brand-primary;
                font-size: 34px;
                line-height: 1.12;
                padding-bottom: 30px;
                margin-bottom: 30px;

                @include breakpoint(large) {
                    padding-bottom: 36px;
                    margin-bottom: 50px;
                }
            }
        }

        .upcoming-event-body__tour__item {
            @include bp(max-width, $bp-large) {
                border-bottom: 1px solid $c-medium-gray;
                margin-bottom: 24px;

                @include breakpoint(large) {
                    margin-bottom: 60px;
                }
            }
            
            h3 {
                margin-bottom: 10px;

                @include breakpoint(large) {
                    font-size: 20px;
                }
            }

            h4 {
                font-size: 14px;
                margin-bottom: 4px;

                &.upcoming-event-body__tour__item__fun {
                    color: $c-red;
                }
            }

            p {
                font-size: 14px;
                margin: 0 0 20px;
            }
        }

        .upcoming-event-body__tour {
            .col {
                &:last-child {
                    .upcoming-event-body__tour__item {
                        border: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .breadcrumb {
        margin-top: 61px;
        margin-bottom: 10px;

        .breadcrumb-element:nth-child(n+2) {
            color: $c-gray;
            font-weight: $f-weight-bold;
        }

        @include breakpoint(medium) {
            margin-bottom: 0;
            margin-top: 75px;
        }
    }

    .content-hero-wrapper + #main .breadcrumb {
        margin-top: 20px;

        @include breakpoint(medium) {
            margin-top: 20px;
        }
    }

    .contact-us-page {
        .body {
            margin-bottom: 19px;
        }
    }

    .credits {
        margin-bottom: 30px;

        h1 {
            text-align: left;

            @include breakpoint(large) {
                text-align: center;
            }
        }

        .credit {
            border-bottom: 1px solid $c-light-gray;
            padding-bottom: 9px;
            margin-top: 30px;

            @include breakpoint(large) {
                padding-bottom: 33px;
            }

            h3 {
                margin-bottom: 14px;
                text-align: left;

                @include breakpoint(large) {
                    text-align: center;
                    margin-bottom: 8px;
                }
            }

            .names {
                display: flex;
                flex-direction: column;

                @include breakpoint(large) {
                    flex-direction: row;
                    align-items: flex-end;
                    margin: 0 auto;
                    justify-content: center;
                }

                p {
                    font-size: 14px;
                    line-height: 1.43;
                    margin: 0;
                }

                .name {
                    margin-bottom: 16px;

                    @include breakpoint(large) {
                        margin: 0 20px;
                        max-width: 166px;
                        text-align: center;
                        width: 37%;
                    }
                }
            }

            .special-thanks {
                margin-bottom: 16px;

                p {
                    margin: 0;
                }

                @include breakpoint(large) {
                    max-width: 60%;
                    text-align: center;
                    margin: 40px auto 0;
                }
            }
        }
    }

    &:not(.pt_ampcontent) .page-title {
        text-align: left;

        @include breakpoint(large) {
            text-align: center;
        }

        &.c-banner-page-title--logo {
            text-align: center;
            img {
                opacity: 0.5;
                max-height: 80%;
                max-width: 324px;

                @include breakpoint(large) {
                    max-width: 450px;
                }
            }
        }
    }

    .c-banner {
        .c-banner-content {
            &--overlay {
                left: 0;
                top: 0;
                transform: initial;
                height: 100%;
                width: 100%;

                .page-title-overlay {
                    background: $brand-primary;
                    height: 100%;
                }
            }

            &.c-banner-content--move-top {
                top: MAX(60%, 160px);
            } 
        }

        &.animate-banner {
            .c-banner-content {
                &--overlay {
                    transition: width 1s ease-in-out;
                    width: 0;
                }
    
                &--move-top {
                    transition: top 1s linear;
                    top: MAX(43%, 160px);
                } 
            }
        }
    }

    @include breakpoint(large) {
        .history-bg1 {
            display: block;
            position: absolute;
                z-index: 0;
            left: 100vw;
            background-image: url("../images/history-bg1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 620px;
            height: 1165px;
            transform: translateX(-100%);
        }
        .history-bg2 {
            display: block;
            position: absolute;
                z-index: 0;
            top: 5000px;
            left: 100vw;
            background-image: url("../images/history-bg2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 894px;
            height: 470px;
            transform: translateX(-100%);
        }
    }

    .history-bg-repeated {
        @include breakpoint(large) {
            background-image:  url("../images/history-repeated.png");
            background-repeat: repeat-y;
            background-size: 100%;
            background-position: left 1250px;
        }
    }

    .history-item {
        margin-top: 20px;
        @include bp(max-width, $bp-large) {
            display: flex;
        }

        @include breakpoint(large) {
            padding-bottom: 60px;
            position: relative;

            .order-lg-1 {
                order: 1;
            }

            .order-lg-2 {
                order: 2;
            }

            .order-lg-3 {
                order: 3;
            }

            .order-lg-4 {
                order: 4;
            }

            .order-lg-5 {
                order: 5;
            }

            .order-lg-6 {
                order: 6;
            }

            .move-img-60-left {
                position: relative;
                    left: -60%;
            }
        }

        .history-item_year {
            display: inline-block;
            margin-right: 8px;
            overflow: hidden;
            position: relative;
            width: 50px;
            @include breakpoint(large) {
                position: absolute;
                    top: 0;
                    left: 50%;
                height: 100%;
                text-align: center;
                transform: translateX(-50%);
                width: initial;
                z-index: 0;
            }

            .history-item_year_title {
                line-height: 1.13;
                letter-spacing: 0.5px;
                @include bp(max-width, $bp-large) {
                    background: $white;
                    transform: rotate(-90deg);
                    position: relative;
                        top: 45px;
                        left: -10px;
                    white-space: nowrap;
                    padding-left: 10px;
                    z-index: 1;

                    &--double-top {
                        left: -63px;
                        top: 55px;
                        width: 152px;
                    }
                }
            }

            .history-item_year_line {
                display: inline-flex;
                border-right: 1px solid $brand-primary;
                height: 100%;
                position: absolute;
                    left: 14px;
                z-index: 0;

                @include breakpoint(large) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .history-item_data {
            display: inline-block;
            width: calc(100% - 58px);

            @include breakpoint(large) {
                width: 100%;
                padding-top: 105px;
                position: relative;
                    z-index: 1;
            }

            .z-index-1 {
                z-index: 1;
            }

            .z-index-2 {
                z-index: 2;
            }

            & > .row {
                margin: 0;

                @include breakpoint(large) {
                    justify-content: space-between;
                }
            }

            .history-item_data_title {
                margin-bottom: 30px;
                @include breakpoint(large) {
                    margin-bottom: 50px;
                }
                .h1 {
                    @include bp(max-width, $bp-large) {
                        font-size: 20px;
                        line-height: 1.1;
                        letter-spacing: 0.5px;
                    }

                    @include breakpoint(large) {
                        &.small-size {
                            font-size: 28px;
                        }
                    }
                }
            }

            .history-item_data_text {
                margin-bottom: 30px;
                @include breakpoint(large) {
                    margin-bottom: 50px;
                }

                p {
                    font-size: 14px;
                    margin: 0 0 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .history-item_data_img {
    
                @include bp(max-width, $bp-medium) {
                    img {
                        width: calc(100% + 27px);
                    }

                    &--small {
                        img {
                            width: initial;
                        }
                    }

                    &--full-image {
                        img {
                            width: 99%;
                        }
                    }
                }

                @include breakpoint(large) {
                    &.mar-bottom-10 {
                        margin-bottom: 10px;
                        img {
                            margin-bottom: 0;
                        }
                    }

                    &.img-desktop-center {
                        text-align: center;
                    }

                    &.img-desktop-right {
                        text-align: right;
                    }

                    &.move-img-50-right {
                        margin-bottom: -100px;
                        position: relative;
                            left: 50%;
                            top: -120px;
                        transform: translateX(-10%);
                    }

                    &.margin-left-33 {
                        margin-left: -33%;
                    }

                    &.margin-top-150 {
                        margin-top: 150px;
                    }

                    &.margin-top-130 {
                        margin-top: 130px;
                    }

                    &.margin-top-350 {
                        margin-top: 350px;
                    }

                    img {
                        width: initial;
                        display: inline-block;
                        margin-bottom: 30px;
                    }
                }
            }
        }

        @include breakpoint(large) {
            .history-item_year {
                .history-item_year_title {
                    opacity: 0;
                }
                .history-item_year_line {
                    block-size: 0;
                }
            }
            .history-item_data {
                .history-item_data_img {
                    picture {
                        display: inline-block;
                        overflow: hidden;
                        width: initial;

                        &.animate-slide-right {
                            width: 0;
                        }
                        
                        &.animate-slide-left {
                            width: 0;
                            right: 0;
                        }

                        &.animate-slide-down {
                            height: 0;
                        }
                    }

                    img {
                        &.animate-slide-up {
                            transform: translateY(500px);
                        }
                    }
                }
                .history-item_data_text {
                    max-height: 0;
                    overflow: hidden;
                }
                .history-item_data_title {
                    .h1 {
                        position: relative;
                        opacity: 0;
                    }
                }
            }
            &.animated {
                .history-item_year {
                    .history-item_year_title {
                        animation: fadeInAnimation ease 3s;
                        animation-fill-mode: forwards;
                        animation-delay: 1s;
                        animation-iteration-count: 1;
                    }

                    .history-item_year_line {
                        animation-name: slideDownLine;
                        animation-fill-mode: forwards;
                        animation-duration: 5s;
                        animation-delay: 1s;
                        animation-iteration-count: 1;
                    }
                }

                .history-item_data {
                    .history-item_data_img {
                        position: relative;

                        &.d-flex {
                            display:flex;
                        }

                        .overflow-v {
                            overflow: visible;
                        }

                        picture {
                            
                            &.animate-slide-right {
                                animation: scaleImage;
                                animation-fill-mode: forwards;
                                animation-duration: 1s;
                                animation-delay: 3s;
                                transform-origin: center left;
                            }

                            &.animate-slide-left {
                                animation: scaleImageLeft;
                                animation-fill-mode: forwards;
                                animation-duration: 3s;
                                animation-delay: 3s;
                                transform-origin: center right;
                            }
                        
                            &.animate-slide-down {
                                animation: slideDownImg 3s ease-in;
                                animation-fill-mode: forwards;
                                animation-delay: 1s;
                            }

                            img {

                                &.animate-slide-up {
                                    animation: slideUp;
                                    animation-fill-mode: forwards;
                                    animation-duration: 1s;
                                    animation-delay: 2s;
                                }
                            }

                            &.delay-1 {
                                animation-delay: 1s;
                            }
                            &.delay-2 {
                                animation-delay: 2s;
                            }
                            &.delay-3 {
                                animation-delay: 3s;
                            }
                            &.delay-4 {
                                animation-delay: 4s;
                            }
                            &.delay-5 {
                                animation-delay: 5s;
                            }

                            img {
                                &.delay-1 {
                                    animation-delay: 1s;
                                }
                                &.delay-2 {
                                    animation-delay: 2s;
                                }
                                &.delay-3 {
                                    animation-delay: 3s;
                                }
                                &.delay-4 {
                                    animation-delay: 4s;
                                }
                                &.delay-5 {
                                    animation-delay: 5s;
                                }
                            }
                        }
                    }

                    .history-item_data_text {
                        animation: slideDownText 2s ease-in;
                        animation-fill-mode: forwards;
                        animation-delay: 1s;

                        &.delay-1 {
                            animation-delay: 1s;
                        }
                        &.delay-2 {
                            animation-delay: 2s;
                        }
                        &.delay-3 {
                            animation-delay: 3s;
                        }
                        &.delay-4 {
                            animation-delay: 4s;
                        }
                        &.delay-5 {
                            animation-delay: 5s;
                        }
                    }

                    .history-item_data_title {
                        .h1 {
                            animation: textRightToLeft 1s linear;
                            animation-delay: 1s;
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }
        }
    }

    .history-logo {
        display: block;
        margin: 10px 0 40px;
        width: 98px;

        @include breakpoint(large) {
            text-align: center;
            width: 100%;
        }

        .svg-m-logo-dims {
            width: 98px;
            height: 116px;
        }
    }
}

.page-bg {
    background-image: url('../images/bg-textured-mobile-white.png');
    background-size: 100% auto;
    
    @include breakpoint(medium) {
        background-image: url('../images/bg-textured-white.png');
        background-size: 75% auto;
    }
}
