#stripe-saved-cards {
    width: inherit;

    input[type="radio"] {
        margin: 0;
    }
}

.stripe .form-row.stripe__card-element {
    width: 100%;
}

.payment-method-expanded .form-row.stripe__saved-cards {
    width: 100%;

    #stripe-saved-cards {
        td, th {
            padding: 5px;
        }
    }
}

.card-details .StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

#payment-request-button {
    margin: 20px 0;
    max-width: 300px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*
    for this error style, it is best to add the #card-errors selector to your existing error declaration
*/
#card-errors {
    background: none;
    border-color: $sangria;
    color: $sangria;
}

#stripe-payment-errors {
    clear:both;
}
