.mini-cart {
    z-index: 20;

    @include breakpoint(medium) {
        z-index: 101;
        position: relative;
    }

    .mini-cart-name {
        a {
            color: $citrus;
        }
    }
}



.mini-cart-total {
    position: relative;

    .mini-cart-link {
        position: relative;
        display: flex;
        align-items: center;
        overflow: visible;
        padding-left: 16px;

        span {
            margin-right: 9px;
        }

        i {
            font-size: $minicart-icon-small-f-size;
            display: flex
        }

        @include svg-view-cart-element {
            transition: fill $animation-base-timing ease;
        }

        .minicart-quantity {
            line-height: 16px;
            background-color: $minicart-number-bg-color;
            font-size: 12px;
            border-radius: 10px;
            color: $minicart-number-color;
            position: absolute;
            left: 6px;
            margin: 0;
            text-align: center;
            top: -4px;
            height: 16px;
            width: 16px;
        }
    }
}

.mini-cart-total {
    display: flex;

    .mini-cart-empty {
        transition: color $animation-base-timing ease;

        &:hover {
            text-decoration: none;
        }

        .minicart-quantity {
            display: none;
        }
    }
}

.mini-cart-content {
    @extend .clearfix;
    background: $nav-mobile-bg-color;
    color: $brand-primary;
    display: flex;
        flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: fixed;
        bottom: 0;
        right: 0;
        top: 0;
    padding-top: 50px;
    transition: transform $default-transition-duration ease-in-out;
    transform: translateX(100%);
    width: 100%;
    z-index: 102;
    @include breakpoint(medium) {
        max-width: 375px;
    }
    @include breakpoint(large) {
        max-width: 600px;
        padding-top: 70px;
    }

    .mini-cart-content-top {
        @include breakpoint(large) {
            padding-left: 40px;
            padding-right: 40px;
        }

        .mini-cart-header {
            margin: 0 0 20px;
            h2 {
                @include bp(max-width, $bp-large) {
                    font-size: 32px;
                    padding-left: 27px;

                    .h3 {
                        font-size: 20px;
                    }
                }
            }
        }

        .mini-cart-products-note {
            background-color: $c-light-gray;
            margin: 20px 25px;
            padding: 24px 27px;
            text-align: center;

            @include breakpoint(large) {
                margin: 0 0 20px;
                padding: 24px 30px;
            }

            p {
                margin: 0;

                &.mini-cart-products-note-title {
                    font-weight: $f-weight-semibold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }

        .mini-cart-product {
            border: 1px solid $c-stroke-gray;
            margin-bottom: 20px;
            padding: 0;

            .mini-cart-image {
                img {
                    object-fit: cover;
                    height: 129px;
                    width: 129px;

                    @include breakpoint(large) {
                        width: 129px;
                        height: 129px;
                    }
                }
            }

            .mini-cart-data {
                margin-left: 12px;
                padding: 12px 0;
                width: calc(100% - 152px);
                display: flex;
                    justify-content: space-between;

                @include breakpoint(large) {
                    margin-left: 20px;
                    padding: 20px 0 16px;
                    width: calc(100% - 180px);
                }

                .mini-cart-data-left {
                    .mini-cart-quantity,
                    .mini-cart-inscription {
                        color: $c-charcoal;
                        text-transform: capitalize;
                    }
                }

                .product-availability-list {
                    .on-order {
                        color: $brand-primary;
                        font-size: 12px;

                        &:first-child {
                            display: block;
                            font-size: 10px;
                            font-weight: $f-weight-bold;
                            line-height: 1;
                            letter-spacing: 1px;
                            text-transform: uppercase;

                            @include breakpoint(large) {
                                display: inline-block;
                            }
                        }
                    }
                }

                .mini-cart-name {
                    a {
                        display: block;
                        font-family: $f-body;
                        font-size: 12px;
                        font-weight: $f-weight-semibold;
                        letter-spacing: 1px;
                        line-height: 14px;
                        margin-bottom: 4px;
                        text-transform: uppercase;

                        @include breakpoint(large) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    &.gift-cert {
                        a {
                            &:hover {
                                cursor: pointer;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }

                .mini-cart-attributes {
                    span {
                        color: $c-charcoal;
                        font-size: 12px;
                        line-height: 1.5;

                        @include breakpoint(large) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .product-availability-list {
                    margin-top: 10px;
                }
            }

            .mini-cart-pricing {
                height: 100%;
                margin: 0 25px 12px;
                padding: 0;

                @include breakpoint(large) {
                    margin: 0 20px;
                }
            }

            .mini-cart-pricing-remove {
                display: flex;
                    flex-wrap: wrap;
                height: 100%;
                text-align: right;
                
                & > div {
                    flex: 100%;

                    &.mini-cart-remove {
                        align-self: flex-end;

                        button {
                            border: 0;
                            font-size: 12px;
                            font-weight: $f-weight-regular;
                            letter-spacing: normal;
                            line-height: 1.5;
                            margin: 0;
                            padding: 0;
                            text-decoration: underline;
                            text-transform: capitalize;

                            &:hover {
                                text-decoration: none;
                            }

                            @include breakpoint(large) {
                                font-size: 14px;
                                line-height: 1.43;
                            }
                        }
                    }
                }
            }
        }
    }

    .mini-cart-content-bottom {
        background-color: $c-light-gray;
        padding: 25px 25px 20px;
        position: fixed;
            bottom: 0;
            left: 0;
        width: 100%;

        @include breakpoint(large) {
            padding: 40px 50px 20px;
        }

        .button {
            &:hover {
                text-decoration: none;
            }
        }

        .button--secondary {
            color: $white;

            &:hover {
                color: $brand-primary;
            }
        }

        .mini-cart-totals {
            .mini-cart-subtotals {
                display: flex;
                    justify-content: space-between;
                margin: 0 0 16px;
                padding-top: 0;
                width: 100%;

                @include breakpoint(large) {
                    margin: 0 0 20px;
                }

                span {
                    font-family: $f-body-bold;
                    font-size: 20px;
                    font-weight: $f-weight-bold;
                    letter-spacing: 0.5px;
                    line-height: 1.14;
                    margin-left: 0;
                    text-transform: uppercase;

                    @include breakpoint(large) {
                        font-size: 28px;
                    }
                }
            }

            .button {
                margin-bottom: 7px;
                width: 100%;

                @include breakpoint(large) {
                    width: 56.5%;

                    &.mini-cart-link-view-cart {
                        width: 40%;
                        margin-right: 2.5%;
                    }
                }
            }
        }
    }

    .mini-cart-close {
        @include position(absolute, 12px 19px null null);
        color: $c-gray;
        display: flex;
        font-weight: $f-weight-bold;

        &:hover {
            border: 0;
            text-decoration: none;
        }

        span {
            margin-left: 9px;
        }

        i {
            line-height: 22px;
        }

        @include svg-view-close-gray-element {
            fill: $c-gray;
        }

        @include breakpoint(large) {
            right: 40px;
        }
    }

    &:before {
        right: 22px;
    }

    .minicart-active & {
        transform: translateX(0);
    }

    .credit-card-acceptance {
        display: flex;
            align-items: center;
            justify-content: center;
        margin-top: 13px;

        h4 {
            font-family: $f-body;
            font-size: 14px;
            font-weight: $f-weight-semibold;
            line-height: 1.43;
            margin-right: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        p {
            margin: 0;
        }

        & > p {
            display: flex;
            @media (min-width: 320px) and (max-width: 399px) {
                img {
                    width: 34px;
                }
            }
        }

        img {
            width: 40px;

            @include breakpoint(large) {
                width: 46px;
            }
        }
    }

    .pre-order-notice, .do-not-combine {
        align-items: center;
        background-color: $c-light-gray;
        display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: center;
        flex-grow: 0;
        height: auto;
        margin: 20px 27px 90px;
        padding: 24px 12px;
        width: auto;

        @include breakpoint(large) {
            margin-left: 0;
            margin-right: 0;
        }

        &__content {
            color: $f-body-color;
            font-family: $f-body;
            flex-grow: 0;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            line-height: 1.43;
            text-align: center;
        }

        &__header {
            font-weight: $f-weight-semibold;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
}

.mini-cart-products {
    margin-bottom: 3px;
    max-height: calc(100vh - 330px);
    overflow: auto;
    padding: 0;

    @include breakpoint(large) {
        max-height: calc(100vh - 360px);
    }
}

.mini-cart-product {
    overflow: hidden;
    padding: 28px 0 17px 0;
    position: relative;
    display: flex;
    border-bottom: 1px solid $c-light-gray;

    &:first-child {
        padding-top: 4px;
    }

    .mini-cart-data {
        .mini-cart-name {
            line-height: 16px;
        }

        .mini-cart-name a {
            color: $f-body-color;
            line-height: 21px;
        }
    }

    .mini-cart-name.gift-cert {
        margin-left: 16px;
    }

    .mini-cart-attributes {
        .attribute {
            line-height: 20px;

            .label {
                color: $c-gray;
            }
        }
    }

    .mini-cart-pricing {
        .label,
        .value {
            display: none;
        }

        .mini-cart-price {
            margin-left: 0;
            font-size: $f-size-xs;
        }
    }
}

.mini-cart-pricing {
    .value, .mini-cart-price {
        font-weight: $f-weight-bold;
    }

    .mini-cart-price {
        margin: $minicart-pli-price-margin;
    }
}

.mini-cart-totals {
    text-align: center;
    @include clearfix;
    margin-bottom: 20px;
}

.mini-cart-subtotals {
    font-weight: 700;
    margin: 0 auto;
    padding-top: .5em;
    width: 16rem; //for IE
    width: max-content;
    font-size: $f-size-xs;

    .label {
        margin: 0 .5rem;
    }

    .value {
        margin-right: .5rem;
    }
}

.mini-cart-header {
    margin: 10px 0;

    h3 {
        text-transform: none;
        font-size: $f-size;
        color: $c-gray;
        line-height: 16px;
        font-family: $sans-serif;
        font-weight: $f-weight-bold;
    }
}

.mini-cart-image {
    img {
        width: 80px;
    }
}

.mini-cart-slot {
    background: $minicart-slot-bg-color;
    font-size: $f-size-xs;
    margin: 12px 0 25px 0;
}

.mini-cart-link-cart {
    @include primary-button;
    float: left;
    width: calc(50% - 22px);
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
}

.dw-apple-pay-button {
    color: #ffffff;
 }

// =============================================
// Mobile Minicart styles
// =============================================
.top-banner {
    .mini-cart {
        .mini-cart-link span.label {
            display: none;

            @include breakpoint(medium) {
                display: flex;
                @include headerlabel;
            }
        }
    }
}
