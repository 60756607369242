.js {
    .ui-dialog-content {
        .product-col-1 {
            padding: 13px 1%;
            width: 39%;
            .product-image {
                max-width: 100%;
            }
        }
        .product-col-2 {
            padding: 20px 0;
            width: 58%;
        }
        .pdp-main {
            .product-set-list {
                height: 500px;
                overflow: auto;
            }
            &.bonus-product-list {
                .product-col-1,
                .product-col-2 {
                    width: 100%;
                }
            }
        }
    }
    .zoomPad {
        position: relative;
        float: left;
        z-index: 99;
        cursor: crosshair;
        width: 100%;
    }
    .zoomPreload {
        -moz-opacity: 0.8;
        opacity: 0.8;
        filter: alpha(opacity = 80);
        color: $night-rider;
        font-size: 12px;
        font-family: $sans-serif;
        text-decoration: none;
        border: 1px solid $very-light-gray;
        background-color: $white;
        padding: 8px;
        text-align: center;
        background-image: url(../images/zoomloader.gif);
        background-repeat: no-repeat;
        background-position: 43px 30px;
        z-index: 110;
        width: 90px;
        height: 43px;
        position: absolute;
        top: 0px;
        left: 0px;
        *width: 100px;
        *height: 49px;
    }
    .zoomPup {
        overflow: hidden;
        background-color: $white;
        -moz-opacity: 0.6;
        opacity: 0.6;
        filter: alpha(opacity = 60);
        z-index: 120;
        position: absolute;
        border: 1px solid $very-light-gray;
        z-index: 101;
        cursor: crosshair;
    }
    .zoomOverlay {
        position: absolute;
        left: 0px;
        top: 0px;
        background: $white;
        z-index: 5000;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 101;
    }
    .zoomWindow {
        position: absolute;
        left: 110%;
        top: 40px;
        background: $white;
        z-index: 6000;
        height: auto;
        z-index: 10000;
        z-index: 110;
    }
    .zoomWrapper {
        position: relative;
        border: 1px solid $nobel;
        z-index: 110;
    }
    .zoomWrapperTitle {
        display: block;
        background: $nobel;
        color: $white;
        height: 18px;
        line-height: 18px;
        width: 100%;
        overflow: hidden;
        text-align: center;
        font-size: 10px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 120;
        -moz-opacity: 0.6;
        opacity: 0.6;
        filter: alpha(opacity = 60);
    }
    .zoomWrapperImage {
        display: block;
        position: relative;
        overflow: hidden;
        z-index: 110;
        img {
            border: 0px;
            display: block;
            position: absolute;
            z-index: 101;
        }
    }
    .zoomIframe {
        z-index: -1;
        filter: alpha(opacity = 0);
        -moz-opacity: 0.80;
        opacity: 0.80;
        position: absolute;
        display: block;
    }
}

#QuickViewDialog {
    padding: $quickview-dialog-padding;

    .back-in-stock-form {
        background-color: $c-light-blue;
        border: 1px solid $black;
        margin-top: 30px;
        max-width: 300px;
        padding: 16px;

        h5 {
            font-size: 21px;
            margin: 0 0 30px;
        }

        button {
            &::after,
            &::before {
                display: none;
            }
        }

        .bisFormStatus.success {
            display: block;
            margin-top: 16px;
        }
    }

    .product-top-content {
        max-height: 630px;

        #product-content {
            .attribute {
                .swatchanchor {
                    display: flex;
                    padding-left: 7px;
                    padding-right: 7px;
                }
            }
        }

        .grid--container {
            padding: 0;
        }
    }

    .product-image-container {
        &.col--lg-offset-1 {
            margin-left: 7%;
        }
        .product-thumbnails {
            left:0;
        }
    }

    .product-detail {
        .breadcrumb {
            margin-bottom: 15px;
        }
        h1.product-name {
            font-size: 20px;
            @include breakpoint(large) {
                font-size: 34px;
            }
        }
        .short-description {
            font-size: 14px;
            @include breakpoint(large) {
                font-size: 16px;
            }
        }
        .product-variations .swatches li a {
            display: block;
        }
        .product-actions-wrapper {
            .pdpForm {
                display: block;
                margin-bottom: 10%;
            }
        }
    }

    .product-add-to-cart {
        .c-product-action {
            .c-product-buttons {
                width: 100%;
                max-width: 300px;
            }
        }
    }

    .product-slot.product-listing {
        display: none;
    }

    .add-to-cart-wrapper {
        .c-product-buttons {
            #add-to-cart {
                padding: 6px 15px;

                &:not(.add-to-cart-disabled) {
                    &:hover {
                        background: $white;
                    }
                }
            }
        }
    }

    .product-detail.digital {
        .digital-notice {
            margin-top: -20px;
        }
    }
}

.quickview-nav {
    display: none;
}

.ui-dialog.quickview-dialog {
    position: fixed;
}
