.play-audio-clip {
    @include svg-view-audio;
    display: inline-block;
    vertical-align: middle;

    &.is-playing {
        @include svg-view-pause;
        display: inline-block;
    }
}
