.pt_medialanding {
    background-image: url("../images/media-lp-bg-texture.png");
    background-repeat: repeat-x;
    background-position-y: 200px;

    @include breakpoint(medium) {
        background-position-y: 400px;
    }

    * {
        box-sizing: border-box;

        img {
            @at-root :where(#{&}) { // where allows us to apply this default override without being too specific when we want to override it
                max-width: 100%;
            }
        }
    }

    .primary-content {
        --slidetext-toptext-offset: 100px;
        --slidetext-bottomtext-offset: 1px;

        background-image: url('../images/media-slidetext.png');
        background-repeat: no-repeat;
        background-position: left var(--slidetext-toptext-offset) top 0;
    }
}


.c-media-explore {
    padding: 54px 0 50px;

    &__heading {
        color: $black;
        font-family: $f-header;
        font-size: 32px;
        font-weight: $f-weight-bold;
        line-height: 1.13;
        letter-spacing: 0.5px;
        margin-bottom: 20px;

        @include breakpoint(medium) {
            font-size: 48px;
            margin-bottom: 14px;
        }

    }

    &__images {
        display: block;

        @include breakpoint(medium) {
            display: flex;
                align-items: flex-start;
        }

        &__item {
            display: block;
            margin-bottom: 10px;
            position: relative;

            @include breakpoint(medium) {
                padding-left: 4px;
                margin-top: 200px;
                margin-bottom: 0;
                width: 50%;

                &:first-child {
                    margin-top: 0;
                    padding-right: 4px;
                    padding-left: 0;
                }
            }

            &__label {
                color: $white;
                font-family: $f-header;
                font-size: 32px;
                font-weight: $f-weight-bold;
                line-height: 1.13;
                letter-spacing: 0.5px;
                text-align: center;
                text-transform: uppercase;

                position: absolute;
                    right: 0;
                    bottom: 40px;
                    left: 0;

                @include breakpoint(medium) {
                    font-size: 48px;
                    bottom: 60px;
                }
            }

            &__img {

            }
        }


    }
}
