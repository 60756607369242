.c-image-carousel {
    color: $white;

    * {
        box-sizing: border-box !important;
        color: inherit;
    }

    &__nav {
        display: flex;
            justify-content: space-between;
            align-items: end;
        margin-bottom: 17px;
    }

    &__more {
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 2.5px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: $f-size-xs;
            letter-spacing: 3px;
        }
    }

    &__more-link {
        display: none;

        &.is-active {
            display: inline-block;
        }
    }

    &__tab {
        color: $c-medium-gray;
        display: inline-block;
        font-size: 20px;
        font-family: $f-header;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.5px;
        margin-right: 10px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: 34px;
            margin-right: 35px;
        }

        &.is-active {
            color: inherit;
        }
    }

    &__section {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__item {
        display: block;
        margin-right: 8px;

        iframe {
            width: 100%;
        }
    }

    &__video-wrap {
        position: relative;

        &::after {
            @include svg-view-media-play-button-white;
            position: absolute;
                top: 50%;
                left: 50%;
            translate: -50% -50%;
        }
    }

    &__credit {
        font-size: $f-size-xs;
        font-weight: $f-weight-medium;
        line-height: 1.43;
        letter-spacing: normal;
    }
}

.s-image-carousel {
    // force equal height slides
    .slick-track {
        display: flex !important;
    }

    .slick-dots {
        li {
            &.slick-active {
                button {
                    &::before {
                        background-color: $c-light-red;
                    }
                }
            }
        }
    }

    .slick-prev {
        &::before {
            @include svg-view-media-carousel-prev;
        }
    }

    .slick-next {
        &::before {
            @include svg-view-media-carousel-next;
        }
    }
}
