.s-liner-notes {
    dl {
        @include clearfix;
        display: block;
    }

    dt,
    dd {
        color: $c-charcoal;
        display: inline;
        font-size: $f-size-xs;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        margin: 0;
    }

    dt {
        float: left;

        &::after {
            content: ':';
            margin-right: 0.25em;
        }
    }

    dd {
        @include clearfix;
    }

    dl:first-child {
        columns: 2;

        dt,
        dd {
            color: $c-black;
            display: block;
            float: none;
            text-transform: uppercase;
        }

        dt {
            font-size: $f-size-xs;
            font-weight: $f-weight-semibold;
            line-height: 1.29;
            letter-spacing: 1px;
        }

        dd {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.1;
            letter-spacing: 0.5px;
            margin-bottom: 20px;

            @include breakpoint(medium) {
                font-size: 21px;
            }

            &::after {
                width: 50%;
            }
        }
    }
}
