// Order History - Order Details Styling

.order-history-header {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $white-smoke;
    padding: 10px;
    .label {
        font-weight: 700;
    }
    button {
        float: right;
    }
    .order-date, .order-status, .order-number {
        float: left;
        margin: 2px 0;
        width: 60%;
    }
}
.order-history-items {
    margin: 20px 0;
    .order-history-table {
        th, td {
            padding: 5px 10px;
        }
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            list-style-position: inside;
            list-style-type: square;
        }
        .order-shipped-to,
        .order-items,
        .order-total-price {
            width: 33%;
        }
        .toggle {
            cursor: pointer;
            display: block;
        }
    }
}
.js .order-history-table .hidden {
    display: none;
}


// Details Styling

.orderdetails {
    font-family: $sans-serif-alt;
    .orderdetails-content {
        margin-bottom: 0;

        .pt_order & {
            margin-bottom: 24px;

            @include breakpoint(large) {
                margin-bottom: 29px;
            }
        }

        .order-history-h1 {
            margin-bottom: 19px;
        }
    }
    h2 {
        clear: both;
    }

    .order-information {
        h3 {
            margin-top: -8px;
            margin-bottom: 0px;

            @include breakpoint(medium) {
                margin-top: 2px;
            }

            @include breakpoint(large) {
                margin-bottom: 0px;
            }
        }

       .label {
           &.custserv {
               color: $black;
           }
       }
    }


    .order-billing-shipping {
        @include breakpoint(medium) {
            margin-bottom: 12px;
        }

        .grid & {
            @include bp(max-width, $bp-medium) {
                margin-left: -$l-small-padding;
                margin-right: -$l-small-padding;
            }
        }

        .section-header {
            sup {
                text-decoration: underline;
                font-size: 11px;
                margin-left: -3px;
            }
        }

        .order-gift-message {
            .label {
                font-weight: $f-weight-regular;
                color: $c-gray;
            }
            div:not(.gift-message-container) {
                display: inline-block;
            }

            &.order-history {
                @include breakpoint(large) {
                    width:58%;
                }
            }
        }

        .order-shipments {
            padding-top: 30px;

            @include breakpoint(large) {
                padding-top: 32px;
            }

            @include breakpoint(xlarge) {
                padding-top: 40px;
            }

            &-wrap {
                @include bp(max-width, $bp-medium) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .mini-address-location address {
        font-style: normal;
    }

    .order-shipment-table {
        .label {
            font-weight: $f-weight-regular;
            color: $c-charcoal;
        }

        .phone .label {
            color: $black;
        }
        .order-shipment-details {
            .trackingnumber {
                clear: both;
                .label, .value {
                    float: left;
                    margin-right: .3em;
                }
            }
        }
        .shipping-status, 
        .shipping-method, 
        .preorder-messaging,
        .order-shipment-gift-message {
            clear:both;
            .label, .value {
                float: left;
                margin-right: .3em;
            }
        }
        .preorder-messaging {
            .value {
                color: $c-red;
            }
        }
    }

    .line-items-row {
        clear: both;
        .section-header {
            margin-bottom: 1px;

            &.item-header {
                margin-top: 16px;

                @include breakpoint(small) {
                    margin-top: 4px;
                }
            }
        }
    }

    .line-items {
        clear: both;
        padding-top: 8px;

        .section-header {
            display: none;

            @include breakpoint(medium) {
                display: inline-block;
            }
        }

        .line-item-details {
            width: 100%;
            a,
            &-value {
                font-size: 14px;

                @include breakpoint(large) {
                    font-size: 16px;
                }
            }

            @include breakpoint(medium) {
                width: 60%;
            }

            @include breakpoint(large) {
                width: 70%;
            }
        }

        .line-item-price {
            text-align: left;

            @include breakpoint(small) {
                text-align: right;
            }

            p {
                margin: 0;
                display: none;
            }
        }

        .line-item {
            padding-bottom: 8px;
            display: flex;
            flex-direction: column;

            @include breakpoint(small) {
                display: block;
            }

            .qty-price {
                display: flex;
                justify-content: flex-start;

                .line-item-quantity {
                    margin-right: 41px;

                    @include breakpoint(large) {
                        margin-right: 0;
                        width: 50%;
                        text-align: right;
                    }
                }

                .line-item-price {
                    @include breakpoint(large) {
                        display: inline-block;
                        width: 48%;
                    }
                }
            }

            .line-item-details,
            .line-item-quantity,
            .line-item-price {
                display: inline-block;
                vertical-align: top;
            }
            .line-item-quantity,
            .line-item-price {
                width: auto;
            }
            
            .line-item-quantity {
                text-align: left;

                @include breakpoint(small) {
                    text-align: center;
                }

                p {
                    margin: 0;
                    display: none;
                }

            }
        }

        .line-item-quantity {
            font-size: 14px;

            @include breakpoint(large) {
                text-align: center;
                font-size: 16px;
            }
        }
    }

    .order-payment-summary  {
        @include bp(max-width, $bp-medium) {
            padding-top: 4px;
        }

        .section-header {
            @include breakpoint(large) {
                margin: 8px 0;
            }
        }
    }

    .order-payment-instruments {
        .section-header {
            @include breakpoint(small) {
                width: 39%;
            }
        }

        .label {
            display: inline-block;
        }

        .payment-type {
            display: inline-block;
        }

        .cc-owner, .cc-type, .cc-number {
            display: none;
        }
    }

    div.section-header-container {
        @include breakpoint(small) {
            display: flex;
            flex-direction: row;
        }

        .section-header {
            display: none;

            &:first-of-type {
                display: block;
                width: 100%;
            }

            @include breakpoint(medium) {
                display: inline-block;
            }
        }
    }

    .section-header-container {
        .item-header {
            width: 70%;
        }

        .qty-header,
        .price-header {
            width: 14%;
        }

        .qty-header {
            text-align: center;
        }

        .price-header {
            text-align: right;
        }
    }

    .product-list-item {
        display: flex;
        flex-direction: column;

        @include breakpoint(small) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .product-details {
            @include breakpoint(small) {
                margin-left: 20px;
            }
        }

        .name {
            padding-right: 10px;

            a,
            .line-item-details-value {
                color: $black;
            }
        }

        .sku, .attribute {
            display: none;
        }

        .product-availability-info {
            display: none;
        }
    }

    .line-item-attributes {
        .attribute .label {
            color: $c-gray;
        }
    }

    .order-information {
        padding-bottom: .5em;
        padding-top: .5em;

        .order-number {
            margin: 0;
        }

        .value {
            font-weight: $f-weight-regular;
        }
    }
    .payment-amount {
        .label {
            font-weight: normal;
            text-transform: none;
        }
    }
    .order-totals-table {
        @include bp(max-width, $bp-medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .subscription-shipments{
        padding-top: 16px;

        h3 {
            margin-top: 2em;
        }
        .label {
            color: $c-gray;
        }
        .section-header {
            border-bottom: none;
            margin-bottom: 8px;
            margin-top: 20px;
        }
        .text-center {
            text-align: center;
        }
        .text-right {
            text-align: right;
        }

        .subscription-shipment-details {
            & > div {
                &:first-child {
                    display: block;
                    order: 1;
                    margin-top: 15px;

                    @include breakpoint(large) {
                        order: initial;
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: start;

                    @include breakpoint(large) {
                        display: block;
                    }
                }

                &:not(:first-child, :nth-child(2)) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include breakpoint(large) {
                        display: block;
                    }
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.pt_order,
.confirmation {
    .actions {
        padding: 1.5em 1%;
    }

    .section-header {
        font-weight: 700;
        border-bottom: 1px solid $white-smoke;
    }
}

.pt_order {
    .orderdetails + .actions {
        padding: 0;
        margin-top: 17px;

        @include breakpoint(large) {
            margin-top: 28px;
        }

        a {
            margin-bottom: 19px;
            display: block;

            &.button {
                width: 187px;

                @include breakpoint(large) {
                    margin-right: 39px;
                    display: inline-block;
                }
            }

            &.button--cta {
                width: 142px;

                @include breakpoint(large) {
                    display: inline-block;
                }
            }
        }
    }
    &.order-history {
        .order-payment-instruments {
            .section-header {
                width: 100%;
            }
        }
        .order-totals-table {
            tr {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                &.order-total {
                    td {
                        padding-top: 20px;
                    }
                }
            }
            td {
                font-weight: inherit;
                text-align: left;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 10px;

                &:first-child {
                    padding-right: 0;
                    text-align: left;
                }
    
                &:last-child {
                    @include breakpoint(large) {
                        width: 15%;
                    }
                }
            }
        }
    }

    .order-shipments {
        background-color: $c-light-gray;
        
    }

    .order-billing-shipping {
        > .row {
            @include bp(max-width, $bp-medium) {
                margin-left: -$l-small-padding;
                margin-right: -$l-small-padding;
            }
        }

        .order-shipments {
            @include bp(max-width, $bp-medium) {
                padding-left: $l-small-padding;
                padding-right: $l-small-padding;
            }
        }
    }

    .line-items-row {
        &:last-child {
            @include bp(max-width, $bp-large) {
                margin-bottom: 27px !important;
            }
        }
    }
}

.order-details {
    &.create-account {
        .visually-hidden {
            width: 1px !important;
            height: 1px !important;
        }
        .login-create-account,
        .order-confirmation-details {
            display: inline-block;
            vertical-align: top;
        }
        .login-box-content {
            border: 1px solid $white-smoke;
            padding: 1em;
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
            .form-row {
                margin: 0;
            }
        }
    }
}

.action-buttons {
    margin-top: 10px;
    .button {
        width: 80%;
        margin-bottom: 15px;
        margin-right: 15px;
        @include breakpoint(large){
            width: 150px;
        }
    }
}

.modal {
     position: absolute;
     z-index: 10000;
     top: 0;
     left: 0;
     visibility: hidden;
     width: 100%;
     height: 100%;
}

    .modal.is-visible {
    visibility: visible;
}

.modal-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

 .modal-wrapper {
    position: relative;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
    z-index: 9999;
    @include breakpoint(large){
        position: absolute;
        z-index: 9999;
        top: 6em;
        left: 50%;
        width: 32em;
        margin-left: -16em;
        max-height: 800px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.modal-transition {
    transition: all 0.3s 0.12s;
    transform: translateY(-10%);
    opacity: 0;
}

.modal.is-visible .modal-transition {
    transform: translateY(0);
    opacity: 1;
}

.modal-content {
    padding: 1em;
}

.modal-header {
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
    border-bottom: 1px solid #e8e8e8;
}

 .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    color: #aaa;
    background: none;
    border: 0;
}

.modal-close:hover {
    color: #777;
}

.modal-heading {
    font-size: 1.125em;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
    margin-top: 0;
}

.modal-content > *:last-child {
    margin-bottom: 0;
}

.col-container {
    width: 100%;
    padding: 10px 30px;
    h1 {
        text-align: center;
    }
    h2.register-header {
        margin-top: 30px;
    }
}

.close-gray-element{
    fill: $c-gray !important;
}

.registration-apply {
    width: 100%;
    @include breakpoint(large){
        width: 50%;
        margin: 0 auto;
    }
}

.modal-close {
    text-transform: uppercase;
    text-decoration: none;
    color: $c-gray;
    float: right;
    padding: 20px;
}


.pt_order-confirmation {
    .visually-hidden {
        width: 1px !important;
        height: 1px !important;
    }

    .order-billing-shipping-content {
        @include breakpoint(large) {
            max-width: $bp-max-content-width;
            width: 100%;
        }
    }
}

.orderdetails-shared {
    .order-payment-summary {
        margin-top: 0;

        .section-header {
            font-size: 20px;
            font-weight: $f-weight-bold;
            line-height: 1.1;
            letter-spacing: 0.5px;
            padding: 0 0 10px;
            text-transform: uppercase;
            @include breakpoint(large) {
                margin-top: 0;
            }

            @include bp(max-width, $bp-medium) {
                margin-bottom: 13px;
            }
        }
        .order-detail-summary {
            .order-totals-table {
                margin: 0;
                padding-top: 10px;

                @include breakpoint(large) {
                    padding-top: 14px;
                }
            }
            dd,
            dt {
                color: $c-charcoal;
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                letter-spacing: 1px;
                padding: 0;
                margin: 0 0 10px 0;

                &.order-total-label,
                &.order-total-value {
                    color: $brand-primary;
                    font-family: $f-subheader;
                    font-size: 21px;
                    line-height: 1.29;
                    letter-spacing: 1px;
                    margin-bottom: 14px;
                    padding-top: 10px;

                    @include breakpoint(large) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }

    .order-billing-shipping {
        margin-bottom: 0;

        @include breakpoint(large) {
            display: flex;

            .row {
                max-width: 1200px;

                & > .col {
                    max-width: 816px;
                }
            }
        }

        &:has(.order-gift-message) {
            @include breakpoint(large) {
                justify-content: center;
            }
        }

        > .row {
            background-color: inherit;
        }

        .order-shipments {
            .line-items-row {
                background-color: $white;
                border: 1px solid $c-stroke-gray;
                margin-bottom: 20px;

                @include breakpoint(large) {
                    margin-bottom: $grid-large-padding;
                }

                @include breakpoint(xlarge) {
                    margin-bottom: $grid-xlarge-padding;
                }

                .shipment-header-details {
                    padding-bottom: 0;
                    padding-left: 20px;
                    padding-right: 20px;
                    
                    div {
                        display: initial;
                    }

                    .label {
                        color: $c-charcoal;
                        font-size: 14px;
                        line-height: 1.43;
                    }

                    .value {
                        color: $brand-primary;
                        font-size: 14px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.43;
                        letter-spacing: 1px;
                        margin-left: 7px;
                        text-transform: uppercase;
                    }
                }

                .shipping-method-details,
                div.section-header-container {
                    border-top: 1px solid $c-stroke-gray;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-top: 15px;

                    @include breakpoint(large) {
                        margin-top: 19px;
                    }
                }

                .shipping-method-details,
                .section-header-container {
                    padding-top: 15px;

                    @include breakpoint(large) {
                        padding-top: 20px;
                    }
                }

                .shipping-method-details {
                    @include breakpoint(large) {
                        margin-bottom: 30px;
                    }
                }

                .shipment-table-section-header {
                    border: 0;
                    color: $c-charcoal;
                    font-size: 10px;
                    font-weight: $f-weight-semibold;
                    line-height: 1.4;
                    letter-spacing: 1px;
                    margin-top: 0;
                    padding-top: 20px;
                    text-transform: uppercase;

                    @include bp(max-width, $bp-large) {
                        @include visually-hidden;
                    }

                    @include breakpoint(large) {
                        &.qty-header {
                            text-align: center;
                        }
                    }
                }

                .line-items {
                    padding: 0 20px;

                    @include breakpoint(large) {
                        margin-bottom: 20px;
                    }

                    .line-item-attributes{
                        display: none;
                    }

                    .line-item-details {
                        margin-bottom: 10px;
                    }

                    .h-mobile-only {
                        border: 0;
                        color: $c-charcoal;
                        font-size: 10px;
                        font-weight: $f-weight-semibold;
                        line-height: 1.4;
                        letter-spacing: 1px;
                        margin-bottom: 2px;
                        margin-top: 0;
                        padding: 0;
                        text-transform: uppercase;
                        @include bp(max-width, $bp-large) {
                            display: block;
                        }
                    }

                    .qty-price {
                        flex-wrap: wrap;
                        @include breakpoint(large) {
                            display: flex;
                        }
                    }

                    .line-item-price {
                        font-size: 14px;
                        font-weight: $f-weight-semibold;
                        padding-top: 2px;
                        margin: 0 0 10px;

                        @include bp(max-width, $bp-medium) {
                            width: 100%;
                        }

                        @include breakpoint(large) {
                            padding-right: 15px;
                        }
                    }

                    .personalize-name {
                        .label {
                            color: $c-charcoal;
                            font-size: 10px;
                            font-weight: $f-weight-semibold;
                            line-height: 1.4;
                            letter-spacing: 1px;
                            margin-bottom: 2px;
                            margin-top: 0;
                            padding: 0;
                            text-transform: uppercase;
                        }

                        .value {
                            color: $brand-primary;
                            font-size: 14px;
                            font-weight: $f-weight-semibold;
                            line-height: 1.43;
                            letter-spacing: 1px;
                            margin-left: 7px;
                            text-transform: none;
                        }
                    }
                }

                .order-shipment-table {
                    &:has(.order-gift-cert-attributes),
                    &:has(.order-shipment-gift-message) {
                        .order-gift-cert-attributes {
                            margin-left: 0;
                            padding: 0 0 0 20px;
                        }
        
                        .order-gift-cert-amount {
                            padding: 10px 20px 0;
                        }
        
                        .shipping-method {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding: 10px 20px 0;
        
                            & > div {
                                float: none;

                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }
        
                        .order-shipment-gift-message {
                            display: flex;
                            flex-direction: row;
                            padding: 10px 20px 0;
                            margin-bottom: 20px;
        
                            & > div {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
        
        .shipment-header-details {
            padding-top: 6px;
            
            &:first-child {
                padding-top: 15px;
    
                @include breakpoint(large) {
                    padding-top: 19px;
                }
            }
        }

        .shipping-method {
            &-details {
                th,
                td {
                    &:first-child {
                        @include breakpoint(large) {
                            padding-left: 20px;
                        }
                    }

                    &:not(:first-child) {
                        padding-left: 0;
                    }

                    &:last-child {
                        @include breakpoint(large) {
                            padding-right: 20px;
                        }
                    }

                    &:not(:last-child) {
                        padding-right: 0;
                    }

                }

                td {
                    display: block;
                    margin-bottom: 10px;
                    padding-top: 1px;
                    

                    @include bp(max-width, $bp-large) {
                        padding-bottom: 0;
                        padding-left: 0;
                        padding-right: 0;
                        text-align: left;
                        width: 100%;
                    }

                    @include breakpoint(large) {
                        display: table-cell;
                    }
                }

                tr:last-child {
                    td:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .order-billing-shipping-content {
            widtH: 100%;

            .row {
                .order-gift-message {
                    margin-bottom: 27px;
                    padding-right: 0;
                    width: 100%;

                    @include breakpoint(medium) {
                        margin-bottom: 0;
                        width: 70%;
                    }

                    .section-header {
                        border-bottom: 2px solid $c-medium-gray;
                        margin-bottom: 10px;
                        padding-bottom: 7px;
                    }
                }
            }
        }
    }

    .order-payment-instruments {
        .order-confirmation-item {
            .section-header {
                font-size: 20px;
                font-weight: $f-weight-bold;
                line-height: 1.1;
                letter-spacing: 0.5px;
                padding: 0 0 10px;
                margin: 0 0 13px;
                text-transform: uppercase;
            }

            & > div {
                color: $brand-primary;
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 5px;

                span {
                    color: $brand-primary;
                    font-size: 14px;
                    line-height: 1.43;
                }
            }
        }
    }
    .order-downloads {
        @include breakpoint(medium) {
            margin-bottom: 60px;
            padding-top: 3px;
        }

        &-row {
            margin-bottom: 40px;

            @include bp(max-width, $bp-medium) {
                border-bottom: 1px solid $c-black;
                border-top: 1px solid $c-black;
                margin-left: -$l-small-padding;
                margin-right: -$l-small-padding;
                margin-top: -2px;
            }

            @include breakpoint(medium) {
                margin-bottom: 20px;
            }

            > .col {
                @include bp(max-width, $bp-medium) {
                    padding-left: $l-small-padding;
                    padding-right: $l-small-padding;
                }
            }
        }

        &-box {
            @include breakpoint(medium) {
                border: 1px solid $c-black;
                padding: 32px;
            }

            @include breakpoint(large) {
                padding: 40px;
            }
        }

        .download-product-box {
            @include breakpoint(large) {
                padding: 30px 30px 0;
                border: 1px solid $brand-primary;
            }
        }

        .section-header {
            border: 0;
            font-size: 20px;
            font-weight: $f-weight-bold;
            line-height: 1.1;
            letter-spacing: 0.5px;
            margin: 8px 0;
            padding: 0 0 20px;
            text-transform: uppercase;

            @include breakpoint(large) {
                font-size: 21px;
                line-height: 1.29;
            }
        }

        .download-product {
            &:last-child {
                .download-list {
                    margin-bottom: 0;
                }
            }

            .download-title {
                color: $c-charcoal;
                font-size: 12px;
                font-weight: $f-weight-semibold;
                line-height: 1.17;
                letter-spacing: 1px;
                margin-bottom: 10px;
                text-transform: uppercase;
                
                @include breakpoint(large) {
                    font-size: 14px;
                    line-height: 1.14;
                    margin-bottom: 16px;
                }
            }
            .download-list {
                display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                margin-bottom: 30px;
                margin-top: 11px;
                padding-left: 0;
                width: 100%;

                @include breakpoint(medium) {
                    margin-bottom: 40px;
                }

                .download-item {
                    list-style-type: none;
                    width: 100%;

                    @include breakpoint(large) {
                        display: inline-block;
                        width: auto;
                    }
                }

                .download-button {
                    width: 100%;
                    line-height: 1.2;
                    text-align: center;
                    white-space: normal;

                    @include breakpoint(large) {
                        width: auto;
                    }
                }
            }
        }
    }
    .order-information {
        h3 {
            font-size: 32px;
            line-height: 1.13;
            letter-spacing: 0.5px;
            @include breakpoint(large) {
                font-size: 34px;
                line-height: 1.12;
            }

            span {
                font-size: 28px;
                line-height: 1.07;
                letter-spacing: 1px;

                @include bp(max-width, $bp-large) {
                    display: block;
                }
            }
        }

        .order-date,
        .order-status {
            .label {
                color: $brand-primary;
                font-size: 14px;
                line-height: 1.43;
            }

            .value {
                color: $brand-primary;
                font-size: 14px;
                font-weight: $f-weight-semibold;
                line-height: 1.43;
                text-transform: uppercase;
            }
        }
    }

    .order-details {
        &-col {
            &:nth-of-type(odd) {
                @include breakpoint(large) {
                    padding-right: 50px;
                }
            }

            &:nth-of-type(even) {
                @include breakpoint(medium) {
                    padding-left: 35px;
                }

                @include breakpoint(large) {
                    padding-left: 50px;
                }
            }
        }
        &-section {
            font-size: 14px;
            line-height: 1.75;
            margin-bottom: 27px;
        
            @include breakpoint(medium) {
                margin-bottom: 38px;
            }
        
            .section-header {
                margin-bottom: 8px;
                padding-bottom: 13px;

                @include breakpoint(large) {
                    border-bottom-width: 2px;
                    margin-bottom: 10px;
                    padding-bottom: 7px;
                }

                &:not(.section-header--downloads) {
                    border-bottom: 2px solid $c-medium-gray;
                }

                &--downloads {
                    @include breakpoint(large) {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    .order-shipments {
        @include breakpoint(large) {
            width: 100%;
            margin-left: 0;
        }        

        &.col {
            @include breakpoint(large) {
                padding-right: $grid-large-padding / 2;
            }

            @include breakpoint(xlarge) {
                padding-right: $grid-xlarge-padding / 2;
            }
        }

        .shipment-section-header {
            @include h6;

            border-bottom: none;
            text-transform: uppercase;
            padding-bottom: 0;
            margin-bottom: 5px;
            
            @include breakpoint(large) {
                @include h5;

                margin-bottom: 12px;
            }

            sup {
                text-decoration: underline;
                font-size: 12px;
                margin-left: -4px;

                @include breakpoint(large) {
                    top: -0.7em;
                }
            }
        }

        .shipment-left-col {
            @include breakpoint(medium) {
                width: calc(50% - 11px);
                display: inline-block;
                padding-right: 24px;
                float: left;
            }
        }

        .order-shipment-address {
            margin-top: 8px;

            @include breakpoint(medium) {
                display: inline-block;
                margin-top: 0;
            }
            
            .order-shipment-gift-message {
                font-size: inherit;
            }

            .label,
            .value {
                float: none;
                margin: 0;
                min-width: 0;
                padding: 0;
            }

            .label {
                color: $c-charcoal;
                display: inline-block;
                flex-shrink: 0;
                font-size: inherit;
            }

            .value {
                display: inline-block;
                margin-left: 0;

                &:not(:last-child) {
                    &::after {
                        content: ', ';
                    }
                }
            }
        }
    }

    .line-items-row {
        .section-header-container {
            .section-header.qty-header, .section-header.price-header {
                @include breakpoint(medium) {
                    text-align: right;
                    margin-bottom: 0;
                }
            }

            .price-header {
                @include breakpoint(medium) {
                    width: 29%;
                }
            }
        }

        .line-items {
            padding-top: 18px;

            .line-item {
                font-size: $f-size;
                line-height: 1.5;
                padding-bottom: 16px;

                .line-item-attributes {

                    @include breakpoint(medium) {
                        width: 70%;
                        display: inline-block;
                    }

                    .label {
                        color: $c-gray;
                    }
                }
            }

            .qty-price {
                @include breakpoint(medium) {
                    display: inline-block;
                    width: 40%;
                    float: right;
                    text-align: right;

                    .line-item-quantity, .line-item-price {
                        width: 33%;

                        @include breakpoint(large) {
                            width: 48%;
                        }
                    }

                    .line-item-quantity {
                        @include breakpoint(large) {
                            text-align: left;
                        }
                    }
                }

                @include breakpoint(large) {
                    width: 30%;
                }
            }
        }

        tr.section-header-container,
        tr.line-items {
            display: block;

            @include breakpoint(large) {
                display: table-row;
            }
        }

        tr.section-header-container {
            @include bp(max-width, $bp-large) {
                
            }
        }

        tr.line-items {
            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &:last-child {
                margin-bottom: 18px;
            }
        }
    }
}