.c-tabbed-carousels {
    $bottom-offset: -$tile-slider-padded-padding-top - 80px;
    $bottom-offset-md: -$tile-slider-padded-padding-top-md - 75px;
    $bottom-offset-lg: -$tile-slider-padded-padding-top-lg - 14px;

    padding-bottom: 10px;

    @include breakpoint(medium) {
        padding-bottom: 30px;
        padding-top: 45px;
    }

    &__tabs {
        &,
        &.ui-tabs-nav {
            display: flex;
            height: auto;
            margin-left: -$carousel-tab-padding-h;
            margin-right: -$carousel-tab-padding-h;

            @include breakpoint(medium) {
                margin-left: -$carousel-tab-padding-h-md;
                margin-right: -$carousel-tab-padding-h-md;
            }
        }

        &__wrap {
            // Since taking the padding off the slider will break the positioning of the accents, move the tabs down
            margin-bottom: $bottom-offset;
            padding-top: $tile-slider-padded-padding-top + 25px;
            position: relative;
                z-index: 1;

            @include breakpoint(medium) {
                margin-bottom: $bottom-offset-md;
                padding-top: 58px;
            }

            @include breakpoint(large) {
                display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                margin-bottom: $bottom-offset-lg;
            }
        }
    }

    &__tab {
        @include h3;
        
        border-bottom: none !important; // Override overly specific plugin selector
        color: $c-medium-gray;
        float: none;
        text-transform: uppercase;

        &:hover,
        &.ui-state-active {
            color: $c-black;
        }

        &__link {
            color: inherit !important; // Override overly specific plugin selector
            display: inline-block;
            float: none !important; // Override overly specific plugin selector
            margin-right: 0 !important; // Override overly specific plugin selector
            padding-left: $carousel-tab-padding-h !important; // Override overly specific plugin selector
            padding-right: $carousel-tab-padding-h !important; // Override overly specific plugin selector

            @include breakpoint(medium) {
                padding-left: $carousel-tab-padding-h-md !important; // Override overly specific plugin selector
                padding-right: $carousel-tab-padding-h-md !important; // Override overly specific plugin selector
            }
        }
    }

    &__selector {
        background-color: transparent;
        border-color: $white;
        color: inherit;
        font-size: 15px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        text-transform: uppercase;

        
        &__wrap {
            color: $white;

            &::after {
                border-top-color: currentColor;
            }
        }
    }

    &__ctas {
        padding-bottom: 26px;
    }

    &__cta {
        font-size: 14px;
        font-weight: $f-weight-bold;
        letter-spacing: 3px;
        line-height: 1;
        text-transform: uppercase;

        &:not(.is-active) {
            display: none;
        }
    }

    &__tabs-content {
        --slidetext-toptext-offset: 430px;

        min-height: $bottom-offset * -1;

        @include breakpoint(medium) {
            min-height: $bottom-offset-md * -1;
        }

        @include breakpoint(large) {
            min-height: $bottom-offset-lg * -1;
        }
    }

    & &__tab-content {
        background-color: transparent;
        border: none;
        padding-top: 0;
    }

    &--dark {
        background-color: $c-black;

        .c-tabbed-carousels {
            &__tab {
                &:hover,
                &.ui-state-active {
                    color: $white;
                }
            }
        }
    }
}

.s-tabbed-carousels {
    &--non-store {
        .c-tile-slider--padded {
            padding-top: 80px;
            
            @include breakpoint(medium) {
                padding-top: 130px;
            }
        }
    }
    
    &__tabs-content {
        &.is-active {
            [id*="recomm_slot"] {
                display: block !important; // Prevent Einstein JS from hiding block with inline styles
            }
        }
    }
}