
.c-more-from-artist {
    padding: 200px 0 300px;

    @include breakpoint(medium) {
        padding-bottom: 200px;
    }

    * {
        color: inherit;
    }

    img {
        max-width: 100%;
    }

    &__container {
        box-sizing: border-box;
        padding: 0 $l-small-padding;

        @include breakpoint(medium) {
            display: flex;
        }
    }

    &__part1,
    &__part2 {
        @include breakpoint(medium) {
            width: 50%;
        }
    }

    &__part1 {
        margin-top: -130px;
        margin-bottom: 10px;
        transition: margin-top 1.5s;

        @include breakpoint(medium) {
            margin-bottom: 0;
            order: 1;
            padding-left: 4px;
        }

        .is-active & {
            margin-top: 0;
        }

    }

    &__part2 {
        text-align: center;

        @include breakpoint(medium) {
            padding-right: 4px;
            text-align: right;
        }
    }

    &__heading {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.05;
        letter-spacing: 0.5px;
        margin-bottom: 50px;
        margin-right: auto;
        margin-left: auto;
        opacity: 0;
        text-align: center;
        transition: opacity 1.5s;
        width: 320px;

        .is-active & {
            opacity: 1;
        }

        @include breakpoint(medium) {
            font-size: 48px;
            font-weight: bold;
            line-height: 1.13;
            letter-spacing: 1px;
            margin-bottom: 40px;
            margin-left: 44px;
            text-align: left;
        }
    }

    &__button {
        border: 1px solid $black;
        display: inline-block;
        font-size: $f-size-s;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 3px;
        margin-top: 50px;
        padding: 11.5px 20px;
        text-transform: uppercase;

        @include breakpoint(medium) {
            margin-top: 60px;
            margin-right: 45px;
        }
    }

}

.dark-theme {
    .c-more-from-artist {
        background-image: url(../images/Background_Texture.jpg);
        clip-path: $polygon-clip-path;
        color: $white;

        @include breakpoint(medium) {
            clip-path: $polygon-clip-path-md;
        }

        @include breakpoint(large) {
            clip-path: $polygon-clip-path-lg;
        }

        &.has-about-artist {
            // bottom hook only if after about-artist
            clip-path: polygon(0 0, 0 81%, 69% 100%, 53% 88%, 100% 100%, 100% 0);
            padding-top: 290px;
        }

        &__button {
            border-color: $white;
        }
    }
}
