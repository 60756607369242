.login-box {
    h3 {
        margin: 0;
    }
    p {
        margin: 5px 0 13px 0;
    }
    .error {
        margin-left: 0;
    }
}

.login-rememberme {
    color: $black;
    margin: -14px 0 30px 0;
}

.login-oauth p:first-child {
    font-size: 2em;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}
