
.about-artist {
    background-color: $black;
    background-size: cover;
    background-position: center;
    clip-path: $polygon-clip-path;
    color: $white;

    @include breakpoint(medium) {
        clip-path: $polygon-clip-path-md;
    }

    &.has-more-from-artist {
        margin-bottom: -201px;
        position: relative;
            z-index: 2; // to allow .more-from-artist to underlay
    }

    * {
        color: inherit;
    }

    &__overlay {
        background-color: rgba(0,0,0,.75);
        padding: 195px 27px;
    }

    &__heading,
    &__body,
    &__more {
        text-align: center;

        @include breakpoint(medium) {
            margin: 0 auto;
            max-width: 777px;
        }
    }

    &__heading {
        font-family: $f-header;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.13;
        letter-spacing: 0.5px;
        margin: 100px auto 15px;
        opacity: 0;
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: 60px;
            line-height: 1;
            margin: 150px auto 20px;
        }

        .is-active & {
            transition: opacity 1.5s, margin-top 1.5s;
            opacity: 1;
            margin-top: 0;
        }
    }

    &__body {
        font-size: $f-size-xs;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        opacity: 0;

        > :first-child ~ * {
            display: none;

            .is-expanded & {
                display: block;
            }
        }

        @include breakpoint(medium) {
            font-size: $f-size;
            line-height: 1.5;
        }

        .is-active & {
            transition: opacity 1.5s 1s;
            opacity: 1;
        }
    }

    &__more {
        margin-top: 20px;
        text-align: center;

        &__cta {
            background-color: transparent;
            border-color: $white;
            font-family: $sans-serif;
            font-size: 15px;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 3px;
            text-align: center;

            &::after {
                content: "+";
                margin-left: 0.5em;
            }

            .is-expanded & {
                display: none;
            }
        }
    }
}
