.tooltip {
    position: relative;
    color: $brand-primary;
    font-size: $f-size-xs;
    
    &.product-tile {
        cursor: pointer;
    }
}

.tooltip-content {
    display: none;
}

.ui-tooltip.ui-widget-content {
    background-color: $white;
    border: 1px solid $c-black;
    box-shadow: none;
    color: $c-charcoal;
    font-size: $f-size-xs;
    line-height: 1.43;
}

.ui-tooltip-content {
    padding: 7px;

    strong {
        color: $c-black;
        font-weight: $f-weight-semibold;
        letter-spacing: .95px;
    }

    p {
        margin-bottom: 5px;
        margin-top: 0;
    }

    .recommendation-tooltip-header {
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.5em;
        .product-price {
            font-weight: 400;
            .price-standard {
                color: $nobel;
                text-decoration: line-through;
            }
        }
    }
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        text-align: right;
        padding-bottom: .3rem;
    }
    .shippingtotal {
        clear: right;
        text-align: right;
        margin-top: .3rem;
        .value {
            border-top: 1px solid $charcoal;
            padding-top: .3rem;
        }
    }
}
