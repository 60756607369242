// =============================================
// Base Slider Elements
// =============================================
.js-slider {

    &--hero {
        min-width: 0;
        min-height: 0;

        .slide {
            position: relative;
            display: none;

            &:first-of-type {
                display: block;
            }
        }

        h1 {
            position: absolute;
            left: 12%;
            max-width: 240px;
            top: 50%;
            transform: translateY(-50%);

            @include breakpoint(medium) {
                max-width: 348px;
            }

            @include breakpoint(large) {
                max-width: 460px;
            }

            @include breakpoint(xlarge) {
                max-width: 578px;
            }
        }

        .slick-arrow {
            display: none !important;

            @include breakpoint(medium) {
                display: block !important;
            }
        }

    }

    img {
        width: 100%;
        height: auto;
    }
}