@mixin grid-row() {
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: -$grid-small-padding -#{$grid-small-padding/2} $grid-small-padding -#{$grid-small-padding/2};
    padding: 0;

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(medium) {
        margin: -$grid-medium-padding -#{$grid-medium-padding/2} $grid-medium-padding -#{$grid-medium-padding/2};
    }

    @include breakpoint(large) {
        margin: -$grid-large-padding -#{$grid-large-padding/2} $grid-large-padding -#{$grid-large-padding/2};
    }

    @include breakpoint(xlarge) {
        margin: -$grid-xlarge-padding -#{$grid-xlarge-padding/2} $grid-xlarge-padding -#{$grid-xlarge-padding/2};
    }

    @include variant(no-gutters) {
      margin: 0;
    } 
}

.row {
   @include grid-row; 
}