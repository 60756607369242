@mixin svg-view {
    background: url("../images/svg/sprites.view.svg") no-repeat;
    content: '';
    display: block;
}

.svg-view {
    @include svg-view;
}

@mixin svg-view-album-arrow-left-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-album-arrow-left() {
    @include svg-view;
    background-position: 96.8% 40.16%;
    @include svg-view-album-arrow-left-dims;
}

@mixin svg-view-album-arrow-left-element() {
    use.album-arrow-left-element {
        @content;
    }
}

@mixin svg-view-album-arrow-right-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-album-arrow-right() {
    @include svg-view;
    background-position: 99.7% 48.67%;
    @include svg-view-album-arrow-right-dims;
}

@mixin svg-view-album-arrow-right-element() {
    use.album-arrow-right-element {
        @content;
    }
}

@mixin svg-view-apple-dims() {
    width: 18px;
    height: 20px;
}

@mixin svg-view-apple() {
    @include svg-view;
    background-position: 86.5% 63.95%;
    @include svg-view-apple-dims;
}

@mixin svg-view-apple-element() {
    use.apple-element {
        @content;
    }
}

@mixin svg-view-apple-follow-dims() {
    width: 18px;
    height: 20px;
}

@mixin svg-view-apple-follow() {
    @include svg-view;
    background-position: 77.61% 63.95%;
    @include svg-view-apple-follow-dims;
}

@mixin svg-view-apple-follow-element() {
    use.apple-follow-element {
        @content;
    }
}

@mixin svg-view-apple-follow-white-dims() {
    width: 12px;
    height: 14px;
}

@mixin svg-view-apple-follow-white() {
    @include svg-view;
    background-position: 99.7% 55.7%;
    @include svg-view-apple-follow-white-dims;
}

@mixin svg-view-apple-follow-white-element() {
    use.apple-follow-white-element {
        @content;
    }
}

@mixin svg-view-arrow-next-dims() {
    width: 6px;
    height: 10px;
}

@mixin svg-view-arrow-next() {
    @include svg-view;
    background-position: 97.44% 83.33%;
    @include svg-view-arrow-next-dims;
}

@mixin svg-view-arrow-next-element() {
    use.arrow-next-element {
        @content;
    }
}

@mixin svg-view-arrow-next-white-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-arrow-next-white() {
    @include svg-view;
    background-position: 97.56% 48.67%;
    @include svg-view-arrow-next-white-dims;
}

@mixin svg-view-arrow-next-white-element() {
    use.arrow-next-white-element {
        @content;
    }
}

@mixin svg-view-arrow-prev-dims() {
    width: 6px;
    height: 10px;
}

@mixin svg-view-arrow-prev() {
    @include svg-view;
    background-position: 98.34% 83.33%;
    @include svg-view-arrow-prev-dims;
}

@mixin svg-view-arrow-prev-element() {
    use.arrow-prev-element {
        @content;
    }
}

@mixin svg-view-arrow-prev-white-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-arrow-prev-white() {
    @include svg-view;
    background-position: 95.27% 31.91%;
    @include svg-view-arrow-prev-white-dims;
}

@mixin svg-view-arrow-prev-white-element() {
    use.arrow-prev-white-element {
        @content;
    }
}

@mixin svg-view-arrow-right-dims() {
    width: 16px;
    height: 12px;
}

@mixin svg-view-arrow-right() {
    @include svg-view;
    background-position: 99.39% 27.84%;
    @include svg-view-arrow-right-dims;
}

@mixin svg-view-arrow-right-element() {
    use.arrow-right-element {
        @content;
    }
}

@mixin svg-view-arrow-right-white-dims() {
    width: 16px;
    height: 12px;
}

@mixin svg-view-arrow-right-white() {
    @include svg-view;
    background-position: 96.94% 27.84%;
    @include svg-view-arrow-right-white-dims;
}

@mixin svg-view-arrow-right-white-element() {
    use.arrow-right-white-element {
        @content;
    }
}

@mixin svg-view-audio-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-audio() {
    @include svg-view;
    background-position: 77.85% 69.21%;
    @include svg-view-audio-dims;
}

@mixin svg-view-audio-element() {
    use.audio-element {
        @content;
    }
}

@mixin svg-view-audio-icon-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-audio-icon() {
    @include svg-view;
    background-position: 79.54% 76.05%;
    @include svg-view-audio-icon-dims;
}

@mixin svg-view-audio-icon-element() {
    use.audio-icon-element {
        @content;
    }
}

@mixin svg-view-awmh-dims() {
    width: 24px;
    height: 12px;
}

@mixin svg-view-awmh() {
    @include svg-view;
    background-position: 94.43% 27.84%;
    @include svg-view-awmh-dims;
}

@mixin svg-view-awmh-element() {
    use.awmh-element {
        @content;
    }
}

@mixin svg-view-awmh-white-dims() {
    width: 24px;
    height: 12px;
}

@mixin svg-view-awmh-white() {
    @include svg-view;
    background-position: 98.76% 23.71%;
    @include svg-view-awmh-white-dims;
}

@mixin svg-view-awmh-white-element() {
    use.awmh-white-element {
        @content;
    }
}

@mixin svg-view-breadcrumb-dims() {
    width: 5px;
    height: 8px;
}

@mixin svg-view-breadcrumb() {
    @include svg-view;
    background-position: 97.14% 52.81%;
    @include svg-view-breadcrumb-dims;
}

@mixin svg-view-breadcrumb-element() {
    use.breadcrumb-element {
        @content;
    }
}

@mixin svg-view-btn_youtube-dims() {
    width: 132px;
    height: 92px;
}

@mixin svg-view-btn_youtube() {
    @include svg-view;
    background-position: 74.35% 49.03%;
    @include svg-view-btn_youtube-dims;
}

@mixin svg-view-btn_youtube-element() {
    use.btn_youtube-element {
        @content;
    }
}

@mixin svg-view-btn_youtube_mobile-dims() {
    width: 66px;
    height: 46px;
}

@mixin svg-view-btn_youtube_mobile() {
    @include svg-view;
    background-position: 66.23% 68.64%;
    @include svg-view-btn_youtube_mobile-dims;
}

@mixin svg-view-btn_youtube_mobile-element() {
    use.btn_youtube_mobile-element {
        @content;
    }
}

@mixin svg-view-camera-dims() {
    width: 19px;
    height: 16px;
}

@mixin svg-view-camera() {
    @include svg-view;
    background-position: 98.62% 63.28%;
    @include svg-view-camera-dims;
}

@mixin svg-view-camera-element() {
    use.camera-element {
        @content;
    }
}

@mixin svg-view-cart-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-cart() {
    @include svg-view;
    background-position: 91.28% 80.47%;
    @include svg-view-cart-dims;
}

@mixin svg-view-cart-element() {
    use.cart-element {
        @content;
    }
}

@mixin svg-view-cart-white-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-cart-white() {
    @include svg-view;
    background-position: 81.5% 80.47%;
    @include svg-view-cart-white-dims;
}

@mixin svg-view-cart-white-element() {
    use.cart-white-element {
        @content;
    }
}

@mixin svg-view-check-dims() {
    width: 8px;
    height: 6px;
}

@mixin svg-view-check() {
    @include svg-view;
    background-position: 91.99% 36.8%;
    @include svg-view-check-dims;
}

@mixin svg-view-check-element() {
    use.check-element {
        @content;
    }
}

@mixin svg-view-checkbox-check-dims() {
    width: 12px;
    height: 9px;
}

@mixin svg-view-checkbox-check() {
    @include svg-view;
    background-position: 98.63% 79.03%;
    @include svg-view-checkbox-check-dims;
}

@mixin svg-view-checkbox-check-element() {
    use.checkbox-check-element {
        @content;
    }
}

@mixin svg-view-checkbox-rollover-graphic-dims() {
    width: 22px;
    height: 22px;
}

@mixin svg-view-checkbox-rollover-graphic() {
    @include svg-view;
    background-position: 82.1% 56.88%;
    @include svg-view-checkbox-rollover-graphic-dims;
}

@mixin svg-view-checkbox-rollover-graphic-element() {
    use.checkbox-rollover-graphic-element {
        @content;
    }
}

@mixin svg-view-circle-ellipse-white-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-circle-ellipse-white() {
    @include svg-view;
    background-position: 74.77% 63.95%;
    @include svg-view-circle-ellipse-white-dims;
}

@mixin svg-view-circle-ellipse-white-element() {
    use.circle-ellipse-white-element {
        @content;
    }
}

@mixin svg-view-circle-open-white-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-circle-open-white() {
    @include svg-view;
    background-position: 74.77% 69.21%;
    @include svg-view-circle-open-white-dims;
}

@mixin svg-view-circle-open-white-element() {
    use.circle-open-white-element {
        @content;
    }
}

@mixin svg-view-close-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-close() {
    @include svg-view;
    background-position: 60.79% 90.98%;
    @include svg-view-close-dims;
}

@mixin svg-view-close-element() {
    use.close-element {
        @content;
    }
}

@mixin svg-view-close-gray-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-close-gray() {
    @include svg-view;
    background-position: 62.61% 90.98%;
    @include svg-view-close-gray-dims;
}

@mixin svg-view-close-gray-element() {
    use.close-gray-element {
        @content;
    }
}

@mixin svg-view-close-white-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-close-white() {
    @include svg-view;
    background-position: 64.44% 90.98%;
    @include svg-view-close-white-dims;
}

@mixin svg-view-close-white-element() {
    use.close-white-element {
        @content;
    }
}

@mixin svg-view-close-white-v2-dims() {
    width: 18px;
    height: 18px;
}

@mixin svg-view-close-white-v2() {
    @include svg-view;
    background-position: 93.71% 75.65%;
    @include svg-view-close-white-v2-dims;
}

@mixin svg-view-close-white-v2-element() {
    use.close-white-v2-element {
        @content;
    }
}

@mixin svg-view-dialog-close-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-dialog-close() {
    @include svg-view;
    background-position: 71.69% 63.95%;
    @include svg-view-dialog-close-dims;
}

@mixin svg-view-dialog-close-element() {
    use.dialog-close-element {
        @content;
    }
}

@mixin svg-view-divider-dims() {
    width: 2px;
    height: 25px;
}

@mixin svg-view-divider() {
    @include svg-view;
    background-position: 91.17% 32%;
    @include svg-view-divider-dims;
}

@mixin svg-view-divider-element() {
    use.divider-element {
        @content;
    }
}

@mixin svg-view-empty-star-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-empty-star() {
    @include svg-view;
    background-position: 68.09% 90.98%;
    @include svg-view-empty-star-dims;
}

@mixin svg-view-empty-star-element() {
    use.empty-star-element {
        @content;
    }
}

@mixin svg-view-facebook-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-facebook() {
    @include svg-view;
    background-position: 80.62% 63.95%;
    @include svg-view-facebook-dims;
}

@mixin svg-view-facebook-element() {
    use.facebook-element {
        @content;
    }
}

@mixin svg-view-facebook-follow-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-facebook-follow() {
    @include svg-view;
    background-position: 83.69% 63.95%;
    @include svg-view-facebook-follow-dims;
}

@mixin svg-view-facebook-follow-element() {
    use.facebook-follow-element {
        @content;
    }
}

@mixin svg-view-facebook-follow-white-dims() {
    width: 6px;
    height: 12px;
}

@mixin svg-view-facebook-follow-white() {
    @include svg-view;
    background-position: 99.7% 23.71%;
    @include svg-view-facebook-follow-white-dims;
}

@mixin svg-view-facebook-follow-white-element() {
    use.facebook-follow-white-element {
        @content;
    }
}

@mixin svg-view-fifth-member-dims() {
    width: 20px;
    height: 24px;
}

@mixin svg-view-fifth-member() {
    @include svg-view;
    background-position: 95.38% 48.67%;
    @include svg-view-fifth-member-dims;
}

@mixin svg-view-fifth-member-element() {
    use.fifth-member-element {
        @content;
    }
}

@mixin svg-view-fifth-member-all-white-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-fifth-member-all-white() {
    @include svg-view;
    background-position: 89.54% 63.95%;
    @include svg-view-fifth-member-all-white-dims;
}

@mixin svg-view-fifth-member-all-white-element() {
    use.fifth-member-all-white-element {
        @content;
    }
}

@mixin svg-view-fifth-member-white-dims() {
    width: 20px;
    height: 24px;
}

@mixin svg-view-fifth-member-white() {
    @include svg-view;
    background-position: 99.85% 40.16%;
    @include svg-view-fifth-member-white-dims;
}

@mixin svg-view-fifth-member-white-element() {
    use.fifth-member-white-element {
        @content;
    }
}

@mixin svg-view-first-dims() {
    width: 12px;
    height: 10px;
}

@mixin svg-view-first() {
    @include svg-view;
    background-position: 96.5% 83.33%;
    @include svg-view-first-dims;
}

@mixin svg-view-first-element() {
    use.first-element {
        @content;
    }
}

@mixin svg-view-full-star-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-full-star() {
    @include svg-view;
    background-position: 89.51% 83.76%;
    @include svg-view-full-star-dims;
}

@mixin svg-view-full-star-element() {
    use.full-star-element {
        @content;
    }
}

@mixin svg-view-grid-dims() {
    width: 18px;
    height: 18px;
}

@mixin svg-view-grid() {
    @include svg-view;
    background-position: 90.95% 75.65%;
    @include svg-view-grid-dims;
}

@mixin svg-view-grid-element() {
    use.grid-element {
        @content;
    }
}

@mixin svg-view-grid-view-dims() {
    width: 88px;
    height: 32px;
}

@mixin svg-view-grid-view() {
    @include svg-view;
    background-position: 91.41% 49.73%;
    @include svg-view-grid-view-dims;
}

@mixin svg-view-grid-view-element() {
    use.grid-view-element {
        @content;
    }
}

@mixin svg-view-half-full-star-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-half-full-star() {
    @include svg-view;
    background-position: 73.56% 90.98%;
    @include svg-view-half-full-star-dims;
}

@mixin svg-view-half-full-star-element() {
    use.half-full-star-element {
        @content;
    }
}

@mixin svg-view-half-star-dims() {
    width: 6px;
    height: 12px;
}

@mixin svg-view-half-star() {
    @include svg-view;
    background-position: 99.85% 67.78%;
    @include svg-view-half-star-dims;
}

@mixin svg-view-half-star-element() {
    use.half-star-element {
        @content;
    }
}

@mixin svg-view-icon-bag-dims() {
    width: 16px;
    height: 18px;
}

@mixin svg-view-icon-bag() {
    @include svg-view;
    background-position: 96.18% 75.65%;
    @include svg-view-icon-bag-dims;
}

@mixin svg-view-icon-bag-element() {
    use.icon-bag-element {
        @content;
    }
}

@mixin svg-view-icon-collapse-dims() {
    width: 14px;
    height: 8px;
}

@mixin svg-view-icon-collapse() {
    @include svg-view;
    background-position: 96.65% 44.64%;
    @include svg-view-icon-collapse-dims;
}

@mixin svg-view-icon-collapse-element() {
    use.icon-collapse-element {
        @content;
    }
}

@mixin svg-view-icon-expand-dims() {
    width: 14px;
    height: 8px;
}

@mixin svg-view-icon-expand() {
    @include svg-view;
    background-position: 98.78% 44.64%;
    @include svg-view-icon-expand-dims;
}

@mixin svg-view-icon-expand-element() {
    use.icon-expand-element {
        @content;
    }
}

@mixin svg-view-icon-plus-dims() {
    width: 8px;
    height: 8px;
}

@mixin svg-view-icon-plus() {
    @include svg-view;
    background-position: 100% 44.64%;
    @include svg-view-icon-plus-dims;
}

@mixin svg-view-icon-plus-element() {
    use.icon-plus-element {
        @content;
    }
}

@mixin svg-view-input-error-dims() {
    width: 18px;
    height: 18px;
}

@mixin svg-view-input-error() {
    @include svg-view;
    background-position: 88.19% 75.65%;
    @include svg-view-input-error-dims;
}

@mixin svg-view-input-error-element() {
    use.input-error-element {
        @content;
    }
}

@mixin svg-view-input-success-dims() {
    width: 14px;
    height: 10px;
}

@mixin svg-view-input-success() {
    @include svg-view;
    background-position: 96.8% 79.23%;
    @include svg-view-input-success-dims;
}

@mixin svg-view-input-success-element() {
    use.input-success-element {
        @content;
    }
}

@mixin svg-view-instagram-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-instagram() {
    @include svg-view;
    background-position: 80.92% 69.21%;
    @include svg-view-instagram-dims;
}

@mixin svg-view-instagram-element() {
    use.instagram-element {
        @content;
    }
}

@mixin svg-view-instagram-follow-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-instagram-follow() {
    @include svg-view;
    background-position: 84% 69.21%;
    @include svg-view-instagram-follow-dims;
}

@mixin svg-view-instagram-follow-element() {
    use.instagram-follow-element {
        @content;
    }
}

@mixin svg-view-instagram-follow-white-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-instagram-follow-white() {
    @include svg-view;
    background-position: 66.26% 90.98%;
    @include svg-view-instagram-follow-white-dims;
}

@mixin svg-view-instagram-follow-white-element() {
    use.instagram-follow-white-element {
        @content;
    }
}

@mixin svg-view-last-dims() {
    width: 12px;
    height: 10px;
}

@mixin svg-view-last() {
    @include svg-view;
    background-position: 94.68% 83.33%;
    @include svg-view-last-dims;
}

@mixin svg-view-last-element() {
    use.last-element {
        @content;
    }
}

@mixin svg-view-linkedin-follow-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-linkedin-follow() {
    @include svg-view;
    background-position: 86.39% 80.47%;
    @include svg-view-linkedin-follow-dims;
}

@mixin svg-view-linkedin-follow-element() {
    use.linkedin-follow-element {
        @content;
    }
}

@mixin svg-view-list-dims() {
    width: 18px;
    height: 18px;
}

@mixin svg-view-list() {
    @include svg-view;
    background-position: 85.43% 75.65%;
    @include svg-view-list-dims;
}

@mixin svg-view-list-element() {
    use.list-element {
        @content;
    }
}

@mixin svg-view-list-view-dims() {
    width: 88px;
    height: 32px;
}

@mixin svg-view-list-view() {
    @include svg-view;
    background-position: 91.41% 41.03%;
    @include svg-view-list-view-dims;
}

@mixin svg-view-list-view-element() {
    use.list-view-element {
        @content;
    }
}

@mixin svg-view-location-marker-dims() {
    width: 10px;
    height: 14px;
}

@mixin svg-view-location-marker() {
    @include svg-view;
    background-position: 97.88% 55.7%;
    @include svg-view-location-marker-dims;
}

@mixin svg-view-location-marker-element() {
    use.location-marker-element {
        @content;
    }
}

@mixin svg-view-logo-dims() {
    width: 270px;
    height: 92px;
}

@mixin svg-view-logo() {
    @include svg-view;
    background-position: 100% 0;
    @include svg-view-logo-dims;
}

@mixin svg-view-logo-element() {
    use.logo-element {
        @content;
    }
}

@mixin svg-view-lyrics-dims() {
    width: 12px;
    height: 16px;
}

@mixin svg-view-lyrics() {
    @include svg-view;
    background-position: 91.64% 68.49%;
    @include svg-view-lyrics-dims;
}

@mixin svg-view-lyrics-element() {
    use.lyrics-element {
        @content;
    }
}

@mixin svg-view-m-logo-dims() {
    width: 400px;
    height: 400px;
}

@mixin svg-view-m-logo() {
    @include svg-view;
    background-position: 0 0;
    @include svg-view-m-logo-dims;
}

@mixin svg-view-m-logo-element() {
    use.m-logo-element {
        @content;
    }
}

@mixin svg-view-mag-minus-gray-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-mag-minus-gray() {
    @include svg-view;
    background-position: 94.04% 68.49%;
    @include svg-view-mag-minus-gray-dims;
}

@mixin svg-view-mag-minus-gray-element() {
    use.mag-minus-gray-element {
        @content;
    }
}

@mixin svg-view-mag-minus-white-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-mag-minus-white() {
    @include svg-view;
    background-position: 96.48% 68.49%;
    @include svg-view-mag-minus-white-dims;
}

@mixin svg-view-mag-minus-white-element() {
    use.mag-minus-white-element {
        @content;
    }
}

@mixin svg-view-mag-plus-gray-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-mag-plus-gray() {
    @include svg-view;
    background-position: 98.93% 68.49%;
    @include svg-view-mag-plus-gray-dims;
}

@mixin svg-view-mag-plus-gray-element() {
    use.mag-plus-gray-element {
        @content;
    }
}

@mixin svg-view-mag-plus-white-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-mag-plus-white() {
    @include svg-view;
    background-position: 98.62% 75.26%;
    @include svg-view-mag-plus-white-dims;
}

@mixin svg-view-mag-plus-white-element() {
    use.mag-plus-white-element {
        @content;
    }
}

@mixin svg-view-mail-dims() {
    width: 20px;
    height: 16px;
}

@mixin svg-view-mail() {
    @include svg-view;
    background-position: 95.69% 63.28%;
    @include svg-view-mail-dims;
}

@mixin svg-view-mail-element() {
    use.mail-element {
        @content;
    }
}

@mixin svg-view-media-carousel-next-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-media-carousel-next() {
    @include svg-view;
    background-position: 97.41% 31.91%;
    @include svg-view-media-carousel-next-dims;
}

@mixin svg-view-media-carousel-next-element() {
    use.media-carousel-next-element {
        @content;
    }
}

@mixin svg-view-media-carousel-prev-dims() {
    width: 14px;
    height: 24px;
}

@mixin svg-view-media-carousel-prev() {
    @include svg-view;
    background-position: 93.14% 31.91%;
    @include svg-view-media-carousel-prev-dims;
}

@mixin svg-view-media-carousel-prev-element() {
    use.media-carousel-prev-element {
        @content;
    }
}

@mixin svg-view-media-play-button-white-dims() {
    width: 64px;
    height: 64px;
}

@mixin svg-view-media-play-button-white() {
    @include svg-view;
    background-position: 66.01% 86.01%;
    @include svg-view-media-play-button-white-dims;
}

@mixin svg-view-media-play-button-white-element() {
    use.media-play-button-white-element {
        @content;
    }
}

@mixin svg-view-menu-dims() {
    width: 16px;
    height: 14px;
}

@mixin svg-view-menu() {
    @include svg-view;
    background-position: 93.88% 55.7%;
    @include svg-view-menu-dims;
}

@mixin svg-view-menu-element() {
    use.menu-element {
        @content;
    }
}

@mixin svg-view-menu-back-dims() {
    width: 8px;
    height: 14px;
}

@mixin svg-view-menu-back() {
    @include svg-view;
    background-position: 99.85% 62.95%;
    @include svg-view-menu-back-dims;
}

@mixin svg-view-menu-back-element() {
    use.menu-back-element {
        @content;
    }
}

@mixin svg-view-menu-carrot-dims() {
    width: 8px;
    height: 14px;
}

@mixin svg-view-menu-carrot() {
    @include svg-view;
    background-position: 99.85% 74.87%;
    @include svg-view-menu-carrot-dims;
}

@mixin svg-view-menu-carrot-element() {
    use.menu-carrot-element {
        @content;
    }
}

@mixin svg-view-menu-search-dims() {
    width: 28px;
    height: 16px;
}

@mixin svg-view-menu-search() {
    @include svg-view;
    background-position: 95.02% 23.96%;
    @include svg-view-menu-search-dims;
}

@mixin svg-view-menu-search-element() {
    use.menu-search-element {
        @content;
    }
}

@mixin svg-view-menu-white-dims() {
    width: 16px;
    height: 14px;
}

@mixin svg-view-menu-white() {
    @include svg-view;
    background-position: 96.33% 55.7%;
    @include svg-view-menu-white-dims;
}

@mixin svg-view-menu-white-element() {
    use.menu-white-element {
        @content;
    }
}

@mixin svg-view-nav-left-dims() {
    width: 8px;
    height: 14px;
}

@mixin svg-view-nav-left() {
    @include svg-view;
    background-position: 92.6% 80.05%;
    @include svg-view-nav-left-dims;
}

@mixin svg-view-nav-left-element() {
    use.nav-left-element {
        @content;
    }
}

@mixin svg-view-navigate-left-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-navigate-left() {
    @include svg-view;
    background-position: 98.35% 52.67%;
    @include svg-view-navigate-left-dims;
}

@mixin svg-view-navigate-left-element() {
    use.navigate-left-element {
        @content;
    }
}

@mixin svg-view-navigate-left-medium-dims() {
    width: 7px;
    height: 11px;
}

@mixin svg-view-navigate-left-medium() {
    @include svg-view;
    background-position: 75.72% 90.75%;
    @include svg-view-navigate-left-medium-dims;
}

@mixin svg-view-navigate-left-medium-element() {
    use.navigate-left-medium-element {
        @content;
    }
}

@mixin svg-view-navigate-right-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-navigate-right() {
    @include svg-view;
    background-position: 97.75% 52.67%;
    @include svg-view-navigate-right-dims;
}

@mixin svg-view-navigate-right-element() {
    use.navigate-right-element {
        @content;
    }
}

@mixin svg-view-navigate-right-medium-dims() {
    width: 6px;
    height: 11px;
}

@mixin svg-view-navigate-right-medium() {
    @include svg-view;
    background-position: 74.7% 90.75%;
    @include svg-view-navigate-right-medium-dims;
}

@mixin svg-view-navigate-right-medium-element() {
    use.navigate-right-medium-element {
        @content;
    }
}

@mixin svg-view-pause-dims() {
    width: 12px;
    height: 14px;
}

@mixin svg-view-pause() {
    @include svg-view;
    background-position: 82.83% 84.2%;
    @include svg-view-pause-dims;
}

@mixin svg-view-pause-element() {
    use.pause-element {
        @content;
    }
}

@mixin svg-view-pause-white-dims() {
    width: 12px;
    height: 14px;
}

@mixin svg-view-pause-white() {
    @include svg-view;
    background-position: 84.65% 84.2%;
    @include svg-view-pause-white-dims;
}

@mixin svg-view-pause-white-element() {
    use.pause-white-element {
        @content;
    }
}

@mixin svg-view-pinterest-follow-white-dims() {
    width: 10px;
    height: 12px;
}

@mixin svg-view-pinterest-follow-white() {
    @include svg-view;
    background-position: 91.06% 83.76%;
    @include svg-view-pinterest-follow-white-dims;
}

@mixin svg-view-pinterest-follow-white-element() {
    use.pinterest-follow-white-element {
        @content;
    }
}

@mixin svg-view-play-dims() {
    width: 10px;
    height: 14px;
}

@mixin svg-view-play() {
    @include svg-view;
    background-position: 87.73% 84.2%;
    @include svg-view-play-dims;
}

@mixin svg-view-play-element() {
    use.play-element {
        @content;
    }
}

@mixin svg-view-play-white-dims() {
    width: 10px;
    height: 14px;
}

@mixin svg-view-play-white() {
    @include svg-view;
    background-position: 86.21% 84.2%;
    @include svg-view-play-white-dims;
}

@mixin svg-view-play-white-element() {
    use.play-white-element {
        @content;
    }
}

@mixin svg-view-rewind-gray-dims() {
    width: 14px;
    height: 14px;
}

@mixin svg-view-rewind-gray() {
    @include svg-view;
    background-position: 80.95% 84.2%;
    @include svg-view-rewind-gray-dims;
}

@mixin svg-view-rewind-gray-element() {
    use.rewind-gray-element {
        @content;
    }
}

@mixin svg-view-rewind-white-dims() {
    width: 14px;
    height: 14px;
}

@mixin svg-view-rewind-white() {
    @include svg-view;
    background-position: 78.81% 84.2%;
    @include svg-view-rewind-white-dims;
}

@mixin svg-view-rewind-white-element() {
    use.rewind-white-element {
        @content;
    }
}

@mixin svg-view-search-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-search() {
    @include svg-view;
    background-position: 83.94% 80.47%;
    @include svg-view-search-dims;
}

@mixin svg-view-search-element() {
    use.search-element {
        @content;
    }
}

@mixin svg-view-search-white-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-search-white() {
    @include svg-view;
    background-position: 88.84% 80.47%;
    @include svg-view-search-white-dims;
}

@mixin svg-view-search-white-element() {
    use.search-white-element {
        @content;
    }
}

@mixin svg-view-slider-next-dims() {
    width: 15px;
    height: 24px;
}

@mixin svg-view-slider-next() {
    @include svg-view;
    background-position: 99.69% 31.91%;
    @include svg-view-slider-next-dims;
}

@mixin svg-view-slider-next-element() {
    use.slider-next-element {
        @content;
    }
}

@mixin svg-view-slider-next-timeline-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-slider-next-timeline() {
    @include svg-view;
    background-position: 100% 27.48%;
    @include svg-view-slider-next-timeline-dims;
}

@mixin svg-view-slider-next-timeline-element() {
    use.slider-next-timeline-element {
        @content;
    }
}

@mixin svg-view-slider-next-white-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-slider-next-white() {
    @include svg-view;
    background-position: 99.25% 78.63%;
    @include svg-view-slider-next-white-dims;
}

@mixin svg-view-slider-next-white-element() {
    use.slider-next-white-element {
        @content;
    }
}

@mixin svg-view-slider-prev-dims() {
    width: 15px;
    height: 24px;
}

@mixin svg-view-slider-prev() {
    @include svg-view;
    background-position: 94.66% 40.16%;
    @include svg-view-slider-prev-dims;
}

@mixin svg-view-slider-prev-element() {
    use.slider-prev-element {
        @content;
    }
}

@mixin svg-view-slider-prev-timeline-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-slider-prev-timeline() {
    @include svg-view;
    background-position: 99.55% 52.67%;
    @include svg-view-slider-prev-timeline-dims;
}

@mixin svg-view-slider-prev-timeline-element() {
    use.slider-prev-timeline-element {
        @content;
    }
}

@mixin svg-view-slider-prev-white-dims() {
    width: 4px;
    height: 7px;
}

@mixin svg-view-slider-prev-white() {
    @include svg-view;
    background-position: 98.95% 52.67%;
    @include svg-view-slider-prev-white-dims;
}

@mixin svg-view-slider-prev-white-element() {
    use.slider-prev-white-element {
        @content;
    }
}

@mixin svg-view-snapchat-follow-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-snapchat-follow() {
    @include svg-view;
    background-position: 69.91% 90.98%;
    @include svg-view-snapchat-follow-dims;
}

@mixin svg-view-snapchat-follow-element() {
    use.snapchat-follow-element {
        @content;
    }
}

@mixin svg-view-snapchat-follow-white-dims() {
    width: 12px;
    height: 12px;
}

@mixin svg-view-snapchat-follow-white() {
    @include svg-view;
    background-position: 71.73% 90.98%;
    @include svg-view-snapchat-follow-white-dims;
}

@mixin svg-view-snapchat-follow-white-element() {
    use.snapchat-follow-white-element {
        @content;
    }
}

@mixin svg-view-soundcloud-follow-dims() {
    width: 14px;
    height: 8px;
}

@mixin svg-view-soundcloud-follow() {
    @include svg-view;
    background-position: 94.51% 52.81%;
    @include svg-view-soundcloud-follow-dims;
}

@mixin svg-view-soundcloud-follow-element() {
    use.soundcloud-follow-element {
        @content;
    }
}

@mixin svg-view-soundcloud-follow-white-dims() {
    width: 14px;
    height: 8px;
}

@mixin svg-view-soundcloud-follow-white() {
    @include svg-view;
    background-position: 94.51% 44.64%;
    @include svg-view-soundcloud-follow-white-dims;
}

@mixin svg-view-soundcloud-follow-white-element() {
    use.soundcloud-follow-white-element {
        @content;
    }
}

@mixin svg-view-spotify-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-spotify() {
    @include svg-view;
    background-position: 71.69% 69.21%;
    @include svg-view-spotify-dims;
}

@mixin svg-view-spotify-element() {
    use.spotify-element {
        @content;
    }
}

@mixin svg-view-spotify-follow-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-spotify-follow() {
    @include svg-view;
    background-position: 92.62% 63.95%;
    @include svg-view-spotify-follow-dims;
}

@mixin svg-view-spotify-follow-element() {
    use.spotify-follow-element {
        @content;
    }
}

@mixin svg-view-spotify-follow-white-dims() {
    width: 14px;
    height: 10px;
}

@mixin svg-view-spotify-follow-white() {
    @include svg-view;
    background-position: 94.66% 79.23%;
    @include svg-view-spotify-follow-white-dims;
}

@mixin svg-view-spotify-follow-white-element() {
    use.spotify-follow-white-element {
        @content;
    }
}

@mixin svg-view-tab-rollover-1-dims() {
    width: 210px;
    height: 28px;
}

@mixin svg-view-tab-rollover-1() {
    @include svg-view;
    background-position: 86.96% 24.73%;
    @include svg-view-tab-rollover-1-dims;
}

@mixin svg-view-tab-rollover-1-element() {
    use.tab-rollover-1-element {
        @content;
    }
}

@mixin svg-view-tab-rollover-2-dims() {
    width: 53px;
    height: 55px;
}

@mixin svg-view-tab-rollover-2() {
    @include svg-view;
    background-position: 75.2% 83.77%;
    @include svg-view-tab-rollover-2-dims;
}

@mixin svg-view-tab-rollover-2-element() {
    use.tab-rollover-2-element {
        @content;
    }
}

@mixin svg-view-tab-rollover-3-dims() {
    width: 209px;
    height: 31px;
}

@mixin svg-view-tab-rollover-3() {
    @include svg-view;
    background-position: 86.77% 32.52%;
    @include svg-view-tab-rollover-3-dims;
}

@mixin svg-view-tab-rollover-3-element() {
    use.tab-rollover-3-element {
        @content;
    }
}

@mixin svg-view-twitter-dims() {
    width: 20px;
    height: 17px;
}

@mixin svg-view-twitter() {
    @include svg-view;
    background-position: 87.08% 68.67%;
    @include svg-view-twitter-dims;
}

@mixin svg-view-twitter-element() {
    use.twitter-element {
        @content;
    }
}

@mixin svg-view-twitter-follow-dims() {
    width: 20px;
    height: 17px;
}

@mixin svg-view-twitter-follow() {
    @include svg-view;
    background-position: 85.23% 56.14%;
    @include svg-view-twitter-follow-dims;
}

@mixin svg-view-twitter-follow-element() {
    use.twitter-follow-element {
        @content;
    }
}

@mixin svg-view-twitter-follow-white-dims() {
    width: 12px;
    height: 10px;
}

@mixin svg-view-twitter-follow-white() {
    @include svg-view;
    background-position: 92.86% 83.33%;
    @include svg-view-twitter-follow-white-dims;
}

@mixin svg-view-twitter-follow-white-element() {
    use.twitter-follow-white-element {
        @content;
    }
}

@mixin svg-view-ul-dash-dims() {
    width: 5px;
    height: 3px;
}

@mixin svg-view-ul-dash() {
    @include svg-view;
    background-position: 95.94% 26.2%;
    @include svg-view-ul-dash-dims;
}

@mixin svg-view-ul-dash-element() {
    use.ul-dash-element {
        @content;
    }
}

@mixin svg-view-ul-disc-dims() {
    width: 4px;
    height: 4px;
}

@mixin svg-view-ul-disc() {
    @include svg-view;
    background-position: 100% 29.04%;
    @include svg-view-ul-disc-dims;
}

@mixin svg-view-ul-disc-element() {
    use.ul-disc-element {
        @content;
    }
}

@mixin svg-view-user-dims() {
    width: 17px;
    height: 17px;
}

@mixin svg-view-user() {
    @include svg-view;
    background-position: 89.74% 68.67%;
    @include svg-view-user-dims;
}

@mixin svg-view-user-element() {
    use.user-element {
        @content;
    }
}

@mixin svg-view-user-white-dims() {
    width: 16px;
    height: 16px;
}

@mixin svg-view-user-white() {
    @include svg-view;
    background-position: 79.05% 80.47%;
    @include svg-view-user-white-dims;
}

@mixin svg-view-user-white-element() {
    use.user-white-element {
        @content;
    }
}

@mixin svg-view-utility-icon-dims() {
    width: 20px;
    height: 20px;
}

@mixin svg-view-utility-icon() {
    @include svg-view;
    background-position: 82.62% 76.05%;
    @include svg-view-utility-icon-dims;
}

@mixin svg-view-utility-icon-element() {
    use.utility-icon-element {
        @content;
    }
}

@mixin svg-view-youtube-dims() {
    width: 20px;
    height: 14px;
}

@mixin svg-view-youtube() {
    @include svg-view;
    background-position: 91.38% 55.7%;
    @include svg-view-youtube-dims;
}

@mixin svg-view-youtube-element() {
    use.youtube-element {
        @content;
    }
}

@mixin svg-view-youtube-follow-dims() {
    width: 20px;
    height: 14px;
}

@mixin svg-view-youtube-follow() {
    @include svg-view;
    background-position: 88.31% 55.7%;
    @include svg-view-youtube-follow-dims;
}

@mixin svg-view-youtube-follow-element() {
    use.youtube-follow-element {
        @content;
    }
}

@mixin svg-view-youtube-follow-white-dims() {
    width: 12px;
    height: 8px;
}

@mixin svg-view-youtube-follow-white() {
    @include svg-view;
    background-position: 96.35% 52.81%;
    @include svg-view-youtube-follow-white-dims;
}

@mixin svg-view-youtube-follow-white-element() {
    use.youtube-follow-white-element {
        @content;
    }
}

