// component class for structure
.c-tracklist {
    background-color: $black;
    background-size: cover;
    color: $white;
    clip-path: $polygon-clip-path;
    padding: 145px $l-small-padding 200px;

    @include breakpoint(medium) {
        clip-path: $polygon-clip-path-md;
        padding-bottom: 260px;
        padding-right: $l-large-padding;
        padding-left: $l-large-padding;
    }

    @include breakpoint(large) {
        clip-path: $polygon-clip-path-lg;
        padding-bottom: 145px;
    }

    * {
        color: inherit;
    }

    &__heading,
    &__body {
        margin-right: auto;
        margin-left: auto;
        max-width: $bp-max-content-width - ($l-large-padding * 2);
        text-transform: uppercase;
    }

    &__heading {
        font-size: $f-size-xs;
        font-weight: $f-weight-semibold;
        line-height: 1.29;
        letter-spacing: 1px;

        @include breakpoint(medium) {
            font-size: 21px;
        }

        &::after {
            content: ":";
            margin-left: -0.25em;
        }
    }

    &__body {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.5px;

        @include breakpoint(medium) {
            font-size: 34px;
        }
    }
}

// scope class for custom attribute content
.s-tracklist {
    ol {
        counter-reset: item;
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        @include breakpoint(large) {
            columns: 2;
        }

        li {
            display: flex;
            margin-bottom: 1em;

            &::before {
                // Removed the dots from the numbers
                content: counter(item) "  ";
                counter-increment: item;
                display: inline-block;
                font-size: $f-size-xs;
                margin-top: 0.35em;
                min-width: 1.75em;

                @include breakpoint(medium) {
                    font-size: 21px;
                    margin-top: 0.5em;
                }
            }
        }
    }
}
