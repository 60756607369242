
.s-do-not-combine-modal {
    margin: 27px;
    max-width: 580px;
    padding: 60px 20px 38px;
    text-align: center;

    * {
        box-sizing: border-box;
    }

    @include breakpoint(small) {
        padding: 60px;
    }

    @include breakpoint(large) {
        max-width: 750px;
    }

    @include bp(max-width, $bp-large) {
        box-sizing: border-box;
        width: 100% !important;
    }


    .ui-widget-header {
        position: absolute;
            top: 0;
            right: 0;
            left: 0;
    }

    .ui-dialog-titlebar-close {
        right: 8px;
        top: 8px;

        &,
        &:hover:not(.disabled):not(:disabled) {
            background-color: transparent;
        }
    }
}

.do-not-combine-modal {
    color: $c-charcoal;
    font-size: $f-size-xs;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;

    @include breakpoint(medium) {
        font-size: $f-size;
    }

    .do-not-combine-header {
        &, h2, h3 {
            color: $black;
            font-family: $f-header;
            font-size: $f-header-h2-size;
            font-weight: bold;
            line-height: 1.13;
            letter-spacing: 0.5px;
            margin-bottom: 10px;

            @include breakpoint(medium) {
                font-size: $f-header-h3-size-md;
                margin-bottom: 20px;
            }
        }
    }

    .do-not-combine-message {
        margin-bottom: 30px;

        @include breakpoint(medium) {
            margin-bottom: 33px;
        }
    }

    .do-not-combine-buttons {
        a {
            color: $white;
            background-color: $black;
            max-width: 384px;
            text-decoration: none;
            width: 100%;

            &:hover:not(.disabled):not(:disabled) {
                color: $black;
                background: $white;
                text-decoration: none;
            }
        }
    }

    &.preorder-modal {
        .do-not-combine-message {
            padding: 3% 5% 0;
            text-align: center;
        }
        .checkbox-acknowledgement {
            justify-content: center;
            margin: 3% 0 0 0;
            .field-wrapper {
                float: none;
                display: flex;
                @include breakpoint(medium) {
                    display: block;
                }
            }
            input[type="checkbox"] {
                @include breakpoint(medium) {
                    position: relative;
                    top: 0;
                }
            }
            label {
                color: #B52532;
                font-weight: $f-weight-bold;
                line-height: normal;
                text-align: center;
            }
        }
    }
}
